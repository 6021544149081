import './TitleAndImage.module.css';
import SectionTitle from '@components/elements/SectionTitle';
import NextImage from '@components/elements/Image';

const SectionTitleAndImage = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      <SectionTitle data={data.section_title} />
      {data?.image &&
        <div>
          <NextImage src={data?.image} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
        </div>
      }
    </div>
  );
};

export default SectionTitleAndImage;
