import HTMLRender from "@components/core/HTMLRender";
import styles from './richEditor.module.css'

const RichEditor = (props) => {
  const data = props.data || props;
  return (
    <div id={data?.section_layout?.section_id} className={`container ${styles.boxStyles}`}>
      <HTMLRender data={data?.content} />
    </div>
  );
};

export default RichEditor;
