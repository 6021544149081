/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import styles from '../AcademyLessonsV2.module.css';
import SectionTitle from '@src/components/elements/SectionTitle';
import { useRegistrationLink } from '@lib/store/hooks/useActiveProductType';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import { Accordion, Select } from '@mantine/core';
import { IoIosArrowDown } from '@react-icons/all-files/io/IoIosArrowDown';
import Arrow from '@src/components/elements/Arrow';
import Link from '@src/components/elements/Link';
import DifficultyLevel from '@src/components/elements/DifficultyLevel';
import Button from '@src/components/elements/Button';
import HTMLRender from '@src/components/core/HTMLRender';
import { useScrollIntoView } from '@mantine/hooks';
import NextImage from '@src/components/elements/Image';

function MobileAcademyLessonsV2({ data }) {
    const [difficulty, setDifficulty] = useState(null)
    const [showMore, setShowMore] = useState({ value: false, index: null })
    const academyParentGroup = data?.academy_categories?.data[0]?.category;
    const registrationLink = useRegistrationLink();
    const { gv } = useGlobalVariable()
    const { scrollIntoView, targetRef } = useScrollIntoView({ offset: 30 });


    const templateStyles = {
        trading: {
            color: '#FF4848',
            borderColor: '#FFAFAF',
            imageSrc: 'https://cdn.capex.com/uploads/trade_hero_bg_1b9c248b2a/trade_hero_bg_1b9c248b2a.png'
        },
        investing: {
            color: '#53266F',
            borderColor: '#ECB6FF',
            imageSrc: 'https://cdn.capex.com/uploads/invest_hero_bg_0f55a91363/invest_hero_bg_0f55a91363.png'
        },
        crypto: {
            color: '#E9FF60',
            borderColor: '#F3FFAB',
            imageSrc: 'https://cdn.capex.com/uploads/crypto_hero_bg_d461a79c2a/crypto_hero_bg_d461a79c2a.png'
        }
    }

    function handleShowMoreButton(index) {
        if (showMore.value && showMore.index === index) {
            setShowMore({ value: false, index: null })
        } else {
            setShowMore({ value: true, index })
        }
    }

    return (
        <div>
            <div className="container">
                <div className={styles?.mobileTitle}>
                    <SectionTitle data={data?.section_title} />
                </div>
                {/* difficulty selector  */}
                <div ref={targetRef} className='flex justify-end w-full pb-lg'>
                    <Select
                        value={difficulty}
                        onChange={setDifficulty}
                        allowDeselect
                        rightSection={<IoIosArrowDown />}
                        placeholder={gv('difficulty')}
                        transitionDuration={300}
                        classNames={{
                            root: styles.selectRoot,
                            input: styles.selectInput,
                            rightSection: styles.selectRightSection,
                            item: styles.selectItem,
                            selected: styles.selectSelectedItem,
                            dropdown: styles.selectDropdown
                        }}
                        data={[
                            { value: 'beginner', label: `${gv('beginner')}` },
                            { value: 'intermediate', label: `${gv('intermediate')}` },
                            { value: 'advanced', label: `${gv('advanced')}` },
                        ]}
                    />
                </div>
            </div>
            <div className='relative rounded-[15px] overflow-hidden bg-[#1C1E21]'>
                {/* background image component */}
                <div className='h-full'>
                    {academyParentGroup == 'crypto' ?
                        <div className='absolute -top-[2rem] -right-[35rem]'>
                            <NextImage
                                width={700}
                                height={700}
                                src="https://cdn.capex.com/uploads/Crypto_X_22710da483/Crypto_X_22710da483.png"
                                priority
                                quality="100"
                                alt="Article Hero"
                            />
                        </div>
                        : academyParentGroup == 'investing' ?
                            <div className='absolute -top-[2rem] -right-[35rem]'>
                                <NextImage
                                    width={700}
                                    height={700}
                                    src="https://cdn.capex.com/uploads/Investing_X_573a4eec36/Investing_X_573a4eec36.png"
                                    priority
                                    quality="100"
                                    alt="Article Hero"
                                />
                            </div> :
                            <div className='absolute -top-[2rem] -right-[35rem]'>
                                <NextImage
                                    width={700}
                                    height={700}
                                    src="https://cdn.capex.com/uploads/Trading_X_f697411072/Trading_X_f697411072.png"
                                    priority
                                    quality="100"
                                    alt="Article Hero"
                                />
                            </div>
                    }
                    <div className='absolute bottom-0 left-0'>
                        <NextImage
                            width={194}
                            height={194}
                            objectFit='cover'
                            src="https://cdn.capex.com/uploads/dots_academy_bg_a355b52d2c/dots_academy_bg_a355b52d2c.png"
                            priority
                            quality="100"
                            alt="Dots"
                        />
                    </div>
                </div>
                <div className='container pt-xl pb-lg relative z-[10]'>
                    <Accordion
                        initialItem={0}
                        icon={<Arrow direction="down" />}
                        iconPosition="right"
                        classNames={{
                            control: styles.AccordionControl,
                            root: styles.AccordionRoot,
                            itemOpened: styles.itemOpened,
                            label: styles.AccordionLabel,
                            item: styles?.AccordionItem,
                            contentInner: styles?.contentInner,
                            icon: styles.borderAccordionIcon,
                            content: styles?.AccordionContent,
                        }}
                        variant="unstyled"
                    >
                        {data?.academy_categories?.data?.map((category, index) => (
                            <Accordion.Item key={index} label={<div onClick={() => scrollIntoView()}>{category.title}</div>}>
                                <div className='flex flex-col'>
                                    <p className="text-[#CCCCCC] pb-lg">{category?.description}</p>
                                    {(category?.academy_subjects
                                        ?.filter(el => difficulty === null || (el?.difficulty_level !== null && el?.difficulty_level?.includes(difficulty)))
                                        ?.slice(0, showMore.value && showMore.index === index ? category?.academy_subjects?.length : 4)
                                        ?.map((lessonCard) => (
                                            <div key={index} className='flex items-start flex-wrap'>
                                                <div key={lessonCard?.id}
                                                    className={`relative w-full ${`bg-[${templateStyles[academyParentGroup].color}] border-[${templateStyles[academyParentGroup].borderColor}]`}  p-md bg-opacity-[16%] cursor-pointer hover:bg-opacity-30 transition-all duration-200 ease-in-out border border-opacity-50 mb-lg rounded-lg`}>
                                                    <Link href={lessonCard?.slug}>
                                                        <a className='h-full w-full top-0 left-0 z-10' />
                                                    </Link>
                                                    <div className='flex flex-col'>
                                                        <div className='flex justify-between items-center mb-2'>
                                                            {/* reading time */}
                                                            {lessonCard?.reading_time && <p className='text-sm text-[#cccccc] mb-0'>
                                                                {lessonCard?.reading_time > 1 ? Math.ceil(lessonCard?.reading_time) : 2} {gv("minutes")}
                                                            </p>}

                                                            {/* difficulty level */}
                                                            {lessonCard?.difficulty_level &&
                                                                <div className='text-white text-sm'>
                                                                    <DifficultyLevel level={lessonCard?.difficulty_level} />
                                                                </div>}
                                                        </div>
                                                        <p className='text-white text-lg font-bold'>
                                                            {lessonCard?.title}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                    <div className='w-full flex justify-center mt-sm mb-md'>
                                        <button
                                            onClick={() => handleShowMoreButton(index)}
                                            type="button"
                                            className='text-white border border-white border-opacity-50 rounded-md py-sm px-lg'>
                                            {showMore.value && showMore.index === index ? gv('show_less') : gv('show_all')}
                                        </button>
                                    </div>
                                </div>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            </div>
            {/* blue box component */}
            {data?.blue_box_text &&
                <div className='flex flex-col justify-center mt-xl container'>
                    <div className={styles.blue_box_text}>
                        <HTMLRender data={data?.blue_box_text} />
                    </div>
                    <div className='w-full mt-xl flex justify-center'>
                        <Button type="secondary" size="lg" label={gv('create_account')} link={registrationLink} />
                    </div>
                </div>}
        </div>
    )
}

export default MobileAcademyLessonsV2