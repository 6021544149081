import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';
import Link from '@components/elements/Link';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './StoxSolutions.module.css';

const StoxSolutions = (props) => {
  const data = props.data || props;

  if (!data) {
    return null;
  } return (
    <>
      <div className="container">
        <SectionTitle data={data?.section_title} />
        {
          data?.stox_sltns_cols.map((col, index) => (
            <div key={index} className='flex flex-col lg:flex-row w-full last:pt-[3.33rem]'>
              <div className={`${styles.infoLeft} ${styles.infoCol}`}>
                <p className='text-dark text-2xl'>{col?.title}</p>
                <div className={styles.content}>
                  <HTMLRender data={col?.content} />
                </div>
                <div className='pt-md'>
                  <Link data={col?.link} />
                </div>
                {col?.disclaimer && 
                  <p className="text-[#737373] pt-lg opacity-60 italic rtl:text-right">{col?.disclaimer}</p>
                }
              </div>
              <div className={`${styles.infoCol} ${styles.onDesktop}`}>
                <div className='text-center max-w-[50%] mx-auto md:max-w-full'>
                  <NextImage src={col?.image} width={col?.image?.data?.attributes?.width} height={col?.image?.data?.attributes?.height} />
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </>
  );
};

export default StoxSolutions;
