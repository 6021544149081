export default function styleMapping(prop) {
    switch (prop) {
      case "dark":
        return "text-dark";
      case "white":
        return "text-white";
      case "top":
        return "items-start";
      case "bottom":
        return "items-end";
      case "middle":
        return "items-middle";
      case "space-between":
        return "items-between";
      case "left":
        return "justify-start";
      case "center":
        return "justify-center";
      case "right":
        return "justify-end";
      case "text-left":
        return "text-left";
      case "text-center":
        return "text-center";
      case "text-right":
        return "text-right";
    }
  }
  