import styles from './ThematixHero.module.css';
import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';

const ThematixHero = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <>
      <div className="relative">
        <div className='container'>
          <div className={`flex rtl:flex-row-reverse w-full ${data?.section_layout?.image?.data && styles.whiteText}`}>
            <div className='ltr:md:w-[20rem] ltr:lg:w-[30rem] rtl:md:w-[20rem] rtl:lg:w-[25rem] w-full flex flex-col'>
              <div className='text-center ltr:md:text-left rtl:md:text-right flex flex-col items-center md:items-start justify-start'>
                <div className="relative max-w-[10rem] md:max-w-[11.5rem]">
                  <NextImage src={data?.image} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
                </div>
                <div className='hidden md:block py-lg'><HTMLRender data={data?.desktop_text} /></div>
                <div className={`block md:hidden ${styles[`mobile-text`]}`}><HTMLRender data={data?.mobile_text} /></div>
                <div className={`pt-[9rem] md:pt-0 ${styles.button}`}>
                  <Button type={data?.button?.type} size={data?.button?.size} arrow={data?.button?.arrow} label={data?.button?.label} link={data?.button?.link} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThematixHero;
