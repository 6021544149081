import './VersionFile.module.css';

const VersionFile = (props) => {
  const data = props.data || props;
  if(!data) return null;
  
  return (
    <>
      <div className='container'>
        VersionFile
      </div>
    </>
  );
};

export default VersionFile;
