
const qs = require('qs');
const memoize = require('lodash/memoize');
// const fetch = require('node-fetch');

function validate(api,route,query){
    if(api == undefined) throw CustomException("Api Field is required");
    if(query == undefined) throw CustomException("Query Field is required");
    if(typeof query !== "object") throw CustomException("Query Field must be an object");
    if(typeof api !== "string") throw CustomException("Api Field must be a string");
    if(route !== undefined && typeof route !== "string") throw CustomException("Route Field must be a string");
}

function CustomException(message) {
    const error = new Error(message);
    return error;
}
const fetchFunc = async (args) =>{
    const data =  await fetch(args);
    return await data.json();
}
const memoizedFetch = memoize(fetchFunc)

const getStrapiRequest = async (path,useMemoization) => {
    const defaultOptions = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    };
    const mergedOptions = {
        method : 'GET',
        ...defaultOptions,
        retry: 3, pause: 1000
    };
    let requestUrl = encodeURI(path);
    if(useMemoization === true){
        return await memoizedFetch(requestUrl, mergedOptions);
    }
    const data = await fetch(requestUrl, mergedOptions);
    return await data.json();
}
class Strapi {
    constructor(options) {
        this.url = process.env.NEXT_PUBLIC_API_STRAPI;
        this.prefix = options.prefix;
        this.axiosOptions = {};
    }
    getPath(api,route){
        if(route != undefined){
            return `${this.url}/${api}/${route}`;        
        }
        return `${this.url}/${this.prefix}/${api}`;
    }
    getQuery(options,api){
        if(typeof options === "string"){
            return options
        }
        return qs.stringify(options,{encode:false}) 
    }
    fullPath(options){
        const path = this.getPath(options.api,options.route);
        const query = this.getQuery(options.query,options.api);
        
        return `${path}?${query}`;
    }
    getLink({api,route,query = {}}) {
        validate(api,route,query);
        const fullPath = this.fullPath({api,route,query});
        return fullPath
    }
    async getCached({api,route,query = {}}) {
        validate(api,route,query);
        const obj = {
            api,
            route,
            query : {
                ...query,
                cache : true
            }
        }
        return await this.get(obj);
    }
    /**
     * @param {Object} config
     * @param {string} config.api Strapi API name
     * @param {string=} config.route Field is optional. Custom Strapi Route (e.g. /${api}/route) where api can be for example pages 
     * @param {Object} config.query Query Object ( populate, locale, filters, etc)
     * @param {*} config.query.populate
     * @param {string} config.query.locale 'en'
     * @param {[string,Date,Number,Generic]} config.query.fields
     * @param {string} config.query.sort
     * @param {Object} config.query.pagination
     * @param {Number} config.query.pagination.limit Limit the number of page returned
     * @param {Number} config.query.pagination.page Number of entries to return	
     * @param {string} config.query.pagination.start Start Value (first entry to return) e.g. 0
     * @param {string} config.query.pagination.end End Value (last entry to return) e.g. 25
     * @param {Object} config.query.filter
     * @param {Object} config.query.filter.field - Field name
     * @param {any} config.query.filter.field.$eq
     * @param {any} config.query.filter.field.$ne
     * @param {Number} config.query.filter.field.$lt Less than
     * @param {Number} config.query.filter.field.$lte Less than or equal
     * @param {Number} config.query.filter.field.$gt Greater than
     * @param {Number} config.query.filter.field.$gte Greater than or equal
     * @param {[any]} config.query.filter.field.$in Array of values
     * @param {[any]} config.query.filter.field.$notIn Array of values
     * @param {[any]} config.query.filter.field.$contains Case Sensitive
     * @param {[any]} config.query.filter.field.$notContains Case Sensitive
     * @param {[any]} config.query.filter.field.$containsi Case Insensitive
     * @param {[any]} config.query.filter.field.$notContainsi Case Insensitive
     * @param {Boolean} config.query.filter.field.$null
     * @param {Boolean} config.query.filter.field.$notNull 
     * @param {any} config.query.filter.field.$between 
     * @param {string} config.query.filter.field.$startsWith
     * @param {string} config.query.filter.field.$endsWith 
     * @param {any} config.query.filter.field.$or 
     * @param {any} config.query.filter.field.$and 
    */
    
    async get({api,route,query = {}}) {
        validate(api,route,query);
        const fullPath = this.fullPath({api,route,query});
        // console.log('fullPath',fullPath);
        const res = await getStrapiRequest(fullPath,query?.cache || false);

        return res;

        
    }
    
}

const strapi = new Strapi({prefix: 'api'});
module.exports = {strapi};