import NextImage from '@components/elements/Image';
import SectionLayout from '@components/elements/SectionLayout';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './ForexGraph.module.css'
import { useRef, useEffect } from 'react';
import { useIntersection } from '@mantine/hooks';
import { useDeviceDetect } from '@utils/hooks';
import HTMLRender from '@components/core/HTMLRender';

const ForexGraph = (props) => {
  const data = props.data || props;
  const device = useDeviceDetect();

  // Mantine Observer Hook to check when the graph is in the client's viewport
  const containerRef = useRef();
  const [ref, observedEntry] = useIntersection({
    root: containerRef.current,
    threshold: 0.5,
  });
  const inView = observedEntry && observedEntry.isIntersecting;
  // End Mantine Observer Hook

  if (!data) {
    return null;
  }

  // function GraphComponent({ graphLineStyles, graphTextStyles, text }) {
  //   return (
  //     <div>
  //       <div className={`${inView ? styles.inview : ''} ${styles.graphLine} ${styles[`${graphLineStyles}`]}`}></div>
  //       <div className={`${inView ? styles.inview : ''} ${styles.graphText} ${styles[`${graphTextStyles}`]}`}>
  //         <p>{text}</p>
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <SectionLayout data={props.section_layout ? props.section_layout : []}>
      <div className="container">
        <SectionTitle data={data?.section_title} />
        <div ref={ref} className='relative'>
          <div className='h-[67vw] md:h-[410px]'>
            <div className='absolute top-auto bottom-0 h-full'>
              <NextImage src={data?.graph_lines_image} width={data?.graph_lines_image?.data?.attributes?.width} height={data?.graph_lines_image?.data?.attributes?.height} objectFit />
            </div>
            <div className='absolute top-auto bottom-0'>
              <NextImage src={data?.graph_lines_image} width={data?.graph_lines_image?.data?.attributes?.width} height={data?.graph_lines_image?.data?.attributes?.height} objectFit />
            </div>
            <div className={`${inView ? styles.inview : ''} ${styles.graph} absolute top-auto bottom-0`}>
              <NextImage src={data?.graph_waves_image} width={data?.graph_waves_image?.data?.attributes?.width} height={`${device == 'mobile' ? '810' : device == 'tablet' ? '600' : '415'}`} />
            </div>
          </div>
          <div className={`${inView ? styles.inview : ''} ${styles.graphLine} ${styles.graphLineOne}`}></div>
          <div className={`${inView ? styles.inview : ''} ${styles.graphText} ${styles.graphTextOne}`}>
            <p>{data?.text_one}</p>
          </div>
          <div className={`${inView ? styles.inview : ''} ${styles.graphLine} ${styles.graphLineTwo}`}></div>
          <div className={`${inView ? styles.inview : ''} ${styles.graphText} ${styles.graphTextTwo}`}>
            <p>{data?.text_two}</p>
          </div>
          <div className={`${inView ? styles.inview : ''} ${styles.graphLine} ${styles.graphLineThree}`}></div>
          <div className={`${inView ? styles.inview : ''} ${styles.graphText} ${styles.graphTextThree}`}>
            <p>{data?.text_three}</p>
          </div>
          <div className={`${inView ? styles.inview : ''} ${styles.graphLine} ${styles.graphLineFour}`}></div>
          <div className={`${inView ? styles.inview : ''} ${styles.graphText} ${styles.graphTextFour}`}>
            <p>{data?.text_four}</p>
          </div>
          <div className={`${inView ? styles.inview : ''} ${styles.graphLine} ${styles.graphLineFive}`}></div>
          <div className={`${inView ? styles.inview : ''} ${styles.graphText} ${styles.graphTextFive}`}>
            <p>{data?.text_five}</p>
          </div>
        </div>
        <div>
          <p className="pt-sm opacity-50"><HTMLRender data={data?.disclaimer} /></p>
        </div>

      </div>
    </SectionLayout>
  );
};

export default ForexGraph;
