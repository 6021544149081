import IconTextColumn from '@components/custom/IconTextColumn';
import SectionLayout from '@components/elements/SectionLayout';
import SectionTitle from '@components/elements/SectionTitle';
import './TitledIconsColumn.module.css'

const TitledIconsColumn = (props) => {
  const data = props.data || props;

  if (!data) {
    return null;
  }
  return (
    <div className="container">
      <SectionTitle data={data?.section_title} />
      <div className={`flex flex-col md:flex-row justify-between text-center ${data?.align_center ? `` : `ltr:md:text-left rtl:md:text-right`}`}>
        {
          data?.icon_text_col.map((column) => (
            <IconTextColumn align={data?.align_center} data={column} length={data?.icon_text_col && data?.icon_text_col?.length} />
          ))
        }
      </div>
    </div>
  );
};

export default TitledIconsColumn;
