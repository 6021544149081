/* eslint-disable arrow-body-style */
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import styles from "../../TradeHomeInstruments.module.css";

const InstrumentsTableHeader = ({ product_type }) => {
  const { gv } = useGlobalVariable();
  return (
    <ul
      className={`${styles.HomeInstrumentRow} relative -mb-sm items-center border-dark py-xs text-sm text-grey lg:m-0 lg:border-b lg:text-base lg:text-dark`}
    >
      <li className="font-semibold">{gv("instrument")}</li>
      {product_type === "CFD" ? (
        <li className="text-center font-semibold">{gv("buy")}</li>
      ) : (
        <li className="text-center font-semibold">{gv("price")}</li>
      )}
      {product_type === "CFD" && <li className="font-semibold text-center">{gv("sell")}</li>}
      <li className={product_type === "CFD" ? `hidden font-semibold lg:flex` : `flex font-semibold`}>
        {gv("24h_change")}
      </li>
      <li className="hidden whitespace-nowrap font-semibold lg:flex">{gv("24h_trend")}</li>
      <li className="hidden font-semibold lg:flex" />
    </ul>
  );
};

export default InstrumentsTableHeader;
