/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useState } from "react";
import Pagination from "@components/elements/Pagination";
import NewsCard from "@components/custom/NewsCard";
import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import { Skeleton } from "@mantine/core";
import NextImage from "@components/elements/Image";
import NextLink from "next/link";
import HTMLRender from "@components/core/HTMLRender";
import Link from "@components/elements/Link";
import get from "lodash/get";
import { GrFacebook } from "@react-icons/all-files/gr/GrFacebook";
import { GrLinkedin } from "@react-icons/all-files/gr/GrLinkedin";
import { GrTwitter } from "@react-icons/all-files/gr/GrTwitter";
import { GrYoutube } from "@react-icons/all-files/gr/GrYoutube";
import { RiInstagramFill } from "@react-icons/all-files/ri/RiInstagramFill";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import chunk from "lodash/chunk";
import styles from "./ArticlesShowcase.module.css";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";
import { useRouter } from "next/router";

// Generate random widths for the skeleton.
function getRandomWidth(min, max) {
  return Math.random() * (max - min) + min;
}

function SocialMediaIcon({ platform }) {
  switch (platform) {
    case "Facebook":
      return <GrFacebook className="text-[2.77rem]" />;
    case "Instagram":
      return <RiInstagramFill className="text-[3.5rem]" />;
    case "LinkedIn":
      return <GrLinkedin className="text-[2.77rem]" />;
    case "Twitter":
      return <GrTwitter className="text-[3.2rem]" />;
    case "YouTube":
      return <GrYoutube className="text-[3.5rem]" />;
    default:
      return null;
  }
}

function SlidedArray(givenArray, isValidating) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {givenArray?.map((featuredArticleCard, index) => (
        <li className={`mb-[3.33rem] w-full last:mb-0 md:max-w-[calc(100%/3-0.5rem)] order-${index + 1}`} key={index}>
          {isValidating ? (
            <>
              <Skeleton height={190} mb="xl" />
              <Skeleton height={8} radius="xl" width={`${getRandomWidth(10, 80)}%`} mb="xl" />
              <Skeleton height={8} mt={6} width={`${getRandomWidth(20, 100)}%`} radius="xl" />
              <Skeleton height={8} mt={6} radius="xl" width={`${getRandomWidth(20, 100)}%`} mb="xl" />
              <Skeleton height={8} mt={6} width={`${getRandomWidth(20, 80)}%`} radius="xl" />
            </>
          ) : (
            <NewsCard data={featuredArticleCard?.attributes} order={index} />
          )}
        </li>
      ))}
    </>
  );
}

function FeaturedArticlePage({ doNotRender = false, data }) {
  const { gv } = useGlobalVariable();
  const paginationProps = useStore((store) => store.paginationProps, shallow);
  const articles = paginationProps?.articles;
  const currentPage = parseInt(get(paginationProps, "pagination_no", 0), 10) + 1;
  const slug = paginationProps?.slug;
  const isValidating = false;
  if (!articles || doNotRender) return null;
  // Chunk article array into a seperate array for each 4 elements
  const chunkedLatestFeaturedArticles = chunk(articles?.data, 4);
  if (chunkedLatestFeaturedArticles.length === 0) return null;

  console.log(`data`, data)

  return (
    <>
      <div className="flex flex-col flex-wrap items-start md:flex-row">
        <ul className="flex w-full flex-wrap justify-between">
          {SlidedArray(chunkedLatestFeaturedArticles[0])}
          {/* Analyst Box */}
          <div
            className={`h-full rounded-lg ${chunkedLatestFeaturedArticles[0] && chunkedLatestFeaturedArticles[0].length < 4 ? `w-full` : `md:w-[65%]`
              } mx-xs ltr:ml-auto rtl:mr-auto order-${chunkedLatestFeaturedArticles[0].length + 2}`}
          >
            <div className={styles.analystBox}>
              <div className="absolute">
                {!data?.analyst_box_bg?.data ? null : (
                  <NextImage
                    src={data?.analyst_box_bg}
                    width={data?.analyst_box_bg?.data?.attributes?.width}
                    height={data?.analyst_box_bg?.data?.attributes?.height}
                  />
                )}
              </div>
              <div className={styles.leftSide}>
                <HTMLRender data={data?.analyst_box_content} />
                <h3 className="pt-sm text-secondary">{data?.analyst?.data[0]?.name}</h3>
                <Link data={data?.analyst_link} />
              </div>
              <div className="">
                <div className="absolute bottom-[-10px] max-w-[37%] ltr:right-0 rtl:left-0 lg:bottom-auto lg:top-0">
                  <NextImage
                    src={data?.analyst?.data[0]?.image_variant_a?.url}
                    width={data?.analyst?.data[0]?.image_variant_a?.width}
                    height={data?.analyst?.data[0]?.image_variant_a?.height}
                  />
                </div>
              </div>
            </div>
          </div>
        </ul>
        <ul className={`flex w-full flex-wrap justify-between ${styles.lastChildOfList}`}>
          {chunkedLatestFeaturedArticles[1] && SlidedArray(chunkedLatestFeaturedArticles[1], isValidating)}
          {/* Social Box */}
          <div
            className={`hidden rounded-lg placeholder:h-full md:block ${chunkedLatestFeaturedArticles[1] && chunkedLatestFeaturedArticles[1].length < 4 ? `w-full` : `md:w-[65%]`
              } mx-xs ltr:mr-auto rtl:ml-auto order-${chunkedLatestFeaturedArticles[1] && chunkedLatestFeaturedArticles[1].length === 4 ? `3` : `5`
              }`}
          >
            <div className="relative rounded-lg bg-lightBorderGrey">
              <div className="pt-xl pb-lg">
                <p className="text-center">{data?.social_box_title}</p>
              </div>
              <div className={`max-w-[28rem] lg:max-w-[30rem] ${styles.line}`} />
              <div className={`max-w-[27.2rem] lg:max-w-[34.2rem] ${styles.media}`}>
                <ul className="flex flex-row items-center justify-around ltr:pl-0 rtl:pr-0 md:justify-between">
                  {data.socials?.data?.map((app, index) => (
                    <li key={index} className="relative mb-0">
                      <div className="relative  w-auto  overflow-hidden rounded text-xl text-[#ccc] hover:text-primary">
                        <NextLink href={`${app?.link}`}>
                          <a
                            target="_blank"
                            className="absolute top-0 z-10 h-full w-full bg-transparent ltr:left-0 rtl:right-0"
                          />
                        </NextLink>
                        <SocialMediaIcon platform={app?.platform} />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.bgText}>
                <p className="font-bold">{gv("follow_us")}</p>
              </div>
            </div>
          </div>
        </ul>
      </div>
      <div className="flex justify-center">
        <Pagination
          changeUrl
          type="primary"
          alignament="center"
          totalPages={articles?.meta?.pagination?.pageCount}
          neighborsSize="1"
          currentPage={currentPage}
          slug={slug}
        />
      </div>
    </>
  );
}

const ArticlesShowcase = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className="container">
      <FeaturedArticlePage data={data} />
      <FeaturedArticlePage doNotRender data={data} />
    </div>
  );
};

export default ArticlesShowcase;
