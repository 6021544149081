import HTMLRender from '@components/core/HTMLRender';
import styles from './Steps.module.css'

const Steps = ({contentPos="center", stepColor="secondary", data=[], index, text}) => {
  if (data.length > 0) {
    return (
      <div key={index} className="hidden md:block">
        <div className="flex w-full justify-between">
          <div
            className={`flex grow basis-1 flex-col ${styles[`position-${contentPos}`]} relative ${
              contentPos === 'center' ? 'px-[0.125rem]' : ''
            }`}
          >
            <div className="mb-md flex w-full items-center justify-center">
              {contentPos === 'left' ? null : (
                <div className={`${styles.dottedBgBorder} ${index === 0 && 'opacity-0'}`}></div>
              )}
              <div
                className={`border-${stepColor} text-${stepColor} z-1 p-md relative flex h-[1.6em] w-[1.6em] items-center justify-center rounded-full border-2 bg-white text-[1.75rem]`}
              >
                {index + 1}
              </div>
              {contentPos === 'right' ? null : (
                <div className={`${styles.dottedBgBorder} ${index === data.length - 1 && 'opacity-0'}`}></div>
              )}
            </div>
            <div className="px-md">
              <HTMLRender data={text} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default Steps;
