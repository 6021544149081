import HTMLRender from '@components/core/HTMLRender';
import styles from './TradingConditions.module.css';

const TradingConditions = (props) => {
  const data = props.data || props;

  if (!data) {
    return null;
  } return (
    <>
      <div className="container">
        <div className='flex w-full items-center mb-lg md:mb-[5.555rem]'>
          <div className='flex-1'>
            <h4 className='text-white text-center md:whitespace-nowrap ltr:pr-md rtl:pl-md'>{data.title}</h4>
          </div>
          <div className='hidden md:block flex-[2] h-[1px] bg-white opacity-30'></div>
        </div>
        <div className='flex flex-col lg:flex-row w-full justify-between items-center flex-wrap mb-lg lg:mb-0'>
          {data.boxes.map((box, index) => (
            <div key={index} className='w-full lg:w-[calc(100%/2-1rem)] py-[1rem] h-full'>
              <div className='text-center ltr:lg:text-left rtl:lg:text-right px-md py-lg lg:pl-[5.55rem] bg-[#7f7f7f] rounded bg-opacity-20 text-white font-bold'>
                {box.box_text}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.terms}>
          <HTMLRender data={data.terms} />
        </div>
        <div className={`${styles.trading_times} mt-xl`}>
          <HTMLRender data={data.trading_times} />
        </div>
      </div>
    </>
  );

};

export default TradingConditions;
