import HTMLRender from '@components/core/HTMLRender';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './TrdCntFeatures.module.css';

const TrdCntFeatures = (props) => {
  const data = props.data || props;
  if (!data) return null;
  
  return (
    <>
      <div className='container'>
        {data?.section_title && <div className={`${styles.title}`}><SectionTitle data={data?.section_title} /></div>}
        <div>
          {data?.features_items.map((item, index) => (
            <div key={index} className="flex flex-col md:flex-row border-b border-b-[#dfdfe7] pt-md first:border-t border-t-[#dfdfe7] last:border-b-0">
              <div className='flex-1 pr-md'>
                <p className='text-secondary pb-md md:pb-0'>{item?.title}</p>
              </div>
              <div className='flex-[2] pb-xl'>
                <HTMLRender data={item?.content} />
              </div>
            </div>
          ))}
        </div>
      </div>
      
    </>
  );
};

export default TrdCntFeatures;
