import HTMLRender from '@components/core/HTMLRender';
import Image from 'next/image';
import styles from './LAndRCountries.module.css';
import NextImage from '@src/components/elements/Image';

const LAndRCountries = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      {data?.content_blocks?.map((block, index) => (
        <div key={index} className='mb-7'>
          <div className='w-full flex items-center justify-between'>
            <div className='md:flex-1'>
              <p className='text-white ltr:pr-md rtl:pl-md'>{block.title}</p>
            </div>
            <div className='flex-grow md:flex-[3]'>
              <div className='h-[1px] bg-white opacity-30 w-full' />
            </div>
          </div>
          <div className="flex flex-col items-center">
            {block?.content_row?.map((row) => (
              <div key={index} className='flex items-center w-full py-md md:py-lg'>
                <div className='hidden md:flex flex-[1] items-center' />
                <div className='flex-[3] flex'>
                  <div className='flex items-center justify-between flex-[2] md:flex-[1] w-full'>
                    <div className='w-[32px]'>
                      <NextImage src={`https://cdn.capex.com/public/assets/flags/${row?.iso}.svg`} width={24} height={18} alt={row?.name} />
                    </div>
                    <p className="w-full ltr:ml-sm rtl:mr-sm text-white">{row?.name}</p>
                  </div>
                  <div className='flex relative items-center flex-[4] md:flex-[4] w-full'>
                    {/* eslint-disable-next-line */}
                    <a className='absolute h-full w-full top-0 left-0' href={row?.url} target="_blank" rel="noreferrer" />
                    <div className={styles.description}>
                      <HTMLRender data={row?.description} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LAndRCountries;
