import SectionTitle from '@src/components/elements/SectionTitle';
import styles from './AcademyHeroV2.module.css';
import Image from 'next/image';
import NextImage from '@src/components/elements/Image';

const AcademyHeroV2 = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='relative overflow-hidden pt-[7rem] pb-[4rem] lg:pt-[10rem] lg:pb-[8rem]'>
      <div className="absolute ltr:left-0 rtl:right-0 -top-2 ltr:lg:left-1/2 rtl:lg:right-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2">
        <div className={`${styles.backgroundImg}`}>
          <NextImage src="https://cdn.capex.com/uploads/Hero_X_516e05b740/Hero_X_516e05b740.png" width={800} height={1000} alt="CAPEX X" />
        </div>
      </div>
      <div className='container relative z-10'>
        <div className={styles.title}>
          <SectionTitle data={data?.section_title} />
        </div>
      </div>
    </div>
  );
};

export default AcademyHeroV2;
