import GenericSlider from "@components/custom/GenericSlider";
import NextImage from "@components/elements/Image";
import instrumentsApi from "@utils/instrumentsApi";
import NextLink from "@components/elements/Link";
import { SwiperSlide } from "swiper/react";
import styles from "./InvestInstrumentsSlider.module.scss";
import { shallow } from "zustand/shallow";
import { useStore } from "@lib/store";
import { useEffect } from "react";

const InvestInstrumentsSlider = (props) => {
  const isIframe = useStore((s) => s.page_key, shallow)?.includes("iframe");

  // send body height to parent
  useEffect(() => {
    if (typeof window !== "undefined" && window && parent) {
      //add listener for resize event OR interval time,  and send the size to parent
      const interval = setInterval(() => {
        parent.postMessage(
          JSON.stringify({
            name: "iframe-invest-slider",
            height: document.querySelector(".invest-instruments-table").scrollHeight,
          }),
          "*"
        );
      }, 250);
      return () => clearInterval(interval);
    }
  }, []);

  const data = props.data || props;
  if (!data) return null;

  // get instruments slugs for the sliding cards
  const instrumentLink = data?.instruments?.data?.map((item) => item?.slug);
  // get instruments icons for the sliding cards
  const instrumentIcon = data?.instruments?.data?.map((item) => item?.icon);
  // insert instrument apis into an array
  const instrumentsList = data?.instruments?.data?.length > 0 && data?.instruments?.data.map((item) => item?.api_id);
  // use the instruments array to make call to instrumentsApi
  const instrumentData = instrumentsApi(instrumentsList, 1000);

  return (
    <div className="invest-instruments-table overflow-hidden">
      <div className=" container">
        <div className="hero-top relative flex">
          {/* left-col */}
          <div className=" relative z-10  flex w-full flex-col pb-sm">
            <div className="heroInsturmentsSlider relative -mx-md mt-sm  lg:mx-0 lg:mt-lg">
              <div className={styles.shadowContainer}>
                <GenericSlider
                  pauseOnMouseEnter
                  grabCursor
                  speed={2600}
                  loop
                  freeMode
                  autoplay
                  autoplayDelay={1}
                  breakpoints={{
                    320: {
                      slidesPerView: 1.6,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2.6,
                      spaceBetween: 50,
                    },
                  }}
                  slidesPerView={2.6}
                  spaceBetween={50}
                >
                  {instrumentsList &&
                    instrumentsList?.map((instrument, index) => {
                      const currentInstrumentObject = instrumentData?.currentData?.[instrument];
                      return (
                        <SwiperSlide key={index}>
                          {/* instrument card */}
                          <div className="relative">
                            <div className={styles.link}>
                              <NextLink blank={isIframe} link={`${instrumentLink[index]}`} />
                            </div>
                            <div
                              className={`flex flex-row items-center rounded-lg py-xs lg:py-md ${styles.customBackground}`}
                            >
                              <div className="relative mx-xs h-[3rem] w-[3rem] rounded-full bg-white  shadow-lg lg:mx-lg">
                                <div
                                  className={`relative top-1/2 flex -translate-y-1/2  justify-center overflow-hidden ${styles.roundedImage}`}
                                >
                                  <NextImage src={instrumentIcon[index]?.url} width={35} height={35} />
                                </div>
                              </div>
                              <div className="flex flex-col justify-center ltr:pl-md rtl:pr-md ltr:lg:pl-0 rtl:lg:pr-0">
                                <p className="mb-0 text-lg font-bold text-black lg:pb-xs">
                                  {currentInstrumentObject?.display}
                                </p>
                                <div className="flex flex-row">
                                  <p className="mb-0">${currentInstrumentObject?.price}</p>
                                  <p
                                    className={`mb-0 px-sm ${
                                      currentInstrumentObject?.change.replace(/[^\d.-]/g, "") >= 0
                                        ? `text-greenCandle`
                                        : `text-primary`
                                    }`}
                                  >
                                    {currentInstrumentObject?.change}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </GenericSlider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestInstrumentsSlider;
