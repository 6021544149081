import Button from "@components/elements/Button";
import NextImage from "@components/elements/Image";
import SectionTitle from "@components/elements/SectionTitle";
import styles from "./TradeHomeStox.module.css";
import { useState, useRef, useEffect } from "react";
import { useIntersection } from "@mantine/hooks";
import HTMLRender from "@components/core/HTMLRender";
import { useStore } from "@lib/store";

const TradeHomeStox = (props) => {
  const data = props.data || props;
  const currentProductTypeKey = useStore((state) => state.currentProductTypeKey);
  const getPageUrl = useStore((state) => state.getPageUrl);
  const stoxUrl = getPageUrl("stox");
  //  Observe when the stox section enters the viewport and start cards animations
  const [isInViewport, setIsInViewport] = useState(false);
  const containerRef = useRef();
  const [ref, observedEntry] = useIntersection({
    root: containerRef.current,
    threshold: 0.7,
  });

  const inView = observedEntry && observedEntry.isIntersecting;

  useEffect(() => {
    if (inView && !isInViewport) {
      setIsInViewport(true);
    }
  }, [inView]);
  //  End observe stox section Entry functions

  if (!data) return null;

  return (
    <div className="container">
      <div className="flex flex-col items-center justify-between lg:flex-row">
        {/* Title & CTA section */}
        <div className="flex w-full flex-col lg:max-w-[calc(100%/2-1rem)]">
          <div className="flex justify-center lg:justify-start">
            <div className="relative max-w-[8.3125rem] lg:max-w-[11.222em]">
              <NextImage
                src={data?.stox_icon}
                width={data?.stox_icon?.data?.attributes?.width}
                height={data?.stox_icon?.data?.attributes?.height}
              />
            </div>
          </div>
          <div className={`my-sm lg:my-8 ${styles.titleStyles}`}>
            <SectionTitle
              data={{ ...data?.section_title, className: "text-center ltr:lg:text-left rtl:lg:text-right" }}
            />
          </div>
          <div className="hidden lg:block">
            <Button data={{ ...data?.button, ...(currentProductTypeKey === "invest" && { link: stoxUrl }) }} />
          </div>
          <div className={`mt-[1rem] hidden text-sm lg:block ${styles.disclaimer}`}>
            <HTMLRender data={data?.disclaimer} />
          </div>
        </div>
        {/* animated presentation section */}
        <div ref={ref} className="mt-lg flex w-full flex-col items-center lg:max-w-[calc(100%/2-1rem)]">
          <div
            className={`relative flex h-full w-full max-w-[15rem] flex-col items-center rounded-2xl lg:min-h-[34.444rem] lg:max-w-[19.444rem] ${styles.gradient_box}`}
          >
            <div
              className={`relative max-w-[70%] -translate-y-[30%] shadow-2xl transition-[top,opacity] duration-[0.5s] ease-in-out ltr:translate-x-[30%] rtl:-translate-x-[30%] lg:max-w-full ${
                isInViewport ? "top-0 opacity-100" : "top-[30rem] opacity-0"
              } `}
            >
              <NextImage
                src={data?.floating_cards?.data[0]}
                width={data?.floating_cards?.data[0].attributes?.width}
                height={data?.floating_cards?.data[0].attributes?.height}
              />
            </div>
            <div
              className={`relative max-w-[70%] transition-[top,opacity] delay-[0.5s] duration-[0.5s] ease-in-out lg:max-w-full ${
                styles.customShadow
              } -translate-y-[7%] overflow-hidden rounded-xl ${
                isInViewport ? "top-0 opacity-100" : "top-[20rem] opacity-0"
              } `}
            >
              <NextImage
                src={data?.floating_cards?.data[1]}
                width={data?.floating_cards?.data[1].attributes?.width}
                height={data?.floating_cards?.data[1].attributes?.height}
              />
            </div>
            <div
              className={`relative max-w-[70%] -translate-y-[45%] overflow-hidden rounded-xl transition-[top,opacity] delay-[1s] duration-[0.5s] ease-in-out ltr:translate-x-[40%] rtl:-translate-x-[40%] lg:max-w-full ${
                styles.customShadow
              } ${isInViewport ? "top-0 opacity-100" : "top-[10rem] opacity-0"} `}
            >
              <NextImage
                src={data?.floating_cards?.data[2]}
                width={data?.floating_cards?.data[2].attributes?.width}
                height={data?.floating_cards?.data[2].attributes?.height}
              />
            </div>
            <div
              className={`relative max-w-[70%] -translate-y-[130%] overflow-hidden rounded-xl shadow-lg transition-[top,opacity] delay-[1.50s] duration-[0.5s] ease-in-out ltr:-translate-x-[40%] rtl:translate-x-[40%] lg:max-w-full ${
                isInViewport ? "top-0 opacity-100" : "top-[5rem] opacity-0"
              }`}
            >
              <NextImage
                src={data?.floating_cards?.data[3]}
                width={data?.floating_cards?.data[3].attributes?.width}
                height={data?.floating_cards?.data[3].attributes?.height}
              />
            </div>
          </div>
        </div>
        {/* mobile button */}
        <div className="mt-xs flex w-full justify-center lg:hidden">
          <Button data={data?.button} />
        </div>
        <div className={`mt-xs flex w-full justify-center text-sm lg:hidden ${styles.disclaimer}`}>
          <HTMLRender data={data?.disclaimer} />
        </div>
      </div>
    </div>
  );
};

export default TradeHomeStox;
