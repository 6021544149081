import styles from "./InstrumentRelatedArticles.module.css";
import SectionTitle from "@components/elements/SectionTitle";
import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import NewsCard from "@components/custom/NewsCard";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { Skeleton } from "@mantine/core";
import GenericSlider from "../GenericSlider";
import { SwiperSlide } from "swiper/react";

const InstrumentRelatedArticles = (props) => {
  const { gv } = useGlobalVariable();
  function getRandomWidth(min, max) {
    return Math.random() * (max - min) + min;
  } // Generate random widths for the skeleton.
  const {
    data: article,
    validating,
    error,
  } = useSwrHook("getArticles", {
    query: {
      populate: {
        image: {
          fields: ["url", "width", "height", "formats"],
        },
      },

      filters: {
        instruments: props?.instrumentId,
      },
      fields: ["title", "slug"],
      sort: ["updatedAt:desc"],
      pagination: {
        limit: 3,
        start: 0,
      },
    },
  });

  const {
    data: articleSubjects,
  } = useSwrHook("getAcademySubjects", {
    query: {
      populate: {
        image: {
          fields: ["url", "width", "height", "formats"],
        },
      },

      filters: {
        instruments: props?.instrumentId,
      },
      fields: ["title", "slug"],
      sort: ["updatedAt:desc"],
      pagination: {
        limit: 3,
        start: 0,
      },
    },
  });

  const allArticles = article?.data.concat(articleSubjects?.data)

  allArticles?.sort((a, b) => new Date(b?.attributes?.updatedAt) - new Date(a?.attributes?.updatedAt));


  if (!allArticles) {
    return null;
  }
  return (
    <div>
      {allArticles && allArticles.length > 0 && (
        <section>
          <div className="container py-xl">
            <SectionTitle title={gv("related_articles")} position="center" />
            <div className="pb-lg">
              <GenericSlider
                loop={allArticles.length > 3}
                navigation
                pagination
                autoplay
                spaceBetween={30}
                slidesPerView={3}
                autoplayDelay={7000}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1025: {
                    slidesPerView: 3,
                  },
                }}
              >
                {allArticles?.map((newsCard, index) => (
                  <SwiperSlide key={index}>
                    <div className="mb-md">
                      {validating ? (
                        <>

                          <Skeleton height={190} mb="xl" />
                          <Skeleton height={8} mt={6} width={`${getRandomWidth(20, 100)}%`} radius="xl" />
                          <Skeleton height={8} mt={6} radius="xl" width={`${getRandomWidth(20, 100)}%`} mb="xl" />
                          <Skeleton height={8} mt={6} width={`${getRandomWidth(20, 80)}%`} radius="xl" />
                        </>
                      ) : (
                        // <p>News Card</p>


                        <>
                          <NewsCard data={{ ...newsCard?.attributes, slugSection: "articles" }} />
                        </>

                      )}
                    </div>
                  </SwiperSlide>
                ))}
              </GenericSlider>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default InstrumentRelatedArticles;
