import HTMLRender from '@components/core/HTMLRender';
import ColoredBox from '@components/elements/ColoredBox';
import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './SafetySteps.module.css';

const SafetySteps = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      <SectionTitle data={data?.section_title} />
      <div className={`${styles.coloredBox}`}>
        <ColoredBox data={data?.coloredBox} />
      </div>
      <div className='flex flex-col md:flex-row justify-between flex-wrap mt-xl'>
        {data?.steps?.map((step, index) => (
          <div key={index} className='w-full md:w-[calc(50%-1.11rem)] bg-white rounded-lg mb-[2rem]'>
            <div className="flex items-center pb-md mb-md border-b-2 border-b-[#ccc]">
              <div className='ltr:mr-sm rtl:ml-sm'>
                <NextImage src={step?.icon} width={step?.icon?.data?.attributes?.width} height={step?.icon?.data?.attributes?.height} />
              </div>
              <div className='font-bold uppercase'>
                {step?.title}
              </div>
            </div>
            <div className='pb-md'>
              <HTMLRender data={step?.content} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SafetySteps;
