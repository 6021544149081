import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import styles from './AffiliatesHero.module.css';

const AffiliatesHero = (props) => {
  const data = props.data || props;
  if (!data) return null;
  return (
    <div className={`${styles[data?.section_layout?.section_id]}`}>
      <div className='container pt-lg lg:pt-0'>
        <div className={`${styles?.content}`}>
          <HTMLRender data={data?.content} />
        </div>
        <div className={`mt-lg  ${styles.button}`}>
          <Button data={data?.button} />
        </div>
        {data?.terms_conditions && <div className={`mt-sm md:mt-2xl ${styles.terms}`}>
          <HTMLRender data={data?.terms_conditions} />
        </div>
        }
      </div>
    </div>
  );
};

export default AffiliatesHero;
