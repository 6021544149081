import useModalHook from "@lib/modals";
import { useStore } from "@lib/store";
import useSetProductType from "@lib/store/hooks/useActiveProductType";
import Link from "@src/components/elements/Link";
import { useEffect, useState } from 'react';

export default function ProductTab({ product, styles, id }) {
  const modal = useModalHook();
  const [isMounted, setIsMounted] = useState(false);
  const { changeProductType } = useSetProductType();
  const currentProductTypeKey = useStore(store => store.currentProductTypeKey);
  const availableOnCurrentLicense = product?.attributes?.availability_status;
  const changeProductTypeAction =
    availableOnCurrentLicense !== "redirect_to_different_license"
      ? () => changeProductType(product.attributes.product_type)
      : () =>
        modal.open("ChangeLicenseProductType", {
          innerProps: {
            product: product.attributes.product_type,
            redirect_to_different_license: product.attributes.redirect_to_different_license,
          },
        });

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Link id={id} href={product?.attributes?.product_homepage?.data?.attributes?.slug} onClick={changeProductTypeAction} className={product.attributes.product_type === currentProductTypeKey && isMounted ? styles.active : ``}>
      <span>{product?.attributes?.website_name}</span>
    </Link>
  );
}
