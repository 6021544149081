import './LicenceModal.module.css';

const LicenceModal = (props) => {
  const name = "LicenceModal";
  return (
    <>
      <div className="text-blue-600">
        Hello, I am {name} component with tailwindcss!
      </div>
    </>
  );
};

export default LicenceModal;
