import { useElementSize } from "@mantine/hooks";

function useHeaderSize() {
  const { ref: headerRef, height: headerHeight } = useElementSize();
  //   const { ref: disclaimersRef, height: disclaimersHeight } = useElementSize();
  return {
    headerRef,
    headerHeight,
    // disclaimersRef, disclaimersHeight
  };
}

export default useHeaderSize;
