import styles from "./MobileMenu.module.css";
import Arrow from "@components/elements/Arrow";
import { useState, useEffect } from "react";
import { Accordion, useAccordionState } from "@mantine/core";
import NextLink from "@components/elements/Link";
import useLocale from "@lib/store/hooks/useLocale";
import { useRouter } from 'next/router';

const MobileMenu = ({ menu, handleMobileMenuClose }) => {
  const [state, handlers] = useAccordionState({ total: 3, initialItem: -1 });

  const router = useRouter();
  useEffect(() => {
    handlers.toggle(-1);
  }, [router.asPath]);
  if (menu) {
    return (
      <div className={`${styles[`mobile-menu`]} text-white`}>
        <Accordion
          iconPosition="right"
          icon={<Arrow direction="down" />}
          classNames={{
            control: styles.control,
            itemOpened: styles.itemOpened,
            label: styles.label,
            item: styles?.item,
            contentInner: styles?.contentInner,
            icon: styles?.icon,
            button: styles?.button,
          }}
          state={state}
          onChange={handlers.setState}
        >
          {menu?.map((item, index) => (
            <Accordion.Item label={item.title} key={index}>
              <ul className="flex flex-wrap justify-between pb-xs">
                {item?.children?.map((menuChildrenItem, menuChildrenItemIndex) => {
                  if (menuChildrenItem.slug) {
                    return (
                      <li
                        key={menuChildrenItemIndex}
                        className={`${item?.children?.length > 6 ? `w-[48%]` : `w-full`}  pb-0`}
                      >
                        <div className="flex flex-col py-xs">
                          {menuChildrenItem.slug ? (
                            <NextLink
                              color="white"
                              link={menuChildrenItem.slug}
                              text={menuChildrenItem.title}
                              onClick={() => handleMobileMenuClose()}
                            />
                          ) : null}

                          <ul>
                            {menuChildrenItem?.children?.map((menuGrandChildrenItem, menuGrandChildrenItemIndex) => (
                              <li
                                key={menuGrandChildrenItemIndex}
                                className="group flex flex-row items-center py-xs pb-xs first:pt-xs last:pb-0"
                              >
                                <NextLink
                                  color="white"
                                  link={menuGrandChildrenItem.slug}
                                  text={menuGrandChildrenItem.title}
                                  onClick={() => handleMobileMenuClose()}
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    );
                  }
                  return (
                    <>
                      {menuChildrenItem.slug ? (
                        <NextLink
                          color="white"
                          link={menuChildrenItem.slug}
                          text={menuChildrenItem.title}
                          onClick={() => handleMobileMenuClose()}
                        />
                      ) : null}

                      {menuChildrenItem?.children?.map((menuGrandChildrenItem, menuGrandChildrenItemIndex) => (
                        <li
                          key={menuGrandChildrenItemIndex}
                          className="group flex w-1/2 flex-row items-center py-xs pb-xs first:pt-xs last:pb-0"
                        >
                          <NextLink
                            color="white"
                            link={menuGrandChildrenItem.slug}
                            text={menuGrandChildrenItem.title}
                            onClick={() => handleMobileMenuClose()}
                          />
                        </li>
                      ))}
                    </>
                  );
                })}
              </ul>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    );
  }
  return null;
};

export default MobileMenu;
