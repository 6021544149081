import HTMLRender from "@components/core/HTMLRender";
import Button from "@components/elements/Button";
import NextImage from "@components/elements/Image";
import useModalHook from "@lib/modals";
import { useStore } from "@lib/store";
import get from "lodash/get";
import { shallow } from "zustand/shallow";
import styles from "./OverviewHeroAnalyst.module.css";

const OverviewHeroAnalyst = (props) => {
  const modal = useModalHook();
  const store = useStore(
    (store) => ({
      page: store.shortName,
      slug: store.slug,
    }),
    shallow
  );

  const data = props.data || props;
  const analyst = get(data, "analysts.data.0", false);
  if (!data) {
    return null;
  }
  return (
    <div className="relative md:pt-[5.55rem]">
      {data?.image?.data && (
        <div
          className={`${styles.image} lg:absolute ${store && store.slug === "/trading/cryptocurrencies"
            ? "top-[15%]"
            : store && store.slug === "/trading/forex"
              ? "-top-[20%]"
              : "top-0"
            } bottom-0 ltr:right-0 rtl:left-0 ${store && store.slug === "/trading/stox"
              ? "ltr:left-[calc(50%+3.33rem)] rtl:right-[calc(50%+3.33rem)]"
              : "ltr:left-[calc(40%+3.33rem)] rtl:right-[calc(40%+3.33rem)]"
            }`}
        >
          <NextImage
            src={data?.image}
            height={data?.image?.data?.attributes?.height}
            width={data?.image?.data?.attributes?.width}
          />
        </div>
      )}
      <div className="container">
        <div className={`flex w-full ${data?.section_layout?.image?.data && styles.whiteText}`}>
          <div className="flex w-full flex-col md:w-[85%] lg:w-[35rem]">
            <div className="flex flex-col justify-start md:mb-xl">
              <h2 className="mb-xl text-[1.666rem]">{data?.title}</h2>
              <div className="hidden md:block">
                <HTMLRender data={data?.content} />
              </div>
            </div>
            <div className="">
              <Button
                arrow="right"
                size="lg"
                label={data?.button_text || ""}
                type="link"
                onClick={() => {
                  if (analyst) {
                    modal.open("analystModal", {
                      innerProps: analyst,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewHeroAnalyst;
