import { memo, useEffect, useRef, useState } from "react";
import styles from "../../../TradeHomeInstruments.module.css";

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const generateNumber = (value) => {
  if (!value) return 0;
  const lastNum = randomIntFromInterval(1, 9);
  const newPrice = value.toString().slice(0, -1) + lastNum;
  return parseFloat(newPrice).toString();
};

export default function InstrumentPrice({ price, isActive, buy, sell }) {
  const isBuy = buy !== undefined;
  const isSell = sell !== undefined;
  let key = "price";

  if (isBuy) key = "buy";
  if (isSell) key = "sell";

  const [state, setState] = useState(price?.[key]);
  const color = useRef("transparent");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 100));
    if (isActive) {
      const numberGenerated = generateNumber(price?.[key]);

      if (numberGenerated < state) {
        color.current = "dump";
      } else if (numberGenerated > state) {
        color.current = "pump";
      } else {
        color.current = "transparent";
      }
      setState(numberGenerated);
      await new Promise(
        (resolve) =>
          // eslint-disable-next-line no-promise-executor-return
          setTimeout(() => {
            color.current = "transparent";
            resolve();
          }),
        150
      );
    } else {
      setState(price?.[key]);
    }
  }, [state, price]);

  if (state === undefined) return "";
  return <span className={`${styles[color.current]} mx-auto`}>${state}</span>;
}
