import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';
import styles from './PartnersDifference.module.css';

const PartnersDifference = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className={`container pb-[15rem] pt-[3rem] ${styles?.strip}`}>
      {/* IB Programs section and cards */}
      <div className='flex flex-col md:flex-row justify-between'>
        <div className={`w-full md:max-w-[30%] ${styles.content}`}>
          <HTMLRender data={data?.section_title?.subtitle} />
        </div>
        <div className='w-full flex flex-col md:flex-row justify-between md:max-w-[60%] md:overflow-y-visible md:first:mt-[10%]'>
          {data?.program_cards.map((card, index) => (
            <div key={index} className="flex flex-col justify-between w-full h-fit md:max-w-[25%] bg-white ltr:md:last:mr-[7rem] rtl:md:last:ml-[7rem] ltr:lg:last:mr-[10rem] rtl:lg:last:ml-[10rem] shadow-lg md:first:mt-[10%] md:last:mt-[20%] rounded-lg my-lg md:my-0 text-center ltr:md:text-left rtl:md:text-right">
              <div className='rounded-lg overflow-hidden'><NextImage src={card?.icon} width={card?.icon?.data?.attributes?.width} height={card?.icon?.data?.attributes?.height} /></div>
              <div className='p-md w-full md:w-[200%] rtl:text-right'>
                <p className='text-dark font-bold text-sm'>{card?.title}</p>
                <div className={styles.programContent}>
                  <HTMLRender data={card?.content} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Marketing portal and LPs cards */}
      <div className='relative flex flex-col md:flex-row justify-between'>
        <div className='flex flex-col w-full md:max-w-[calc(50%-2rem)]'>
          <div>
            <div className={`w-full md:max-w-[75%] ${styles.marketingContent}`}>
              <HTMLRender data={data?.marketing_content} />
            </div>
            <div className='mt-lg'>
              <Button data={data?.marketing_button} />
            </div>
          </div>
          <div className="relative mt-[6rem] max-w-[80%] self-end">
            <div className="absolute z-10 -right-[7%] -top-[8%] border rounded shadow-sm bg-white w-fit p-xs -rotate-90 text-xs uppercase italics">
              <p>{data?.partners_lp[0].lp_tag}</p>
            </div>
            <NextImage src={data?.partners_lp[0].lp_image} width={data?.partners_lp[0].lp_image?.data?.attributes?.width} height={data?.partners_lp[0].lp_image?.data?.attributes?.height} />
            <div className='absolute ltr:-left-[25%] rtl:-right-[25%] top-[70%] p-lg border border-[#c9c9c9] rounded bg-white bg-opacity-90 max-w-[85%] lg:max-w-[70%]'>
              <div className={styles.lpContent}>
                <HTMLRender data={data?.partners_lp[0].content} />
              </div>
              <div className="mt-lg flex justify-start">
                <Button data={data?.lp_button} />
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col w-full md:max-w-[calc(50%-2rem)] mt-[6rem]'>
          <div className="relative mt-[12rem] md:mt-[6rem] max-w-[80%] self-end md:self-start">
            <div className="absolute z-10 -right-[7%] -top-[8%] border rounded shadow-sm bg-white w-fit p-xs -rotate-90 text-xs uppercase italics">
              <p>{data?.partners_lp[1].lp_tag}</p>
            </div>
            <NextImage src={data?.partners_lp[1].lp_image} width={data?.partners_lp[1].lp_image?.data?.attributes?.width} height={data?.partners_lp[1].lp_image?.data?.attributes?.height} />
            <div className='absolute ltr:-left-[25%] rtl:-right-[25%] ltr:md:left-auto rtl:md:right-auto ltr:right-auto rtl:left-auto ltr:md:-right-[25%] rtl:md:-left-[25%] top-[70%] p-lg shadow-lg rounded bg-white max-w-[85%] lg:max-w-[70%]'>
              <div className={styles.lpContent}>
                <HTMLRender data={data?.partners_lp[1].content} />
              </div>
              <div className="mt-lg flex justify-start">
                <Button data={data?.lp_button} />
              </div>
            </div>
          </div>
          <div className="relative mt-[16rem] lg:mt-[11rem] max-w-[80%] self-end">
            <div className="absolute z-10 ltr:left-0 rtl:right- ltr:right-auto rtl:left-auto -top-[15%] border rounded shadow-sm bg-white w-fit p-xs -rotate-90 text-xs uppercase italics">
              <p>{data?.partners_lp[2].lp_tag}</p>
            </div>
            <NextImage src={data?.partners_lp[2].lp_image} width={data?.partners_lp[2].lp_image?.data?.attributes?.width} height={data?.partners_lp[2].lp_image?.data?.attributes?.height} />
            <div className='absolute ltr:-left-[25%] rtl:-right-[25%] top-[70%] p-lg border border-[#c9c9c9] rounded bg-white bg-opacity-90 max-w-[85%] lg:max-w-[70%]'>
              <div className={styles.lpContent}>
                <HTMLRender data={data?.partners_lp[2].content} />
              </div>
              <div className="mt-lg flex justify-start">
                <Button data={data?.lp_button} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnersDifference;
