import useGlobalVariable from '@lib/store/hooks/useGlobalVariables'
import Link from '@src/components/elements/Link';
import React from 'react'
import styles from '../AcademyFeaturedLessons.module.css'

function LatestLinks({ data }) {
    const { gv } = useGlobalVariable()
    const getLastLinks = data?.latest_articles?.data?.slice(1, 5);
    return (
        <>
            <div className='w-full'>
                <p className='text-2xl'>{gv('latest_lessons')}</p>
            </div>
            {getLastLinks?.map((article, index) => (
                <div key={index} className={`w-full max-w-[calc(100%/4)] mx-sm ${styles.link}`}>
                    <Link color="red" href={article?.slug}>
                        <a className='text-darkGrey line-clamp-2'>
                            {article.title}
                        </a>
                    </Link>
                </div>
            ))}
        </>
    )
}

export default LatestLinks