import GenericSlider from "@components/custom/GenericSlider";
import IPOPast from "@components/custom/IPOPast";
import SectionTitle from "@components/elements/SectionTitle";
import { SwiperSlide } from "swiper/react";
import "./IpoPrevious.module.css";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'

const IpoPrevious = (props) => {
  const data = props.data || props;
  dayjs.extend(customParseFormat)

  const updatedArray = data?.ipos?.data?.map(obj => {
    // Check if the current object has the key `ipo_date`
    if ('ipo_date' in obj) {
      // Create a new object with the updated value for `ipo_date`
      return { ...obj, ipo_date: dayjs(obj?.ipo_date, "DD/MM/YYYY").format() !== "Invalid Date" ? dayjs(obj?.ipo_date, "DD/MM/YYYY").format() : obj?.ipo_date };
    }

    // If the key `ipo_date` doesn't exist, return the original object unchanged
    return obj;
  });


  updatedArray.sort((a, b) => {
    const dateA = dayjs(a.ipo_date);
    const dateB = dayjs(b.ipo_date);

    if (dateA.isValid() && dateB.isValid()) {
      return dateB - dateA; // Sort valid dates in ascending order
    }

    if (dateA.isValid()) {
      return -1; // 'a' has a valid date, so it comes before 'b'
    }

    if (dateB.isValid()) {
      return 1; // 'b' has a valid date, so it comes before 'a'
    }

    return 0; // Both 'a' and 'b' have "Invalid Date" value (or other non-valid inputs)
  });



  if (!data) {
    return null;
  }
  return (
    <div className="container">
      <SectionTitle data={data?.section_title} />
      <div className="previousIpoSlider">
        <GenericSlider
          loop
          navigation
          pagination
          autoplay
          slidesPerView={3}
          autoplayDelay={7000}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1025: {
              slidesPerView: 3,
            },
          }}
        >
          {updatedArray?.map((ipo, index) => (
            <SwiperSlide key={index}>
              <IPOPast data={ipo} />
            </SwiperSlide>
          ))}
        </GenericSlider>
      </div>
    </div>
  );
};

export default IpoPrevious;
