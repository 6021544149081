import HTMLRender from "@components/core/HTMLRender";
import Button from "@components/elements/Button";
import NextImage from "@components/elements/Image";
import { useState, useEffect } from "react";
import { Slider } from "@mantine/core";
import styles from "./PartnersPayments.module.css";
import useModalHook from "@lib/modals";

const PartnersPayments = (props) => {
  const data = props.data || props;

  const bonusValue = data?.calculator_bonus_value;
  const maxValue = data?.calculator_max_value;
  const [numberOfClients, setNumberOfClients] = useState(50);
  const [bonusResult, setbonusResult] = useState(numberOfClients * bonusValue);

  const modal = useModalHook();
  useEffect(() => {
    setbonusResult(numberOfClients * bonusValue);
  }, [numberOfClients, bonusResult]);

  if (!data) return null;
  const handlerShowModal = () => {
    modal.open("partnersRegistrationForm", {});
  };

  return (
    <div className={`container py-[5rem] pb-0 ${styles?.strip}`}>
      <div className={`w-full md:max-w-[30%] ${styles.content}`}>
        <HTMLRender data={data?.section_title?.subtitle} />
      </div>
      <div className="mt-xl flex flex-col justify-between rounded-lg bg-white p-md shadow-lg md:flex-row md:p-xl">
        <div className="flex w-full flex-col justify-between md:max-w-[60%]">
          <div className="mb-md flex w-full justify-center md:mb-xl md:max-w-[79%] md:justify-start">
            <div className={`${styles.incomeContent}`}>
              <HTMLRender data={data?.calculater_total_nrh} />
            </div>
            <p className="mb-0 py-0 px-sm text-[2rem] font-bold text-secondary ltr:font-gilroy rtl:font-dubai md:px-lg lg:text-[3.277rem]">
              {numberOfClients}
            </p>
          </div>
          <div className="flex w-full items-end justify-between">
            {Array(10)
              .fill(1)
              .map((el, i) => (
                <div
                  key={i}
                  style={{ height: `${3 + i * 0.55}rem` }}
                  className="w-0 border border-dashed border-lightBorderGrey"
                />
              ))}
          </div>
          <Slider
            value={numberOfClients}
            onChange={setNumberOfClients}
            defaultValue={25}
            min={0}
            max={maxValue}
            step={1}
            classNames={{
              bar: styles.sliderBar,
              thumb: styles.sliderThumb,
              mark: styles.sliderMark,
              markWrapper: styles.sliderMarkWrapper,
              track: styles.sliderTrack,
            }}
          />
          <div className="flex w-full items-end justify-between pt-md ltr:font-gilroy rtl:font-dubai">
            <p className="mb-0">0</p>
            <p className="mb-0">{maxValue}</p>
          </div>
        </div>
        <div className="mt-md flex w-full flex-col text-center md:max-w-[35%]">
          <div className={styles.guideContent}>
            <HTMLRender data={data?.calculator_guide} />
          </div>
          <div className="flex flex-row items-center justify-center md:flex-col">
            <p className="md:order-0 order-1 mb-0 px-sm py-lg text-[2rem] font-bold text-secondary ltr:font-gilroy rtl:font-dubai lg:text-[3.277rem]">
              $ {new Intl.NumberFormat("de-DE").format(bonusResult)}
            </p>
            <div className={`order-0 md:order-1 ${styles.incomeContent}`}>
              <HTMLRender data={data?.calculator_income} />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-xl flex flex-col justify-between md:mt-[6.667rem] md:flex-row">
        <div className="w-full md:max-w-[30%]">
          <HTMLRender data={data?.payments_title} />
          <div className="mt-lg">
            <Button data={data?.payments_button} />
            {/* <button onClick={handlerShowModal.bind(this)}>Register</button> */}
          </div>
        </div>
        <div className="mt-lg w-full md:mt-0">
          <NextImage
            src={data?.payments_image?.data?.attributes?.url}
            width={data?.payments_image?.data?.attributes?.width}
            height={data?.payments_image?.data?.attributes?.height}
          />
        </div>
      </div>
    </div>
  );
};

export default PartnersPayments;
