import HTMLRender from '@components/core/HTMLRender';
import GenericSlider from '@components/custom/GenericSlider';
import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import { SwiperSlide } from 'swiper/react';
import styles from'./InvestEtFsTypes.module.css';

const InvestEtFsTypes = (props) => {
  const data = props.data || props;
  if(!data) return null;
  
  return (
    <>
      <div className='container'>
        <div className={`${styles.title}`}>
          <SectionTitle data={data?.section_title} />
        </div>
        <div className='hidden md:flex flex-row justify-between'>
          {data?.cards?.map((card, index) => (
            <div key={index} className={`flex flex-col w-full items-center max-w-[calc(100%/7-0.5rem)] even:pt-[3.333rem] ${styles.cardShadow}`}>
              <NextImage src={card?.icon} width={card?.icon?.data?.attributes?.width} height={card?.icon?.data?.attributes?.height} objectFit={false} />
              <p className="text-secondary text-xs pt-md mb-xs">{card?.title}</p>
              <div className={styles.content}>
                <HTMLRender data={card?.content} />
              </div>
            </div>
          ))}
        </div>
        <div className="md:hidden etfsSlider mb-lg">
          <GenericSlider autoplay coverflowEffect navigation slidesPerView="2.0" loop centeredSlides>
            {data?.cards?.map((card, index) => {
              return (
                <SwiperSlide key={index} className={styles.cardShadow}>
                  <NextImage src={card?.icon} width={card?.icon?.data?.attributes?.width} height={card?.icon?.data?.attributes?.height} objectFit={false} />
                  <p className="text-secondary text-xs text-center pt-md mb-xs">{card?.title}</p>
                  <div className={styles.content}>
                    <HTMLRender data={card?.content} />
                  </div>
                </SwiperSlide>
              );
            })}
          </GenericSlider>
        </div>
        <div className="flex flex-col justify-center text-center pt-lg md:pt-xl  lg:pt-[7.2rem]">
          <p>{data?.text_above_button}</p>
          <div className="flex justify-center text-center mt-md">
            <Button data={data?.button} />
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestEtFsTypes;
