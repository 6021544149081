import Divider from '@components/elements/Divider';
import './Divider.module.css'


const DividerSection = (props) => {
  return (
        <section>
          <div className='container'>
            <Divider data={props.divider} />
          </div>
        </section>
  );
};

export default DividerSection;
