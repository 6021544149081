import Divider from '@components/elements/Divider';
import styles from './FooterPress.module.css'
import NextImage from '@components/elements/Image';
import GenericSlider from '@components/custom/GenericSlider';
import { SwiperSlide } from 'swiper/react';
import Link from '@components/elements/Link';

const FooterPress = (props) => {
  const data = props?.data || props;
  if (!data) return null;
  return (
    <div className="container pt-md lg:pt-0">
      <Divider bold type="center" text={data?.text} />
      <div>
        <div className="h-[5.55rem] max-h-[5rem]">
          <div className="-mx-md md:mx-0 md:w-full py-[2rem] press-slide">
            <GenericSlider
              pauseOnMouseEnter
              grabCursor
              rewind
              autoplay
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  spaceBetween: 20
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 50
                },
              }}
              slidesPerView={5}
              spaceBetween={50}
            >
              {data?.presses?.data?.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="relative">
                    <div>
                      <div className="">
                        <div className="relative h-[2rem] md:h-[2.25rem] w-full min-w-[9rem] grayscale hover:grayscale-0 cursor-pointer press-image">
                          <a
                            href={slide?.link_url}
                            target="_blank"
                            rel="noreferrer"
                            className='absolute h-full w-full top-0 left-0 z-10 bg-transparent'
                          />
                          <NextImage src={slide?.image?.url} />
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </GenericSlider>
          </div>
        </div>
      </div>
    </div >
  );
};

export default FooterPress;
