import NextImage from "../Image";
import styles from "./SectionLayout.module.css";
import get from "lodash/get";
import merge from "lodash/merge";
import kebabCase from "lodash/kebabCase";
import mapValues from "lodash/mapValues";
import { shallow } from "zustand/shallow";
import { useStore } from "@lib/store";

const SectionLayout = (props) => {
  const sectionName = get(props, "sectionName", "section-name-undefined");
  const sectionId =
    get(props, "sectionName", "section-name-undefined") + "_id_" + get(props, "sectionId", "section-id-undefined");
  const sectionData = get(props, "data", props) || [];
  const unitSize = sectionData?.component_layout?.unitSize;
  const reference = props?.reference;

  const isIframe = useStore((s) => s.page_key, shallow)?.includes("iframe");

  const predefinedStyles = {
    scrollMarginTop: "4.8rem",
    background: `${sectionData?.backgroundColor ? sectionData?.backgroundColor : "transparent"}`,
    ...(sectionData?.borderRadius && {
      borderRadius: `${sectionData?.borderRadius ? sectionData?.borderRadius : ""}`,
    }),
    paddingTop: `${sectionData?.backgroundColor || sectionData?.image?.data ? "5rem" : "0"}`,
    paddingBottom: `${sectionData?.backgroundColor || sectionData?.image?.data ? "5rem" : "0"}`,
  };

  const predefinedDesktopStyles = merge(
    { ...predefinedStyles },
    {
      marginBottom: "5rem",
    }
  );

  const predefinedMobileStyles = merge(
    { ...predefinedStyles },
    {
      marginBottom: "4rem",
      borderRadius: sectionData?.borderRadius ? "20px" : "0",
    }
  );

  const desktopStyles = mapValues(sectionData?.component_layout?.onlyDesktop?.styles || {}, (styleValue, styleName) => {
    return styleValue.replace(/[^\d.-]/g, "") + unitSize;
  });

  const mobileStyles = mapValues(sectionData?.component_layout?.onlyMobile?.styles || {}, (styleValue, styleName) => {
    return styleValue.replace(/[^\d.-]/g, "") + unitSize;
  });

  const globalStyles = mapValues(sectionData?.component_layout?.global?.styles || {}, (styleValue, styleName) => {
    return styleValue.replace(/[^\d.-]/g, "") + unitSize;
  });

  const mergedDesktopStyles = merge({ ...predefinedDesktopStyles }, globalStyles, desktopStyles);

  const mergedMobileStyles = merge(merge({ ...predefinedMobileStyles }, globalStyles), mobileStyles);

  if (isIframe) {
    return props.children;
  }

  if (!sectionData) {
    return (
      <section
        ref={reference}
        id={sectionId}
        sectionkey={sectionName}
        className={`sectionLayout ${sectionData?.section_type !== "fullPage" && styles.lastSectionPadding} ${sectionData?.section_type === "fullPage" ? "md:h-auto" : "h-auto"} group`}
      >
        <style jsx>{`
          /* for desktop */
          @media (min-width: 1024px) {
            .sectionLayout {
              ${Object.keys(mergedDesktopStyles)
            .map((key) => `${kebabCase(key)}: ${mergedDesktopStyles[key]};`)
            .join(" ")}
            }
          }
          /* for mobile */
          @media (max-width: 1023px) {
            .sectionLayout {
              ${sectionData?.desktop_only === true ? `display: none;\n` : ""}
              ${Object.keys(mergedMobileStyles)
            .map((key) => `${kebabCase(key)}: ${mergedMobileStyles[key]};`)
            .join(" ")}
            }
          }
        `}</style>
        {props.children}
      </section>
    );
  }
  if (!sectionData?.image?.data) {
    return (
      <section
        ref={reference}
        id={sectionId}
        sectionkey={sectionName}
        className={`sectionLayout ${sectionData?.section_type !== "fullPage" && styles.lastSectionPadding} ${sectionData?.section_type === "fullPage" ? "md:h-auto" : "h-auto"} group relative`}
      >
        <style jsx>{`
          /* for desktop */
          @media (min-width: 1024px) {
            .sectionLayout {
              ${Object.keys(mergedDesktopStyles)
            .map((key) => `${kebabCase(key)}: ${mergedDesktopStyles[key]};`)
            .join(" ")}
            }
          }
          /* for mobile */
          @media (max-width: 1023px) {
            .sectionLayout {
              ${sectionData?.desktop_only === true ? `display: none;\n` : ""}
              ${Object.keys(mergedMobileStyles)
            .map((key) => `${kebabCase(key)}: ${mergedMobileStyles[key]};`)
            .join(" ")}
            }
          }
        `}</style>
        <div className="relative z-10 h-full">{props.children}</div>
      </section>
    );
  }
  return (
    <section
      ref={reference}
      id={sectionId}
      sectionkey={sectionName}
      className={`sectionLayout ${styles.lastSectionPadding} ${sectionData?.section_type === "fullPage" ? "md:h-auto" : "h-auto"} relative ${styles.titleColor} group`}
    >
      <style jsx>{`
        /* for desktop */
        @media (min-width: 1024px) {
          .sectionLayout {
            ${Object.keys(mergedDesktopStyles)
          .map((key) => `${kebabCase(key)}: ${mergedDesktopStyles[key]};`)
          .join(" ")}
          }
        }
        /* for mobile */
        @media (max-width: 1023px) {
          .sectionLayout {
            ${sectionData?.desktop_only === true ? `display: none;\n` : ""}
            ${Object.keys(mergedMobileStyles)
          .map((key) => `${kebabCase(key)}: ${mergedMobileStyles[key]};`)
          .join(" ")}
          }
        }
      `}</style>
      {sectionData?.mobile_image?.data?.attributes?.url ? (
        <>
          <div className="hidden md:block">
            <NextImage src={sectionData?.image} />
          </div>
          <div className="block md:hidden">
            <NextImage src={sectionData?.mobile_image} />
          </div>
        </>
      ) : (
        <NextImage src={sectionData?.image} />
      )}
      <div className="relative z-10 h-full">{props.children}</div>
    </section>
  );
};

export default SectionLayout;
