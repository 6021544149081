import { Select } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import Arrow from "@components/elements/Arrow";
import styles from "../../TradingCInstruments.module.css";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { PLATFORM } from "../../shared/constants";

export default function SelectPlatform({ platform, setPlatform, productType }) {
  const { gv } = useGlobalVariable();

  if (productType !== 'trade') {
    return null;
  }

  return (
    <div className="mb-lg rounded bg-white text-lg text-secondary  lg:flex lg:justify-start">
      <Select
        value={platform}
        onChange={(e) => {
          setPlatform(e);
        }}
        label={gv("select_platform")}
        rightSection={<Arrow direction="down" />}
        rightSectionWidth={30}
        data={[
          { value: PLATFORM.COSMOS, label: gv("WebTrader") },
          { value: PLATFORM.MT5, label: gv("metatrader") },
        ]}
        classNames={{
          label: styles.selectLabel,
          input: styles.selectInput,
          root: styles.selectRoot,
          rightSection: styles.rightSection,
          item: styles.itemSelect,
        }}
      />
    </div>
  );
}
