import Head from "next/head";
import { WebPageJsonLd, ArticleJsonLd } from "next-seo";
import htmlToCleanText from "@utils/html-to-text";

export default function StructuredData({ pageType, pageData, metaData }) {
  if (["page"].includes(pageType)) {
    return <WebPageJsonLd id={pageData?.url} name={pageData?.display_name} description={metaData?.metaDescription} />;
  }
  if (["instruments"].includes(pageType)) {
    return <WebPageJsonLd id={pageData?.url} name={pageData?.display_name} description={metaData?.metaDescription} publisher={{ type: 'Organization', name: "CAPEX.com" }} />;
  }
  if (["article", "academy"].includes(pageType)) {
    return (
      <ArticleJsonLd
        url={pageData?.fullSlug}
        title={pageData?.title}
        description={htmlToCleanText(pageData?.shortDescription)}
        images={[pageData?.image?.url]}
        datePublished={pageData?.publishedAt}
        dateModified={pageData?.updatedAt}
        publisherLogo="https://capex.com/assets/logo/capex-com-logo-red.svg"
        publisherName="Capex.com"
        authorName={[{ name: pageData?.global_analyst?.name || "Capex.com", url: "https://capex.com" }]}
        isAccessibleForFree
      />
    );
  }
  return null;
}
