import { GiCheckMark } from '@react-icons/all-files/gi/GiCheckMark';
import Link from '@components/elements/Link';
import HTMLRender from '@components/core/HTMLRender';
import styles from './List.module.css'
import Arrow from '../Arrow';

const List = (props) => {
  const data = props.data || props;
  return (
    <ul className={`flex flex-col list-type-${data.type}`}>
      {data.list_items?.map((item, index) => (
        <li
          className={`m-0 flex ${data.centered ? "justify-center" : ""} ${!data.border && !data.striped ? 'py-sm' : 'py-md px-md'}  ${styles[`list-${data.type}`]} ${data.border && `${styles['list-type-bordered']} py-md`
            } ${data.striped && 'bg-[#f0f0f0] odd:bg-[#f7f7f7]'}`}
          key={index}
        >
          <div>
            {data.type === 'arrow' && !item.hide_icon ? (
              <div className="text-secondary ltr:mr-[1em] rtl:ml-[1em]">
                <Arrow direction="right" />
              </div>
            ) : null}
            {data.type === 'checked' && !item.hide_icon ? (
              <GiCheckMark className="text-secondary ltr:mr-[1em] rtl:ml-[1em] mt-[0.3em] text-[0.9em]" />
            ) : null}
            {data.type === 'decimal' ? `${index + 1}.` : null}
            {data.type === 'bullet' ? (
              <div className="bg-secondary mt-[0.5rem] ltr:mr-[1em] rtl:ml-[1em]  h-[0.555rem] w-[0.555rem] rounded-full" />
            ) : null}
          </div>
          <div>{item.link ? <div className={styles.listLink}> <Link arrow="none" text={<HTMLRender data={item.text} />} link={item.link} /></div> : <HTMLRender data={item.text} />}</div>
          {data.type === 'checked-right' && !item.hide_icon ? (
            <GiCheckMark className="text-secondary ltr:ml-[1em] rtl:mr-[1em] mt-[0.3em] text-[0.9em]" />
          ) : null}
          {data.type === 'bullet-right' ? (
            <div className="bg-secondary mt-[0.5rem] mr-[0.5em] ltr:ml-[1em] rtl:ml-[1em] h-[0.555rem] w-[0.555rem] rounded-full" />
          ) : null}
        </li>
      ))}
    </ul>
  );
};

export default List;
