import dayjs from "dayjs";
import { useState } from "react";
import Button from "@components/elements/Button";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import useLocale from "@lib/store/hooks/useLocale";
import useFormHandlers from "@src/components/elements/Forms/hooks/useFormHandlers";
import { Field, Formik } from "formik";
import PhoneFieldInput from "@src/components/elements/Forms/components/PhoneFieldInput";
import get from "lodash/get";
import HTMLRender from "@src/components/core/HTMLRender";
import errorsValidatingMessages from "@src/components/elements/Forms/error-validation-messages";
import * as Yup from "yup";
import ErrorLabel from "@src/components/elements/Forms/components/ErrorLabel";
import YupPassword from "yup-password";
import { useRouter } from "next/router";
import NextImage from '@src/components/elements/Image';
YupPassword(Yup);
// format date to be readable using dayjs
const advancedFormat = require("dayjs/plugin/advancedFormat");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
// dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const formatDate = (date) => dayjs(date).utc(true).local().format("DD MMMM YYYY, HH:mm z");

function LayoutWebinar({ pageData }) {
  const router = useRouter();
  const data = pageData;
  const { gv } = useGlobalVariable();
  const [submitResponse, setSubmitResponse] = useState(null);
  const locale = useLocale();
  const handler = useFormHandlers();
  if (!data) return null;

  const WebinarValdationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(<ErrorLabel err="forms_errors_firstname_required" />)
      // .matches(/^[a-zA-Z0-9 ]+$/, {
      //   message: <ErrorLabel err="forms_errors_rule_characters_not_allowed" />,
      //   when: (value) => !/^[a-zA-Z0-9 ]+$/.test(value),
      // })
      .min(2, <ErrorLabel err="forms_errors_rule_min_length" value="2" />)
      .max(50, <ErrorLabel err="forms_errors_rule_max_length" />),
    last_name: Yup.string()
      .required(<ErrorLabel err="forms_errors_lastname_required" />)
      // .matches(/^[a-zA-Z0-9 ]+$/, {
      //   message: <ErrorLabel err="forms_errors_rule_characters_not_allowed" />,
      //   when: (value) => !/^[a-zA-Z0-9 ]+$/.test(value),
      // })
      .min(2, <ErrorLabel err="forms_errors_rule_min_length" value="2" />)
      .max(50, <ErrorLabel err="forms_errors_rule_max_length" />),
    password: Yup.string()
      .required(<ErrorLabel err="forms_errors_password_required" />)
      .min(8, <ErrorLabel err="forms_errors_password_rule_min_length" />)
      .max(16, <ErrorLabel err="forms_errors_password_rule_max_length" />)
      .minSymbols(1, <ErrorLabel err="forms_errors_password_rule_symbols" />)
      .minNumbers(1, <ErrorLabel err="forms_errors_password_rule_numbers" />)
      .minLowercase(1, <ErrorLabel err="forms_errors_password_rule_lowercase" />)
      .minUppercase(1, <ErrorLabel err="forms_errors_password_rule_uppercase" />),
    phone_number: Yup.string()
      .required(<ErrorLabel err="forms_errors_phone_required" />)
      .min(8, <ErrorLabel err="forms_errors_phone_required" />)
      .max(16, <ErrorLabel err="forms_errors_phone_required" />),
    email: Yup.string()
      .email(<ErrorLabel err="forms_errors_email_required" />)
      .required(<ErrorLabel err="forms_errors_email_required" />),
    agree_personal_collecting_data: Yup.boolean()
      .oneOf([true], <ErrorLabel err="forms_errors_field_required" />)
      .required(<ErrorLabel err="forms_errors_field_required" />),
    agree_marketing_offers: Yup.boolean().required(<ErrorLabel err="forms_errors_field_required" />),
  });
  return (
    <section className="mb-[5rem]">
      <div className="relative pt-[6rem] md:pt-[8rem]">
        <div className="absolute left-0 top-0 h-full w-full">
          <NextImage
            layout="fill"
            objectFit="cover"
            src="/assets/images/webinars-show-bg.png"
            priority
            alt="Webinar Hero Background Image"
          />
        </div>
        <div className="container relative pb-lg">
          <h1 className="text-center leading-[1.4] text-secondary md:pb-lg">
            {data?.title} <br />
            {formatDate(data?.date)}. {data?.language}
            <br />
            <div className="mx-auto pt-md text-lg leading-[1.2] text-grey md:max-w-[85%] md:text-2xl">
              {gv("webinar_register_to_our_webinar_title")}
            </div>
          </h1>
          <div>
            <Formik
              style={{
                display: submitResponse && submitResponse?.statusCode !== 400 ? "none" : "block",
              }}
              initialValues={{}}
              validationSchema={WebinarValdationSchema}
              onSubmit={async (values) => {
                const body = {
                  ...values,
                  webinarKey: data.webinarKey,
                  name: data.title,
                  language: locale,
                };
                const response = await handler.onSubmitWebinars(body).catch((err) => { });
                if (response?.status === "APPROVED") {
                  //redirect to thank you page
                  router.push(`/${locale}/webinar-thank-you`);
                  setSubmitResponse(response);
                } else {
                  setSubmitResponse({
                    statusCode: 400,
                  });
                }
                console.log("Response: ", response);
              }}
            >
              {({ errors, touched, handleSubmit, isSubmitting, submitCount }) => (
                <form onSubmit={handleSubmit} className="bg-white">
                  <div>
                    <div
                      name="schedule"
                      id="schedule"
                      encType="application/json"
                      method="post"
                      noValidate="novalidate"
                      className="justiy-center flex flex-col flex-wrap pt-lg md:flex-row md:justify-between"
                    >
                      <Field type="hidden" name="webinarKey" id="webinarKey" value={data?.webinarKey} />
                      <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                        <Field type="text" name="first_name" placeholder={gv("first_name")} />
                        {errors?.first_name && submitCount > 0 && errors?.first_name}
                      </div>
                      <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                        <Field type="text" name="last_name" placeholder={gv("last_name")} />
                        {errors?.last_name && submitCount > 0 && errors?.last_name}
                      </div>
                      <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                        <Field type="email" name="email" placeholder={gv("email_address")} />
                        {errors?.email && submitCount > 0 && errors?.email}
                      </div>
                      <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                        {/* Phone Number */}
                        <div className="phone-field-wrapper">
                          <PhoneFieldInput
                            label="Phone Number"
                            name="phone_number"
                            type="tel"
                            placeholder={gv("phone")}
                          />
                        </div>
                        {errors?.phone_number && submitCount > 0 && errors?.phone_number}
                      </div>
                      <div className="margin-0 relative w-full pb-md">
                        <HTMLRender data={gv("webinar_demo_account_choose_password")} />
                        <Field type="password" name="password" placeholder={gv("password")} />
                        {errors?.password && submitCount > 0 && errors?.password}
                      </div>
                      <div className="margin-0 relative w-full pb-md ">
                        {/* STYLE Box shadow only for dev purposes */}
                        <div className="flex">
                          <Field name="agree_marketing_offers" id="agree_marketing_offers" type="checkbox" />
                          <label htmlFor="agree_marketing_offers">
                            {gv("forms_field_name_webinar_accept_terms_1")}
                          </label>
                        </div>
                        {errors?.agree_marketing_offers && submitCount > 0 && errors?.agree_marketing_offers}
                      </div>

                      <div className="margin-0 relative w-full pb-md ">
                        {/* STYLE Box shadow only for dev purposes */}
                        <div className="flex">
                          <Field
                            name="agree_personal_collecting_data"
                            id="agree_personal_collecting_data"
                            type="checkbox"
                          />
                          <label htmlFor="agree_personal_collecting_data">
                            <HTMLRender data={gv("forms_field_name_webinar_accept_terms_2")} />
                          </label>
                        </div>
                        <p>
                          {errors?.agree_personal_collecting_data &&
                            submitCount > 0 &&
                            errors?.agree_personal_collecting_data}
                        </p>
                      </div>

                      <div className="margin-0 relative w-full ">
                        <div className="my-xl flex justify-center">
                          <Button submit type="secondary" label={gv("submit")} size="lg" disabled={isSubmitting} />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div className="mt-md">
            <p className="text-xs">
              <HTMLRender data={gv("webinar_register_bottom_disclaimer")} />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LayoutWebinar;
