/* eslint-disable arrow-body-style */
import { Fragment, useEffect, useRef, useState } from "react";
import SectionTitle from "@components/elements/SectionTitle";
import groupBy from "lodash/groupBy";
import get from "lodash/get";
import omit from "lodash/omit";
import keys from "lodash/keys";
import map from "lodash/map";
import pickBy from "lodash/pickBy";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { Popover } from "@mantine/core";
import HTMLRender from "@src/components/core/HTMLRender";
import { CrossIcon, CheckIcon } from "./Icons";
import styles from "./AccountTypeTable.module.css";
import Button from "@src/components/elements/Button";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Arrow from "@src/components/elements/Arrow";
import { useRegistrationLink } from "@lib/store/hooks/useActiveProductType";

function TableHeader({ items, mostPopularCategory }) {
  const { gv } = useGlobalVariable();
  const onlyKeys = keys(omit(items, ["id"]));

  const tableRows = map(onlyKeys, (key, index) => {
    const category = get(items, key, false);
    if (!category) return null;
    const isMostPopular = key.toLowerCase() === mostPopularCategory.toLowerCase();
    return (
      <div
        key={index}
        style={{ maxWidth: `calc(100% / ${onlyKeys.length + 2})` }}
        className={`${isMostPopular ? styles.mostPopularParent : ""
          } w-full items-center justify-center border border-[#8d8d8d] border-opacity-[0.15] bg-white py-[2rem] px-md text-center first:flex-[2] ltr:border-r-0 ltr:last:border-r rtl:border-l-0 rtl:last:border-l
            max-w-[calc(100/${onlyKeys.length + 2})] first:max-w-full
          `}
      >
        <p className="mb-0 text-2xl text-dark">{category}</p>
        {isMostPopular && <p className={styles.mostPopularBadge}>{gv("most_popular")}</p>}
      </div>
    );
  });
  return (
    <>
      <div
        style={{ maxWidth: `calc(100% / ${onlyKeys.length + 2})` }}
        className={`${styles.emptyHeaderColumn} w-full items-center justify-center border border-[#8d8d8d] border-opacity-[0.15] py-lg px-md text-center first:flex-[2] first:border-0 ltr:border-r-0 ltr:last:border-r rtl:border-l-0 rtl:last:border-l`}
      >
        <p className="text-[1.557rem] text-dark" />
      </div>
      {tableRows}
    </>
  );
}

const RenderValue = ({ value }) => {
  switch (value) {
    case "yes":
      return <CheckIcon className={`${styles.TableIcon}`} />;
    case "no":
      return <CrossIcon className={`${styles.TableIcon}`} />;
    default:
      return value;
  }
};

function FeatureName({ value, hiddenDescription = false }) {
  const [opened, setOpened] = useState(false);
  return (
    <div className="flex w-full flex-[2] items-center border border-[#8d8d8d] border-opacity-[0.15] py-sm px-sm first:border-0 ltr:border-r-0 ltr:last:border-r rtl:border-l-0 rtl:last:border-l lg:max-w-full lg:py-md lg:px-md">
      <HTMLRender data={value} className="text-xs ltr:text-left rtl:text-right lg:text-base" />
      {hiddenDescription && (
        <Popover
          opened={opened}
          onClose={() => setOpened(false)}
          position="bottom"
          placement="center"
          withArrow
          trapFocus={false}
          closeOnEscape={false}
          transition="pop-top-left"
          width={260}
          // styles={{ body: { pointerEvents: "none" } }}
          target={
            <button
              type="button"
              onClick={() => setOpened((o) => !o)}
              className="block h-[1rem] w-[1rem] rounded-full bg-slate-500 text-center text-[0.80rem] leading-[1.1rem] text-white ltr:ml-2 rtl:mr-2 lg:h-[1.2rem]  lg:w-[1.2rem]"
            >
              ?
            </button>
          }
        >
          <div className={styles.hiddenDescription}>
            <HTMLRender data={hiddenDescription} />
          </div>
        </Popover>
      )}
    </div>
  );
}

function FeatureValue({ value, onlyKeys }) {
  return (
    <div
      style={{ maxWidth: `calc(100% / ${onlyKeys.length + 2})` }}
      className="flex w-full items-center justify-center border border-t-0 border-b-0 border-[#8d8d8d] border-opacity-[0.15] py-md px-md text-center first:border-0 ltr:border-r-0  ltr:last:border-r-0 rtl:border-l-0 rtl:last:border-l-0"
    >
      <RenderValue value={value} />
    </div>
  );
}

function FeatureValueMobile({ value }) {
  return (
    <div className="flex w-full flex-[2] items-center justify-center border border-t-0 border-b-0 border-[#8d8d8d] border-opacity-[0.15] py-md px-md text-center text-xs first:border-0  ltr:border-r-0 rtl:border-l-0 lg:text-base">
      <RenderValue value={value} />
    </div>
  );
}

function TableRow({ row, filteredCategoryNames, onlyKeys }) {
  return (
    <div className="odd:bg-white even:bg-[#f8f8f8]">
      <div className="hidden flex-row lg:flex">
        {/* desktop */}
        <FeatureName value={row?.name} hiddenDescription={row?.hidden_description} />
        {/* Get only the keys that are present in the category names */}
        {map(keys(filteredCategoryNames), (key, index) => (
          <FeatureValue value={row[key]} key={index} onlyKeys={onlyKeys} />
        ))}
      </div>
      {/* mobile */}
      <div className="flex flex-row lg:hidden">
        <FeatureName value={row?.name} hiddenDescription={row?.hidden_description} />
        {/* Get only the keys that are present in the category names */}
        {map(keys(filteredCategoryNames), (key, index) => (
          <FeatureValueMobile value={row[key]} key={index} onlyKeys={onlyKeys} />
        ))}
      </div>
    </div>
  );
}

function AccountTypeTable(props) {
  const data = props.data || props;
  console.log(`props`, props)
  const { gv } = useGlobalVariable();
  // swiper consts
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const registrationLink = useRegistrationLink();
  //  for pricing button hover
  const [hovered, setHovered] = useState({ value: false, index: null });

  const { riskWarningHeight } = useStore((state) => state, shallow);
  // store for swiper dir
  const store = useStore(
    (store) => ({
      dir: store.direction,
      riskWarningHeight: store.riskWarningHeight,
    }),
    shallow
  );

  const categoryNames = get(data, "qd_account_type.data[0].acc_types_table_item_category", false);
  const filteredCategoryNames = omit(pickBy(categoryNames), "id");
  const categoryItems = groupBy(get(data, "qd_account_type.data[0].acc_types_table_item", false), "category");
  const mostPopularCategory = get(data, "qd_account_type.data[0].most_popular", false);
  const onlyKeys = keys(omit(categoryNames, ["id"]));
  const accountCategoriesPrices = get(data, "qd_account_type.data[0].account_types_category_prices", {});

  const currentProductType = useStore((state) => state.currentProductType);
  const activeWrongLicenseBar = useStore((state) => state.activeWrongLicenseBar);

  const [headerOffset, setHeaderOffset] = useState("4.8888rem");

  useEffect(() => {
    if (currentProductType?.attributes?.risk_warning_type === "adgm") {
      if (activeWrongLicenseBar) {
        setHeaderOffset(`calc(7.94675rem + ${riskWarningHeight}px)`);
      } else {
        setHeaderOffset(`calc(4.8888rem + ${riskWarningHeight}px)`);
      }
    } else {
      if (activeWrongLicenseBar) {
        setHeaderOffset(`calc(7.94675rem`);
      } else {
        setHeaderOffset(`calc(4.8888rem`);
      }
    }
  }, [activeWrongLicenseBar, riskWarningHeight]);

  if (!data || !categoryNames) return null;

  return (
    <div className="container">
      <div className="hidden md:block md:pt-xl lg:pt-0">
        <SectionTitle data={data?.section_title} />
      </div>
      {/* desktop version */}
      <div className="hidden pt-[5rem] lg:block">
        {/* table header */}
        <div className="sticky  z-[5] flex w-full flex-row justify-end" style={{ top: headerOffset }}>
          <TableHeader items={categoryNames} mostPopularCategory={mostPopularCategory} />
        </div>
        {/* service name */}
        {map(keys(categoryItems), (key, index) => {
          const category = categoryItems[key];
          return (
            <div className={`flex flex-col `} key={index}>
              <div className="flex flex-row pt-md">
                {key !== "hide_category" && (
                  <div className="flex items-center justify-start py-lg px-md first:flex-[2]">
                    <p className="header-title first-header-title w-full text-h3 text-secondary">{gv(key)}</p>
                  </div>
                )}
              </div>
              {/* service row */}
              {map(category, (item, indexRow) => (
                <TableRow onlyKeys={onlyKeys} row={item} key={indexRow} filteredCategoryNames={filteredCategoryNames} />
              ))}
            </div>
          );
        })}
        <div className="flex min-h-[8rem] w-full flex-row items-start justify-between">
          <div className="w-full flex-[2] py-sm px-sm lg:py-lg lg:px-md">
            <p className="text-secondary">{gv("starting_from")}</p>
          </div>
          {onlyKeys.map((row, index) => {
            const accountCategoryPrice = accountCategoriesPrices[row];
            return (
              <div
                onMouseEnter={() => setHovered({ value: true, index })}
                onMouseLeave={() => setHovered({ value: false, index: null })}
                key={index}
                style={{ maxWidth: `calc(100% / ${onlyKeys.length + 2})` }}
                className="group relative flex w-full flex-col items-center justify-center py-lg"
              >
                {/* add tooltip with gv select_account_type */}
                <p className="cursor-pointer text-secondary">{accountCategoryPrice}</p>
                <div
                  className={`${styles.customButton} ${hovered.value && hovered.index === index ? "block" : "hidden"
                    } absolute top-[70%] z-10 w-full transition-all duration-300 ease-in-out ltr:left-[5%] rtl:right-[5%]`}
                >
                  <Button
                    type="white"
                    size="sm"
                    arrow="right"
                    link={registrationLink}
                    label={gv("select_account_type")}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* mobile version */}
      <div className="-mx-[1.111rem] bg-[#f8f8f8] pt-[4rem] md:bg-transparent lg:hidden">
        <div className="accountTypeSwiper relative">
          <div
            ref={navigationPrevRef}
            className="absolute top-0 z-10 max-h-[2.709375rem] min-h-[2.709375rem] cursor-pointer border bg-white px-sm py-xs text-[1.3rem] text-secondary transition-all duration-300 ltr:left-0 rtl:right-0"
          >
            <Arrow direction="left" />
          </div>
          <div
            ref={navigationNextRef}
            className="absolute top-0 z-10 max-h-[2.709375rem] min-h-[2.709375rem] cursor-pointer border bg-white px-sm py-xs text-[1.3rem] text-secondary transition-all duration-300 ltr:right-0 rtl:left-0"
          >
            <Arrow direction="right" />
          </div>
          <Swiper
            dir={store ? store.dir : "ltr"}
            loop
            modules={[Navigation]}
            navigation={{
              prevEl: navigationPrevRef.current ? navigationPrevRef.current : undefined,
              nextEl: navigationNextRef.current ? navigationNextRef.current : undefined,
            }}
            onSwiper={(swiper) => {
              // Delay execution for the refs to be defined
              setTimeout(() => {
                // Override prevEl & nextEl now that refs are defined
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
                // Re-init navigation
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              });
            }}
            slidesPerView={1}
          >
            {map(onlyKeys, (currentAccountType, accountTypeIndex) => {
              const otherAccountType = onlyKeys.filter((e) => e !== currentAccountType);
              const currentAccountTypeName = filteredCategoryNames[currentAccountType];
              const omitFilteredCategoryNames = omit(filteredCategoryNames, otherAccountType);
              return (
                <SwiperSlide>
                  <div className="flex flex-col">
                    <div className="flex max-h-[2.709375rem] min-h-[2.709375rem] flex-row justify-center border-t border-b bg-white pt-[0.4rem]">
                      <p className="text-xl uppercase">{currentAccountTypeName}</p>
                    </div>
                    {map(keys(categoryItems), (key, index) => {
                      const category = categoryItems[key];
                      return (
                        <Fragment key={index}>
                          <div className="flex flex-row">
                            {key !== "hide_category" && (
                              <div className="flex items-center justify-start bg-[#f8f8f8] py-lg px-md first:flex-[2]">
                                <p className="header-title first-header-title w-full text-center text-h5 text-secondary">
                                  {gv(key)}
                                </p>
                              </div>
                            )}
                          </div>
                          {map(category, (item, indexRow) => (
                            <TableRow
                              onlyKeys={currentAccountType}
                              row={item}
                              key={indexRow}
                              filteredCategoryNames={omitFilteredCategoryNames}
                            />
                          ))}
                        </Fragment>
                      );
                    })}
                  </div>
                  <div className="flex w-full flex-row items-start justify-center">
                    <div className="w-full py-lg text-center" />
                    <div className="flex w-full flex-col items-center justify-center py-lg">
                      <p className="text-secondary">{accountCategoriesPrices[currentAccountType]}</p>
                      <div className={styles.customButton}>
                        <Button type="white" size="sm" link={registrationLink} label={gv("select_account_type")} />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default AccountTypeTable;
