/* eslint-disable lodash/collection-ordering */
import { useRef } from "react";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import values from "lodash/values";
import orderBy from "lodash/orderBy";
import toNumber from "lodash/toNumber";

const fetcher = (...args) => fetch(...args).then((res) => res.json());
/**
 * [default description]
 *
 * @param   {[type]}  query    [sends your instruments (api_id) array as query string to fetch data from apiV2]
 * @param   {[type]}  refresh  [data refresh rate, in ms]
 * @param   {[type]}  10       [default refresh rate, 10ms]
 *
 * @return  {Object}           [return instrument object]
 */
export default function instrumentsApi(query, refresh = "10") {
  const currentDataRef = useRef(null);
  const previousDataRef = useRef(null);
  const { data: instrumentData } = useSWR(`https://api-v2.capex.com/quotesv2?key=1&q=${query}`, fetcher, {
    refreshWhenOffline: false,
    refreshInterval: refresh,
    revalidateOnReconnect: true,
    refreshWhenHidden: false,
  });
  previousDataRef.current = currentDataRef.current || undefined;
  currentDataRef.current = instrumentData;

  return { currentData: instrumentData, previousData: previousDataRef.current || currentDataRef.current };
}

/**
 * [default description]
 *
 * @param   {[type]}  query    [sends your instruments (api_id) array as query string to fetch data from apiV2]
 * @param   {[type]}  refresh  [data refresh rate, in ms]
 * @param   {[type]}  10       [default refresh rate, 10ms]
 *
 * @return  {Object}           [return instrument object]
 */
export function useInstrumentsApiRefresh(query, refresh = "10") {
  const currentDataRef = useRef(undefined);
  const previousDataRef = useRef(undefined);
  const { data: instrumentData } = useSWR(`https://api-v2.capex.com/quotesv2?key=1&q=${query}`, fetcher, {
    refreshWhenOffline: false,
    refreshInterval: refresh,
    revalidateOnReconnect: true,
    refreshWhenHidden: false,
    keepPreviousData: false,
  });

  // Safe assignment of current and previous data references
  previousDataRef.current = currentDataRef.current || {};
  currentDataRef.current = instrumentData || {};

  if (previousDataRef?.current && currentDataRef?.current) {
    try {
      Object.keys(currentDataRef.current).forEach(api_id => {
        if (api_id && previousDataRef.current[api_id] && currentDataRef.current[api_id]) {
          if (previousDataRef.current[api_id]?.buy !== currentDataRef.current[api_id]?.buy) {
            currentDataRef.current[api_id].isActive = true;
            previousDataRef.current[api_id].cancelActive = 0;
          } else if (previousDataRef.current[api_id]?.sell !== currentDataRef.current[api_id]?.sell) {
            currentDataRef.current[api_id].isActive = true;
            previousDataRef.current[api_id].cancelActive = 0;
          } else if (previousDataRef.current[api_id]?.price !== currentDataRef.current[api_id]?.price) {
            currentDataRef.current[api_id].isActive = true;
            previousDataRef.current[api_id].cancelActive = 0;
          } else {
            const previousCount = Number.parseInt(previousDataRef.current[api_id]?.cancelActive, 10) || 0;
            currentDataRef.current[api_id].cancelActive = previousCount + 1;
            if (previousDataRef.current[api_id]?.cancelActive > 15) {
              currentDataRef.current[api_id].isActive = false;
            }
          }
        }
      });
    } catch (err) {
      console.warn(err);
    }
  }

  return { currentData: instrumentData, previousData: previousDataRef?.current || currentDataRef?.current };
}

export function gainersAndLosers(query, refresh = "0") {
  const { data } = useSWRImmutable(`https://api-v2.capex.com/quotesv2?key=1`, fetcher);
  const dataWithoutPercentage = values(data)
    .filter((item) => {
      if (item.change !== "-") {
        return item;
      }
    })
    .map((item) => ({
      ...item,
      ...(item.change && { numberChange: toNumber(item.change.replace("%", "")) }),
    }));
  const gainers = orderBy(dataWithoutPercentage, ["numberChange"], ["desc"]).slice(0, 50);
  const losers = orderBy(dataWithoutPercentage, ["numberChange"], ["asc"]).slice(0, 50);

  return { gainers, losers };
}
