
import HTMLRender from '@components/core/HTMLRender';
import propTypes from './Columns.props';

const Columns = (props) => {
  const data = props.data || props;
  const colsNum = data?.column?.length;
  if (!data) {
    return null
  }
  return (
    <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-${colsNum} gap-6`}>
      {data?.column?.map((item, index) => (
        <div className="pb-xl last:pb-0" key={index}>
          {item.title ? <p className='text-white text-2xl'>{item?.title}</p> : null}
          {item.content ?
            <div>
              <HTMLRender data={item?.content} />
            </div>
            : null}
        </div>
      ))}
    </div>
  )
};

Columns.propTypes = propTypes;

export default Columns;
