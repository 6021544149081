import styles from './Logo.module.css'
import NextLink from 'next/link';
import Image from 'next/image';
import { useEffect, useState } from 'react';

const LogoImageSrc = (color) => `/assets/logo/capex-com-logo-${color}.svg`;

const Logo = (props) => {
  const data = props.data || props;
  const [src, setSrc] = useState(LogoImageSrc(data.color));
  useEffect(() => {
    setSrc(LogoImageSrc(data.color))
  }, [data.color])
  const LogoImage = () => {
    return (
      <div
        className='relative'
        style={{ width: '100%', height: '100%' }}
      >
        <Image
          src={src}
          unoptimized={false}
          alt="CAPEX.com"
          title="CAPEX.com"
          layout="fill"
          priority={true}
          sizes="50vw"
          onError={(e) => {
            //fallback
            setSrc(LogoImageSrc('red'));
          }}
        />
      </div>
    )
  }
  return (
    <div className="w-[6.5em] h-[2.055555em] relative ">
      {data.link ?
        <NextLink href={data.link} >
          <a>
            <LogoImage />
          </a>
        </NextLink>
        :
        <LogoImage />
      }
    </div>

  );
};

export default Logo;
