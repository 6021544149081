import Button from '@src/components/elements/Button';
import SectionTitle from '@src/components/elements/SectionTitle';
import styles from './PartnersCyHero.module.css';

const PartnersCyHero = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      <div className="lg:py-[10rem] lg:max-w-[70%]">

        <div className={styles.title}>
          <SectionTitle data={data?.section_title} />
        </div>
        <div className='lg:mt-xl'>
          <Button data={data?.button} />
        </div>
      </div>
      {/* desktop disclaimer */}
      <p className='hidden lg:block mb-0'>{data?.disclaimer}</p>
      {/* mobile disclaimer */}
      <div className='relative -bottom-[9rem] lg:hidden'>
        <p className='text-center text-sm'>{data?.disclaimer}</p>
      </div>
    </div>
  );
};

export default PartnersCyHero;
