import HTMLRender from '@src/components/core/HTMLRender';
import NextImage from '@src/components/elements/Image';
import styles from './RegulationsTable.module.css';

const FilterCategoryDesktop = ({ data, category }) => {
  const filteredData = data?.content_rows?.filter((item) => item?.category === category?.key)
  return (
    filteredData?.map(((el, index) => (
      <div key={index} className={`grid ${data?.licences?.length === 3 ? 'grid-cols-5' : 'grid-cols-6'} my-sm gap-x-0 border-b`}>
        <p className='text-sm px-xs col-span-2 mb-0 pb-sm ltr:pl-[2.77rem] rtl:pr-[2.77rem] font-bold'>{el?.title}</p>
        {el?.cysec && <p className='text-sm px-xs mb-0 pb-sm'>{el?.cysec}</p>}
        {el?.fsca && <p className='text-sm px-xs mb-0 pb-sm'>{el?.fsca}</p>}
        {el?.adgm && <p className='text-sm px-xs mb-0 pb-sm'>{el?.adgm}</p>}
        {el?.fsa && <p className='text-sm px-xs mb-0 pb-sm'>{el?.fsa}</p>}
        {el?.all_licenses_row &&
          <p className='col-span-3 text-sm px-xs text-center mb-0 pb-sm'>{el?.all_licenses_row}</p>
        }
      </div>
    )))
  )
}
const FilterCategoryMobile = ({ data, category }) => {
  const filteredData = data?.content_rows?.filter((item) => item?.category === category?.key)
  return (
    filteredData?.map(((el, index) => (
      <div key={index} className='flex flex-col justify-between my-sm gap-x-0'>
        <p className='text-sm  w-full mb-sm pb-xs font-bold border-b-2'>{el?.title}</p>
        <div className='flex justify-between'>
          <div className='w-full max-w-[35%]'>
            {data?.licences?.map((licence) => (
              <div key={index} className='flex items-center mb-xs'>
                <NextImage src={licence?.icon?.data?.attributes?.url} height={20} width={30} />
                <p className='text-xs mb-0 pt-[0.25rem] px-xs'>{licence?.name}</p>
              </div>
            ))}
          </div>
          <div className='w-full max-w-[60%] flex flex-col items-end'>
            {el?.cysec && <p className='text-xs mb-0 pt-[0.25rem] pb-sm'>{el?.cysec}</p>}
            {el?.fsca && <p className='text-xs mb-0 pt-[0.25rem] pb-sm'>{el?.fsca}</p>}
            {el?.adgm && <p className='text-xs mb-0 pt-[0.25rem] pb-sm'>{el?.adgm}</p>}
            {el?.fsa && <p className='text-xs mb-0 pt-[0.25rem] pb-sm'>{el?.fsa}</p>}
            {el?.all_licenses_row &&
              <div className='flex flex-col items-center justify-center h-full'>
                <p className='w-full text-xs text-center mb-0 pt-[0.25rem] pb-sm'>{el?.all_licenses_row}</p>
              </div>
            }
          </div>
        </div>
      </div>
    )))
  )
}

const RegulationsTable = (props) => {
  const data = props.data || props;

  if (!data) return null;
  return (
    <div className='container'>
      {/* desktop version */}
      <div className='hidden md:block'>
        <div className={`grid  ${data?.licences?.length === 3 ? 'grid-cols-5' : 'grid-cols-6'} gap-y-5 gap-x-0`}>
          {/* empty col */}
          <div className='col-span-2' />
          {data?.licences?.map((licence, index) => (
            <div key={index}>
              <p className='text-sm px-xs mb-0 pb-sm'>{licence?.name}</p>
            </div>
          ))}
        </div>
        <div className=''>
          {data?.categories?.map((category, index) => (
            <>
              <div key={index}>
                <div className="ltr:pl-[2.77rem] rtl:pr-[2.77rem] py-sm bg-[#f6f6f6] rounded-sm flex items-center whitespace-pre">
                  <p className='text-sm font-bold text-secondary mb-0'>{category?.name}{' '}</p>
                  <div className={styles.description}>
                    <HTMLRender data={category?.description} />
                  </div>
                </div>
              </div>
              <div>
                <FilterCategoryDesktop data={data} category={category} />
              </div>
              <div className={`${styles.disclaimer} grid  ${data?.licences?.length === 3 ? 'grid-cols-5' : 'grid-cols-6'} my-sm gap-x-0`}>
                <div className='col-start-3 col-end-7'>
                  <HTMLRender data={category?.disclaimer} />
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      {/* mobile version */}
      <div className='md:hidden'>
        {data?.categories?.map((category, index) => (
          <>
            <div key={index} className='bg-[#f6f6f6] rounded-sm flex flex-col justify-center items-center text-center py-sm mb-sm'>
              <p className='mb-0 font-bold text-secondary'>{category?.name}</p>
              <div className={styles.description}>
                <HTMLRender data={category?.description} />
              </div>
            </div>
            <div>
              <FilterCategoryMobile data={data} category={category} />
            </div>
            <div className={`${styles.disclaimer} pb-sm`}>
              <HTMLRender data={category?.disclaimer} />
            </div>
          </>
        ))}
      </div>
    </div >
  );
};

export default RegulationsTable;
