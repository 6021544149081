import { getCookie } from "cookies-next";
import qs from "qs";
import { useEffect, useState } from "react";

export default function useMobileLinks() {
  const useGetMobileLink = (deviceParam) => {
    const [tracking, setTracking] = useState({});
    const [urlParams, setUrlParams] = useState("");
    useEffect(() => {
      try {
        const trackingCookie = JSON.parse(getCookie("tracking")) || {};
        if (trackingCookie !== tracking) {
          setTracking(JSON.parse(getCookie("tracking")) || {});
          setUrlParams(qs.stringify(trackingCookie, { addQueryPrefix: true }));
        }
      } catch (error) {
        setTracking({});
      }
    }, []);

    let device = deviceParam;
    if (deviceParam && deviceParam.includes("ios.capex.com")) {
      device = "ios";
    } else if (deviceParam && deviceParam.includes("android.capex.com")) {
      device = "android";
    }
    switch (device) {
      case "android":
        return `https://android.capex.com${urlParams}`;
      case "ios":
        return `https://ios.capex.com${urlParams}`;
      default:
        return `https://android.capex.com${urlParams}`;
    }
  };

  return {
    useGetMobileLink,
  };
}
