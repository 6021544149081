import styles from "./ipo.module.css";
import NextImage from "@components/elements/Image";
import Link from "@components/elements/Link";
import Button from "@components/elements/Button";
import Divider from "@components/elements/Divider";
import HTMLRender from "@components/core/HTMLRender";
import Arrow from "@components/elements/Arrow";
import { IoCalendarSharp } from "@react-icons/all-files/io5/IoCalendarSharp";
import { ImHourGlass } from "@react-icons/all-files/im/ImHourGlass";
import { GiPriceTag } from "@react-icons/all-files/gi/GiPriceTag";
import { GiCheckMark } from "@react-icons/all-files/gi/GiCheckMark";
import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import GenericSlider from "@components/custom/GenericSlider";
import { SwiperSlide } from "swiper/react";
import IPOPast from "@components/custom/IPOPast";
import IPOSimpleCard from "@components/custom/IPOSimpleCard";
import InstrumentHeader from "@components/custom/InstrumentHeader";
import InstrumentChart from "@components/custom/InstrumentChart";
import InstrumentTrends from "@components/custom/InstrumentTrends";
import InstrumentInfo from "@components/custom/InstrumentInfo";
import { useRegistrationLink } from "@lib/store/hooks/useActiveProductType";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { useIntersection } from "@mantine/hooks";
import { useEffect, useRef } from "react";
import { NextSeo } from 'next-seo';

const GeneralInfoList = ({ title, value }) => (
  <div className="mb-sm flex w-full justify-between border-b border-b-[#eee]">
    <p className="text-sm text-dark w-full max-w-[30%] mb-0 pb-sm">{title}</p>
    <p className="text-sm text-dark w-full max-w-[65%] ltr:text-right rtl:text-left mb-0 pb-sm">{value}</p>
  </div>
);
const IPODetailsList = ({ global_var, title, value }) => (
  <div className={styles.ipo_details_content}>
    <HTMLRender data={value} />
  </div>

);
const SummaryList = ({ title, value }) => (
  <div className="mb-sm flex w-full justify-between last:mb-0 md:w-[90%] lg:w-[85%]">
    <p className="mb-0 w-full max-w-[30%] text-sm font-bold text-dark">{title}</p>
    <p className="mb-0 w-full max-w-[65%] text-sm font-bold text-secondary ltr:text-right rtl:text-left">{value}</p>
  </div>
);
const SneakPeakList = ({ title, value, icon }) => (
  <div className="mb-xs flex w-full flex-row items-center justify-between last:mb-0">
    <div className="flex items-center">
      <div className="text-grey ltr:mr-xs rtl:ml-xs">{icon}</div>
      <p className="mb-0">{title} </p>
    </div>
    <p className="mb-0 text-secondary">{value}</p>
  </div>
);
const TradingPlatformsFeatures = ({ value, type }) => (
  <div className={`flex ${type === "mt5" ? "flex-row-reverse ltr:text-right rtl:text-left" : "flex-row"} mb-md items-center`}>
    <GiCheckMark
      className={`text-secondary ${type === "mt5" ? "ltr:ml-[1em] rtl:mr-[1em]" : "ltr:mr-[1em] rtl:ml-[1em]"}`}
    />
    <p className="text-dark">{value}</p>
  </div>
);

const LayoutIpo = ({ pageData }) => {
  const data = pageData;
  const registrationLink = useRegistrationLink();

  const { gv } = useGlobalVariable();

  const {
    data: previousIpos,
    loading,
    error,
  } = useSwrHook("ipo", {
    query: {
      populate: {
        image: {
          fields: ["url", "width", "height"],
        },
        global_instrument: {
          fields: ["api_id", "slug"],
        },
      },
      // fields: ["name", "price", "slug", "status", "ipo_date", "buy_price", "sell_price"],
      filters: {
        status: {
          $eq: "previous",
        },
      },
      sort: ["publishedAt:desc"],
      pagination: {
        start: 0,
        limit: 9,
      },
    },
  });

  const {
    data: mostAnticipatedIpo,
    loading: isloading,
    error: err,
  } = useSwrHook("ipo", {
    query: {
      populate: {
        image: {
          fields: ["url", "width", "height"],
        },
      },
      fields: ["name", "price", "slug", "status", "ipo_date", "description"],
      filters: {
        most_anticipated: {
          $eq: true,
        },
      },
      sort: ["publishedAt:desc"],
      pagination: {
        start: 0,
        limit: 9,
      },
    },
  });

  const containerRef = useRef();
  const [ref, observedEntry] = useIntersection({
    root: containerRef.current,
    threshold: 0,
  });
  const inView = observedEntry && observedEntry.isIntersecting;

  useEffect(() => {
    const body = document.querySelector('body');
    if (!inView) {
      body.classList.add('show-sticky');
    } else {
      body.classList.remove('show-sticky');
    }
  }, [inView])

  return (
    <>
      <NextSeo
        twitter={{
          handle: 'CAPEX.com',
          site: 'CAPEX.com',
          cardType: 'summary_large_image',
        }}
        openGraph={{
          images: [{
            url: pageData?.image?.url,
            width: pageData?.image?.width,
            height: pageData?.image?.height,
          }]
        }}
      />
      <section className="mb-[8rem]">
        <div ref={ref} className="relative py-[3rem] pt-[7.88rem] md:pb-[5rem]">
          <div className="absolute top-0 h-full w-full ltr:left-0 rtl:right-0">
            <NextImage src="https://cdn.capex.com/uploads/past_ipo_bg_ba448c650a/past_ipo_bg_ba448c650a.png" />
          </div>
          <div className="container relative flex flex-col items-center justify-center pb-md">
            <NextImage src={data?.image?.url} width={data?.image?.width} height={data?.image?.height} />
            <h2 className="pt-sm">{gv("ipo_page_title", "raw")}</h2>
            <Link text={gv('get_ready_to_trade_it')} link={registrationLink} color="blue" arrow="left" />
          </div>
        </div>
        {/* ADD INSTRUMENT CHART IF THE IPO IS LISTED AND HAS INSTRUMENT CHART DATA */}
        {data?.global_instrument && (
          <div>
            <InstrumentHeader heading="h2" data={data?.global_instrument} />
            <InstrumentChart
              instrumentId={data?.global_instrument?.api_id}
              instrumentName={data?.global_instrument?.display_name}
            />
            <InstrumentTrends instrumentId={data?.global_instrument?.api_id} />
            <InstrumentInfo data={data?.global_instrument} />
          </div>
        )}
        <div className="container">
          <div className={`py-xl lg:pb-2xl ${data?.global_instrument && "border-t border-t-[#eee]"}`}>
            <h1 className="w-full text-center pb-xl md:pb-2xl inline-block mb-0">
              <span className="w-full">{gv("general_info_on")} </span>
              <span className="w-full">{data?.name}</span>
            </h1>
            <div className="flex flex-col md:flex-row">
              <div className="mb-lg flex w-full flex-col items-start ltr:border-r-[#eee] rtl:border-l-[#eee] md:mb-0 md:max-w-[50%] md:items-end ltr:md:border-r ltr:md:pr-[3rem] rtl:md:border-l rtl:md:pl-[3rem]">
                <p className="pb-sm text-2xl text-dark">{gv("about_the_company")}</p>
                <GeneralInfoList title={gv("name", "raw")} value={data?.name || "-"} />
                <GeneralInfoList title={gv("industry", "raw")} value={data?.industry || "-"} />
                <GeneralInfoList title={gv("employees", "raw")} value={data?.employees || "-"} />
                <GeneralInfoList title={gv("founding_year", "raw")} value={data?.founding_year || "-"} />
                <GeneralInfoList title={gv("ticker", "raw")} value={data?.ticker || "-"} />
              </div>
              <div className="flex w-full flex-col items-start md:max-w-[50%] ltr:md:pl-[3rem] rtl:md:pr-[3rem]">
                <p className="pb-sm text-2xl text-dark">{gv("filing_information")}</p>
                <GeneralInfoList title={gv("ipo_file_date", "raw")} value={data?.ipo_file_date || "-"} />
                <GeneralInfoList title={gv("nr_of_shares", "raw")} value={data?.nr_of_shares || "-"} />
                <GeneralInfoList title={gv("price", "raw")} value={data?.price || "-"} />
                <GeneralInfoList title={gv("exchange", "raw")} value={data?.exchange || "-"} />
                <GeneralInfoList title={gv("bookrunners", "raw")} value={data?.bookrunners || "-"} />
              </div>
            </div>
            <div className="mt-xl flex justify-center">
              <Button type="secondary" size="lg" link={registrationLink} label={gv("open_account", "raw")} />
            </div>
            <div className={`${styles.divider} my-2xl mb-lg lg:mb-2xl`}>
              <Divider type="left" text={gv("ipo_details", "raw")} />
            </div>
            <div className="flex flex-col flex-wrap justify-between md:flex-row">

              {data?.valuation && <IPODetailsList title={gv("valuation", "raw")} value={data?.valuation} />}
              {data?.ipo_figures && <IPODetailsList title={gv("ipo_figures", "raw")} value={data?.ipo_figures} />}

              {data?.valuation &&
                <IPODetailsList title={gv("valuation", "raw")} value={data?.valuation} />
              }
              {data?.ipo_figures &&
                <IPODetailsList title={gv("ipo_figures", "raw")} value={data?.ipo_figures} />
              }
              {data?.basic_info && (
                <div className={styles.twoColumns}>
                  <IPODetailsList
                    title={`${data?.name}`}
                    global_var={`${gv("basic_info_about", "raw")}${" "}`}
                    value={data?.basic_info}
                  />
                </div>
              )}
            </div>
            {data?.sources && (
              <div className="flex w-full justify-end py-lg">
                <div className="w-full md:max-w-[calc(50%-2rem)]">
                  <p className="text-sm italic">
                    {gv("sources", "raw")}: {data?.sources}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="bg-[#f8f8f8] py-[1.997rem] md:p-[1.997rem]">
          <div className="container flex flex-col items-center justify-center md:flex-row md:justify-between">
            <div className="flex w-full justify-center ltr:border-r-[#eee] ltr:pr-[1.666rem] rtl:border-l-[#eee] rtl:pl-[1.666rem] md:block md:w-[16.666%] md:py-xs ltr:md:border-r-2 rtl:md:border-l-2">
              <p className="text-2xl text-grey">{gv("summary", "raw")}</p>
            </div>
            <div className="flex w-full flex-wrap justify-center py-lg md:w-[63.33%] md:px-[1.666rem] md:py-0">
              <SummaryList title={gv("ticker", "raw")} value={data?.ticker || "-"} />
              <SummaryList title={gv("price", "raw")} value={data?.price || "-"} />
              <SummaryList title={gv("exchange", "raw")} value={data?.exchange || "-"} />
              <SummaryList title={gv("bookrunners", "raw")} value={data?.bookrunners || "-"} />
            </div>
            <div className="flex w-full justify-center md:w-[20%]">
              <Button type="primary" size="sm" link={registrationLink} label={gv("create_account", "raw")} />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="pt-[3rem] lg:pt-[5rem] text-center">
            <h3>{gv("key_points", "raw")}</h3>
            <p className="text-center text-sm">
              <span>{gv("ipo_quick_overview", "raw")} </span>
              <span className="rtl:hidden">
                {data?.name} {gv("ipo")}
              </span>
              <span className="ltr:hidden">
                {data?.name}
              </span>
            </p>
          </div>
          <div className="flex flex-wrap justify-between pt-xl">
            {data?.ipo_key_points?.map((box, index) => (
              <div
                key={index}
                className="mb-[1rem] flex w-full max-w-[calc(100%/2-0.5rem)] flex-col rounded-lg bg-[#f8f8f8] p-md md:mb-0 md:w-full md:max-w-[calc(100%/4-1rem)]"
              >
                <p className="text-sm">{box?.title}</p>
                <p className="py-sm ltr:font-gilroy rtl:font-dubai text-[2.5rem] font-bold leading-[1]">{box?.content}</p>
              </div>
            ))}
            <div className="relative mb-[1rem] flex max-w-[calc(100%/2-0.5rem)] cursor-pointer flex-col rounded-lg bg-secondary p-md text-white transition-all duration-300 ease-in-out hover:bg-secondaryHover md:mb-0 md:w-full md:max-w-[calc(100%/4-1rem)]">
              <Link className="absolute top-0 z-10 h-full w-full ltr:left-0 rtl:right-0" href={registrationLink}></Link>
              <h3 className="text-white">
                {gv("trade", "raw")} <br /> {data?.name}
              </h3>
              <div className="flex items-center text-white">
                <Arrow direction="right" />
                <p className="mb-0 text-white ltr:ml-sm rtl:mr-sm">{gv("open_account", "raw")}</p>
              </div>
            </div>
          </div>
          <p className="my-xl text-center text-xl">{gv("ipo_trading_online", {
            type: 'html', template: {
              ipo_name: data?.name
            }
          })}</p>
          <div className="flex flex-col items-center rounded-lg bg-[#f8f8f8] py-lg md:flex-row">
            <div className="w-full px-sm text-center ltr:md:max-w-[55%] rtl:md:max-w-[50%] md:px-[2rem] ltr:md:border-r-2 ltr:md:border-r-[#eee] ltr:md:text-left rtl:md:border-l-2 rtl:md:border-l-[#eee] rtl:md:text-right">
              <h4>
                <strong className="text-secondary">
                  <span className="ltr:hidden font-normal text-dark">{gv("sneak_peak", "raw")}</span>{" "}
                  {data?.name}{" "}
                  <span className="rtl:hidden">{gv("ipo", "raw")}{' '}</span>
                  <span className="rtl:hidden font-normal text-dark">{gv("sneak_peak", "raw")}</span>
                </strong>
                <br />
                {gv("see_why_traders_seek_and_cherich_ipos", "raw")}
              </h4>
              <div className="mt-lg mb-lg flex flex-wrap md:mb-0">
                <Link
                  text={gv("going_public_here_are_three_exciting_alternatives_to_ipos", "raw")}
                  link={registrationLink}
                  color="blue"
                  arrow="left"
                />
                <Link text={gv("spacs_the_initial_path_to_ipos", "raw")} link={registrationLink} color="blue" arrow="left" />
              </div>
            </div>
            <div className="w-full px-sm ltr:md:max-w-[45%] rtl:md:max-w-[50%] md:px-[2rem]">
              <div className="flex flex-col items-center md:flex-row">
                <div className="flex w-full justify-center">
                  <NextImage src={data?.image?.url} width={data?.image?.width} height={data?.image?.height} />
                </div>
                <div className="flex w-full flex-col items-center justify-center ltr:md:ml-md rtl:md:mr-md">
                  <SneakPeakList title={gv("status", "raw")} value={gv(`${data?.status === 'previous' ? `ipo_status_available` : `ipo_status_${data?.status}`}`)} icon={<ImHourGlass />} />
                  <SneakPeakList title={gv("ipo_date", "raw")} value={data?.ipo_date} icon={<IoCalendarSharp />} />
                  <SneakPeakList title={gv("price", "raw")} value={data?.price} icon={<GiPriceTag />} />
                </div>
              </div>
            </div>
          </div>
          <div className="py-[3rem] text-center md:py-[5rem]">
            <h3>{gv("previous_ipos", "raw")}</h3>
            <p className="text-dark">{gv("see_a_track_record_of_the_most_significant_ipos_of_the_past", "raw")}</p>
          </div>
          <div className="previousIpoSlider">
            <GenericSlider
              navigation
              pagination
              autoplay
              autoplayDelay={7000}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1025: {
                  slidesPerView: 3,
                },
              }}
            >
              {previousIpos?.data?.map((ipo, index) => (
                <SwiperSlide key={index}>
                  <IPOPast data={ipo?.attributes} />
                </SwiperSlide>
              ))}
            </GenericSlider>
          </div>
          <div className="py-[3rem] text-center md:py-[5rem]">
            <h3>{gv("most_aniticipated_ipos_of_2022", "raw")}</h3>
            <p className="text-sm">{gv("ipo_anticipated_subtitle", "raw")}</p>
          </div>
          <div className="flex flex-row flex-wrap justify-center">
            {mostAnticipatedIpo?.data?.map((ipo, index) => (
              <div className="w-full md:max-w-[calc(100%/2-1rem)]" key={index}>
                <IPOSimpleCard data={ipo?.attributes} />
              </div>
            ))}
          </div>
          {/* TRADING PLATFORMS */}
          {/* <div className="py-[3rem] text-center md:py-[5rem]">
          <h3>{gv("trade_ipos_section_title", "raw")}</h3>
          <p className="text-dark">{gv("trade_ipos_section_subtitle", "raw")}</p>
        </div> */}
          {/* <div className="flex flex-col justify-between md:flex-row">
          <div className="relative flex md:max-w-[calc(100%/3-1rem)]">
            <div className="flex w-full max-w-[60%] flex-col items-start md:max-w-full">
              <h3 className="mb-lg md:mb-xl">{gv("WebTrader", "raw")}</h3>
              <TradingPlatformsFeatures value={gv("advanced_charts", "raw")} />
              <TradingPlatformsFeatures value={gv("customizable_layouts", "raw")} />
              <TradingPlatformsFeatures value={gv("trading_central", "raw")} />
              <TradingPlatformsFeatures value={gv("user_friendly_interface", "raw")} />
              <TradingPlatformsFeatures value={gv("risk_management_tools", "raw")} />
              <TradingPlatformsFeatures value={gv("sophisticared_analysis_tools", "raw")} />
              <TradingPlatformsFeatures value={gv("intuitive_navigation_interface", "raw")} />
              <TradingPlatformsFeatures value={gv("key_statistics", "raw")} />
            </div>
            <div className="absolute top-0 h-full w-[45%] ltr:right-0 ltr:-mr-md rtl:left-0 rtl:-ml-md md:hidden">
              <NextImage
                layout="fill"
                objectFit={"cover"}
                src="https://cdn.capex.com/uploads/platform_mobile_982ff85567/platform_mobile_982ff85567.png"
                priority={true}
                alt={"Platforms"}
              />
            </div>
          </div> */}
          {/* DESKTOP PLATFORMS IMAGE */}
          {/* <div className="relative hidden w-full md:block md:max-w-[calc(100%/3-1rem)]">
            <div className="absolute top-0 h-full w-full ltr:left-0 rtl:right-0">
              <NextImage
                layout="fill"
                objectFit={"cover"}
                src="https://cdn.capex.com/uploads/platforms_e1a1f76b49/platforms_e1a1f76b49.png"
                priority={true}
                alt={"Platforms"}
              />
            </div>
          </div>
          <div className="relative flex md:max-w-[calc(100%/3-1rem)]">
            <div>
              <div className="absolute top-0 h-full w-[45%] ltr:left-0 ltr:-ml-md rtl:right-0 rtl:-mr-md md:hidden">
                <NextImage
                  layout="fill"
                  objectFit={"cover"}
                  src="https://cdn.capex.com/uploads/platform2_mobile_35883f6b7c/platform2_mobile_35883f6b7c.png"
                  priority={true}
                  alt={"Platforms"}
                />
              </div>
            </div>
            <div className="flex w-full max-w-[60%] flex-col items-end ltr:ml-auto rtl:mr-auto md:max-w-full">
              <h3 className="mb-lg md:mb-xl">{gv("metatrader", "raw")}</h3>
              <TradingPlatformsFeatures type={"mt5"} value={gv("30_build_in_indicators", "raw")} />
              <TradingPlatformsFeatures type={"mt5"} value={gv("9_timeframes", "raw")} />
              <TradingPlatformsFeatures type={"mt5"} value={gv("access_to_2000_free_custom_indicators", "raw")} />
              <TradingPlatformsFeatures type={"mt5"} value={gv("customizable_tools", "raw")} />
              <TradingPlatformsFeatures type={"mt5"} value={gv("easy_access_control_panels", "raw")} />
              <TradingPlatformsFeatures type={"mt5"} value={gv("instant_execution", "raw")} />
              <TradingPlatformsFeatures type={"mt5"} value={gv("intuitive_navigation_interface", "raw")} />
              <TradingPlatformsFeatures type={"mt5"} value={gv("multi_window_views", "raw")} />
            </div>
          </div>
        </div> */}
        </div>
      </section>

    </>
  );
};

export default LayoutIpo;
