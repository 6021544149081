import styles from './IPOBanner.module.css';
import Link from '@components/elements/Link';
import { IoCalendarSharp } from '@react-icons/all-files/io5/IoCalendarSharp';
import { ImHourGlass } from '@react-icons/all-files/im/ImHourGlass';
import { GiPriceTag } from '@react-icons/all-files/gi/GiPriceTag';
import NextImage from '@components/elements/Image';
import HTMLRender from '@components/core/HTMLRender';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';

const IPOBanner = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable()

  const STATUS = {
    available: gv('ipo_status_available', 'raw'),
    upcoming: gv('ipo_status_upcoming', 'raw'),
  }

  if (!data) {
    return null;
  }

  return (
    <div className="relative boder-b-[#dfdfe7] flex flex-col md:flex-row border-b pb-sm md:pb-[2.8125rem]  lg:mb-[2.8125rem] group">
      <div className={`${styles[`link-wrapper`]}`}>
        <Link href={data?.slug} />
      </div>
      <div className={`order-1 md:order-0  ${styles['column-left']}`}>
        <h3 className="mb-md tex-lg text-black">{data?.name}</h3>
        <div className="mb-md mt-0 text-base"><HTMLRender data={data?.description} /></div>
        <Link arrow="right" text={gv('read_full_ipo_report', 'raw')} link={data?.slug} />
      </div>
      <div className={`order-0 mb-md md:mb-0 md:order-1 ${styles[`column-middle`]}`}>
        <div className={`${styles[`logo-wrapper`]} group-hover:shadow-inner`}>
          <div className="min-w-[50%] md:min-w-full">
            <NextImage src={data?.image} width={data?.image?.width || data?.image?.data?.attributes?.width} height={data?.image?.height || data?.image?.data?.attributes?.height} />
          </div>
        </div>
      </div>
      <div className={`order-2 ${styles[`column-right`]}`}>
        <ul className={`${styles.list} py-md`}>
          <li>
            <ImHourGlass className={`${styles.icon}`} />
            <div>
              <span>{gv('status', 'raw')} </span>
              {STATUS[`${data?.status}`] || '-'}
            </div>
          </li>
          <li>
            <IoCalendarSharp className={`${styles.icon}`} />
            <div>
              <span>{gv('ipo_date', 'raw')} </span>
              {data?.ipo_date}
            </div>
          </li>
          <li>
            <GiPriceTag className={`${styles.icon}`} />
            <div>
              <span>{gv('ipo_price', 'raw')}  </span>
              {data?.price}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default IPOBanner;
