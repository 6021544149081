import React from "react";
import { Modal } from "@mantine/core";
import { useRef } from "react";
import Arrow from "@components/elements/Arrow";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Keyboard } from "swiper";
import styles from "../SponsorshipsSlide.module.css";
import NextImage from "@components/elements/Image";
import "swiper/css/keyboard";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";

const SliderModal = ({ data, open, setOpen, openSlideIndex }) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const store = useStore(
    (store) => ({
      dir: store.direction,
    }),
    shallow
  );

  return (
    <Modal
      styles={{
        modal: {
          backgroundColor: "transparent",
        },
      }}
      padding={"xs"}
      withCloseButton={false}
      centered
      opened={open}
      onClose={() => setOpen(false)}
    >
      <div className="sponsorsWrapper ModalSlider relative flex">
        <div
          ref={navigationPrevRef}
          className="absolute top-1/2 z-10 mt-[0.5%] h-[fit-content] cursor-pointer text-[3em] text-[#fff] transition-all duration-300 hover:text-lightGrey ltr:left-[-7%] ltr:mr-md rtl:left-[-12%] rtl:ml-md rtl:rotate-180"
        >
          <Arrow direction="left" />
        </div>
        <Swiper
          dir={store ? store.dir : "ltr"}
          keyboard={{
            enabled: true,
          }}
          modules={[Navigation, Keyboard]}
          initialSlide={openSlideIndex}
          navigation={{
            prevEl: navigationPrevRef.current ? navigationPrevRef.current : undefined,
            nextEl: navigationNextRef.current ? navigationNextRef.current : undefined,
          }}
          onSwiper={(swiper) => {
            // Delay execution for the refs to be defined
            setTimeout(() => {
              // Override prevEl & nextEl now that refs are defined
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
              // Re-init navigation
              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
        >
          {data?.images?.data?.map((image, index) => (
            <SwiperSlide key={index} styles={{ cursor: "pointer" }}>
              <div className={`${styles.imageWrapperModal}`} onClick={() => console.log(index)}>
                <NextImage
                  src={image?.attributes?.url}
                  width={image?.attributes?.width}
                  height={image?.attributes?.height}
                  objectFit
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          ref={navigationNextRef}
          className="absolute top-1/2 z-10 mt-[0.5%] h-[fit-content] cursor-pointer text-[3em] text-[#fff] transition-all duration-300 hover:text-lightGrey ltr:right-[-7%] ltr:ml-md rtl:right-[-12%] rtl:mr-md rtl:rotate-180"
        >
          <Arrow direction="right" />
        </div>
      </div>
    </Modal>
  );
};

export default SliderModal;
