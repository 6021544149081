import SectionTitle from '@src/components/elements/SectionTitle';
import Button from '@src/components/elements/Button';
import styles from './OnboardingHero.module.css';
import Image from 'next/image';
import Link from '@src/components/elements/Link';
import NextImage from '@src/components/elements/Image';

const OnboardingHero = (props) => {
  const data = props.data || props;
  if (!data) return null;

  const LANGSELECTOR = [
    {
      name: "EN",
      url: "/wiredmarket-faq",
      src: "https://cdn.capex.com/uploads/en_fc0fd22a60/en_fc0fd22a60.svg"
    },
    {
      name: "PL",
      url: "/wiredmarket-faq-pl",
      src: "https://cdn.capex.com/uploads/pl_9f2e58a4f8/pl_9f2e58a4f8.svg"
    },
    {
      name: "GR",
      url: "/wiredmarket-faq-gr",
      src: "https://cdn.capex.com/uploads/gr_3e7ba27871/gr_3e7ba27871.svg"
    },
    {
      name: "CZ",
      url: "/wiredmarket-faq-cz",
      src: "https://cdn.capex.com/uploads/cz_f37560813e/cz_f37560813e.svg"
    },
  ]

  return (
    <div className='container'>
      {data?.has_language_selector &&
        <div className={`w-full flex items-center justify-center pb-lg lg:pb-xl ${styles.firstChild}`}>
          {LANGSELECTOR.map((lang, index) => (
            <Link key={index} href={lang.url}>
              <div className='flex items-center hover:opacity-80'>
                <p className='mx-sm mb-0 text-dark'>{lang.name}</p>
                <NextImage src={lang.src} width={26} height={26} alt={lang?.name} />
              </div>
            </Link>
          ))}
        </div>}
      <div className={styles.title}>
        <SectionTitle data={data?.section_title} />
      </div>
      <div className='flex flex-col md:flex-row justify-between items-center mt-[2.75rem] w-full max-w-[80%] md:max-w-[65%] lg:max-w-[50%] mx-auto'>
        {
          data?.buttons?.map((button, index) => (
            <div key={index} className={`${styles.buttons}`}>
              <Button data={button} />
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default OnboardingHero;
