import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import './CareersCv.module.css';

const CareersCv = (props) => {
  const data = props.data || props;
  if (!data) return null;
  
  return (
    <>
      <div className='container'>
        <div className='relative flex flex-col md:flex-row justify-between bg-white shadow-lg rounded-lg'>
          <div className='order-1 md:order-0 relative w-full max-w-[50%] mx-auto md:max-w-[calc(50%-1rem)] flex justify-center'>
            <div className='md:absolute bottom-0 left-[15%]'>
              <NextImage src={data?.image} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
            </div>
          </div>
          <div className='order-0 md:order-1 w-full md:max-w-[calc(55%-1rem)] flex flex-col justify-center py-lg md:py-2xl px-md'>
            <SectionTitle data={data?.section_title} />
            <div className='flex justify-start mt-lg'>
              <Button data={data?.button} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareersCv;
