import styles from './SingleSlide.module.css'
import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';
import styleMapping from './StyleMapping';


const SingleSlide = ({image="", text="", alt="", color="white", valign="left", halign="middle", text_align="text-left", customClass=""}) => {
  return (
    <div  className={`${styles[`single-slide`]} ${customClass} flex relative h-[50vh] bg-gray-600 ${styleMapping(text_align)}  ${styleMapping(color)} ${styleMapping(halign)}`}>
        <NextImage src={image} />
        <div className={`container relative z-[1]  py-[5em]`}>
            <div className={`text-container ${styleMapping(valign)} flex`}>
                <div>
                  <HTMLRender data={text}/>
                </div>
            </div>
        </div>
    </div>
  );
};

export default SingleSlide;
