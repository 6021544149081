import './FooterCta.module.css'
import NextLink from 'next/link'
import Button from '@components/elements/Button';
import get from 'lodash/get';
import HTMLRender from "@components/core/HTMLRender";
const FooterCta = (props) => {
  const data = get(props, 'data.0', props);
  if (!props.data.length > 0 || data === null || data === undefined) return null;

  return (
    <>
      <div className="w-vw bg-primary ">
        <div className="container">
          <div className="py-lg flex flex-col items-center justify-between px-0 text-white lg:flex-row">
            <h3 className="mb-0 text-white">{data.title}</h3>
            <div className="rounded lg:border-l-[0.3125rem] max-w-[60%]">
              <div className="py-lg lg:ml-[2em] lg:py-0">
                <div className="text-center text-white lg:text-left"><HTMLRender data={data.content} /></div>
              </div>
            </div>
            <NextLink href={`${data.button_link}`}>
              <a>
                <Button data={data.button} />
              </a>
            </NextLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterCta;
