import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import HTMLRender from '@src/components/core/HTMLRender';
import styles from './CareersBenefits.module.css';

const CareersBenefits = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <>
      <div className='container'>
        <SectionTitle data={data?.section_title} />
        <div className='flex justify-center mt-lg'>
          <Button data={data?.button} />
        </div>
        <div className='flex flex-col md:flex-row justify-between items-start mt-lg 2xl:mt-2xl -mx-md md:mx-0 overflow-hidden md:overflow-auto'>
          <div className={`w-full md:max-w-[calc(100%/3-0.5rem)] flex flex-col items-start relative z-10 whitespace-nowrap`}>
            {data?.icon_text?.slice(0, 3).map((card, index) => (
              <div key={index} className={`flex justify-between items-center  bg-white py-md px-sm md:mb-xl xl:mb-[6.72rem] lg:mb-[4.5rem] lg:mb-[4.5rem] odd:self-start even:self-end md:even:self-start md:odd:self-start lg:first:ml-[8.5rem] md:last:ml-md ${styles.shadow}`}>
                <div className={styles.imageWrapper}>
                  <NextImage src={card?.icon} width={card?.icon?.data?.attributes?.width} height={card?.icon?.data?.attributes?.height} />
                </div>
                <div className="text-sm"> <HTMLRender data={card?.content} /></div>
              </div>
            ))}
          </div>
          <div className="mt-[8rem] hidden md:block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <NextImage src={data?.image} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
          </div>
          <div className="w-full md:max-w-[calc(100%/3-0.5rem)] flex flex-col items-end relative z-10 whitespace-nowrap mb-xl md:mb-0">
            {data?.icon_text?.slice(3, 6).map((card, index) => (
              <div key={index} className={`flex justify-between items-center  bg-white py-md px-sm md:mb-xl xl:mb-[6.72rem] lg:mb-[4.5rem] even:self-start odd:self-end md:self-start md:even:self-end md:odd:self-end lg:md:first:mr-[8.5rem] md:last:mr-md ${styles.shadow} last:after:hidden last:before:hidden`}>
                <div className={styles.imageWrapper}>
                  <NextImage src={card?.icon} width={card?.icon?.data?.attributes?.width} height={card?.icon?.data?.attributes?.height} />
                </div>
                <div className="text-sm"> <HTMLRender data={card?.content} /></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CareersBenefits;
