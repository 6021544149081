import HTMLRender from "@components/core/HTMLRender";
import styles from "./ThematixWhyTrade.module.css";
import Link from "@components/elements/Link";
import { SwiperSlide } from "swiper/react";
import { useRef, useState } from "react";
import { useIntersection } from "@mantine/hooks";
import { Swiper } from "swiper/react";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Navigation, Pagination, Autoplay } from "swiper";

const ThematixWhyTrade = (props) => {
  const data = props.data || props;
  const store = useStore(
    (store) => ({
      dir: store.direction,
    }),
    shallow
  );
  if (!data) return null;

  // Mantine Observer Hook to check when the graph is in the client's viewport
  const containerRef = useRef();
  const [ref, observedEntry] = useIntersection({
    root: containerRef.current,
    threshold: 0,
  });
  const inView = observedEntry && observedEntry.isIntersecting;

  const [tradeSwiper, setTradeSwiper] = useState({});

  if (inView) {
    tradeSwiper.slideTo(1);
  }

  return (
    <div className="overflow-hidden">
      <div className="container">
        {data?.title && <h2 className="mb-xl text-center ltr:lg:text-left rtl:lg:text-right">{data?.title}</h2>}
        <div className="trade-thematix-slide">
          <Swiper
            modules={[Navigation]}
            spaceBetween={15}
            dir={store ? store.dir : "ltr"}
            slidesPerView={1}
            navigation={true}
            loop={true}
            draggable={false}
            onSwiper={setTradeSwiper}
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              1280: {
                slidesPerView: 3,
                allowTouchMove: false,
                loop: false,
              },
            }}
          >
            {data?.thematix_trade_card?.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`flex h-full flex-col justify-center rounded-md bg-lightBorderGrey  px-lg py-lg transition-all duration-300 xl:h-auto xl:min-h-[18em] xl:py-xs xl:hover:min-h-[20em] ${
                    styles[`thematix-trade-card`]
                  }`}
                >
                  <h4 className="m-0 font-bold ltr:font-gilroy rtl:font-dubai">{item.title}</h4>
                  <div className="pt-md">
                    <HTMLRender data={item?.text} />
                  </div>
                  <div className={`${styles[`hidden-link`]}`}>
                    <div className="pt-md">
                      <Link data={data?.card_link} />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div ref={ref}></div>
        {data?.disclaimer && (
          <div className="pt-xl">
            <HTMLRender data={data?.disclaimer} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ThematixWhyTrade;
