import styles from "./Pagination.module.css";
import { useEffect, useState } from "react";

/* This example requires Tailwind CSS v2.0+ */
import { BsFillCaretRightFill } from "@react-icons/all-files/bs/BsFillCaretRightFill";
import { BsFillCaretLeftFill } from "@react-icons/all-files/bs/BsFillCaretLeftFill";
import Link from "@components/elements/Link";
import { useRouter } from "next/router";

export default function Pagination(props) {
  const router = useRouter();
  const totalPages = parseInt(props.totalPages, 10);
  const neighborsSize = parseInt(props.neighborsSize, 10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    try {
      const paginationNumber = parseInt(router.asPath.split("/").slice(-1)[0], 10);
      if (Number.isNaN(paginationNumber)) {
        setCurrentPage(1);
      } else {
        setCurrentPage(paginationNumber);
      }
    } catch (err) {
      if (currentPage !== 1) setCurrentPage(1);
    }
  }, [router.asPath]);

  function previousNeighborPages() {
    const previousPages = [];
    for (let i = currentPage - 1; i >= currentPage - neighborsSize && i > 1; i -= 1) {
      previousPages.push(
        <Link href={`${props.slug}${i}`} key={i}>
          {i}
        </Link>
      );
    }
    previousPages.reverse();
    return previousPages;
  }

  function nextNeighborPages() {
    const nextPages = [];
    for (let i = currentPage + 1; i <= currentPage + neighborsSize && i < props.totalPages; i += 1) {
      nextPages.push(
        <Link href={`${props.slug}${i}`} key={i}>
          {i}
        </Link>
      );
    }
    return nextPages;
  }

  return (
    <div
      className={`${totalPages > 1 ? `flex` : "hidden"} ${styles.pagination} justify-center ${
        props.type === "bordered" ? `${styles.bordered}` : ""
      } ${
        // eslint-disable-next-line no-nested-ternary
        props.alignament === "right"
          ? `md:justify-end`
          : props.alignament === "left"
          ? `md:justify-start`
          : `md:justify-center`
      }  pt-xl`}
    >
      <nav>
        <div className={`${styles["pagination-icon"]} ${styles["pagination-left"]}`}>
          {currentPage > 1 && (
            <Link href={`${props.slug}${parseInt(currentPage, 10) - 1}`}>
              <BsFillCaretLeftFill className="h-4 w-4 rtl:rotate-180" aria-hidden="true" />
            </Link>
          )}
        </div>
        <div className={`${styles["pagination-center"]}`}>
          {currentPage !== 1 && (
            <Link aria-hidden="true" href={props.slug + 1}>
              1
            </Link>
          )}
          {currentPage > neighborsSize + 2 && <span>...</span>}
          {previousNeighborPages()}
          <a className={`${styles.active}`}>{currentPage}</a>
          {nextNeighborPages()}
          {currentPage < props.totalPages - neighborsSize - 1 && <span>...</span>}
          {currentPage !== props.totalPages && (
            <Link href={`${props.slug}${props.totalPages}`}>{props.totalPages}</Link>
          )}
        </div>
        <div className={`${styles["pagination-icon"]} ${styles["pagination-right"]}`}>
          {currentPage < props.totalPages && (
            <Link href={`${props.slug}${parseInt(currentPage, 10) + 1}`}>
              <BsFillCaretRightFill className="h-4 w-4 rtl:rotate-180" aria-hidden="true" />
            </Link>
          )}
        </div>
      </nav>
    </div>
  );
}
