import instrumentsApi from "@utils/instrumentsApi";
import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import { AiOutlineQuestionCircle } from "@react-icons/all-files/ai/AiOutlineQuestionCircle";
import { Tooltip } from "@mantine/core";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";
import styles from "./InstrumentTrends.module.css";

const InstrumentTrends = (props) => {
  const { gv } = useGlobalVariable();
  const instrumentPrice = instrumentsApi(props.instrumentId, 10000);
  const instrumentObj = instrumentPrice?.currentData?.[`${props.instrumentId}`];

  const store = useStore(
    (store) => ({
      dir: store.direction,
    }),
    shallow
  );

  // const price = instrumentObj?.price;
  const low = instrumentObj?.low;
  const high = instrumentObj?.high;
  // const change = instrumentObj?.change;
  const sell = instrumentObj?.sell;
  // const buy = instrumentObj?.buy;
  const dailyDiff = high - low;
  const dailyDiffsmall = sell - low;
  const dailyProcLow = (dailyDiffsmall / dailyDiff) * 100;
  const dailyProcHigh = 100 - dailyProcLow;

  const { data: sentimentData } = useSwrHook("instrumentSentiment", {
    query: {
      instrument: props?.instrumentId,
    },
  });
  const shorts = sentimentData?.[`${props.instrumentId}`]?.percentageOfShorts;
  const longs = sentimentData?.[`${props.instrumentId}`]?.percentageOfLongs;

  return (
    (dailyProcLow && dailyProcHigh) || (longs && shorts) ?
      <section>
        < div className="container" >
          <div className="rounded-md bg-lightBorderGrey px-lg py-xl  md:px-xl md:py-2xl">
            <div className="grid gap-10 md:grid-cols-2">
              {dailyProcLow && dailyProcHigh && (
                <div>
                  <div className="flex flex-wrap items-center pb-lg text-sm uppercase text-lightGrey">
                    <span>{gv("daily_change")}</span>
                    <div className="mx-sm text-center">
                      <Tooltip
                        wrapLines
                        width={220}
                        withArrow
                        transition="fade"
                        transitionDuration={200}
                        label={gv("presents_actual_bid")}
                      >
                        <div className="text-md cursor-pointer text-center text-secondary ltr:font-body rtl:font-cairo">
                          <AiOutlineQuestionCircle size={20} />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="relative flex">
                    <div
                      className="h-[0.5em] rounded-md bg-secondary ltr:rounded-r-none rtl:rounded-l-none"
                      style={{ width: `${dailyProcLow}%` }}
                    />
                    <div
                      className="h-[0.5em] rounded-md bg-lightGrey ltr:rounded-l-none rtl:rounded-r-none"
                      style={{ width: `${dailyProcHigh}%` }}
                    />
                    <div
                      className="absolute bottom-[100%] pb-xs text-sm text-lightGrey ltr:-translate-x-1/2 rtl:translate-x-1/2"
                      style={store.dir === "ltr" ? { left: `${dailyProcLow}%` } : { right: `${dailyProcLow}%` }}
                    >
                      {sell}
                    </div>
                  </div>
                  <div className="flex justify-between pt-lg text-sm text-grey">
                    <div>
                      {gv("low")}: {low}
                    </div>
                    <div>
                      {gv("high")}: {high}
                    </div>
                  </div>
                </div>
              )}

              {longs && shorts && (
                <div>
                  <div className="flex flex-wrap items-center pb-lg text-sm uppercase text-lightGrey">
                    <span>{gv("traders_trend")}</span>
                    <div className="mx-sm text-center">
                      <Tooltip
                        wrapLines
                        width={220}
                        withArrow
                        transition="fade"
                        transitionDuration={200}
                        label={gv("traders_trend_exp")}
                      >
                        <div className="text-md cursor-pointer text-center text-secondary ltr:font-body rtl:font-cairo">
                          <AiOutlineQuestionCircle size={20} />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="relative flex">
                    <div
                      className="h-[0.5em] rounded-md bg-primary ltr:rounded-r-none rtl:rounded-l-none"
                      style={{ width: `${shorts}%` }}
                    />
                    <div
                      className="h-[0.5em] rounded-md bg-greenCandle ltr:rounded-l-none rtl:rounded-r-none"
                      style={{ width: `${longs}%` }}
                    />
                  </div>
                  <div className="flex justify-between pt-lg text-sm text-grey">
                    <div className="text-primary">
                      {shorts}% {gv("selling")}
                    </div>
                    <div className="text-greenCandle">
                      {longs}% {gv("buying")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div >
      </section >
      : null

  );
};

export default InstrumentTrends;
