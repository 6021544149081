import styles from './WebtraderFeatures.module.css';
import IconTextColumn from '@components/custom/IconTextColumn';
import Button from '@components/elements/Button';

const WebtraderFeatures = (props) => {
  const data = props.data || props;
  if(!data) return null;
  
  return (
    <>
      <div className='container'>
          <div className={`${styles[`feature-cards`]} mb-xl md:mb-lg`}>
          {
            data?.feature_icon_card.map((column, index) => (
              <IconTextColumn key={index} data={column} length={data?.featured_icon_card && data?.featured_icon_card?.length} />
            ))
          }
          </div>
         
         {data?.feature_card.length > 0 &&
          <div className="bg-lightBorderGrey pt-xl px-md xl:px-xl rounded-md pb-xl md:pb-0">
            <div className={`${styles[`feature-cards`]} ${styles[`no-icons`]}`}>
            {
              data?.feature_card.map((column, index) => (
                <IconTextColumn key={index} data={column} length={data?.featured_icon_card && data?.featured_icon_card?.length} />
              ))
            }
            </div>
          </div>
         }
        <div className="flex justify-center pt-xl">
          <Button type={data?.button?.type} size={data?.button?.size} arrow={data?.button?.arrow} label={data?.button?.label} link={data?.button?.link}/>
        </div>
       
         
      </div>
    </>
  );
};

export default WebtraderFeatures;
