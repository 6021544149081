import styles from './FooterLogo.module.css'
import NextImage from '@components/elements/Image';
import Button from '@components/elements/Button';

const FooterLogo = (props) => {
  const data = props.data[0] || props;

  return (
    <div className="relative pb-md lg:pb-xl lg:pt-0">
      <div className='w-full flex justify-center lg:justify-between items-start'>
        <div className='w-full flex justify-center lg:justify-start max-w-[calc(100%/2-1rem)]'>
          <NextImage
            src={data?.logo}
            width={data?.logo?.data?.attributes?.width}
            height={data?.logo?.data?.attributes?.height}
            alt="CAPEX.com"
            title="CAPEX.com"
          />
        </div>
        <div className='hidden lg:flex justify-center w-full max-w-[calc(100%/2-1rem)]'>
          <Button data={data?.button} />
        </div>
      </div>
    </div>
  );
};

export default FooterLogo;
