import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import Image from 'next/image';
import styles from './TradeHomeTools.module.css';
import { useState } from 'react';
import { Accordion } from '@mantine/core';

const TradeHomeTools = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  const data = props.data || props;


  if (!data) return null;

  return (
    <div className='relative hidden lg:flex container container-large bg-[#F8F8F8] rounded-[1.6667rem] overflow-hidden'>
      <div className="absolute top-0 ltr:right-[-0.5%] rtl:left-[-0.5%] rtl:scale-x-[-1] opacity-10 max-w-[32%] pointer-events-none transition-all duration-700">
        <NextImage src="https://cdn.capex.com/uploads/Vector_x_left_9f63442255/Vector_x_left_9f63442255.png" width={348} height={740} alt="x-icon" />
      </div>
      <div className="absolute top-0 ltr:right-[-0.5%] rtl:left-[-0.5%] rtl:scale-x-[-1] opacity-10 max-w-[32%] pointer-events-none transition-all duration-700">
        <NextImage src="https://cdn.capex.com/uploads/Vector_x_top_063880bc16/Vector_x_top_063880bc16.png" width={443} height={503} alt="x-icon" />
      </div>
      <div className="absolute bottom-[-7%] ltr:right-[-0.5%] rtl:left-[-0.5%] rtl:scale-x-[-1] opacity-10 max-w-[54%]  pointer-events-none transition-all duration-700">
        <NextImage src="https://cdn.capex.com/uploads/Vector_x_bottom_5b45cfcdfc/Vector_x_bottom_5b45cfcdfc.png" width={786} height={418} alt="x-icon" />
      </div>
      <div className='w-full mt-[2rem] 2xl:mt-[3rem]'>
        <SectionTitle data={data?.section_title} />
      </div>
      <div className='pt-[4.444rem] pb-[5rem] flex h-[36rem]'>
        <div className='w-full max-w-1/2 ltr:border-r rtl:border-l border-[rgba(153,153,153,0.5)]'>
          <Accordion
            icon={null}
            iconSize={0}
            initialItem={0}
            transitionDuration={700}
            classNames={{
              item: styles.accordionItem,
              control: styles.accordionControl,
              contentInner: styles.contentInner,
              icon: styles.accordionIcon,
              label: styles.accordionLabel,
              itemOpened: styles.accordionItemOpened
            }}
          >
            {data?.features?.map((feature, index) => (
              <Accordion.Item

                onClick={() => setActiveTab(index)}
                key={index}
                label={<p className={`transition-all duration-300 ease-in-out leading-[1.944rem] ${activeTab === index ? 'pointer-events-none font-bold text-primary text-[1.555rem]' : 'font-light text-black text-[1.222rem]'}`}>{feature?.title}</p>}
                className={`relative flex flex-col transition-all duration-700 ease-in-out mt-[1.3rem] rtl:rounded-tl-xl rtl:rounded-bl-xl ltr:rounded-tr-xl ltr:rounded-br-xl border-[0.5px] ${index === activeTab && 'bg-white py-[1rem]'}`}>
                <div>
                  <HTMLRender data={feature?.content} />
                </div>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
        <div className="relative w-full max-w-1/2 flex justify-center items-end ltr:-mr-md rtl:-ml-md">
          <div className={`absolute bottom-[5%]  left-[30%] origin-center w-[40%] h-[85%] bg-white shadow-md rounded-xl ${styles.skewed}`} />
          {data?.features?.map((featureIcon, index) => ((
            <div key={index} className={`max-w-[70%] 2xl:max-w-full ${activeTab === index ? `block ${styles.customAnimation}` : 'hidden'}`}>
              <NextImage src={featureIcon?.icon} width={featureIcon?.icon?.data?.attributes?.width} height={featureIcon?.icon?.data?.attributes?.height} />
            </div>
          )))}
        </div>
      </div>
    </div>
  );
};

export default TradeHomeTools;
