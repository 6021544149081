import Sections from "@components/sections";

export default function LayoutPage({ pageData, pageType }) {
  if (pageType !== "page") return null;

  return (
    <Sections
      pageType={pageData?.page_type}
      sections={pageData?.sections || []}
      pageId={pageData?.id}
    />
  );
}
