import styles from './ArticleRating.module.css'
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import HTMLRender from '@src/components/core/HTMLRender';
import { AiOutlineStar } from "@react-icons/all-files/ai/AiOutlineStar";
import { AiFillStar } from "@react-icons/all-files/ai/AiFillStar";
import { useEffect, useState } from 'react';
import { useRouter } from "next/router";

const ArticleRating = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable();
  const [rating, setRating] = useState("");
  const [comment, setComment] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const router = useRouter();

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = () => {
    // props.onSubmit({ rating, comment });
    setSubmitted(true);
  };

  useEffect(() => {
    setSubmitted(false);
  }, [router.asPath]);




  if (!data) {
    return null;
  }
  return (
    <div className="bg-whiteCream px-md py-xl lg:p-xl -mx-md sm:mx-0 sm:rounded-lg mb-2xl ltr:font-gilroy rtl:font-dubai">
      {submitted ?
        <>
          <h4 className='text-center font-semibold text-greenCandle'>{gv('thank_you_for_rating')}</h4>
        </>
        :
        <>
          <h4 className='text-center font-semibold mb-xl'>{gv('article_rating_title')}</h4>
          <div className='flex flex-col items-center'>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 w-full lg:w-auto">
              <div className="flex items-center cursor-pointer "
                onClick={() => handleRatingChange("awful")}>
                <div className='text-2xl mx-xs'>
                  {rating === "awful" ?
                    < AiFillStar className='text-[#FFD700]' />
                    :
                    <AiOutlineStar />
                  }
                </div>
                {gv('awful')}
              </div>
              <div className="flex items-center cursor-pointer "
                onClick={() => handleRatingChange("ok")}>
                <div className='text-2xl mx-xs'>
                  {rating === "ok" ?
                    < AiFillStar className='text-[#FFD700]' />
                    :
                    <AiOutlineStar />
                  }
                </div>
                {gv('ok')}
              </div>
              <div className="flex items-center cursor-pointer "
                onClick={() => handleRatingChange("great")} >
                <div className='text-2xl mx-xs'>
                  {rating === "great" ?
                    < AiFillStar className='text-[#FFD700]' />
                    :
                    <AiOutlineStar />
                  }
                </div>
                {gv('great')}
              </div>
              <div className="flex items-center cursor-pointer "
                onClick={() => handleRatingChange("awesome")} >
                <div className='text-2xl mx-xs'>
                  {rating === "awesome" ?
                    < AiFillStar className='text-[#FFD700]' />
                    :
                    <AiOutlineStar />
                  }
                </div>
                {gv('awesome')}
              </div>
            </div>
            <div className='pt-xl flex flex-col text-center justify-center w-full'>
              <div className={styles.inputWrapper}>
                <input
                  type="text"
                  id="comment"
                  value={comment}
                  onChange={handleCommentChange}
                  placeholder={gv("article_rating_input")}
                />
              </div>
              <div className={styles.buttonWrapper}>
                <button className={rating === "" ? `pointer-events-none opacity-40` : ``} type="submit" onClick={handleSubmit}>
                  {gv("submit")}
                </button>
              </div>
            </div>
          </div>
        </>
      }

    </div >
  );
};

export default ArticleRating;
