import LayoutAcademy from "./academy";
import LayoutArticle from "./article";
import LayoutFaq from "./faq";
import LayoutInstruments from "./instruments";
import LayoutIpo from "./ipo";
import LegalDocuments from "./legal_documents";
import LayoutPage from "./page";
import LayoutWebinar from "./webinar";

const layoutDictionary = {
  article: LayoutArticle,
  faq: LayoutFaq,
  instruments: LayoutInstruments,
  ipo: LayoutIpo,
  legal_document: LegalDocuments,
  page: LayoutPage,
  webinar: LayoutWebinar,
  academy: LayoutAcademy,
};

export default layoutDictionary;
