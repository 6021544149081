import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import Link from '@components/elements/Link';
import SectionTitle from '@components/elements/SectionTitle';
import TradeHomeInstruments from '../trade-home-instruments';
import styles from './InvestMarketsHero.module.css';
import InstrumentTable from '../trade-home-instruments/components/InstrumentsTable';

const InvestMarketsHero = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className="container">
      <div className={`text-center`}>
        <h1
          className={`ltr:font-gilroy rtl:font-dubai mb-lg lg:mb-xl text-[4.67rem] ltr:font-black rtl:font-bold leading-[1] text-black lg:text-[5.65em] ${styles.title}`}
        >
          <span>{data?.section_title.title}</span>
        </h1>
        <div className="hidden md:block">
          <HTMLRender data={data?.section_title.subtitle} />
        </div>
      </div>
      <div className="flex flex-col justify-between pt-0 md:flex-row md:pt-[6.667rem]">
        <div
          className={`flex w-full flex-col text-center md:max-w-[27%] ltr:md:text-left rtl:md:text-right xl:max-w-[25%] ${styles.exhangesTitle}`}
        >
          <div className="mx-auto max-w-[95%] md:max-w-full">
            <HTMLRender data={data?.exchanges_title} />
          </div>
          <ul className="mt-[1.667rem] hidden flex-col justify-between md:flex">
            {data?.exchanges_list?.map((item, index) => (
              <li key={index} className="mb-sm">
                <HTMLRender data={item?.text} />
              </li>
            ))}
          </ul>
          <div className="my-lg">
            <Button data={data?.button} />
          </div>
          <div className="mt-sm ltr:text-left rtl:text-right text-sm hidden lg:block md:mt-0">
            <Link data={data?.link} />
          </div>
        </div>
        <div className="h-full w-full flex-col ltr:pl-0 ltr:md:pl-[2.777rem] ltr:xl:pl-[4rem] rtl:pr-0 rtl:md:pr-[2.777rem] rtl:xl:pr-[4rem]">
          <div className='-mx-[1.111rem] pt-lg lg:pt-0'>
            <InstrumentTable data={props} />
          </div>
          <div className="mt-sm ltr:text-left rtl:text-right text-sm block lg:hidden md:mt-0">
            <Link data={data?.link} />
          </div>
          <div className="pt-lg text-sm">
            <HTMLRender data={data?.disclaimer} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestMarketsHero;