import HTMLRender from "@components/core/HTMLRender";
import GenericSlider from "@components/custom/GenericSlider";
import NextImage from "@components/elements/Image";
import SectionTitle from "@components/elements/SectionTitle";
import { useDeviceDetect } from "@utils/hooks";
import { useState } from "react";
import { SwiperSlide } from "swiper/react";
import styles from "./TradeHomeApps.module.css";
import { motion } from "framer-motion";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import useMobileLinks from "@lib/store/hooks/useMobileLinks";

const TradeHomeApps = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable();

  const device = useDeviceDetect();
  const { useGetMobileLink } = useMobileLinks();

  if (!data) return null;

  return (
    <div className="overflow-hidden">
      <div className="container-large container">
        <div className={`mx-auto mb-lg w-full max-w-[70%] lg:mb-[12rem] ${styles.title}`}>
          <SectionTitle data={data?.section_title} />
        </div>
        <div className="rounded-[1.11111rem] lg:bg-[#1B1616]">
          <div className="lg:container">
            <div className="flex flex-col justify-between lg:flex-row">
              {/* left Column */}
              <div className="hidden w-full max-w-[35%] flex-col items-start lg:flex">
                {/* Rating Card Desktop */}
                <div className={`rounded-[0.5555rem] bg-[#F6F6F6] ${styles.customShadow} p-lg text-center lg:-mt-xl`}>
                  <p className="pb-sm text-[3.111rem] text-dark ltr:font-gilroy ltr:font-black rtl:font-dubai rtl:font-bold">
                    {gv("app_rating")}
                  </p>
                  <p className="text-lg text-black">{gv("app_rating_text")}</p>
                  <NextImage
                    src={data?.apps_rating_image}
                    alt={data?.apps_rating_image?.data?.attributes?.alternativeText}
                    width={data?.apps_rating_image?.data?.attributes?.width}
                    height={data?.apps_rating_image?.data?.attributes?.height}
                  />
                  {/* <div className="mt-md w-full text-center">
                    <p className="mb-0 text-lg text-dark">
                      <strong>{gv("app_downloads_count")}</strong>
                      <br></br>
                      {gv("downloads")}
                    </p>
                  </div> */}
                </div>
                {/* Reviews Slider */}
                {/* <div
                className={`homepage-reviews-slider hidden w-full lg:block ${styles.sliderStyles} mt-xl mb-3xl cursor-pointer`}
              >
                <GenericSlider effect="fade" autoplay loop pagination spaceBetween={25}>
                  {data?.reviews?.map((review, index) => (
                    <SwiperSlide key={index}>
                      <div className={`flex flex-row justify-between py-md px-lg`}>
                        <div className="w-full max-w-[30%]">
                          <NextImage
                            src={review?.icon}
                            width={review?.icon?.data?.attributes?.width}
                            height={review?.icon?.data?.attributes?.height}
                          />
                        </div>
                        <div className={`flex w-full max-w-[65%] items-center ${styles.reviewText}`}>
                          <HTMLRender data={review?.content} />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </GenericSlider>
              </div> */}
              </div>
              {/* middle column, floating phone */}
              <div className="lg:max-w-[40%]">
                <motion.div
                  animate={device === "desktop" ? { y: [-50, -100, -50] } : {}}
                  transition={{ type: "tween", duration: 4, repeat: Infinity }}
                  className="relative flex w-full flex-col items-center justify-center pt-xl text-center md:pt-2xl lg:-mt-2xl lg:pt-0"
                >
                  <div className="relative -my-[25%] w-[250%] max-w-[70rem] md:-my-[15%] lg:-mb-[30%] lg:w-[65rem] lg:max-w-none">
                    <NextImage
                      src={data?.phones_image}
                      alt={data?.phones_image?.data?.attributes?.alternativeText}
                      width={data?.phones_image?.data?.attributes?.width}
                      height={data?.phones_image?.data?.attributes?.height}
                    />
                  </div>
                  {/* Rating Card Mobile */}
                  <motion.div
                    animate={device !== "desktop" ? { y: [-50, -100, -50] } : {}}
                    transition={{ type: "tween", duration: 4, repeat: Infinity }}
                    className={`absolute left-0 top-[60%] flex max-w-[30%] -translate-y-1/2 flex-col rounded-[0.5555rem] bg-[#F6F6F6] md:left-[20%] lg:hidden ${styles.customShadow} p-xs py-sm text-center`}
                  >
                    <p className="mb-xs pb-0 text-[1.55rem] text-dark ltr:font-gilroy ltr:font-black rtl:font-dubai rtl:font-bold">
                      {gv("app_rating")}
                    </p>
                    <p className="mb-xs text-base text-black">{gv("app_rating_text")}</p>
                    <NextImage
                      src={data?.apps_rating_image}
                      alt={data?.apps_rating_image?.data?.attributes?.alternativeText}
                      width={data?.apps_rating_image?.data?.attributes?.width}
                      height={data?.apps_rating_image?.data?.attributes?.height}
                    />
                  </motion.div>
                </motion.div>
              </div>
              {/* right column - apps download buttons */}
              <div className="relative flex w-full flex-row items-end justify-evenly  lg:mt-lg lg:max-w-[20%] lg:flex-col lg:justify-center">
                {data?.apps?.data?.map((app, index) => (
                  <div
                    key={index}
                    className={`relative top-0 my-xs overflow-hidden rounded-lg transition-all duration-300 hover:-top-[0.15rem] md:mx-md lg:mx-0 ${styles.appsDownloadButtons}`}
                  >
                    <a href={useGetMobileLink(app?.link)} target="_blank" rel="nofollow noreferrer" className="cursor-pointer">
                      <NextImage
                        src={app?.image?.url}
                        alt={app?.image?.alternativeText}
                        width={app?.image?.width}
                        height={app?.image?.height}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeHomeApps;
