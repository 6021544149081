import React from 'react'
import SectionTitle from '@src/components/elements/SectionTitle';
import HTMLRender from '@src/components/core/HTMLRender';
import styles from '../AcademyOffers.module.css';
import NextImage from '@src/components/elements/Image';

function DesktopAcademyOffers({ data }) {
    return (
        <div>
            <div className="bg-[#F8F6F6] rounded-[20px] relative overflow-hidden py-2xl px-[4rem]">
                <div className='absolute top-[7%] left-1/2 -translate-x-1/2 z-0'>
                    <NextImage
                        layout='intrinsic'
                        objectFit='cover'
                        width={650}
                        height={817}
                        src="https://cdn.capex.com/uploads/Trading_X_f697411072/Trading_X_f697411072.png"
                        priority
                        quality="100"
                        alt="Article Hero"
                    />
                </div>
                <div className='relative z-10'>
                    <SectionTitle data={data?.section_title} />
                    <div className='flex justify-between'>
                        {data?.content?.map((item, index) => (
                            <div key={index} className='w-full max-w-[calc(100%/2-2rem)]'>
                                <div className={styles?.contentTitle}>
                                    <HTMLRender data={item?.title} />
                                </div>
                                <div className='h-[1px] w-[60px] my-lg bg-primary shadow-[0px_5px_8px_#FF4848]' />
                                <HTMLRender data={item?.content} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DesktopAcademyOffers