import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';
import styles from './IndicesMajors.module.css'

const IndicesMajors = (props) => {
  const data = props.data || props;

  if (!data) {
    return null;
  } return (
    <div className="container">
      <div className='flex flex-col lg:flex-row flex-wrap justify-between'>
        {data?.content_column?.map((column, index) => (
          <div key={index} className={`flex flex-col justify-between w-full lg:max-w-[calc(33.33%-0.8rem)]`}>
            <div className='w-full relative bg-lightBorderGrey rounded mb-lg  flex ltr:justify-end rtl:justify-start overflow-hidden'>
              <div className='absolute top-0 left-0'>
                <NextImage src={column?.image} width={column?.image?.data?.attributes?.width} height={column?.image?.data?.attributes?.height} />
              </div>
              <p className='py-[1.7rem] px-[1.11rem] lg:pb-[2.7rem] w-[56%] text-right text-dark'>{column?.title}</p>
            </div>
            <div className="flex flex-col h-full">
              {column?.content?.map((item, index) => (
                <div key={index} className={`pb-lg md:pb-md mb-xl md:mb-md border-b border-[#dfdfe7] last:border-0 h-full lg:min-h-[15rem] max-h-full ${styles.content}`}>
                  <HTMLRender data={item?.content} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className='mt-lg lg:mt-0 flex justify-center'>
        <Button data={data?.button} />
      </div>
    </div>
  );
};

export default IndicesMajors;
