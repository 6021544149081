export default function getAlphabet(locale) {
    switch (locale) {
        case 'en':
        case 'eu':
            return ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'y'];
        case 'it':
            return ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'y'];
        case 'es':
        case 'lat':
            return ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'r', 's', 't', 'u', 'v', 'y'];
        case 'cz':
            return ['a', 'b', 'c', 'd', 'e', 'f', 'h', 'i', 'k', 'l', 'm', 'n', 'o', 'p', 's', 'u', 'v', 'z'];
        case 'de':
            return ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'k', 'l', 'm', 'o', 'p', 'q', 'r', 's', 't', 'ü', 'v', 'w', 'z'];
        case 'ro':
            return ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'r', 's', 't', 'u', 'v', 'y'];
        case 'ar':
        case 'ae-ar':
            return ['a', 'b', 'p', 'أ', 'إ', 'ا', 'ب', 'ت', 'ج', 'ح', 'خ', 'ر', 'ز', 'س', 'ص', 'ط', 'ع', 'غ', 'ف', 'ك', 'ل', 'م', 'ن', 'ه', 'و', 'ي'];
        case 'vn':
            return ['A', 'B', 'C', 'D', 'Đ', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y'];
        default:
            return ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'y'];
    }
}