import styles from "./QuickEdit.module.css";
import { Button, Menu } from "@mantine/core";
import { Settings, Edit, BrandReactNative, Code } from "tabler-icons-react";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";
const GetPageEditUrl = (pageType, pageId, locale) => {
  switch (pageType) {
    case "page":
      return `${process.env.NEXT_PUBLIC_API_STRAPI}/admin/content-manager/collectionType/api::page.page/${pageId}?plugins[i18n][locale]=${locale}`;
    case "reusable-component":
      return `${process.env.NEXT_PUBLIC_API_STRAPI}/admin/content-manager/collectionType/api::reusable-component.reusable-component/${pageId}?plugins[i18n][locale]=${locale}`;
    default:
      return "";
  }
};

const GetSectionEditUrl = (sectionKey, type) => {
  switch (type) {
    case "strapi":
      return `${process.env.NEXT_PUBLIC_API_STRAPI}/admin/plugins/content-type-builder/component-categories/sections/${sectionKey}`;
    default:
      return "";
  }
};

export function QuickEditPage({ pageData }) {
  const store = useStore(
    (store) => ({
      locale: store.locale,
    }),
    shallow
  );
  if (typeof window !== "undefined") {
    window.store = store;
    window.pageProps = pageData;
  }
  if (
    pageData &&
    process.env.NEXT_PUBLIC_QUICK_EDIT &&
    process.env.NEXT_PUBLIC_QUICK_EDIT === "true" &&
    (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "staging" || process.env.NODE_ENV == "local")
  ) {
    return (
      <Button
        size="xs"
        className={styles.Button}
        onClick={() => window.open(GetPageEditUrl(pageData.pageType, pageData.id, store.locale), "_blank")}
      >
        📝Edit
      </Button>
    );
  }
  return null;
}

export function QuickEditSection({ sectionKey, pageId, reusableSectionId, reusableContentTypeId }) {
  const store = useStore(
    (store) => ({
      locale: store.locale,
    }),
    shallow
  );
  if (
    sectionKey &&
    process.env.NEXT_PUBLIC_QUICK_EDIT &&
    process.env.NEXT_PUBLIC_QUICK_EDIT === "true" &&
    (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "staging" || process.env.NODE_ENV == "local")
  ) {
    const editOnPageOrReusableSection = (reusableSectionId && reusableSectionId !== '' ? 'reusable-component' : 'page')
    const id = reusableContentTypeId || pageId;
    return (
      <Menu
        className={`${styles.QuickEditSection} absolute top-5 right-5 hidden group-hover:block`}
        control={
          <Button variant="default" size="xs" compact onClick>
            ✏️ Edit {reusableSectionId !== '' ? `Reusable ${reusableSectionId}` : sectionKey}
          </Button>
        }
      >
        <Menu.Label>Options</Menu.Label>
        <Menu.Item
          icon={<Edit size={14} />}
          onClick={() => window.open(GetPageEditUrl(editOnPageOrReusableSection, id, store.locale), "_blank")}
        >
          Edit Content (on Page)
        </Menu.Item>
        <Menu.Item
          icon={<Settings size={14} />}
          onClick={() => window.open(GetSectionEditUrl(sectionKey, "strapi"), "_blank")}
        >
          Edit Structure
        </Menu.Item>
        <Menu.Item
          disabled
          icon={<Code size={14} />}
          onClick={() => window.open(GetSectionEditUrl(sectionKey, "strapi"), "_blank")}
        >
          Edit JSON Schema
        </Menu.Item>
        <Menu.Item
          disabled
          icon={<BrandReactNative size={14} />}
          onClick={() => window.open(GetSectionEditUrl(sectionKey, "strapi"), "_blank")}
        >
          Edit React Component
        </Menu.Item>

        {/* Menu items */}
      </Menu>
    );
  }
  return null;
}
