/* eslint-disable arrow-body-style */
import Arrow from "@src/components/elements/Arrow";
import styles from "./SitemapContent.module.css";
import Link from "@src/components/elements/Link";

const SitemapContent = (props) => {
  const data = props.data || props;
  const sitemap_content = data?.qd_sitemap_content?.data[0]?.sitemap;
  if (!data) return null;

  // function to check if item has children
  const checkChildren = (child) => {
    if (!child) return null;
    if (
      (child.children && child.children.length > 0) ||
      (child.instruments_children && child.instruments_children.length > 0) ||
      (child.article_children && child.article_children.length > 0) ||
      child.academyCategoriesWithLessons
    ) {
      return true;
    }
    return false;
  };

  // Array to store already rendered slugs so we dont have duplicates
  const renderedSlugs = [];

  // render second level deep nested children
  // example instruments in trading pages
  const renderDeepChildren = (children) =>
    children?.length > 0 &&
    children.map((deepChild) => {
      if (deepChild?.s && renderedSlugs.includes(deepChild.s)) {
        return null; // Skip rendering if slug has already been rendered
      }

      renderedSlugs.push(deepChild?.s);
      return (
        <div key={deepChild?.s} className={styles.nestedDetailsDeep}>
          <details className="text-black">
            <summary className="grid cursor-pointer grid-cols-2 grid-rows-1 gap-4 px-md py-[0.875rem] text-sm text-black lg:grid-cols-6 lg:px-[1.7rem]">
              <p className="mb-0 text-black lg:col-span-3 lg:px-[7rem]">
                {checkChildren(deepChild) && (
                  <i className="text-xs ltr:mr-[1rem] rtl:ml-[1rem]">
                    <Arrow direction="down" />
                  </i>
                )}
                {deepChild?.t || deepChild?.n}
              </p>
              <Link
                href={`${deepChild?.s}`}
                text={deepChild?.s}
                disablePrefetch={true}
                className="mb-0 text-secondary lg:col-span-3"
              />
            </summary>
            {deepChild.children &&
              deepChild.children.map((child) => {
                if (child?.s && renderedSlugs.includes(child.s)) {
                  return null; // Skip rendering if slug has already been rendered
                }
                renderedSlugs.push(child?.s); // Add current slug to renderedSlugs array
                return (
                  <div key={child?.s} className={styles.nestedDivDeep}>
                    <div className="text-black">
                      <div className="grid cursor-pointer grid-cols-2 grid-rows-1 gap-4 px-md py-[0.875rem] text-sm text-black lg:grid-cols-6 lg:px-[1.7rem]">
                        <p className="mb-0 text-black lg:col-span-3 lg:px-[9rem]">{child.t}</p>
                        <Link
                          href={child?.s}
                          text={child?.s}
                          className="mb-0 text-secondary lg:col-span-3"
                          disablePrefetch={true}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </details>
        </div>
      );
    });
  return (
    <div className="container flex flex-col px-0 lg:px-md">
      {sitemap_content?.map((item, itemIndex) => (
        <div key={item?.s} className={`${styles.mainWrapper}`}>
          <details open={itemIndex === 0 && true}>
            <summary className="grid cursor-pointer grid-cols-2 grid-rows-1 gap-4 px-md py-[0.875rem] text-black lg:grid-cols-6 lg:px-[1.7rem]">
              <p className="col-span-2 mb-0 text-xl text-black">
                <i className="ltr:mr-[1rem] rtl:ml-[1rem]">
                  <Arrow direction="down" />
                </i>{" "}
                {item?.t}
              </p>
            </summary>
            {item?.children?.map((child) => {
              if (child?.s && renderedSlugs.includes(child.s)) {
                return null; // Skip rendering if slug has already been rendered
              }
              renderedSlugs.push(child?.s); // Add current slug to renderedSlugs array
              return (
                <div key={child?.s} className={styles.nestedDetails}>
                  <details className="text-black">
                    <summary className="grid cursor-pointer grid-cols-2 grid-rows-1 gap-4 px-md py-[0.875rem] text-sm text-black lg:grid-cols-6 lg:px-[1.7rem]">
                      <p className="mb-0 text-base text-black lg:col-span-3 lg:px-[4rem]">
                        {checkChildren(child) && (
                          <i className="text-xs ltr:mr-[1rem] rtl:ml-[1rem]">
                            <Arrow direction="down" />
                          </i>
                        )}{" "}
                        {child?.t}
                      </p>
                      <Link
                        href={child?.s}
                        text={child?.s}
                        className="mb-0 text-secondary lg:col-span-3"
                        disablePrefetch={true}
                      />
                    </summary>
                    {child && (
                      <>
                        {renderDeepChildren(child.children)}
                        {renderDeepChildren(child.instruments_children)}
                      </>
                    )}
                  </details>
                </div>
              );
            })}
          </details>
        </div>
      ))}
    </div>
  );
};

export default SitemapContent;
