import HTMLRender from "@components/core/HTMLRender";
import styles from "./StickyRiskWarning.module.css";
import { useEffect } from "react";
import useRiskWarningSize from "@lib/store/hooks/useRiskWarningSize";
import { usePersistentStore, useStore } from "@lib/store";
import { shallow } from "zustand/shallow";
import useScrollDirection from "@utils/hooks";
import { useElementSize } from "@mantine/hooks";

const StickyRiskWarning = (props) => {
  const scrollDirection = useScrollDirection();
  const currentProductType = useStore((state) => state.currentProductType);
  const { ref: riskWarningRef, height: riskWarningHeight } = useElementSize();

  const { riskWarningHeight: riskWarningHeightStore, updateRiskWarningHeight: updateRiskWarningHeightStore } = usePersistentStore(
    (state) => state,
    shallow
  );

  useEffect(() => {
    if (riskWarningHeight !== riskWarningHeightStore) {
      updateRiskWarningHeightStore(riskWarningHeight);
    }
  }, [riskWarningHeight]);

  return (
    <div
      ref={riskWarningRef}
      className={`sticky-risk-warning left-0 z-[100] w-full bg-white text-center text-xs   leading-[1.1em] text-grey transition-transform duration-300  ease-in-out  lg:translate-y-0 lg:text-sm lg:leading-[1.25em] ${
        currentProductType?.attributes?.risk_warning_type === "adgm"
          ? `relative top-0  shadow-[0px_0px_4px_2px_rgb(0,0,0,0.07)]`
          : `fixed bottom-0  shadow-[0px_0px_4px_2px_rgb(0,0,0,0.07)]`
      }
    ${
      scrollDirection === "down" && currentProductType?.attributes?.risk_warning_type === "cysec"
        ? `translate-y-full`
        : `translate-y-0`
    }
    `}
    >
      <div className="py-[0.3rem] lg:py-xs">
        <div
          className={`${styles.containerLarge} container   text-base leading-4  xl:leading-[1.5]
          ${
            currentProductType?.attributes?.risk_warning_type === "adgm"
              ? `max-w-full`
              : `lg:px-xl xl:px-[1.111rem] ${styles.darkText}`
          } `}
        >
          <HTMLRender data={currentProductType?.attributes?.risk_warning} />
        </div>
      </div>
    </div>
  );
};

export default StickyRiskWarning;
