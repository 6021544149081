import SectionTitle from '@src/components/elements/SectionTitle';
import styles from './TradingViewMarkets.module.css';
import NextImage from '@src/components/elements/Image';
import Link from '@src/components/elements/Link';
import useGlobalVariables from '@lib/store/hooks/useGlobalVariables';

const TradingViewMarkets = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariables()

  if (!data) return null;

  return (
    <div className='container'>
      <SectionTitle data={data?.section_title} />
      <div className='flex flex-wrap gap-4 justify-center'>
        {data?.markets?.data?.map((market, index) => (
          <div
            key={index}
            className='w-full max-w-[calc(100%/2-0.5rem)] mb-[1rem] lg:max-w-[calc(100%/5-1rem)] flex flex-col items-center overflow-hidden bg-white rounded-lg shadow-[0px_0px_20px_rgba(0,0,0,0.1)] p-lg'>
            <div className="redFilter">
              <NextImage src={market?.icon?.url} width={30} height={30} />
            </div>
            <p className='mt-sm'>{market?.display_name}</p>
            <div className='text-sm text-center'>
              <Link text={gv('read_more')} href={market?.category_page?.slug} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TradingViewMarkets;

// CMS markets custom query

// {
//   fields: [
//     'display_name'
//   ],
//     populate: {
//     icon: {
//       fields: ['width', 'height', 'url']
//     },
//     category_page: {
//       fields: ['slug']
//     }
//   },
//   filters: {
//     category_page: {
//       slug: {
//         $notNull: true
//       }
//     }
//   }
// }