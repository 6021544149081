import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';

const ForexIntro = (props) => {
  const data = props.data || props

  if (!data) {
    return null;
  } return (

    <div id={data?.section_layout?.section_id} className="container relative scroll-mt-[8rem] lg:scroll-mt-[15rem]">
      <div className='text-center ltr:lg:text-left rtl:lg:text-right w-full pb-lg ltr:lg:pr-xl rtl:lg:pl-xl md:w-[75%] mx-auto lg:mx-0 lg:max-w-[55%]'>
        <h2>{data?.title}</h2>
        <div className='pb-xs'><HTMLRender data={data?.subtitle} /></div>
      </div>
      <div className='pb-[27%] mx-[-1.111rem] lg:pb-0 lg:rounded bg-primary text-white lg:max-w-[calc(100%-13.7rem)]'>
        <div className='w-full md:w-[75%] mx-auto lg:mx-0 lg:max-w-[68%] p-lg'>
          <p className='text-white text-center ltr:lg:text-left rtl:lg:text-right'>
            {data?.box_text}
          </p>
        </div>
      </div>
      <div className='max-w-[80%] mx-auto -mt-[24%] lg:max-w-[48%] lg:-mt-0 lg:absolute -bottom-[15%] ltr:right-0 rtl:left-0'>
        <NextImage src={data?.image} height={data.image.data.attributes.height} width={data.image.data.attributes.width} />
      </div>
    </div>
  )
};

export default ForexIntro;
