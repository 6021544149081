import styles from './ZachKingSection.module.css';
import NextImage from "@components/elements/Image";
import HTMLRender from '@src/components/core/HTMLRender';

const ZachKingSection = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className={`text-white ${styles.ZachKingSection} overflow-hidden`}>
      <div className='container'>
        <div className="flex  flex-col lg:flex-row items-center ">
          <div className='lg:w-[60%] relative h-full flex'>
            <div className='relative h-full ltr:xl:-left-[14%]  rtl:xl:-right-[14%]'>
              <NextImage src={data?.image?.data?.attributes?.url} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} alt="Zach King - Experience the
MAGIC
 OF TRADING" />
            </div>
          </div>
          <div className='lg:w-[40%]  text-center  ltr:lg:text-right rtl:lg:text-left flex flex-col justify-center pt-md pb-[2.5rem] lg:py-[5rem] lg:items-end'>
            <HTMLRender data={data.title} />
            {data?.word_list &&
              <div className='flex justify-between sm:justify-center lg:justify-end -mx-xs sm:-mx-sm pt-md lg:pt-lg'>
                {data?.word_list?.map((item, index) => (
                  <div key={index} className="px-xs sm:px-sm ltr:font-gilroy rtl:font-dubai text-h5 lg:text-h3 text-white font-[300]"><HTMLRender data={item.text} /> </div>
                ))}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZachKingSection;
