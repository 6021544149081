import HTMLRender from '@components/core/HTMLRender';
import SectionTitle from '@components/elements/SectionTitle';
import IframeResizer from 'iframe-resizer-react'
import styles from './EconomicCIframe.module.css';

const EconomicCIframe = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <>
      <div className="container">
        <div className={styles.title}>
          <SectionTitle data={data?.section_title} />
        </div>
        <div className="mb-lg w-full min-h-[50vh]">
          <div class={`${styles.iframeContainer}`}>
            <IframeResizer
              id="economicCalendar"
              src="https://site.recognia.com/capex/serve.shtml?page=economic_calendar_prelogin"
              allow="encrypted-media"
              style={{ minWidth: '100%' }}
              allowfullscreen
            />
          </div>
        </div>
        <div className={styles.disclaimer}>
          <HTMLRender data={data?.disclaimer} />
        </div>
      </div>
    </>
  );
};

export default EconomicCIframe;
