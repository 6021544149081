import styles from "../../TradingCInstruments.module.css";

export default function InstrumentDMARow({ cssParent, headers, cssClass, rowData, productType }) {
  return (
    <div key={productType} className={cssParent}>
      {Object.keys(headers[0]).map((key) => (
        <p key={key} className={styles[cssClass]}>
          {rowData[key]}
        </p>
      ))}
    </div>
  );
}
