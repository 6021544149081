import styles from './HeaderMenu.module.css'
import RenderMenu from './RenderMenu';
import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';

// TODO Add left arrow

const HeaderMenu = ({ locale, menu }) => {
  const { gv } = useGlobalVariable();
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleClick = () => setMenuOpen(value => !value);
  const dropdown = useRef(null);

  useEffect(() => {
    // only add the event listener when the dropdown is opened
    if (!menuOpen) return;
    function handleClick(event) {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }
    window.addEventListener("click", handleClick);
    // clean up
    return () => window.removeEventListener("click", handleClick);
  }, [menuOpen]);

  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = (url) => {
      setMenuOpen(false);

    }

    router.events.on('routeChangeStart', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  if (menu) {
    return (
      <>
        <div
          ref={dropdown}
          className={`${styles[`header-menu-wrapper`]} ${menuOpen ? styles.open : ''
            }`}
        >
          <div
            onClick={toggleClick}
            className={`xl:hidden ltr:ml-[1rem] rtl:mr-[1rem] ltr:pl-[1rem] ltr:pr-[0.5rem] rtl:pr-[1rem] rtl:pl-[0.5rem] cursor-pointer flex items-center h-full ${styles[`menu-toggle`]
              }`}
          >
            <div className={styles[`toggle-bg`]}></div>
            <div className="transition-none duration-500 ease-InOutBack">
              {gv('menu')}
            </div>
            <div className={styles.hamburger}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <div className={`main-menu ${styles[`header-menu`]}`}>
            <ul>
              <RenderMenu locale={locale} menu={menu} />
            </ul>
          </div>
        </div>
      </>
    );
  } return null

};

export default HeaderMenu;
