import { useLoginLink, useRegistrationLink } from "@lib/store/hooks/useActiveProductType";

import Button from "@components/elements/Button";
import Hamburger from "@components/elements/Hamburger";
import HeaderMenu from "@components/custom/HeaderMenu";
import LanguageSelector from "@components/custom/LanguageSelector";
import Link from "@components/elements/Link";
import Logo from "@components/elements/Logo";
import ProductTabs from "../ProductTabs";
import { Router } from "tabler-icons-react";
import StickyRiskWarning from "../StickyRiskWarning";
import WrongLicenceBar from "../WrongLicenceBar";
import { shallow } from "zustand/shallow";
import styles from "./NavBar.module.css";
import useActiveProductType from "@lib/store/hooks/useActiveProductType";
import { useEffect } from "react";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import useHeaderSize from "@lib/store/hooks/useHeaderSize";
import useLayoutSharedData from "@lib/store/hooks/useLayoutSharedData";
import { useRouter } from "next/router";
import { usePersistentStore, useStore } from "@lib/store";

const NavBar = ({
  logo = "red",
  color = "dark",
  background = "transparent",
  navigation = [],
  sticky = false,
  locale,
}) => {
  const router = useRouter();
  const { gv } = useGlobalVariable();
  const activeRegistrationLink = useRegistrationLink();
  const activeLoginLink = useLoginLink();
  const redHeader = logo === "white" && color === "white";
  const websiteLanguages = useStore((state) => state.websiteLanguages);
  const currentProductType = useStore((state) => state.currentProductType);
  const logoUrl = useStore((state) => state.logoUrl);

  // group websiteLanguages by key 'region'
  const websiteLanguagesPerRegion = websiteLanguages?.data?.reduce((r, a) => {
    r[a.attributes.region] = [...(r[a.attributes.region] || []), a];
    return r;
  }, {});


  const { headerRef, headerHeight } = useHeaderSize();
  const { headerHeight: headerHeightStore, updateHeaderHeight: updateHeaderHeightStore } = usePersistentStore(
    (state) => ({headerHeight: state.headerHeight, updateHeaderHeight: state.updateHeaderHeight}),
    shallow
  );


  useEffect(() => {
    if (headerHeight !== headerHeightStore && headerHeight !== 0) {
      updateHeaderHeightStore(headerHeight);
    }
  }, [headerHeight, headerHeightStore, router.asPath]);

  return (
    <div
      ref={headerRef}
      style={{
        zIndex: 100,
      }}
      className={`navbar
  ${sticky ? `sticky-navbar` : ` text-${color}`}
  ${redHeader ? `red-bg` : ``}
  ${background === "dark" ? `bg-[#111114]` : `bg-transparent`}
  `}
    >
      {/* <WrongLicenceBar /> */}
      {currentProductType?.attributes?.risk_warning_type === "adgm" && <StickyRiskWarning currentProductType={currentProductType} id="adgm_mobile_risk_warning" />}

      <div className="px-md lg:m-auto  lg:w-[68.88rem] xl:w-full xl:px-[2.75em] 2xl:px-[5.5555em]  ">
        <div className="flex h-[4.8888em] items-center  lg:relative">
          <div className={`xl:pr flex h-full items-center ltr:pr-sm rtl:pl-sm ${styles[`header-logo`]}`}>
            <div className="relative h-[2.055555em] w-[6.5em] ">
              <Logo color={sticky ? `white` : logo} link={`/${locale + logoUrl}`} />
            </div>
          </div>

          <div className="z-20 flex    grow  items-center justify-between ltr:pl-md rtl:pr-md">
            <div className=" relative z-[400] hidden lg:flex xl:items-center">
               {!["eu", "ro", "de", "cz", "it", "el", "es", "ae", "ae-ar", "za"].includes(locale)  && <ProductTabs color={color} />}
              <HeaderMenu locale={locale} menu={navigation} />
            </div>

            <div className=" z-1 flex  h-full items-center justify-end lg:relative ltr:ml-auto rtl:mr-auto">
              <div className="header-language-selector hidden  h-[4.8888em] text-inherit lg:block">
                <LanguageSelector
                  containerId="lang_selector_container_header"
                  langSelectorId="lang_selector_header"
                  locale={locale}
                  websiteLanguages={websiteLanguages}
                  websiteLanguagesPerRegion={websiteLanguagesPerRegion}
                  className="h-full"
                  grid
                />
              </div>
              {!["ae","ae-ar"].includes(locale)  && (
              <div className="link-wrapper hidden whitespace-nowrap lg:block ltr:pl-xs ltr:pr-lg rtl:pl-lg rtl:pr-xs">
                <Link
                  id="login_btn"
                  arrow="right"
                  color="black"
                  link={activeLoginLink}
                  text={gv("header_login_btn_text")}
                />
              </div>
              )}
              {!["eu", "ro", "de", "cz", "it", "el", "es", "za"].includes(locale) && (
              <div className="register-button hidden lg:block">
                <Button
                  id="register_btn"
                  size="sm"
                  link={activeRegistrationLink}
                  type={`${redHeader ? `white-borderless` : `primary`}`}
                  label={gv("register")}
                />
              </div>)}
            </div>
          </div>
          <div className="header-icons flex items-center lg:hidden ltr:ml-auto rtl:mr-auto">
            <Hamburger color={color} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
