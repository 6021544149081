import styles from './SocialBox.module.css';
import { GrFacebook } from '@react-icons/all-files/gr/GrFacebook';
import { GrLinkedin } from '@react-icons/all-files/gr/GrLinkedin';
import { GrTwitter } from '@react-icons/all-files/gr/GrTwitter';
import { GrYoutube } from '@react-icons/all-files/gr/GrYoutube';
import { RiInstagramFill } from '@react-icons/all-files/ri/RiInstagramFill';
import NextLink from 'next/link'
import HTMLRender from '@components/core/HTMLRender';
import SectionTitle from '@components/elements/SectionTitle';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';

const SocialBox = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable()

  if (!data) return null;

  return (
    <div className='container'>
      {data?.section_title &&
        <SectionTitle data={data?.section_title} />
      }
      <div className="w-full max-w-[58rem] mx-auto">
        <ul className="pl-0 flex flex-row items-center justify-around md:justify-between">
          {data?.social_apps?.data?.map((app, index) => (
            <li key={index} className="relative mb-0">
              <div className="relative  w-auto  overflow-hidden rounded text-xl text-[#ccc] hover:text-primary">
                <NextLink href={`${app?.link}`}>
                  <a
                    target={'_blank'}
                    className="absolute top-0 left-0 z-10 h-full w-full bg-transparent"
                  ></a>
                </NextLink>
                {app?.platform === 'Facebook' ? (
                  <div className='flex flex-col text-center items-center'>
                    <GrFacebook className="text-[2rem] md:text-[2.77rem]" />
                    <p className='hidden md:block h3 md:text-xl mt-md md:mt-lg'>Facebook</p>
                  </div>
                ) : app?.platform === 'Instagram' ? (
                  <div className='flex flex-col text-center items-center'>
                    <RiInstagramFill className="text-[2.8rem] md:text-[3.5rem]" />
                    <p className='hidden md:block h3 mt-md md:mt-lg'>Instagram</p>
                  </div>
                ) : app?.platform === 'LinkedIn' ? (
                  <div className='flex flex-col text-center items-center'>
                    <GrLinkedin className="text-[2.2rem] md:text-[2.77rem]" />
                    <p className='hidden md:block h3 mt-md md:mt-lg'>LinkedIn</p>
                  </div>
                ) : app?.platform === 'Twitter' ? (
                  <div className='flex flex-col text-center items-center'>
                    <GrTwitter className="text-[2.7rem] md:text-[3.2rem]" />
                    <p className='hidden md:block h3 mt-md md:mt-lg'>Twitter</p>
                  </div>
                ) : app?.platform === 'YouTube' ? (
                  <div className='flex flex-col text-center items-center'>
                    <GrYoutube className="text-[3rem] md:text-[3.5rem]" />
                    <p className='hidden md:block h3 mt-md md:mt-lg'>Youtube</p>
                  </div>
                ) : null}
              </div>
            </li>
          ))}
        </ul>
        <div className={styles.bgText}>
          <p className="font-bold">{gv('follow_us')}</p>
        </div>
      </div>
    </div>
  );
};

export default SocialBox;
