import useSwrHook from "@lib/services/hooks/swr/useSwrHook";

export default function useInstrumentSearch(searchValue, category) {
  const withCategoryFilter = category
    ? {
        filter: {
          category,
        },
      }
    : {};
  const { data, isValidating, error } = useSwrHook("searchInstruments", {
    query: {
      searchValue,
      ...withCategoryFilter,
    },
  });
  const parsedData = data?.data?.map((item) => ({
    value: item.attributes.api_id,
    label: item.attributes.display_name,
  }));

  return {
    data: !data ? [] : parsedData,
    isValidating,
    error,
  };
}
