import Button from '@components/elements/Button';
import ColoredBox from '@components/elements/ColoredBox';
import SectionLayout from '@components/elements/SectionLayout';
import SectionTitle from '@components/elements/SectionTitle';
import { useDeviceDetect } from '@utils/hooks';
import './MultiBoxes.module.css'

const MultiBoxes = (props) => {
  const data = props.data || props;
  const device = useDeviceDetect();

  if (!data) {
    return null;
  }

  const stackType = data?.stack_type
  const stackStyle = {
    width: `${device == 'desktop' ? `calc(100% / ${stackType == 'two per row' ? '2' : stackType == 'three per row' ? '3' : '1'} - 0.5rem)` : '100%'}`,
  }

  return (
    <div className="container">
      {data?.section_title ? <SectionTitle data={data.section_title} /> : null}
      <div className='w-full flex flex-col md:flex-row flex-wrap justify-between'>
        {data.box?.map((boxData, index) => {
          return (
            <div key={index} style={stackStyle} className={`mt-sm last:mr-0 bg-whiteCream rounded-md ${stackType == 'stacked' && 'md:max-w-[75%] lg:max-w-[60%] mx-auto last:mr-auto'} ${stackType == 'stackedFullWidth' && 'max-w-full w-full'}`}>
              <ColoredBox data={boxData} />
            </div>
          )
        })}
      </div>
      {
        data?.button &&
        <div className='flex justify-center mt-xl md:mt-2xl'>
          <Button data={data?.button} />
        </div>
      }
    </div>
  );
};

export default MultiBoxes;
