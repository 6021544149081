import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';
import styles from './InvestEtFsHero.module.css';

const InvestEtFsHero = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      <div className="flex flex-col lg:flex-row justify-between items-center">
        <div className="w-full lg:max-w-[calc(100%/2-2.55rem)] flex flex-col text-center ltr:lg:text-left rtl:lg:text-right">
          <div className={`${styles.title} mb-lg`}>
            <HTMLRender data={data?.title} />
          </div>
          <div className={`hidden lg:block pt-md text-[1.3rem] w-full leading-[1.5] mx-auto ${styles.content}`}>
            <HTMLRender data={data?.content} />
          </div>
          <div className="w-full max-w-[80%] flex flex-row lg:hidden justify-center mx-auto">
            <NextImage src={data?.hero_img} width={data?.hero_img?.data?.attributes?.width} height={data?.hero_img?.data?.attributes?.height} />
          </div>
          <div className="my-lg mt-2xl hidden lg:block">
            <Button data={data?.button} />
          </div>
          <div className={`${styles.disclaimer} hidden lg:block`}>
            <HTMLRender data={data?.disclaimer} />
          </div>
        </div>
        <div className="hidden w-fullmax-w-[calc(100%/2-2.55rem)] lg:flex flex-col">
          <NextImage src={data?.hero_img} width={data?.hero_img?.data?.attributes?.width} height={data?.hero_img?.data?.attributes?.height} />
        </div>
        <div className={`block lg:hidden mt-lg text-center ${styles.subtitleMobile}`}>
          <HTMLRender data={data?.subtitle} />
        </div>
        <div className="my-xl mb-sm mt-lg block text-center lg:hidden">
          <Button data={data?.button} />
        </div>
        <div className={`${styles.disclaimer} pb-lg block lg:hidden`}>
          <HTMLRender data={data?.disclaimer} />
        </div>
      </div>
    </div>
  );
};

export default InvestEtFsHero;
