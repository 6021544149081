import ColoredBox from '@components/elements/ColoredBox';
import SectionTitle from '@components/elements/SectionTitle';

const HomepageCompanyFeatures = (props) => {
  const data = props.data || props;

  if (!data) {
    return null
  } return (
    <div className='container'>
      {
        data?.section_title && <SectionTitle data={data.section_title} />
      }
      <div className='w-full flex-col md:flex-row flex flex-wrap justify-between'>
        {data.box?.map((boxData, index) => (
          <div key={index} className={`w-full ${data?.two_columns ? 'md:max-w-[calc(100%/2-0.5rem)]' : 'md:max-w-[calc(100%/3-0.5rem)]'} mt-sm ltr:last:mr-0 rtl:last:ml-0 bg-whiteCream rounded-md`}>
            <ColoredBox data={boxData} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default HomepageCompanyFeatures;
