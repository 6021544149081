import HTMLRender from '@components/core/HTMLRender';
import Arrow from '@components/elements/Arrow';
import { Accordion } from '@mantine/core';
import styles from './InvestMarketsFaq.module.css';
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";


const InvestMarketsFaq = (props) => {
  const data = props.data || props;
  if (!data) return null;

  const { gv } = useGlobalVariable()

  return (
    <div className='container'>
      <h2 className='text-center mb-xl'>{gv("faq", 'raw')}</h2>
      <Accordion
        iconPosition="right"
        transitionDuration={600}
        icon={<div className="text-secondary"><Arrow direction="down" /></div>}>
        {data?.FAQ_acc.map((faq, index) => (
          <Accordion.Item
            key={index}
            label={
              <div className={`${styles.title}`}>
                <HTMLRender data={faq?.title} />
              </div>
            }
            classNames={{
              control: styles.accordionControl,
              accordionLabel: styles.accordionLabel,
              itemOpened: styles.itemOpened,
              label: styles.accordionLabel,
              item: styles.accordionItem,
              itemTitle: styles.accordionItemTitle,
              contentInner: styles.contentInner,
              icon: styles.icon,
            }}
          >
            <div className={`w-full flex justify-between ${styles.content}`}>
              <HTMLRender data={faq.content} />
            </div>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default InvestMarketsFaq;
