import Arrow from '@components/elements/Arrow';
import Button from '@components/elements/Button';
import Link from '@components/elements/Link';
import Loaders from '@components/elements/Loaders';
import { IoSearchOutline } from '@react-icons/all-files/io5/IoSearchOutline';
import flattenDeep from "lodash/flattenDeep";
import { useState } from 'react';
import instrumentsApi from '../../../../utils/instrumentsApi';
import InstrumentRow from './components/index';
import styles from "./InstrumentTable.module.css";

const InstrumentTable = (props) => {

  const data = props.data || props;
  const instrumentsTab = data.global_instrument_types?.data.map((item) => item.attributes.global_instruments);

  // return <pre>{JSON.stringify(data,null,2)}</pre>
  return <>Instrument Table</>
  const [activeTab, setActiveTab] = useState(0);
  const [mobileSelectOpen, setMobileSelectOpen] = useState(false)
  const query = flattenDeep(instrumentsTab?.map((item) => item.data.map((item) => item.attributes.api_id))) //Merge all instruments from strapi into one array
  const instrumentData = instrumentsApi(query, 5000) //Fetch Data form api

  if (!data) {
    return null;
  }
    return (
      <>
        <div className="container">
          {/* Search Bar */}
          <div className={`${data.has_categ_tabs && `border-b border-b-[#dedede]`} ${data.on_markets && data.has_categ_tabs && 'border-none'}`}>
            <div className='py-sm px-sm w-full flex jus items-center h-[4rem]'>
              <IoSearchOutline className='text-xl text-[#4a4a4a]' />
              
              <input className={styles.input} type="text" id="search-instrument" placeholder="Search instruments..." />
            </div>
          </div>
          {/* Categories List */}
          {data.has_categ_tabs &&
            <div className='border border-[#dfdfe7] md:border-0 rounded-md my-md md:my-0'>
              {/* -- Desktop Select */}
            <div className='md:hidden' onClick={() => setMobileSelectOpen(!mobileSelectOpen)}>
              {data?.global_instrument_types?.data?.map((category, index) => (
                <div key={index} className={`py-md border-0 ${activeTab == index ? 'block' : 'hidden'} ${mobileSelectOpen ? 'border-b border-[#dfdfe7]' : 'border-0'}`}>
                  <span className='text-secondary text-base ml-md'>{category?.attributes?.featured && category?.attributes?.name}</span>
                </div>
              ))}
            </div>
              {/* Mobile Select -- */}
              <ul className={`${styles.categoryList} ${data.on_markets && data.has_categ_tabs ? 'justify-around md:bg-[#e5e5e5]' : 'justify-between'} ${mobileSelectOpen ? 'block' : 'hidden'} pl-0`}>
              {data?.global_instrument_types?.data?.map((category, index) => (
                <li onClick={() => { setActiveTab(index); setMobileSelectOpen(false) }} key={index} className={`w-full order-${category.attributes.order} ${activeTab == index && data.on_markets && data.has_categ_tabs ? 'md:bg-[#f6f6f6]' : 'bg-transparent'} ${activeTab == index && `text-secondary`} mb-0 cursor-pointer p-sm md:py-lg flex justify-center md:justify-center items-center`}>
                  {activeTab == index && <div className='hidden md:block mr-xs'><Arrow direction="right" /></div>}
                  <span className={`ml-xs md:m-0`}>{category?.attributes?.featured && category?.attributes?.name}</span>
                </li>
              ))}
            </ul>
            </div>
          }
          <div>
            {/* Table Header */}
            <div className='bg-[#fafafa]'>
              <ul className="flex items-center justify-between h-[4.25rem]">
                {data?.table_header?.map((item, index) => (
                  <li key={index} className={`${!item.show_mobile && 'hidden md:flex'} flex text-dark mb-0 py-md md:py-sm items-center justify-center w-full`}>
                    {item?.title}
                  </li>
                ))}
              </ul>
            </div>
            {/* Instruments List */}
            <div className={data.on_markets && 'shadow-lg'}>
              {instrumentData ? <ul>
                {instrumentsTab?.map(function mapInstrumentsTab(tab, indexTab) {
                  return (
                    <div key={indexTab}>
                      {
                        indexTab == activeTab && tab?.data?.map((instrument, indexList) => {
                        const currentInstrumentObject = instrumentData?.currentData?.[instrument?.attributes?.api_id]
                        const previousInstrumentObject = instrumentData?.previousData?.[instrument?.attributes?.api_id]
                        return (
                          <>
                            {instrument?.attributes?.featured &&
                              <li key={indexList} className='mb-0 relative border-b border-b-[#eeeff2]'>
                                <InstrumentRow instrument={instrument} currentInstrumentObject={currentInstrumentObject} previousInstrumentObject={previousInstrumentObject} />
                              </li>
                            }
                          </>
                          )
                        })
                      }
                    </div>
                  )
                })}
              </ul> :
                <div className='min-h-[15rem] flex items-center justify-center w-full'>
                  <Loaders color="primary" />
                </div>
              }
            </div>
          </div>
          {/*Table Footer */}
          {data?.has_table_footer ? <div className='hidden md:flex bg-whiteCream py-xs px-lg lg:px-xl justify-between items-center'>
            {data?.global_instrument_types?.data?.map((category, index) => (
              activeTab == index &&
              <div key={index}>
                <Link arrow="none" color="blue" link={category?.attributes?.bottom_link_url} text={category?.attributes?.bottom_link_text} />
              </div>
            ))}
            <div><Link arrow="left" color="blue" link={data?.link_url} text={data?.link_text} /></div>
          </div> :
            <div>
              <div className="hidden md:flex justify-center w-full pt-xl mt-md">
                <Button arrow="right" type="primary" size="lg" label={data?.link_text} />
              </div>
            </div>
          }
          <div className="md:hidden flex justify-center w-full my-xl">
            <Button type="primary" size="lg" label={data?.mobile_button_text} />
          </div>
        </div>
      </>
    )
};

export default InstrumentTable;
