import HTMLRender from '@components/core/HTMLRender';
import PressRoomCard from '@components/custom/PressRoomCard';
import Button from '@components/elements/Button';
import SectionLayout from '@components/elements/SectionLayout';
import SectionTitle from '@components/elements/SectionTitle';
import useSwrHook from '@lib/services/hooks/swr/useSwrHook';
import { Skeleton } from '@mantine/core';
import './LatestPress.module.css'

function getRandomWidth(min, max) {
  return Math.random() * (max - min) + min;
} // Generate random widths for the skeleton.

const LatestPress = (props) => {
  const data = props.data || props;

  if (!data) {
    return null;
  }

  const { data: pressRoomCards, isValidating, error } = useSwrHook('pressRoomCards', {
    query: {
      populate: {
        press_publisher: {
          populate: {
            logo: {
              fields: [
                'url',
                'width',
                'height'
              ]
            }
          }
        },
        pdf_file: {
          populate: "*",
          fields: ['url']
        }
      },
      fields: ["title", "description", "link_url", "publishedAt", 'press_type', 'publication_date'],
      sort: ["publication_date:desc"],
      pagination: {
        start: 0,
        limit: 4,
      },
    }
  });

  return (
    <div className="container" id={data?.section_layout?.section_id || ''}>
      <SectionTitle data={data?.section_title} />
      <ul className='flex flex-col md:flex-row  flex-wrap'>
        {pressRoomCards?.data?.map((pressRoomCard, index) => (
          <li className='mb-[3.33rem] pb-[2.777rem] last:mb-0 md:last:mb-[3.33rem] w-full border-b border-b-[#e6e6e6]' key={index}>
            {
              isValidating ?
                <>
                  <Skeleton height={190} mb="xl" />
                  <Skeleton height={8} mt={6} width={`${getRandomWidth(20, 100)}%`} radius="xl" />
                  <Skeleton height={8} mt={6} radius="xl" width={`${getRandomWidth(20, 100)}%`} mb="xl" />
                  <Skeleton height={8} mt={6} width={`${getRandomWidth(20, 80)}%`} radius="xl" />
                </>
                :
                <PressRoomCard data={pressRoomCard?.attributes} />
            }
          </li>
        ))}
      </ul>
      <div className='mb-xl'>
        <HTMLRender data={data?.disclaimer} />
      </div>
      {
        data?.button &&
        <div className='mt-lg flex justify-center'>
          <Button data={data?.button} />
        </div>
      }
    </div>
  );
};

export default LatestPress;
