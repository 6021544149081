import Button from "@src/components/elements/Button";
import { useEffect, useState } from "react";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import styles from "./WrongLicenceBar.module.css";
import NextLink from "next/link";
import { getCookie } from "cookies-next";
import useCheckLicenseCookie from "./hooks/useCheckLicenseCookie";
import { usePersistentStore, useStore } from "@lib/store";
import { Router } from "tabler-icons-react";
import { useRouter } from "next/router";

const Bar = ({ props }) => {
  const setActiveWrongLicenseBar = usePersistentStore((state) => state.setActiveWrongLicenseBar);
  const correctLocale = getCookie("regulation_locale") || "en";
  const { gv } = useGlobalVariable();

  return (
    <div className="wrong-licence-bar text-xs lg:text-sm">
      <div className="flex flex-col items-center justify-between bg-[#ddd] px-md py-[0.5rem] lg:flex-row lg:py-xs">
        <div
          id="wrong_licence_disclaimer"
          className="pb-[0.3rem] text-center text-dark lg:pb-0 lg:ltr:text-left rtl:lg:text-right"
        >
          {gv("wrong_licence_bar")}
        </div>
        <div id="wrong_licence_proceed_btn" className={`flex items-center ${styles.buttonProceed}`}>
          <NextLink href={`/${correctLocale}`}>
            <Button label={gv("change_license_text_accept_sub")} size="sm" type="primary" />
          </NextLink>
          <div className="ltr:pl-md rtl:pr-md">
            <Button
              label={gv("change_license_text_decline")}
              size="sm"
              type="link"
              onClick={() => setActiveWrongLicenseBar(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const WrongLicenceBar = ({ props }) => {
  useCheckLicenseCookie();
  const router = useRouter();
  const isWrongLicence = getCookie("wrong_license") || false;
  const setActiveWrongLicenseBar = usePersistentStore((state) => state.setActiveWrongLicenseBar);
  const activeWrongLicenseBar = usePersistentStore((state) => state.activeWrongLicenseBar);

  useEffect(() => {
    if (isWrongLicence) {
      setActiveWrongLicenseBar(true);
    } else if (activeWrongLicenseBar) {
      setActiveWrongLicenseBar(false);
    }
  }, [isWrongLicence]);


  if (!router.isReady || !activeWrongLicenseBar) {
    return null;
  }

  return <Bar />;
};

export default WrongLicenceBar;
