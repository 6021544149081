import styles from './Divider.module.css'

const Divider = (props) => {
  const data = props.data || props;
  return (
    <div>
      <div className={`${data?.size === 'small' ? `${styles[`small-divider`]}` : ""}`}>
        {data?.type === 'left' ? (
          <div className={`${styles.divider} ${styles[`title-left`]}`}>
            <span>{data?.text}</span>
          </div>
        ) : null}
        {data?.type === 'center' ? (
          <div className={`${styles.divider} ${styles[`title-center`]} font-bold text-lg`}>
            <span>{data?.text}</span>
          </div>
        ) : null}
        {data?.type === 'right' ? (
          <div className={`${styles.divider} ${styles[`title-right`]}`}>
            <span>{data?.text}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Divider;
