import './AcademyExplore.module.css'
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { shallow } from "zustand/shallow";
import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import { useStore } from "@lib/store";
import ArticleExploreCard from "@components/custom/ArticleExploreCard";

const AcademyExplore = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable();

  const store = useStore(
    (store) => ({
      locale: store.locale,
    }),
    shallow
  );
  const { data: exploreData, isValidating } = useSwrHook("academyCategories", {
    query: {
      fields: ['title', 'description', 'category', 'unique_key'],
      filters: {
        unique_key: {
          '$eq': data?.articleType
        }
      },

      populate: {
        academy_subjects: {
          fields: ['title', 'slug', 'updatedAt', 'difficulty_level'],
          limit: 3,
          sort: ['updatedAt:desc'],
          filters: {
            id: {
              $ne: data?.articleId,
            }
          },
          populate: {
            global_analyst: {
              fields: '*',
              populate: {
                image_variant_a: {
                  fields: ['url', 'width', 'height', 'formats']
                }
              },
            },
          }
        }
      }
    },
  });


  if (!data) {
    return null;
  }
  return (
    <div className="mb-2xl">
      <h2 className='text-center mb-xl'>{gv('read_more')}</h2>

      <div className='grid-cols-1 lg:grid-cols-3 gap-4 grid'>
        {exploreData &&
          exploreData?.data[0]?.attributes?.academy_subjects?.data.map((card, index) => (
            <div key={index} className='h-full'>
              <ArticleExploreCard slug={`/${store.locale}`} data={card} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default AcademyExplore;
