import NewsCard from "@components/custom/NewsCard";
import Pagination from "@components/elements/Pagination";
import SectionTitle from "@components/elements/SectionTitle";
import { useStore } from "@lib/store";
import get from "lodash/get";
import { shallow } from "zustand/shallow";

function OverviewPage({ doNotRender = false }) {
  const paginationProps = useStore((store) => store.paginationProps, shallow);
  const articles = paginationProps?.articles;
  const currentPage = parseInt(get(paginationProps, "pagination_no", 0), 10) + 1;
  const slug = paginationProps?.slug;

  if (!articles?.data || doNotRender) return null;

  return (
    <>
      <div className="flex flex-col flex-wrap justify-between   md:flex-row">
        {paginationProps.articles?.data?.map((overviewCard, index) => (
          <div
            className="mb-[3.33rem] last:mb-0 md:w-full md:max-w-[calc(100%/3-0.5rem)] md:last:mb-[3.33rem]"
            key={index}
          >
            <NewsCard data={overviewCard?.attributes} order={index} />
          </div>
        ))}
      </div>

      <div className="flex justify-center">
        <Pagination
          changeUrl
          type="primary"
          alignament="center"
          totalPages={articles?.meta?.pagination?.pageCount}
          neighborsSize="1"
          currentPage={currentPage}
          slug={slug}
        />
      </div>
    </>
  );
}

const OverviewShowCase = (props) => {
  const data = props.data || props;

  if (!data) {
    return null;
  }

  return (
    <div className="container">
      <SectionTitle data={data.section_title} />
      <OverviewPage />
      <OverviewPage doNotRender />
    </div>
  );
};

export default OverviewShowCase;
