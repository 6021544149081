import HTMLRender from '@components/core/HTMLRender';
import styles from './ColoredBox.module.css'

const ColoredBox = (props) => {
  const data = props.data || props;

  return (
    <>
      <div className={`${styles['colored-box']}
        ${styles[`box-size-${data.size}`]} rounded-lg 
        ${data.center && 'text-center'}
        ${data.type == 'blue' ? `${styles['red-box']} bg-secondary text-white` : ''}
        ${data.type == 'grey' ? `${styles['grey-box']} bg-whiteCream text-grey` : ''}
        ${data.type == 'red' ? `${styles['red-box']} bg-primary text-white` : ''}
        ${data.type == 'white' ? `${styles['white-box']} bg-[rgba(127,127,127,.2)] text-white` : ''}
        `}
        >
        <HTMLRender data={data.text} />
      </div>
      
    </>
  );
};

export default ColoredBox;
