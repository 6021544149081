import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import SectionTitle from '@components/elements/SectionTitle';
import Columns from '@components/sections/columns';
import styles from './IndicesIntro.module.css';

const IndicesIntro = (props) => {
  const data = props.data || props;

  return (
    <div className="container">
      <div className={styles.title}>
        <SectionTitle data={data.section_title} />
      </div>
      <div className={styles.cols}>
        <Columns data={data.multi_columns} />
      </div>
      {data?.content_above_button &&
        <div className={`${styles.content}  mt-xl text-center`}>
          <HTMLRender data={data.content_above_button} />
        </div>
      }
      <div className="text-center mt-xl md:mt-lg">
        <Button data={data.button} />
      </div>
    </div>
  );
};

export default IndicesIntro;
