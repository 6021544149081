import HTMLRender from '@src/components/core/HTMLRender';
import Button from '@src/components/elements/Button';
import SectionTitle from '@src/components/elements/SectionTitle';
import styles from './PromotionsPromos.module.css';

const PromotionsPromos = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container' id={data?.section_layout?.section_id}>
      <SectionTitle data={data?.section_title} />
      <div className='flex flex-col md:flex-row justify-between border-b pb-lg'>
        {
          data?.promos?.map((promo, index) => (
            <div key={index} className="w-full md:max-w-[calc(100%/3-0.5rem)] flex flex-col justify-between items-center bg-[#f6f6f6] py-lg px-sm rounded-lg mb-md">
              <p className='text-dark text-xl text-center'>{promo?.title}</p>
              <div className={`${styles.content}`}>
                <HTMLRender data={promo?.content} />
              </div>
              <div className='mt-lg'>
                <Button data={data?.button} />
              </div>
            </div>
          ))
        }
      </div>
      <div className='mt-lg lg:mt-2xl'>
        <HTMLRender data={data?.disclaimer} />
      </div>
    </div>
  );
};

export default PromotionsPromos;
