import FeaturedInstrument from '@components/elements/FeaturedInstrument';
import './MarketsShowCase.module.css'

const MarketsShowCase = (props) => {
  const data = props.data || props
  const featuredInstrument = data?.instruments?.data;

  if (!data) {
    return null
  } return (
    <div className='container'>
      <div className="text-center mb-xl">
        <h2>{data?.title}</h2>
        <p>{data?.subtitle}</p>
      </div>
      <div>
        <FeaturedInstrument data={featuredInstrument} />
      </div>
    </div>
  )
};

export default MarketsShowCase;
