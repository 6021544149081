export default function flagMapping(flag) {
    switch (flag) {
      case "en":
        return "gb";
      case "cs":
        return "cz";
      case "ar":
        return "sa";
      default:
        return flag;
    }
  }
  