import NewsCard from "@components/custom/NewsCard";
import SectionTitle from "@components/elements/SectionTitle";
import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import { useStore } from "@lib/store";
import { Skeleton } from "@mantine/core";
import { shallow } from "zustand/shallow";
import styles from "./AcademyShowCase.module.css";

function getRandomWidth(min, max) {
  return Math.random() * (max - min) + min;
} // Generate random widths for the skeleton.

const AcademyShowCase = (props) => {
  const data = props.data || props;
  const store = useStore(
    (store) => ({
      locale: store.locale,
      singularApi: store.singularApi,
    }),
    shallow
  );

  const { data: slugData, loading: loadingSlug } = useSwrHook("getNestedPage", {
    query: {
      _limit: 1,
      locale: store.locale,
      populate: {
        global_pages: {
          fields: ["slug"],
        },
      },
      filters: {
        collectionAPI: {
          $eq: store.singularApi,
        },
      },
    },
  });

  const {
    data: featuredAcademyCards,
    isValidating,
    error,
  } = useSwrHook("getArticles", {
    query: {
      locale: store.locale,
      populate: {
        articles_category: {
          populate: {
            page: {
              populate: {
                child_nested_page: "*",
              },
            },
          },
        },
        lessons: {
          fields: ["title"],
        },
        image: {
          fields: ["url", "width", "height"],
        },
      },
      filters: {
        articles_category: {
          name: "Market News",
        },
      },
      fields: ["title", "slug"],
      sort: ["publishedAt:asc"],
      pagination: {
        pageSize: 3,
      },
    },
  });
  const getSlugArticle = () => {
    if (slugData && slugData.data && slugData.data.length > 0) {
      const item = slugData.data[0];
      if (item.attributes && item.attributes.global_pages.data.length == 0) {
        return item.attributes.alternative_page_slug;
      } else {
        return item.attributes.global_pages.data[0].attributes.slug;
      }
    }
    return "";
  };

  if (!data) {
    return null;
  }
  return (
    <div className="container">
      <SectionTitle data={data?.section_title} />
      <div className="flex flex-col flex-wrap   md:flex-row">
        {featuredAcademyCards?.data?.map((AcademyCard, index) => (
          <div className="mx-xs mb-[3.33rem] last:mb-0 md:w-[31.67%] md:last:mb-[3.33rem]" key={index}>
            {isValidating ? (
              <>
                <Skeleton height={190} mb="xl" />
                <Skeleton height={8} radius="xl" width={`${getRandomWidth(10, 80)}%`} mb="xl" />
                <Skeleton height={8} mt={6} width={`${getRandomWidth(20, 100)}%`} radius="xl" />
                <Skeleton height={8} mt={6} radius="xl" width={`${getRandomWidth(20, 100)}%`} mb="xl" />
                <Skeleton height={8} mt={6} width={`${getRandomWidth(20, 80)}%`} radius="xl" />
              </>
            ) : (
              <NewsCard
                data={AcademyCard?.attributes}
                locale={store?.locale}
                slug={`${store.locale}/${getSlugArticle()}`}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AcademyShowCase;
