import NextImage from '@components/elements/Image';
import List from '@components/elements/List';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './IndicesSteps.module.css';

const IndicesSteps = (props) => {
  const data = props.data || props;

  if (!data) {
    return null;
  } return (
    <div className="container">
      <div className='flex justify-between items-center'>
        <div className="w-full flex flex-col lg:block lg:w-[calc(50%-2rem)]">
          <div className={styles.title}>
            <SectionTitle data={data.section_title} />
          </div>
          <div className='max-w-[90%] mx-auto py-xl lg:hidden'>
            <NextImage src={data?.image} height={data?.image?.data?.attributes?.height} width={data?.image?.data?.attributes?.width} />
          </div>
          <div className={styles.list}>
            <List type="bullet" list_items={data?.list_items} />
          </div>
        </div>
        <div className="hidden lg:block w-[calc(50%-2rem)]">
          <NextImage src={data?.image} height={data?.image?.data?.attributes?.height} width={data?.image?.data?.attributes?.width} />
        </div>
      </div>
    </div>
  );
};

export default IndicesSteps;
