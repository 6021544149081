import HTMLRender from '@components/core/HTMLRender';
import Arrow from '@components/elements/Arrow';
import Button from '@components/elements/Button';
import { IoSearchOutline } from '@react-icons/all-files/io5/IoSearchOutline';
import flattenDeep from "lodash/flattenDeep";
import instrumentsApi from '@utils/instrumentsApi';
import NextImage from '@components/elements/Image';
import useSwrHook from '@lib/services/hooks/swr/useSwrHook';
import { useState } from 'react';
import styles from './InvestEtFsTable.module.css';
import TradeHomeInstruments from '../trade-home-instruments';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import InstrumentTable from '../trade-home-instruments/components/InstrumentsTable';

const DIRECTION = {
  ASC: "asc",
  DESC: "desc"
}



const InvestEtFsTable = (props) => {
  const data = props.data || props;
  const [changeDirection, setChangeDirection] = useState(DIRECTION.ASC);

  const { gv } = useGlobalVariable()

  // SWR HOOK TO GET TOP PERFORMANCE
  const { data: getInstrumentPerformance, loading, error } = useSwrHook('getInstrumentPerformance', {
    query: {
      limit: 3,
      type: "ETFs"
    }
  });

  const handleChangeDirection = () => changeDirection === DIRECTION.DESC ? setChangeDirection(DIRECTION.ASC) : setChangeDirection(DIRECTION.DESC)

  if (!data || !getInstrumentPerformance) return null;
  return (
    <div className='container'>
      <div className="text-center w-full">
        <h2 className="mb-xs ltr:font-gilroy rtl:font-dubai">{data?.section_title.title}</h2>
        <div className={`${styles.subitle}`}>
          <HTMLRender data={data?.section_title.subtitle} />
        </div>
      </div>
      <div className="flex flex-row w-full pt-xl md:pt-3xl">
        {/* Card */}
        <div className={`${styles.card} max-w-[23%] w-full  p-md hidden md:flex flex-col justify-between`}>
          <div className="flex flex-col border-b-2 border-b-[#ccccc] ">
            <div className={`${styles.card_title}`}>
              <HTMLRender data={data?.card_title} />
            </div>
            <div className='flex justify-between py-lg'>
              <div className="w-full">
                <div className='text-dark flex items-center cursor-pointer hover:opacity-70' onClick={() => handleChangeDirection()}>
                  <p className="mb-0 ltr:mr-sm rtl:ml-sm text-dark">{gv('24h_change')}</p>
                  <Arrow direction={`${changeDirection === DIRECTION.DESC ? "down" : "up"}`} />
                </div>
              </div>
            </div>
            {changeDirection === DIRECTION.ASC ?
              getInstrumentPerformance?.positive?.map((instrument, index) => (
                <div key={index} className='pb-[2.188rem] flex justify-between flex-wrap'>
                  <div className="w-full max-w-[50%] flex justify-start">
                    <p className="uppercase">{instrument?.name}</p>
                  </div>
                  <div className="w-full max-w-[50%] flex justify-center">
                    <p className={`${instrument?.change >= 0 ? "text-greenCandle" : "text-primary"}`}>{instrument?.change > 0 ? "+" : ''}{instrument?.change}%</p>
                  </div>
                </div>
              ))
              : getInstrumentPerformance?.negative?.map((instrument, index) => (
                <div key={index} className='pb-[2.188rem] flex justify-between flex-wrap'>
                  <div className="w-full max-w-[50%] flex justify-start">
                    <p className="uppercase">{instrument?.name}</p>
                  </div>
                  <div className="w-full max-w-[50%] flex justify-center">
                    <p className={`${instrument?.change >= 0 ? "text-greenCandle" : "text-primary"}`}>{instrument?.change > 0 ? "+" : ''}{instrument?.change}%</p>
                  </div>
                </div>
              ))
            }
          </div>
          <div className={`${styles.card_text}`}>
            <HTMLRender data={data?.card_text} />
          </div>
        </div>
        {/* Card ends */}

        {/* Table Starts */}
        <div className="flex flex-col items-end w-full">
          <div className="w-full h-full md:max-w-[95%]">
            <div className='-mx-[1.111rem]'>
              <InstrumentTable data={props} />
            </div>
          </div>
        </div>
        {/* Table Ends */}

      </div>
      <div className='w-full flex flex-col md:flex-row  justify-between pt-lg md:pt-[3.05rem]'>
        <div className={`${styles.content} md:max-w-[55%]`}>
          <HTMLRender data={data.content} />
          <p className="text-sm pt-xs opacity-50 cursor-pointer"><HTMLRender data={data?.disclaimer} /> </p>
        </div>
        <div className="mt-lg md:mt-0 flex justify-center md:block">
          <Button data={data?.button} />
        </div>
      </div>
    </div>
  );
};

export default InvestEtFsTable;
