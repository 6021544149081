import HTMLRender from '@src/components/core/HTMLRender';
import SectionTitle from '@src/components/elements/SectionTitle';
import chunk from "lodash/chunk";
import styles from './PartnersCyWhy.module.css';

const PartnersCyWhy = (props) => {
  const data = props.data || props;
  if (!data) return null;

  // Chunk article array into a seperate array for each 4 elements
  const chunkedReasons = chunk(data?.list, 3);

  return (
    <div className='container pt-[4rem]'>
      <SectionTitle data={data?.section_title} />
      <div className='flex flex-col lg:flex-row flex-wrap'>
        {chunkedReasons[0].map((item, index) => (
          <div key={index} className='w-full lg:even:border-l lg:even:border-r lg:max-w-[calc(100%/3)] flex lg:justify-center items-center py-lg border-b lg:border-b-0'>
            <div className={styles.content}>
              <HTMLRender data={item?.content} />
            </div>
          </div>
        ))}
      </div>
      <div className='h-[1px] w-full bg-[#d6d6d6] my-xl hidden lg:block' />
      <div className='flex flex-col lg:flex-row flex-wrap'>
        {chunkedReasons[1].map((item, index) => (
          <div key={index} className='w-full lg:even:border-l lg:even:border-r lg:max-w-[calc(100%/3)] flex lg:justify-center items-center py-lg border-b lg:border-b-0'>
            <div className={styles.content}>
              <HTMLRender data={item?.content} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnersCyWhy;
