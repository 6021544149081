import styles from './ArticleShareBox.module.css'
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { useRouter } from "next/router";
import { useEffect, useState } from 'react';
import NextImage from '@src/components/elements/Image';


const ArticleShareBox = (props) => {
  const data = props.data || props;
  const router = useRouter();
  const { gv } = useGlobalVariable();
  const [absoluteUrl, setAbsoluteUrl] = useState("");

  useEffect(() => {
    setAbsoluteUrl(window?.location?.href)
  }, []);


  if (!data) {
    return null;
  }
  return (
    <div className={` sm:rounded-lg  ltr:font-gilroy rtl:font-dubai ${data?.small ? `px-md py-lg bg-[#F8F6F6]` : `py-lg px-xl -mx-md sm:mx-0 bg-whiteCream mb-2xl`}`}>
      <h4 className={`text-center mb-lg font-semibold ${data?.small ? `text-h5` : ``} ${styles.title}`}>{data?.academy ? gv('course_share_title') : gv('article_share_title')}</h4>
      <div className="flex justify-center text-primary text-xl">
        <div className="mx-md hover:opacity-70 relative w-[1.66rem] h-[1.66rem]">
          <FacebookShareButton url={absoluteUrl} quote={data?.title}>
            <NextImage
              layout="fill"
              objectFit="cover"
              src="https://cdn.capex.com/uploads/article_facebook_4fcc593268/article_facebook_4fcc593268.png"
              priority
              alt="Article Hero"
              quality={100}
            />
          </FacebookShareButton>
        </div>
        <div className="mx-md hover:opacity-70 relative w-[1.66rem] h-[1.66rem]">
          <TwitterShareButton url={absoluteUrl} quote={data?.title}>
            <NextImage
              layout="fill"
              objectFit="cover"
              src="https://cdn.capex.com/uploads/article_twitter_6fbe95d797/article_twitter_6fbe95d797.png"
              priority
              alt="Article Hero"
              quality={100}
            />
          </TwitterShareButton>
        </div>
        <div className="mx-md hover:opacity-70 relative w-[1.66rem] h-[1.66rem]">
          <LinkedinShareButton url={absoluteUrl} quote={data?.title}>
            <NextImage
              layout="fill"
              objectFit="cover"
              src="https://cdn.capex.com/uploads/article_linkedin_b7511cb10d/article_linkedin_b7511cb10d.png"
              priority
              alt="Article Hero"
              quality={100}
            />
          </LinkedinShareButton>
        </div>
      </div>
    </div>
  );
};

export default ArticleShareBox;
