import NextImage from '@src/components/elements/Image';
import styles from './TradeHomeEtfs.module.css';
import Button from '@src/components/elements/Button';
import SectionTitle from '@src/components/elements/SectionTitle';

const TradeHomeEtfs = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='overflow-hidden md:overflow-visible'>
      <div className='relative container'>
        {/* image */}
        <div className='hidden lg:block w-full top-0 absolute lg:max-w-[60%] -mt-[10%] ltr:-ml-[2%] rtl:-mr-[2%] pointer-events-none'>
          <NextImage
            src={data?.image}
            width={data?.image?.data?.attributes?.width}
            height={data?.image?.data?.attributes?.height}
            quality={100}
          />
        </div>
        <div className="flex flex-col items-center justify-end lg:flex-row">
          {/* Title & CTA section */}
          <div className="flex w-full flex-col lg:max-w-[36%]">
            <div className={`my-sm lg:mb-8 ${styles.titleStyles}`}>
              <SectionTitle
                data={data?.section_title}
              />
            </div>
            <div className='lg:hidden pointer-events-none -mt-xl md:-mt-2xl mx-[-3rem] md:mx-[0]'>
              <NextImage
                src={data?.image}
                width={data?.image?.data?.attributes?.width}
                height={data?.image?.data?.attributes?.height}
                quality={100}
              />
            </div>
            <div className='flex justify-center lg:block'>
              <Button data={data?.cta} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeHomeEtfs;
