import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import List from '@components/elements/List';
import NextImage from '@components/elements/Image';
import styles from './AboutUsCommunity.module.css'

const AboutUsCommunity = (props) => {
  const data = props.data || props;

  return (
    <div style={{ backgroundColor: `${data?.section_layout?.backgroundColor}`, position: 'relative' }}>
      <div className={`absolute h-[43%] md:h-full w-full md:w-[calc(50%-2rem)] overflow-hidden ${styles.imgWrapper}`}>
        <NextImage src={data?.image} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
      </div>
      <div className='container relative'>
        <div className='flex flex-col md:flex-row justify-between items-center'>
          <div className="w-full md:w-[calc(50%-5rem)] py-[3rem] lg:py-[5rem]">
            <h2 className="text-white text-center ltr:md:text-left rtl:text-right">{data?.title}</h2>
            <div className={styles.content}>
              <HTMLRender data={data?.content} />
            </div>
            <div className='text-center ltr:md:text-left rtl:text-right mt-[2rem]'>
              <Button data={data?.button} />
            </div>
          </div>
          <div className="w-full md:w-[calc(50%-2rem)] py-[3rem] lg:py-[5rem]">
            <div className="bg-whiteCream rounded-lg py-[2.222rem] px-sm">
              <List type="checked" border list_items={data?.list_items} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsCommunity;
