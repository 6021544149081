import NextImage from "@src/components/elements/Image";
import Link from "@src/components/elements/Link";
import SectionTitle from "@src/components/elements/SectionTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";
import { Autoplay } from "swiper";
import "./InvestGlobalMarkets.module.css";
import GenericSlider from "@src/components/custom/GenericSlider";

const InvestGlobalMarkets = (props) => {
  const data = props.data || props;
  const store = useStore(
    (store) => ({
      dir: store.direction,
    }),
    shallow
  );
  console.log("render investglobalmarket");
  if (!data) return null;

  return (
    <div className="overflow-hidden">
      <div className="container">
        <SectionTitle data={data?.section_title} />
        <div className="global-markets-swiper -mx-xs -mt-lg">
          <Swiper
            slidesPerView={1.3}
            modules={[Autoplay]}
            loop
            autoplay
            breakpoints={{
              768: {
                slidesPerView: 3,
              },
              1280: {
                slidesPerView: 5,
                allowTouchMove: props?.market?.length < 6 && false,
                loop: props?.market?.length < 6 && false,
                autoplay: props?.market?.length < 6 && false,
              },
            }}
            dir={store ? store.dir : "ltr"}
          >
            {props?.market?.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="h-full px-xs pt-md">
                  <div className="flex h-full items-center rounded-lg px-md py-lg shadow-[0px_0px_5px_0px_rgba(0,0,0,0.15)]">
                    <div className="relative h-[18px] w-[28px] flex-[0_0_28px] overflow-hidden rounded-sm ltr:mr-sm rtl:ml-sm">
                      <NextImage objectFit src={item?.icon} />
                    </div>
                    <div>{item?.title}</div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <Link data={data?.link} />
      </div>
    </div>
  );
};

export default InvestGlobalMarkets;
