import Arrow from '@components/elements/Arrow';
import { Accordion } from '@mantine/core';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './StoxFaq.module.css';
import HTMLRender from '@components/core/HTMLRender';

const StoxFaq = (props) => {
  const data = props.data || props;

  if (!data) {
    return null
  } return (
    <>
      <div className="container">
        <SectionTitle data={data.section_title} />
        <div>
          <Accordion
            iconPosition="right"
            transitionDuration={600}
            icon={<div className="text-secondary"><Arrow direction="down" /></div>}>
            {data?.FAQ_acc.map((faq, index) => (
              <Accordion.Item
                key={index}
                label={
                  <div className={`${styles.title}`}>
                    <HTMLRender data={faq?.title} />
                  </div>
                }
                classNames={{
                  control: styles.accordionControl,
                  accordionLabel: styles.accordionLabel,
                  itemOpened: styles.itemOpened,
                  label: styles.accordionLabel,
                  item: styles.accordionItem,
                  itemTitle: styles.accordionItemTitle,
                  contentInner: styles.contentInner,
                }}
              >
                <div className={`w-full flex justify-between ${styles.content}`}>
                  <HTMLRender data={faq.content} />
                </div>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default StoxFaq;
