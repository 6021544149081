import { useCallback, useEffect, useState } from "react";

import MobileHeader from "@components/custom/MobileHeader";
import NavBar from "@components/custom/NavBar";
import { shallow } from "zustand/shallow";
import useActiveProductType from "@lib/store/hooks/useActiveProductType";
import { useHeaderLogo } from "./hooks/useHeaderLogo";
import useLayoutSharedData from "@lib/store/hooks/useLayoutSharedData";
import useLocale from "@lib/store/hooks/useLocale";
import { useStore } from "@lib/store";

/*
 * Header React Component
 * @param {string} position
 * @param {string} headerBackground
 * @returns {JSX.Element} Header
 */
const Header = ({ position = "absolute", background = "white" }) => {
  const { layoutSharedData } = useLayoutSharedData();
  const navigation = layoutSharedData?.globalNavigation;
  const logo = useHeaderLogo("red");
  const locale = useLocale();
  const store = useStore(
    (store) => ({
      header_style: store?.header_style,
      layout_type: store?.layout_type,
    }),
    shallow
  );

  const currentProductTypeKey = useStore((state) => state.currentProductTypeKey);

  const header = navigation?.header?.filter((el) => el.product_type === currentProductTypeKey);
  const propsMobileHeader = {
    header: header?.[0]?.structure?.tree,
    locale,
  };
  const navBarProps = {
    position,
    locale,
    color: store?.layout_type === "article" || store?.layout_type === "academy" ? "white" : store.header_style?.color,
    background: store.header_style?.background,
    logo: logo,
    navigation: header?.[0]?.structure?.tree,
  };

  if (!navigation) return null;
  return (
    <header>
      <NavBar {...navBarProps} />
      <div className="mobile-header-wrapper">
        <MobileHeader {...propsMobileHeader} />
      </div>
    </header>
  );
};

export default Header;
