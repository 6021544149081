import './AcademyCardDesktop.module.css';
import { BiTime } from '@react-icons/all-files/bi/BiTime';

const AcademyCardDesktop = (props) => {
  const data = props.data?.attributes || props;
  const available = props?.available;

  return (
    <>
      <div
        className="py-md px-lg h-auto mb-0 w-full items-center justify-between rounded bg-white flex"
      >
        <div>
          {data.title && <div className="text-secondary uppercase font-light">{data.title}</div>}
          {data?.lessons?.data?.length > 0 && (
            <div className="text-tiny">
              <p className="text-[#4a4a4a]">
                {`${data.lessons.data.length} Lessons `}
                <span className="text-[#a6a6a6]">{available}</span>
              </p>
            </div>
          )}
        </div>
        {data.total_time && (
          <div className="flex items-center text-[#a6a6a6]">
            <BiTime />
            <span className="px-xs">{data.total_time}</span>
          </div>
        )}
      </div>
    </>
  )
};

export default AcademyCardDesktop;
