import IconText from '@components/custom/IconText';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './TitledIcons.module.css';

const TitledIcons = (props) => {
  const data = props.data || props;

  if (!data) {
    return null
  } return (
    <div className="container">
      <div className={styles.title}>
        <SectionTitle data={data.section_title} />
      </div>
      <div>
        <IconText data={data.icon_text} />
      </div>
    </div>
  );
};

export default TitledIcons;
