import { useEffect } from "react";
import { useLoginLink, useRegistrationLink } from "@lib/store/hooks/useActiveProductType";

import Button from "@components/elements/Button";
import ChatIcon from "@public/assets/images/chaticon.svg";
import Hamburger from "@components/elements/Hamburger";
import Logo from "@components/elements/Logo";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { useRouter } from "next/router";
import { useStore } from "@lib/store";
import LanguageSelector from "../LanguageSelector";
import MobileMenu from "../MobileMenu";
import MobileProductTabs from "../MobileProductTabs";
import styles from "./MobileHeader.module.css";

const MobileHeader = ({ header, locale }) => {
  // use next/router to detect when page changed and close the menu
  const router = useRouter();
  const { gv } = useGlobalVariable();
  const registrationLink = useRegistrationLink();
  const loginLink = useLoginLink();
  const handleMobileMenuClose = () => {
    if (document.body.classList.contains("mobile-open")) {
      document.body.classList.remove("mobile-open");
    }
  };
  useEffect(() => {
    handleMobileMenuClose();
  }, [router.asPath]);

  const websiteLanguages = useStore((state) => state.websiteLanguages);

  // group websiteLanguages by key 'region'
  const websiteLanguagesPerRegion = websiteLanguages?.data?.reduce((r, a) => {
    r[a.attributes.region] = [...(r[a.attributes.region] || []), a];
    return r;
  }, {});

  function openChat() {
    if (window && window?.zE) {
      try {
        window?.zE("webWidget", "open");
      } catch (err) {
        console.warn("[Zendesk] WebWidget is not present on this license.");
      }
      try {
        window?.zE("messenger", "open");
      } catch (err) {
        console.warn("[Zendesk] messenger is not present on this license.");
      }
    }
  }

  const logoUrl = useStore((state) => state.logoUrl);

  return (
    <div className="mobile-header flex flex-col ">
      <div className="  relative z-[100] px-md">
        <div className="flex h-[4.8888em] w-full items-center justify-between border-b  border-[#999]">
          <div className=" flex h-[4.8888em] items-center">
            <Logo color="red" link={`/${locale + logoUrl}`} />
          </div>
          <div className="flex items-center">
            <button
              type="button"
              className={`${styles.chatIcon} header-icons text-[1.25rem] ltr:mr-sm rtl:ml-sm lg:hidden`}
              onClick={() => openChat()}
            >
              <ChatIcon />
            </button>
            <div className="header-language-selector z-10 h-full text-inherit">
              <LanguageSelector
                containerId="lang_selector_container_mobile"
                langSelectorId="lang_selector_mobile"
                locale={locale}
                websiteLanguages={websiteLanguages}
                websiteLanguagesPerRegion={websiteLanguagesPerRegion}
                className="h-full"
                grid
              />
            </div>
            <Hamburger />
          </div>
        </div>
      </div>
      <div className="flex grow flex-col justify-between px-md">
        <div className="w-full">
          {!["eu", "ro", "de", "cz", "it", "el", "es", "ae", "ae-ar", "za"].includes(locale) && <MobileProductTabs />}
          <MobileMenu menu={header} handleMobileMenuClose={handleMobileMenuClose} />
        </div>
        <div className={`flex justify-center py-2xl ${styles.mobileMenuButtons}`}>
          {!["ae","ae-ar"].includes(locale) && (
          <div className="w-1/2 ltr:pr-xs rtl:pl-xs">
            <Button type="secondary" fullWidth size="lg" link={loginLink} label={gv("header_login_btn_text", "raw")} />
          </div>
          )}
          {!["eu", "ro", "de", "cz", "it", "el", "es", "za"].includes(locale) && (
            <div className="w-1/2 ltr:pl-xs rtl:pr-xs">
              <Button type="primary" fullWidth size="lg" link={registrationLink} label={gv("register", "raw")} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
