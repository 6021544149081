import NextImage from '@components/elements/Image';
import NextLink from 'next/link';
import './HomepagePlatforms.module.css';

const MobileApp = ({ app, mobile = false, index }) => (
  <div
    key={index}
    className="mr-sm last:mt-sm relative max-h-[2.75rem] w-auto lg:last:mt-0"
  >
    <NextLink href={`${app?.attributes?.link || app.link}`}>
      <a
        target={app?.attributes?.newTab ? '_blank' : '' || ''}
        className="absolute top-0 left-0 z-10 h-full w-full bg-transparent"
      ></a>
    </NextLink>
    <NextImage
      src={app?.attributes?.image}
      alt={
        app?.attributes?.image?.data?.attributes?.alternativeText ||
        'Capex Apps'
      }
      width={app?.attributes?.image?.data?.attributes?.width}
      height={app?.attributes?.image?.data?.attributes?.height}
    />
  </div>
)

const HomepagePlatforms = (props) => {
  const data = props.data || props;

  return (
    <section className='mb-[2rem]'>
      <div className='container'>
        {/* Desktop Version */}
        <div className='hidden bg-tramsparent md:block md:bg-primary rounded-lg'>
          <div className='p-[3.888rem]'>
            <div className='flex justify-between w-full'>
              <div className='w-1/2'>
                <div className='text-white mb-[1.94rem]'>
                  <h4 className='mb-0'>{data?.title}</h4>
                  <h3>{data?.subtitle}</h3>
                </div>
                <div className="flex w-full flex-wrap lg:flex-nowrap items-center lg:justify-between lg:w-[65%]">
                  {data.mobile_apps?.data.map((app, index) => (
                    <div key={index}>
                      <MobileApp app={app} index={index} />
                    </div>
                  ))}
                </div>
              </div>
              <div className='w-1/2'>
                <div className='max-w-[23.88rem]'>
                  <p className='text-white'>{data.content_upper}</p>
                  <p className='text-white'>{data.content_lower}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile Version */}
        <div className='md:hidden flex flex-col justify-between items-center'>
          <div className='relative w-full min-h-[405px]'>
            <NextImage objectFit src={data.phones_mobile_image} />
          </div>
          <div className='text-center mt-xs mb-md'>
            <h4 className='mb-0 text-xl'>{data.title}</h4>
            <h3 className="text-2xl">{data.subtitle}</h3>
          </div>
          <div className="flex w-full flex-wrap items-center justify-center">
            {data.mobile_apps?.data.map((app, index) => (
              <div key={index}>
                <MobileApp app={app} index={index} mobile />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
};

export default HomepagePlatforms;
