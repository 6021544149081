import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';
import styles from './PodcastsHero.module.css';
import { FaPlayCircle } from '@react-icons/all-files/fa/FaPlayCircle';
import useLocale from '@lib/store/hooks/useLocale';
import useSwrHook from '@lib/services/hooks/swr/useSwrHook';
import NextLink from 'next/link';
import { useState } from 'react';
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";

// TODO header style for red hero
const PodcastsHero = (props) => {
  const data = props.data || props;
  if (!data) return null;

  const [iframeOpen, setIframeOpen] = useState(false);

  const locale = useLocale();

  const { gv } = useGlobalVariable()


  const podcastsPlatforms = data.podcast_platforms

  const { data: podcastsEpisodes, loadingEp, errorEp } = useSwrHook(`podcastsEpisodes`, {
    query: {
      fields: ['url'],
      sort: ['publishedAt:desc'],
      pagination: {
        start: 0,
        limit: 1,
      },
      locale: locale
    }
  });

  return (
    <div className={styles.PodcastsHero}>
      <div className='container text-white'>
        <div className="flex items-center flex-col-reverse lg:flex-row lg:items-start text-center ltr:lg:text-left rtl:lg:text-right">
          <div className='lg:w-7/12'>
            {data?.text &&
              <HTMLRender data={data?.text} />
            }

            {data?.play_text &&
              <div className='py-lg'>
                {!iframeOpen &&
                  <div className='flex justify-center lg:justify-start items-center cursor-pointer' onClick={() => setIframeOpen(true)}>
                    <FaPlayCircle className='text-3xl md:text-[3.5em]' />
                    <h3 className='uppercase px-md md:px-lg'>{data.play_text}</h3>
                  </div>
                }
                <div className={`${iframeOpen ? 'block' : 'hidden'}`}>
                  <iframe src={podcastsEpisodes?.data[0]?.attributes?.url} frameborder="0" scrolling="no" width="100%" height="150"></iframe>
                </div>

              </div>
            }

            <div className='hidden items-center lg:flex'>
              {podcastsPlatforms?.data?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className='py-xs px-md bg-[#e26a6d] flex items-center rounded-[3.5em] mx-xs first:ml-0 relative'
                  >
                    <a href={item.attributes.url} target="_blank" className="absolute top-0 right-0 z-10 h-full w-full"></a>
                    <div className={`relative w-[2em] h-[2em] ${styles.platformIcon}`}>
                      <NextImage
                        objectFit
                        src={item?.attributes?.icon?.data?.attributes?.url}
                      />
                    </div>
                    <div className='ltr:pl-xs rtl:pr-xs'>
                      <div>{gv('listen_on', 'raw')}</div>
                      <div className='uppercase'>{item?.attributes?.name}</div>
                    </div>

                  </div>
                )
              })}
            </div>
          </div>

          <div className='w-[60%] pb-lg lg:w-5/12 lg:px-md'>
            <NextImage
              src={data?.image?.data?.attributes?.url}
              width={
                data?.image?.data?.attributes?.width
              }
              height={
                data?.image?.data?.attributes?.height
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PodcastsHero;
