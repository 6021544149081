import HTMLRender from '@src/components/core/HTMLRender';
import SectionTitle from '@src/components/elements/SectionTitle';
import styles from './ReferralTable.module.css';

const ReferralTable = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container bg-transparent lg:bg-[#f6f6f6] rounded-lg lg:p-[2rem]'>
      <div className='bg-[#f6f6f6] lg:bg-transparent rounded-lg  p-[0.75rem] pb-lg lg:p-0'>
        <div className='mb-lg'>
          <SectionTitle data={data?.section_title} />
        </div>
        <div className='flex items-center lg:items-start flex-wrap bg-[#ebebeb] rounded-md py-sm mb-sm'>
          {
            data?.table_header?.map((header, index) => (
              <div key={index} className={`w-full px-xs max-w-[calc(100%/4)] ${styles.table_header}`}>
                <HTMLRender data={header?.content} i />
              </div>
            ))
          }
        </div>
        <div className='flex flex-wrap py-sm mb-sm'>
          {
            data?.table_content?.map((header, index) => (
              <div key={index} className={`w-full flex lg:block items-center justify-center px-xs max-w-[calc(100%/4)] border-b pb-sm mb-sm ${styles.content}`}>
                <HTMLRender data={header?.content} />
              </div>
            ))
          }
        </div>
        <div className={styles.disclaimer}>
          <HTMLRender data={data?.disclaimer} />
        </div>
      </div>
    </div>
  );
};

export default ReferralTable;
