import styles from './DesktopHomeSlider.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/thumbs";
import { Thumbs } from "swiper";
import NextImage from '@components/elements/Image';
import { useState } from 'react';
import SingleSlide from '@components/custom/SingleSlide';
import { useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect';

const DesktopHomeSlider = ({slides}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0)

  // useEffect(() => {
  //   const slide = slides[activeIndex];
  //   // console.log('ActiveIndex Modified State');
  //   // if(style.header.color != slide?.attributes?.header_layout?.color || style.header.logo != slide.attributes?.header_layout?.logo){
  //   //   // console.log('dispatchStyle Modified State',style.header.logo,slide.attributes.header_layout.logo);
  //   //   dispatchStyle({type: "HEADER", color: slide?.attributes.header_layout.color, logo:slide.attributes.header_layout.logo })
  //   // }
  //   // if(style?.header?.color != slide?.attributes?.header_layout?.color || style?.header?.logo != slide?.attributes?.header_layout?.logo){
  //   //   // console.log('dispatchStyle Modified State',style.header.logo,slide.attributes.header_layout.logo);
  //   //   dispatchStyle({type: "HEADER", color: slide.attributes.header_layout.color, logo:slide.attributes.header_layout.logo })
  //   // }
  // },[activeIndex])
  if(!isMobileOnly){
    return (
      <div className="relative hidden md:block">
       <Swiper
        className={`${styles[`main-slider`]}`}
        spaceBetween={0}
        slidesPerView={1}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Thumbs, Autoplay]}
        onSlideChange={swiper => setActiveIndex(swiper.realIndex)}
        loop={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        }}
      >
       
        {slides.map((slide, index) => {
          // console.log(slide.attributes.css_id)
          return(
            
            <SwiperSlide key={index}  data-swiper-autoplay={slide.attributes.duration || 6000}>
              <SingleSlide  
              image={slide.attributes.desktop_background.data?.attributes.url} 
              text={slide.attributes?.text} 
              color={slide.attributes?.header_layout?.color} 
              halign={slide.attributes?.desktop_horizontal_align} 
              valign={slide.attributes?.desktop_vertical_align} 
              text_align={slide.attributes?.desktop_text_align} 
              customClass={slide.attributes?.custom_class}/>
            </SwiperSlide>   
          )
        })}
      </Swiper>
       <Swiper
              className={`${styles.thumbnails}`}
              modules={[Thumbs]}
              watchSlidesProgress={true}
              onSwiper={setThumbsSwiper}
              spaceBetween={0}
              slidesPerView={4}
              allowTouchMove={true}
              loop={true}
              loopedSlides={20}
              autoplay={{
                delay: 6000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
              }}
          
              breakpoints={{
                1280: {
                  // slidesPerView:5,
                  // loop: false,
                  // autoplay: false,
                  centeredSlides: true,
                  allowTouchMove:false,
                }
              }}
            >
            {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                    <div className="relative">
                      <div className={`rounded-md overflow-hidden   h-[30px] w-[100px] xl:w-[7.777em] xl:h-[2.222em] relative  ${activeIndex == index ? "opacity-100" : "opacity-70"}`}> 
                          <NextImage 
                          src={slide.attributes.desktop_thumbnail}  
                          alt={slide.attributes.desktop_thumbnail.data?.attributes.alternativeText} />
                      </div>
                      <div className={`${styles.progress} mt-[0.5em] h-[1px] w-[100px] lg:w-full bg-white relative`}>
                        <div className={`${styles[`progress-red`]}   absolute left-0 top-0 h-full bg-primary ${activeIndex == index ? "w-full transition-all ease-linear" : "transition-none  w-0"}`} style={{transitionDuration:`${slide.attributes.duration || 6000}ms`}}></div>
                      </div>
                    </div>
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
    );
  } return null

};

export default DesktopHomeSlider;
