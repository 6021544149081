import styles from './AcademyScopes.module.css';
import SectionTitle from '@src/components/elements/SectionTitle';
import HTMLRender from '@src/components/core/HTMLRender';
import Button from '@src/components/elements/Button';
import { useRegistrationLink } from '@lib/store/hooks/useActiveProductType';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import { Accordion } from '@mantine/core';
import Arrow from '@src/components/elements/Arrow';
import union from 'lodash/union';
import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useStore } from '@lib/store';
import { shallow } from 'zustand/shallow';
import NextImage from '@src/components/elements/Image';

// custom image Component, different image positioning if item's index is even or odd
function CustomImage({ index }) {
  return (
    index % 2 === 0 ?
      <div className='absolute -top-1/2 lg:-top-[44%] -left-[40%] lg:-left-[30%] z-0 opacity-30'>
        <NextImage
          layout='intrinsic'
          objectFit='cover'
          width={312}
          height={385}
          src="https://cdn.capex.com/uploads/red_x_bb9e333d22/red_x_bb9e333d22.png"
          priority
          quality="100"
          alt="Article Hero"
        />
      </div> :
      <div className='absolute -bottom-[17%] lg:-bottom-[12%] -right-[35%] lg:-right-[20%] z-0 opacity-30'>
        <NextImage
          layout='intrinsic'
          objectFit='cover'
          width={312}
          height={385}
          src="https://cdn.capex.com/uploads/red_x_bb9e333d22/red_x_bb9e333d22.png"
          priority
          quality="100"
          alt="Article Hero"
        />
      </div>
  )
}

const AcademyScopes = (props) => {
  const data = props.data || props;
  const registrationLink = useRegistrationLink();
  const { gv } = useGlobalVariable();

  // get dir for swiper
  const store = useStore(
    (store) => ({
      dir: store.direction,
    }),
    shallow
  );

  if (!data) return null;

  return (
    <>
      {/* desktop component */}
      <div className="lg:container px-0">
        <SectionTitle data={data?.section_title} />
        <div className='flex flex-col lg:flex-row justify-between w-full overflow-hidden flex-wrap lg:max-w-[90%] mx-auto'>
          <div className='bg-[#F8F6F6] relative py-md lg:py-xl w-full lg:max-w-[calc(100%/2-0.5rem)] lg:rounded-lg overflow-hidden mb-md lg:mb-0'>
            <CustomImage index={0} />
            <div className="relative z-10 h-full">
              <h3 className='font-bold px-md mb-lg lg:mb-xl lg:px-lg'>{gv('will_do')}</h3>
              <div className='academy-scopes-slider relative h-[calc(100%-3.333rem)]'>
                <Swiper
                  dir={store ? store.dir : "ltr"}
                  spaceBetween={50}
                  slidesPerView={1}
                  draggable
                  autoplay
                  loop={false}
                  modules={[Pagination, Autoplay]}
                  pagination={{
                    clickable: true,
                  }}
                >
                  {data?.will_do?.map((item, index) => (
                    <SwiperSlide key={index}>
                      <HTMLRender data={item?.content} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
          <div className='bg-[#F8F6F6] relative py-md lg:py-xl w-full lg:max-w-[calc(100%/2-0.5rem)] lg:rounded-lg overflow-hidden'>
            <CustomImage index={1} />
            <div className="relative z-10 h-full">
              <h3 className='font-bold px-md mb-lg lg:mb-xl lg:px-lg'>{gv('will_not_do')}</h3>
              <div className='academy-scopes-slider relative h-[calc(100%-3.333rem)]'>
                <Swiper
                  dir={store ? store.dir : "ltr"}
                  spaceBetween={50}
                  slidesPerView={1}
                  draggable
                  autoplay
                  loop={false}
                  modules={[Pagination, Autoplay]}
                  pagination={{
                    clickable: true,
                  }}
                >
                  {data?.wont_do?.map((item, index) => (
                    <SwiperSlide key={index}>
                      <HTMLRender data={item?.content} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        {/* blue box component desktop */}
        {data?.blue_box_text && <div className='bg-secondary hidden lg:flex flex-row justify-between items-center mt-2xl rounded-lg py-lg text-white text-xl px-3xl'>
          <div className={styles.blue_box_text}>
            <HTMLRender data={data?.blue_box_text} />
          </div>
          <Button type="white-borderless" size="sm" label={gv('create_account')} link={registrationLink} />
        </div>}
        {/* blue box component mobile */}
        {data?.blue_box_text &&
          <div className='flex lg:hidden flex-col justify-center mt-xl container'>
            <div className={styles.blue_box_text}>
              <HTMLRender data={data?.blue_box_text} />
            </div>
            <div className='w-full mt-xl flex justify-center'>
              <Button type="secondary" size="lg" label={gv('create_account')} link={registrationLink} />
            </div>
          </div>}
      </div>
    </>
  );
};

export default AcademyScopes;
