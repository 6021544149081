import HTMLRender from '@components/core/HTMLRender';
import GenericSlider from '@components/custom/GenericSlider';
import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import useSwrHook from '@lib/services/hooks/swr/useSwrHook';
import { useDeviceDetect } from '@utils/hooks';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import styles from './TradeHomeReviews.module.css';

const TradeHomeReviews = (props) => {
  const data = props.data || props;

  const device = useDeviceDetect()
  const [reviewOpen, setReviewOpen] = useState({ value: false, index: null })

  const { data: testimonials, loading, error } = useSwrHook('testimonials', {
    query: {
      populate: {
        image: {
          fields: ['url', 'width', 'height']
        }
      },
      fields: ['name', 'rating', 'review', 'review_url'],
      sort: ['publishedAt:desc'],
      pagination: {
        start: 0,
        limit: 10
      },
    }
  });

  // if device is desktop setReviewOpen to false
  useEffect(() => device === 'desktop' && setReviewOpen({ value: false, index: null }), [device])


  const handleReviewExpansion = (index) => {
    if (device !== 'desktop') {
      if (reviewOpen.value && index === reviewOpen.index) {
        setReviewOpen({ value: false, index: null })
      } else if (!reviewOpen.value) {
        setReviewOpen({ value: true, index })
      } else if (reviewOpen.value && index !== reviewOpen.index) {
        setReviewOpen({ value: true, index })
      }
    } else {
      setReviewOpen({ value: false, index: null })
    }
  }

  if (!data || !testimonials) return null;

  return (
    <div className={`overflow-hidden lg:pb-0 transition-all duration-300 ease-in-out ${styles.shadowContainer} ${reviewOpen.value && 'pb-[10rem]'}`}>
      <div className='container w-full flex flex-col lg:flex-row justify-center lg:justify-between items-center'>
        <div className='w-full max-w-[60%] hidden lg:block'>
          <SectionTitle data={data?.section_title} />
        </div>
        <div className='lg:hidden text-center mb-lg'>
          <h2 className='font-bold ltr:font-gilroy rtl:font-dubai text-[1.777rem]'>{data?.section_title?.title}</h2>
        </div>
        <div className='w-full lg:max-w-[25%] flex flex-col'>
          <div className='relative flex items-end justify-evenly lg:justify-between'>
            <NextImage src="https://cdn.capex.com/uploads/trustpilot_logo_984db4a0d4/trustpilot_logo_984db4a0d4.png" width={130} height={32} alt="TrustPilot" />
            <NextImage src={data?.trustpilot_rating} width={data?.trustpilot_rating?.data?.attributes?.width} height={data?.trustpilot_rating?.data?.attributes?.height} />
          </div>
          <div className={`${styles.trustpilot_rating_text} mt-md lg:mt-xs flex justify-center`}>
            <HTMLRender data={data?.trustpilot_rating_text} />
          </div>
          {/* mobile border below rating */}
          <div className='w-full h-[1px] bg-black opacity-50 rounded mt-[2.05rem] max-w-[40%] mx-auto lg:hidden' />
        </div>
      </div>
      <div className='container mt-xl testimonialSlider'>
        <GenericSlider slidesPerView={device === 'desktop' || device === 'tablet' ? 2.5 : 1.3} spaceBetween={20} freeMode>
          {testimonials?.data?.map((testimonial, index) => {

            // get the name object and split it into first/last name and get first char of both names to assign it as avatar icon if image does not exist
            const [getFirstName, getLastName] = testimonial?.attributes?.name.split(' ') || '';
            const firstName = getFirstName?.charAt(0).toUpperCase() || ''
            const lastName = getLastName?.charAt(0).toUpperCase() || ''

            return (
              <SwiperSlide key={index}>
                <div className={`relative h-full flex flex-col lg:bg-[#F9F9F9] rounded-lg p-lg overflow-hidden ${testimonial?.attributes?.review_url && 'cursor-pointer'}`}>
                  {testimonial?.attributes?.review_url &&
                    // eslint-disable-next-line
                    <a
                      href={testimonial?.attributes?.review_url}
                      target="_blank"
                      className='absolute h-full w-full top-0 left-0 z-10 bg-transparent'
                      rel="noreferrer"
                    />
                  }
                  <div
                    className='absolute -right-[20%] -top-[18%] max-w-[90%] opacity-[10%] z-[1] hidden lg:block'>
                    <NextImage src="https://cdn.capex.com/uploads/x_icon_reddish_f9c1172498/x_icon_reddish_f9c1172498.png" width={220} height={260} alt="X" />
                  </div>
                  <div
                    className='absolute right-[23%] -top-[68%] max-w-[90%] opacity-[5%] z-[1] hidden lg:block'>
                    <NextImage src="https://cdn.capex.com/uploads/x_icon_reddish_f9c1172498/x_icon_reddish_f9c1172498.png" width={200} height={260} alt="X" />
                  </div>
                  {/* avatar and name */}
                  <div className='relative z-[5] flex lg:items-center'>
                    {/* if image exists use image else get first and last name initials as avatar image */}
                    <div className='pr-lg relative z-[99]'>
                      {!testimonial?.attributes?.image?.data ?
                        <div className="relative">
                          <div className={`relative z-[5] bg-white rounded-full h-[5rem] w-[5rem] shadow-lg ${styles.customShadow}`}>
                            <p className='font-bold text-lg text-[#DEA46E] flex justify-center items-center w-full h-full tracking-widest'>
                              {firstName + lastName}
                            </p>
                          </div>
                          <div className="flex h-[55px] w-[55px] absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-[1] lg:hidden">
                            <span className="absolute bg-[#DEA46E] h-full w-full rounded-full inline-flex animate-ping" />
                            <span className="relative inline-flex rounded-full h-[55px] w-[55px] bg-[#DEA46E]" />
                          </div>
                        </div>
                        :
                        <NextImage src={testimonial?.attributes?.image} width={testimonial?.attributes?.image?.data?.attributes?.width} height={testimonial?.attributes?.image?.data?.attributes?.height} />
                      }
                    </div>
                    <div className={`flex flex-col transition-all duration-[1s] ease-in-out ${reviewOpen.value && index === reviewOpen.index && 'pt-sm'}`}>
                      <p className='text-black text-xl lg:text-lg font-semibold lg:font-bold'>{testimonial?.attributes?.name}</p>
                      <div>
                        {testimonial?.attributes?.rating === 'five' ?
                          <NextImage src="https://cdn.capex.com/uploads/5_stars_11278d54b2/5_stars_11278d54b2.png" width={125} height={24} alt="Rating" />
                          :
                          <NextImage src="https://cdn.capex.com/uploads/4_stars_5af08c31af/4_stars_5af08c31af.png" width={125} height={24} alt="Rating" />
                        }
                      </div>
                    </div>
                  </div>
                  {/* review content */}
                  <p
                    aria-hidden
                    className={`relative z-[60] text-black mt-md text-sm lg:hidden w-fit origin-top transition-all duration-700 ease-in-out ${reviewOpen.value && index === reviewOpen.index ? 'scale-[0] -top-[50%] opacity-0' : 'scale-[1] top-0 opacity-100'}`}
                    onClick={() => { handleReviewExpansion(index) }}
                  >
                    Open review
                  </p>
                  <div className='mt-lg relative z-[5] hidden lg:block'>
                    <HTMLRender data={testimonial?.attributes?.review} />
                  </div>
                </div>
                {/* mobile review card */}
                <div
                  aria-hidden
                  className={`bg-white absolute top-[13%] left-[20%] flex flex-col px-lg transition-all origin-top-left duration-700 ease-in-out  ${reviewOpen.value && index === reviewOpen.index ? 'scale-[1]' : 'scale-[0]'} ${styles.customShadowReviewCardExtended}`}
                  onClick={() => { setReviewOpen({ value: !reviewOpen.value, index }) }}
                >
                  <p className="text-[3.667rem] leading mt-[8rem]">”</p>
                  <div className={styles.mobileReviewText}>
                    <HTMLRender data={testimonial?.attributes?.review} />
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </GenericSlider>
      </div>
    </div>
  );
};

export default TradeHomeReviews;
