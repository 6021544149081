import { useState } from "react";

export default function useInstrumentCategoriesTabs(withTabs, categoryName) {
  const [activeTab, setActiveTab] = useState(0);
  const [category, setCategory] = useState((withTabs && categoryName) || null);
  const changeTab = (active, tabKey) => {
    setActiveTab(active);
    setCategory(tabKey);
  };
  return {
    activeTab,
    setActiveTab,
    category,
    setCategory,
    changeTab,
  };
}
