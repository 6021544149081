/* eslint-disable react/jsx-no-useless-fragment */
import './ReusableComponent.module.css';
import generatedDictionary from "./../../components_dictionary";
import get from 'lodash/get';

const ReusableComponent = (props) => {
  const data = props.data || props;
  const sectionDataList = get(data, 'qd_reusable_component.data.0.sections', []);

  const renderSection = (sectionData) => {
    const componentName = get(sectionData, '__component', false);
    const Component = generatedDictionary[componentName];
    if (!Component) {
      console.error('Component not found for:', componentName);
      return null;  // Ensure that null is returned if no component is found
    }
    return <Component {...sectionData} />;
  };

  // Render all sections and elements
  const renderedSections = sectionDataList && sectionDataList?.map((section, index) => (
    <div key={`section-${index}`}>
      {renderSection(section)}
    </div>
  ));

  // Check if there are any sections to render 
  if (renderedSections.length === 0) return null;

  return (
    <>
      {renderedSections}
    </>
  );
};

export default ReusableComponent;