import NextImage from '@components/elements/Image';
import './CommercialLicence.module.css';

const CommercialLicence = (props) => {
  const data = props.data || props;
  if(!data) return null;
  
  return (
    <>
      <div className='container'>
        <div className=''>
          <NextImage src={data?.image} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
        </div>
      </div>
    </>
  );
};

export default CommercialLicence;
