import HTMLRender from '@components/core/HTMLRender';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './ExpirationDHero.module.css';

const ExpirationDHero = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <>
      <div className='container'>
        <div className={styles.title}>
          <SectionTitle data={data?.section_title} />
        </div>
        {data?.content &&
          <div className='max-w-[50rem]'>
            <div className={`hidden md:block bg-white py-[3.333rem] px-[2.22rem] w-full md:mb-[3.33rem] rounded-lg ${styles.content}`}>
              <HTMLRender data={data?.content} />
            </div>
          </div>}
      </div>
    </>
  );
};

export default ExpirationDHero;
