import GenericSlider from "@components/custom/GenericSlider";
import SectionTitle from "@components/elements/SectionTitle";
import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./TradeHeroLicences.module.css";
import useLocale from "@lib/store/hooks/useLocale";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import NextImage from "@src/components/elements/Image";
import HTMLRender from "@src/components/core/HTMLRender";

const AuthorizationType = ({ type, className = "" }) => {
  const { gv } = useGlobalVariable();
  // "authorised_by", "regulated_by", "registered_with";
  let authorizationTypeText = "";
  switch (type) {
    case "authorised_by":
      authorizationTypeText = gv("authorised_by");
      break;
    case "regulated_by":
      authorizationTypeText = gv("regulated_by");
      break;
    case "registered_with":
      authorizationTypeText = gv("registered_with");
      break;
    default:
      break;
  }
  return <p className={`m-0 text-dark lg:block ${className}`}>{authorizationTypeText}</p>;
};

const TradeHeroLicences = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable();
  const locale = useLocale();
  const [activeIndex, setActiveIndex] = useState(null);
  const {
    data: regulators,
    isValidating,
    error,
  } = useSwrHook(`regulators`, {
    query: {
      populate: "*",
      fields: ["name", "name_long", "url", "authorization_type"],
      sort: ["order:asc"],
      locale,
    },
  });

  if (!data || isValidating || !regulators) return null;
  return (
    <div className="container">
      <div className={styles.title}>
        <SectionTitle data={data?.section_title} />
      </div>
      <div className="-mx-md md:m-0 licence-swiper-homepage">
        <Swiper
          loop={false}
          slidesPerView={regulators?.data?.length > 1 ? 2.2 : 1}
          breakpoints={{
            768: {
              slidesPerView: regulators?.data?.length < 3 ? regulators?.data?.length : 3,
            },
            1024: {
              slidesPerView: regulators?.data?.length > 5 ? 5 : regulators?.data?.length,
              allowTouchMove: regulators?.data?.length > 5 ? true : false,
            },
          }}
        >
          {regulators?.data?.map((regulator, index) => {
            const isActive = index === activeIndex;
            return (
              <SwiperSlide key={index}>
                <div className={`px-md pt-[2em] md:m-0 ${regulators?.data?.length > 1 ? `ltr:-mr-md rtl:-ml-md` : `mr-0`}`}>
                  <div
                    className={`relative min-h-[8.75rem] rounded-md bg-white px-xs pb-lg text-center shadow-lg transition-shadow duration-300 lg:min-h-0 lg:shadow-none lg:hover:shadow-lg`}
                    onClick={isActive ? () => setActiveIndex() : () => setActiveIndex(index)}
                  >
                    {regulator.attributes.url && (
                      <a
                        target="_blank"
                        href={regulator?.attributes?.url}
                        rel="noopener noreferrer"
                        className={`absolute top-0 left-0 z-10 hidden h-full w-full bg-transparent lg:block ${styles.linkWrapper}`}
                      />
                    )}
                    {regulator.attributes.flag_image && (
                      <div className="flex justify-center  -translate-y-1/2">
                        <div className="relative h-[4em] w-[4em] overflow-hidden rounded-full  shadow-xl">
                          <div className="" />
                          <NextImage
                            src={regulator?.attributes?.flag_image?.data?.attributes?.url}
                            alt={regulator?.attributes?.flag_image?.data?.attributes?.alternativeText}
                            width={72}
                            height={72}

                          />
                        </div>
                      </div>
                    )}
                    {regulator?.attributes?.authorization_type && (
                      <AuthorizationType type={regulator?.attributes?.authorization_type} className="hidden" />
                    )}
                    {regulator.attributes.name && <h5 className="m-0 pt-0 -mt-sm pb-xs lg:py-md font-bold">{regulator.attributes.name}</h5>}
                    {regulator.attributes.name_long && (
                      <div className={`hidden text-secondary lg:block  ${styles.blueText}`}><HTMLRender data={regulator.attributes.name_long} /></div>
                    )}
                    <p
                      className={`${styles.greekStyling} absolute bottom-[1rem] left-0 block w-full overflow-hidden text-secondary transition-all duration-200 lg:hidden ${isActive ? `opacity-0` : `opacity-100`
                        }`}
                    >
                      {gv("read_more")}
                    </p>
                    {regulator.attributes.name_long && (
                      <p
                        className={`block text-secondary  transition-all duration-200 lg:hidden ${isActive ? "visible m-0 opacity-100 max-h-[6rem] overflow-hidden" : `invisible -mt-7 opacity-0 max-h-0 overflow-hidden`
                          }`}
                      >
                        <a target="_blank" href={regulator.attributes.url} rel="noopener noreferrer">
                          <div className={`text-secondary lg:block  ${styles.blueText}`}><HTMLRender data={regulator.attributes.name_long} /></div>
                        </a>
                      </p>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default TradeHeroLicences;
