import Button from '@components/elements/Button';
import ColumnImage from '@components/elements/ColumnImage';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './BondsIntro.module.css';

const BondsIntro = (props) => {
  const data = props.data || props
  if (!data) {
    return null;
  } return (
      <div className="container">
        <div className={`${styles.title}`}>
          <SectionTitle data={data.section_title} />
        </div>
        <div className={styles.column_text}>
          <ColumnImage data={data.column_image} />
        </div>
        <div className='flex justify-center'>
          <p className="text-white text-center md:w-[40%] mx-auto">{data.text_above_button}</p>
        </div>
        <div className='flex justify-center pt-lg'>
          <Button data={data.button} />
        </div>
      </div>
  );
};

export default BondsIntro;
