import HTMLRender from '@components/core/HTMLRender';
import GenericSlider from '@components/custom/GenericSlider';
import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import { SwiperSlide } from 'swiper/react';
import styles from './CareersReviews.module.css';

const CareersReviews = (props) => {
  const data = props.data || props;
  if(!data) return null;

  return (
    <>
      <div className='container reviewSlider'>
        <SectionTitle data={data?.section_title} />
        <GenericSlider pagination slidesPerView={'auto'} spaceBetween={25} loop centeredSlides>
          {data?.careers_r_slides?.map((slide, index) => (
            <SwiperSlide key={index}>
              <div className='relative mt-xl flex flex-col justify-center items-center bg-white'>
                <div className="absolute -top-[20%]">
                  <NextImage src={slide?.image} width={slide?.image?.data?.attributes?.width} height={slide?.image?.data?.attributes?.height} />
                </div>
                <div className={`text-center p-md shadowbox pt-xl rounded-lg`}>
                  <div>
                    <p className="text-dark">{slide?.name}</p>
                  </div>
                  <div>
                    <p className="text-primary text-sm">{slide?.position}</p>
                  </div>
                  <div className={styles.review}>
                    <HTMLRender data={slide?.review} />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </GenericSlider>
      </div>
    </>
  );
};

export default CareersReviews;
