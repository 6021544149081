import AcademyCategoryCard from "@components/custom/AcademyCategoryCard";
import SectionTitle from "@components/elements/SectionTitle";
import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import { useStore } from "@lib/store";
import useLocale from "@lib/store/hooks/useLocale";
import { Skeleton } from "@mantine/core";
import { shallow } from "zustand/shallow";
import get from "lodash/get";
import "./LatestAcademyCategories.module.css";

function getRandomWidth(min, max) {
  return Math.random() * (max - min) + min;
} // Generate random widths for the skeleton.

const LatestAcademyCategories = (props) => {
  const data = props.data || props;

  return (
    <div className="container">
      <SectionTitle data={data.section_title} />
      <div className="flex flex-col justify-between  md:flex-row ">
        {data?.categories?.data?.map((newsCard, index) => (
          <div className="mb-[3.33rem] last:mb-0 md:w-[calc(33.3%-0.5rem)] lg:mb-0" key={index}>
            <AcademyCategoryCard data={{ ...newsCard, academy_homepage: data?.academy_homepage }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LatestAcademyCategories;
