import NextImage from '@components/elements/Image';
import NextLink from '@components/elements/Link';
import styles from './InstrumentCard.module.css'

const InstrumentCard = (props) => {
  const data = props.data || props;
  const instrumentSlug = data?.instrument?.data[0]?.slug;

  if (!data) {
    return null;
  }
  return (
    <div
      className={`${styles[`article-card`]
        } group flex h-full cursor-pointer flex-col justify-between ${styles?.primary
        } relative`}
    >
      <div className={styles.link}>
        <NextLink
          href={instrumentSlug}
          className="flex flex-col justify-between"
        />
      </div>
      <div className={`${styles['article-card-image']} h-full`}>
        <div
          className={`${styles['article-card-image']} relative h-[10em] overflow-hidden rounded-md`}
        >
          <NextImage
            src={data?.image}
            width={data?.image?.data?.attributes?.width}
            height={data?.image?.data?.attributes?.height}
          />
          <div className="absolute top-auto bottom-0 w-full bg-[rgba(0,0,0,.80)] transition duration-200 ease-in-out group-hover:bg-black">
            <p className="text-secondary p-xs">{data?.title}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstrumentCard;
