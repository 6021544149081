import styles from "@styles/article-typography.module.css";
import HTMLRender from "@src/components/core/HTMLRender";

const InstrumentContent = (props) => {
  const instrument = props.data || props;
  if (!instrument || !instrument?.content || instrument?.content === "") return null;
  return (
    <section className="mb-[3rem] py-2xl">
      <div className="container">
        <div className={styles[`article-text`]}>
          <HTMLRender data={instrument?.content} />
        </div>
      </div>
    </section>
  );
};

export default InstrumentContent;
