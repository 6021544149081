import { NextSeo } from "next-seo";
import Head from "next/head";
import { getStrapiMedia } from "@utils/media";
import { useAmp } from "next/dist/shared/lib/amp";
import StructuredData from "@src/components/custom/Seo/StructuredData";
import get from 'lodash/get'
import format from 'string-template';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import htmlToCleanText from "@utils/html-to-text";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import locale_de from 'dayjs/locale/de'
import locale_it from 'dayjs/locale/it'
import locale_es from 'dayjs/locale/es'
import locale_ro from 'dayjs/locale/ro'
import locale_cs from 'dayjs/locale/cs'
import locale_vi from 'dayjs/locale/vi'
import locale_ar from 'dayjs/locale/ar'
dayjs.extend(localizedFormat);

const Seo = ({ page, app }) => {
  const isAmp = useAmp();
  const { gv } = useGlobalVariable();

  const locale = app?.locale;
  let dayJSactiveLocale = '';

  switch (locale) {
    case 'ae-ar':
      dayJSactiveLocale = 'ar';
      break;
    case 'za':
    case 'ae':
    case 'eu':
      dayJSactiveLocale = 'en';
      break;
    case 'lat':
      dayJSactiveLocale = 'es';
      break;
    case 'cz':
      dayJSactiveLocale = 'cs';
      break;
    case 'vn':
      dayJSactiveLocale = 'vi';
      break;
    default:
      dayJSactiveLocale = locale;
      break;
  }

  dayjs.locale(dayJSactiveLocale);

  // set current year and month
  const currentYear = dayjs().format('YYYY');
  const currentMonth = dayjs().format('MMMM');

  const { slug, pageType, metaData, pageData } = {
    slug: `${app?.domain}/${app?.locale}${page?.slug}`,
    pageType: page?.layout_type || "page",
    metaData: {
      ...(page?.seo || page?.globalSeo),
      metaTitle:
        page?.seo?.metaTitle && page?.seo?.metaTitle !== "" ? format((page?.seo?.metaTitle), {
          year: currentYear,
          month: currentMonth
        }) : format((page?.globalSeo?.metaTitle), {
          year: currentYear,
          month: currentMonth
        }),
      metaDescription:
        page?.seo?.metaDescription && page?.seo?.metaDescription !== ""
          ? format((page?.seo?.metaDescription), {
            year: currentYear,
            month: currentMonth
          }) : format((page?.globalSeo?.metaDescription), {
            year: currentYear,
            month: currentMonth
          })
    },
    pageData: page || {},
  };

  if (pageType === "instruments") {
    const hasDynamicMetas = get(page, 'global_instrument_types.0.seo.metaTitle', false) && get(page, 'global_instrument_types.0.seo.metaDescription', false);
    if (hasDynamicMetas) {
      metaData.metaTitle = format((get(page, 'global_instrument_types.0.seo.metaTitle', '')), {
        display_name: page.display_name,
        symbol: page.symbol,
        year: currentYear,
        month: currentMonth
      });
      metaData.metaDescription = format((get(page, 'global_instrument_types.0.seo.metaDescription', '')), {
        display_name: page.display_name,
        symbol: page.symbol,
        year: currentYear,
        month: currentMonth
      });
    }
  }

  if (pageType === "ipo") {
    metaData.metaTitle = format((get(pageData, 'nested_page[0].seo.metaTitle', '')), {
      ipo_name: pageData.name,
      year: currentYear,
      month: currentMonth
    });
    metaData.metaDescription = format((get(pageData, 'nested_page[0].seo.metaDescription', '')), {
      ipo_name: pageData.name,
      year: currentYear,
      month: currentMonth
    });
  }

  if (pageType === "faq") {
    const questions = [];

    for (let i = 0; i < pageData?.accordion_item.length; i++) {
      if (i < 3) {
        questions.push(pageData?.accordion_item[i].title);
      }
    }
    const concatenated_question = questions.join(" ").replace(/<\/?p>/gi, "");
    metaData.metaDescription = htmlToCleanText(concatenated_question)


    metaData.metaTitle = format((get(pageData, 'nested_page[0].seo.metaTitle', '')), {
      faq_name: pageData.title,
      year: currentYear,
      month: currentMonth
    });

  }

  const checkPageType = () => {
    if (pageType === "ipo" || pageType === "article" || pageType === "academy") {
      return true;
    }
    return false;
  }

  const twitterConfig = !checkPageType() ? {
    twitter: {
      handle: 'Capex.com',
      site: 'Capex.com',
      cardType: 'summary_large_image',
    }
  } : {};

  return (
    <>
      <Head>
        {!isAmp && <meta name="viewport" content="width=device-width, initial-scale=1" />}
        {!checkPageType() && <meta property="og:image" content={metaData?.metaImage?.url || metaData?.metaImage?.data?.attributes?.url || `https://cdn.capex.com/uploads/capex_og_6786406942/capex_og_6786406942.png`} />}
        {/* <meta name="keywords" content={metaData?.keywords} /> */}

        {/* <link rel="canonical" href={"./"} /> */}
        {/* <script type="application/ld+json" dangerouslySetInnerHTML={addJsonLd()} key="item-jsonld" /> */}
      </Head>
      <StructuredData pageType={pageType} pageData={page} metaData={metaData} />
      <NextSeo
        title={metaData?.metaTitle}
        description={metaData?.metaDescription}
        openGraph={{
          // Title and description are mandatory
          title: metaData?.metaTitle,
          description: metaData?.metaDescription,
          type: !checkPageType() ? 'website' : 'article',
          url: page?.fullSlug,
          images: () => {
            let image = metaData?.metaImage;
            if (metaData?.metaImage?.hasOwnAttribute("data")) {
              image = image?.data?.attributes;
            }
            return {
              url: getStrapiMedia(image?.url),
              width: image?.width,
              height: image?.height,
            };
          },
          ...checkPageType() ? {
            article: {
              publishedTime: page?.publishedAt,
              modifiedTime: page?.updatedAt,
              authors: [
                'https://CAPEX.com'
              ]
            },
          } : {}
        }}
        {...!checkPageType() ? {
          twitter: {
            handle: 'CAPEX.com',
            site: 'CAPEX.com',
            cardType: 'summary_large_image',
          }
        } : {}}
      />
    </>
  );
};

export default Seo;
