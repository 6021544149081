import styles from './PressRoomCard.module.css'
import { IoCalendarSharp } from '@react-icons/all-files/io5/IoCalendarSharp';
import NextLink from 'next/link'
import NextImage from '@components/elements/Image';
import Link from '@components/elements/Link';
import HTMLRender from '@components/core/HTMLRender';
import dayjs from 'dayjs'
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import { Modal } from '@mantine/core';
import { useState } from 'react';
import Arrow from '@src/components/elements/Arrow';
import { getStrapiMedia } from '@utils/media';

const PressRoomCard = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable();
  const formatDate = dayjs(data?.publication_date).format('DD MMMM YYYY')
  const pressType = data?.press_type || 'external_url';
  const externalUrl = data?.link_url || '';
  const pdfUrl = data?.pdf_file?.data?.attributes?.url || '';

  // modal state
  const [showModal, setShowModal] = useState(false);

  if (!data) {
    return null;
  }

  return (
    <div className={`relative group cursor-pointer ${styles.cardWrapper}`}>
      {/* add link to external source if press type is external_url and link_url is set */}
      {pressType === 'external_url' && externalUrl && <a href={data?.link_url} target="_blank" className={styles.linkUrl} />}
      {/* add iframe for pdf viewer pdf if press type is pdf file and pdf file is set */}
      {pressType === 'pdf_file' && pdfUrl && <div onClick={() => setShowModal(true)} className={`${styles.linkUrl} hidden lg:block`} />}
      {/* add pdf download link for mobile devices */}
      {pressType === 'pdf_file' && pdfUrl && <a href={pdfUrl} target="_blank" className={`${styles.linkUrl} lg:hidden`} />}
      <Modal
        opened={showModal}
        onClose={() => setShowModal(false)}
        size="70%"
        centered
      >
        <iframe title="Press Article" src={pdfUrl} className="w-full h-[70vh]" />
      </Modal>
      <div className={styles.cardContent}>
        <div className={styles.cardLeft}>
          <div className={styles.date}>
            <p className='flex items-center text-sm'><IoCalendarSharp className='ltr:mr-3 rtl:ml-3' />{formatDate}</p>
            <h5 className="mb-0 leading-snug"><HTMLRender data={data.title} /></h5>
          </div>
        </div>
        <div className={`${styles.cardCenter}`}>
          <NextImage src={data?.press_publisher?.data?.attributes?.logo?.data?.attributes?.url} width={data?.press_publisher?.data?.attributes?.logo?.data?.attributes?.width || 280} height={data?.press_publisher?.data?.attributes?.logo?.data?.attributes?.height || 90} />
        </div>
        <div className={styles.cardRight}>
          <HTMLRender data={data.description} />
          <div className={styles.readMore}>
            {gv('read_more')}
            <i className='px-xs'><Arrow direction="right" /></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PressRoomCard;
