import HTMLRender from "@components/core/HTMLRender";
import styles from "./AcademyHero.module.css";

const AcademyHero = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className="relative">
      <div className="container relative flex flex-col justify-center lg:h-[26em]">
        <div className="w-full lg:max-w-[70%]">
          <h1 className="mb-0 pb-xs text-[2.6rem] font-bold text-white ltr:font-gilroy rtl:font-dubai">
            {data?.title}
          </h1>
          <h3 className="max-w-[75%] text-white md:max-w-full">{data?.subtitle}</h3>
          <div className={`${styles.content} max-w-[70%] lg:max-w-full`}>
            <p>
              <HTMLRender data={data?.content} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcademyHero;
