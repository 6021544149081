import SectionTitle from "@components/elements/SectionTitle";
import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import { useEffect, useState } from "react";
import { Select } from "@mantine/core";
import styles from "./PaymentMethods.module.css";
import Arrow from "@components/elements/Arrow";
import HTMLRender from "@components/core/HTMLRender";
import Loaders from "@components/elements/Loaders";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";

const PaymentMethods = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable();
  const [activeLocation, setActiveLocation] = useState("0");
  const tableHeaderItems = [
    gv("payment_provider"),
    gv("payment_method"),
    gv("payment_authority"),
    gv("payment_description"),
  ];
  const { data: paymentMethods, isValidating: paymentMethodsIsValidating } = useSwrHook("paymentMethods", {
    query: {},
  });

  if (!data || paymentMethodsIsValidating) return null;
  return (
    <div className="container">
      <SectionTitle data={data?.section_title} />
      <div className="flex flex-col md:flex-row items-center justify-between rounded-lg bg-[#ececec] py-md md:py-lg px-xl">
        <p className="text-center ltr:md:text-left rtl:md:text-right mb-md md:mb-0">{gv("payment_methods_available")}</p>
        <Select
          value={activeLocation}
          onChange={setActiveLocation}
          searchable
          rightSection={<Arrow direction="down" />}
          rightSectionWidth={50}
          dropdownPosition="bottom"
          data={paymentMethods}
          classNames={{
            label: styles.selectLabel,
            input: styles.selectInput,
            wrapper: styles.wrapper,
            rightSection: styles.rightSection,
            item: styles.itemSelect
          }}
        />
      </div>
      <div className="mt-lg flex flex-row lg:flex-col lg:min-h-[70vh]">
        <div className="flex flex-col w-[40%] lg:w-full lg:flex-row items-center lg:justify-between border-b border-b-[#dfdfe7]">
          {tableHeaderItems.map((item, index) => (
            <div key={index} className="h-[4.375rem] last:h-[250px] lg:h-auto lg:last:h-auto w-full lg:max-w-[calc(100%/5-2rem)] lg:py-[2.777rem] lg:last:max-w-[calc(40%-2rem)] flex items-center">
              <p className="font-bold text-dark">{item}</p>
            </div>
          ))}
        </div>
        <div className="flex h-full flex-row justify-between w-[60%] lg:w-full overflow-auto">
          <div className="flex w-full flex-row lg:flex-col items-start  justify-start">
            {paymentMethods[activeLocation]?.data.map((fields, index) => (
              <div key={index} className="flex w-full flex-col lg:flex-row  justify-between lg:py-[2.777rem] border-b border-b-[#dfdfe7] flex-[0_0_100%] lg:flex-auto  text-xs lg:text-base">
                <div className="h-[4.375rem] flex lg:block p-[0.25rem] lg:p-0 items-center lg:items-start lg:h-auto lg:w-[calc(100%/5-2rem)]">
                  <p className="">{fields?.provider}</p>
                </div>
                <div className="h-[4.375rem] flex lg:block p-[0.25rem] lg:p-0 items-center lg:items-start lg:h-auto lg:w-[calc(100%/5-2rem)]">
                  <p className="">{fields?.method}</p>
                </div>
                <div className="h-[4.375rem] flex lg:block p-[0.25rem] lg:p-0 items-center lg:items-start lg:h-auto lg:w-[calc(100%/5-2rem)]">
                  <p className="">{fields?.authority}</p>
                </div>
                <div className="h-[15.625rem] flex lg:block p-[0.25rem] lg:p-0 items-center lg:items-start lg:h-auto lg:w-[calc(40%-2rem)]">
                  <HTMLRender data={fields?.desc} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
