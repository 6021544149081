import Button from '@components/elements/Button';
import styles from './InvestMarketsInvest.module.css';
import { getStrapiMedia } from '@utils/media';
import { useState, useRef, useEffect } from 'react';
import { useIntersection } from '@mantine/hooks';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';

const InvestMarketsInvest = (props) => {
  const data = props.data || props;
  //Observe when the platform video enters the viewport and play the video
  const [isInViewport, setIsInViewport] = useState(false);
  const containerRef = useRef();
  const videoRef = useRef(null);
  const [ref, observedEntry] = useIntersection({
    root: containerRef.current,
    threshold: 0,
  });

  const { gv } = useGlobalVariable()

  const inView = observedEntry && observedEntry.isIntersecting;
  const [flag, setFlag] = useState(true);

  useEffect(() => {
    if (inView && flag) {
      setIsInViewport(true);
      videoRef.current.play();
      setFlag(false)
    } return
  }, [inView])
  //End observe video Entry functions

  if (!data) return null;

  return (
    <div className="container">
      <div ref={ref} className="flex w-full flex-col items-center justify-between md:flex-row">
        <div className="flex w-full flex-col lg:max-w-[calc(100%/2-2.5rem)]">
          <div className="hidden md:block">
            <p className="mb-xs text-dark text-[1.333rem]">
              {data?.uppertitle}
            </p>
            <h2 className="ltr:font-gilroy rtl:font-dubai text-[2.44rem] ltr:font-black rtl:font-bold uppercase">
              {data?.title}
            </h2>
          </div>
          <div className="block text-center md:hidden">
            <p className="mb-xs text-sm text-[#737373]">
              {data?.title}
            </p>
            <p
              className={` ${styles.title} mb-xs text-dark ltr:font-gilroy rtl:font-dubai text-[4.444rem] ltr:font-black rtl:font-bold leading-[1]`}
            >
              <span>{data?.title_mobile}</span>
            </p>
            <h2
              className={` ${styles.title} ltr:font-gilroy rtl:font-dubai text-[1.11rem] font-semibold`}
            >
              <span>{data?.subtitle_mobile}</span>
            </h2>
          </div>
          <div
            className={`mt-xl mx-auto flex w-full max-w-[70%] flex-col items-center justify-between md:mx-0 md:max-w-[90%] md:flex-row xl:max-w-full ${styles.buttons}`}
          >
            <Button data={data?.button_one} />
            <p className="mb-0 hidden text-[#b3b3b3] md:block whitespace-nowrap">
              - {gv('or')} -
            </p>
            <Button data={data?.button_two} />
          </div>
        </div>
        <div className="hidden w-full md:block">
          <video
            ref={videoRef}
            muted
            playsInline
            preload="none"
            width="522"
            height="643"
            style={{ maxWidth: '100%' }}
          >
            <source
              src={getStrapiMedia(
                data?.dma_phones?.data?.attributes?.url
              )}
              type="video/mp4"
            />
            <source
              src={getStrapiMedia(
                data?.dma_phones?.data?.attributes?.url
              )}
              type="video/ogg"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default InvestMarketsInvest;
