import styles from './FooterMenu.module.css'
import Link from '@components/elements/Link';
import Arrow from '@components/elements/Arrow';
import { Accordion } from '@mantine/core';
import { memo, useState } from 'react';
import useLocale from '@lib/store/hooks/useLocale';
import get from 'lodash/get';
import { FiPlus } from '@react-icons/all-files/fi/FiPlus';
import { FiMinus } from '@react-icons/all-files/fi/FiMinus';

const MenuChildrenItems = ({ menuParentItem }) => {
  const locale = useLocale();
  return (
    <ul>
      {menuParentItem?.children?.map((menuChildrenItem, index) =>
      (
        menuChildrenItem && <li key={index} className="pb-0">
          <div className='flex flex-col py-xs lg:pt-0 lg:pb-[0.35rem]'>
            <Link color="grey" link={`${menuChildrenItem?.slug}`} text={menuChildrenItem.title} />
            <ul>
              {menuChildrenItem?.children?.map((menuGrandChildrenItem) => (
                <li key={index} className="flex flex-row items-center pb-xs first:pt-xs  group">
                  <div className='text-grey mr-sm flex flex-col items-center group-hover:text-secondary'>
                    <Arrow direction="right" />
                  </div>
                  <Link color="grey" link={menuGrandChildrenItem.slug} text={menuGrandChildrenItem.title} />
                </li>
              ))}
            </ul>
          </div>
        </li>
      )
      )}
    </ul>
  )
}

const MenuChildrenItemsMemo = memo(MenuChildrenItems);

const FooterMenu = (props) => {
  const locale = useLocale();
  const data = get(props, 'data', props);

  const [indexOpened, setIndexOpened] = useState(null);

  if (!data) return null;

  return (
    <>
      {/* mobile menu */}
      <div className="block lg:hidden">
        <ul className='flex flex-col justify-between'>
          <Accordion
            styles={{ icon: { color: '#FFF' } }}
            iconPosition="right"
            variant="unstyled"
            transitionDuration={500}
            classNames={{
              control: styles.control,
              itemOpened: styles.itemOpened,
              label: styles.label,
              item: styles?.item,
              contentInner: styles?.contentInner,
              icon: styles?.icon
            }}
          >
            {data && data.length > 0 && data?.map((menuParentItem, index) => (
              <Accordion.Item key={index}
                icon={indexOpened === index ? <FiMinus className='text-white' /> : <FiPlus className='text-white' />}
                onClick={(e) => {
                  if (e.target.tagName === 'DIV' || e.target.tagName === 'svg') {
                    setIndexOpened(indexOpened === index ? null : index)
                  }
                }}
                label={!menuParentItem.slug ? menuParentItem.title : <Link color="secondary"
                  href={menuParentItem?.slug}
                  text={menuParentItem.title} />}
              >
                <MenuChildrenItems setIndexOpened={setIndexOpened} menuParentItem={menuParentItem} />
              </Accordion.Item>
            ))}
          </Accordion>
        </ul>
      </div>
      {/* Desktop menu */}
      <div className="hidden lg:block">
        <ul className='flex  text-grey flex-wrap -mx-sm'>
          {data && data.length > 0 && data?.map((menuParentItem, index) => (
            <li key={index} className='pb-0 w-full max-w-[calc(100%/3)] px-sm'>
              <div className='mb-[3.111rem]'>
                <h2 className="text-white font-bold text-sm mb-md capitalize">{!menuParentItem.slug ? menuParentItem.title : <Link color="secondary" link={`${menuParentItem?.slug}`} text={menuParentItem.title} />}</h2>
                <MenuChildrenItemsMemo menuParentItem={menuParentItem} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default FooterMenu;
