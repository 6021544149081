/* eslint-disable jsx-a11y/anchor-has-content */
import NextImage from "@components/elements/Image";
import NextLink from "@components/elements/Link";
import styles from "./FaqMainpage.module.css";

const FaqMainpage = (props) => {
  const data = props.data || props;
  if (!data) return null;
  const faqItems = data?.faq_pages;

  return (
    <div className="container">
      <div className="grid gap-[1.5rem] md:grid-cols-2 md:gap-[1.111rem] lg:grid-cols-3 lg:gap-[2rem]">
        {faqItems?.data.map((item, index) => (
          <div className={`flex items-center py-[2rem] px-lg ${styles[`faq-card`]} relative`} key={index}>
            <NextLink href={item?.slug} className="flex flex-col justify-between">
              <a className="absolute top-0 right-0 z-10 h-full w-full" />
            </NextLink>
            <div className="relative h-[1.75rem] w-[1.75rem]">
              <NextImage src={item?.icon} width={item?.icon?.data?.width} height={item?.icon?.data?.height} />
            </div>
            <p className="ltr:pl-lg rtl:pr-lg">{item?.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqMainpage;
