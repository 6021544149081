import styles from './OnboardingFaq.module.css';
import SectionTitle from '@src/components/elements/SectionTitle';
import HTMLRender from '@components/core/HTMLRender';
import { Accordion } from '@mantine/core';
import { HiOutlineChevronDown } from '@react-icons/all-files/hi/HiOutlineChevronDown';

const OnboardingFaq = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div id={data?.section_layout?.section_id} className='container container-large'>
      <div className='bg-[#F8F8F8] rounded-[30px] pt-[3.888rem] pb-[5rem] md:pb-[7rem]'>
        <div className='w-full max-w-[85%] md:max-w-full mx-auto'>
          <SectionTitle data={data?.section_title} />
        </div>
        <div className='w-full max-w-[85%] md:max-w-[55rem] mx-auto'>
          <Accordion
            styles={{ icon: { color: '#000' } }}
            classNames={{
              control: styles.AccordionControl,
              root: styles.AccordionRoot,
              itemOpened: styles.itemOpened,
              label: styles.AccordionLabel,
              item: styles?.AccordionItem,
              contentInner: styles?.contentInner,
              icon: styles.borderAccordionIcon,
              content: styles?.AccordionContent,
            }}
            variant="unstyled"
            transitionDuration={500}
            icon={<HiOutlineChevronDown />}
            iconPosition="right"
            initialItem={data?.initialItem || null}
          >
            {data?.faq_list?.map((question, index) => (
              <Accordion.Item key={index} label={<HTMLRender data={question?.title} />}>
                <HTMLRender data={question?.content} />
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default OnboardingFaq;
