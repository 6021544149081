import HTMLRender from '@components/core/HTMLRender';
import Arrow from '@components/elements/Arrow';
import NextImage from '@components/elements/Image';
import NextLink from '@components/elements/Link';
import './HomepageIntegratedTools.module.css';

const ToolsList = ({ tool, index }) => {
  return (
    <div key={index} className={`mb-[2.22rem] ${tool?.position === 'left' ? 'ltr:text-left rtl:text-right' : 'ltr:text-right rtl:text-left'}`}>
      <p className='text-secondary uppercase mb-md'>{tool.title}</p>
      <div className="mb-md">
        <HTMLRender data={tool.subtitle} />
      </div>
    </div>
  );
}

const HomepageIntegratedTools = (props) => {
  const data = props.data || props;

  let hasData = true;
  for (const [key, value] of Object.entries(data)) {
    if (value === null || value.data === null) hasData = false;
  }

  if (hasData) {
    return (
      <>
        {/* Desktop Version */}
        <div className="container mt-0">
          <div className='hidden md:flex w-full items-center'>
            <div className='py-[2.222rem] flex-1'>
              {data.tools_description.slice(0, 3).map((tool, index) => (
                <div key={index}>
                  <ToolsList tool={tool} index={index} />
                </div>
              ))}
            </div>
            <div className='px-lg -mt-[4.444rem]'>
              <div className='relative min-w-[250px] min-h-[283px] lg:min-w-[447px] lg:min-h-[540px]'>
                <NextImage objectFit src={data?.image.data.attributes?.url} />
              </div>
            </div>
            <div className="py-[2.222rem] flex-1">
              {data.tools_description.slice(3, data.tools_description.length).map((tool, index) => (
                <div key={index}>
                  <ToolsList tool={tool} index={index} />
                </div>
              ))}
            </div>
          </div>
          {/* Mobile Version */}
          <div className="md:hidden">
            <div className='w-full text-center bg-whiteCream py-sm px-xs border-b border-b-[#e2e2e2]'>
              <p>{data.mobile_title}</p>
            </div>
            {data.tools_description.map((tool, index) => (
              <ul key={index}>
                <NextLink href={tool.title_link}>
                  <a>
                    <li className='mb-0 py-sm px-xs border-b border-b-[#e2e2e2] w-full flex items-center justify-between text-secondary uppercase'>
                      {tool.title}
                      <Arrow direction="right" />
                    </li>
                  </a>
                </NextLink>
              </ul>
            ))}
          </div>
        </div>
      </>
    )
  }
  return null
};

export default HomepageIntegratedTools;
