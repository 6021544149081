import { usePersistentStore, useStore } from "@lib/store";
import { useLocaleObject } from "@lib/store/hooks/useLocale";
import { getCookie } from "cookies-next";
import { setCookie } from "nookies";
import { useEffect } from "react";

export default function useCheckLicenseCookie() {
  const { company_id } = useLocaleObject();
  const { wrongLicenceState, setWrongLicenceState } = usePersistentStore((store) => ({
    wrongLicenceState: store.wrongLicenceState,
    setWrongLicenceState: store.setWrongLicenceState,
  }));

  useEffect(() => {
    const currentUserLicense = getCookie("regulation_licence");
    if (company_id.split("_")[0] !== currentUserLicense) {
      setWrongLicenceState(currentUserLicense);
      setCookie(null, "wrong_license", JSON.stringify(true), {
        maxAge: 30 * 24 * 60 * 60,
        path: "/",
      });
    } else {
      setWrongLicenceState(false);
      setCookie(null, "wrong_license", JSON.stringify(false), {
        maxAge: 30 * 24 * 60 * 60,
        path: "/",
      });
    }
  }, [company_id]);

  return {
    wrongLicenceState,
  };
}
