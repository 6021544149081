import ColumnImage from '@components/elements/ColumnImage';
import SectionTitle from '@components/elements/SectionTitle';
import './TradeIndices.module.css';

const TradeIndices = (props) => {
  const data = props.data || props;
  
  if (!data) {
    return null;
  } return (
    <>
      <div className="container">
        {
          data?.section_title && <SectionTitle data={data.section_title} />
        }
        <div>
          <ColumnImage data={data.column_image} />
        </div>
      </div>
    </>
  );
};

export default TradeIndices;
