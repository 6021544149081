import styles from './InstrumentTradingConditions.module.css'
import { Select } from '@mantine/core';
import { useState } from 'react';
import Arrow from '@components/elements/Arrow';
import HTMLRender from '@components/core/HTMLRender';
import useSwrHook from '@lib/services/hooks/swr/useSwrHook';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import NextImage from '@src/components/elements/Image';

const InstrumentTradingConditions = (props) => {
  const { gv } = useGlobalVariable();
  const capexWeb = gv('capex_webtrader');
  const [value, setValue] = useState("0");
  const instrument = props.data || props;
  const { data, error } = useSwrHook(!instrument ? null : 'getDealingData', { query: { instrument: instrument?.api_id } });
  if (!data || error) return null;
  if (!instrument) {
    return null;
  }
  const DEALING_DATA = data?.[instrument?.api_id];
  return (
    <section
      className={`bg-dark text-white py-xl pb-2xl lg:py-2xl ${styles[`trading-conditions`]
        } relative`}
    >
      <div className="absolute h-full w-full left-0 top-0">
        <NextImage
          src="https://cdn.capex.com/uploads/trading_conditions_bg_51bf1ca8e4/trading_conditions_bg_51bf1ca8e4.png"
          layout="fill"
          quality="100"
          objectFit="cover"
        />
      </div>
      <div className="container relative">
        <h4 className="m-0 text-center ltr:lg:text-left rtl:lg:text-right">
          {gv('trading_conditions')}
        </h4>
        <div className="flex flex-col lg:flex-row  items-center  py-xl">
          <div className="bg-white rounded max-w-[16rem] text-lg py-md px-lg text-secondary">
            <Select
              value={value}
              onChange={setValue}
              label={gv('select_platform')}
              rightSection={<Arrow direction="down" />}
              rightSectionWidth={30}
              data={[
                { value: '0', label: gv('capex_webtrader') },
                // { value: '1', label: gv('metatrader_5') },
              ]}
              classNames={{
                wrapper: styles.selectWrapper,
                label: styles.selectLabel,
                input: styles.selectInput,
                rightSection: styles.selectRightSection,
                dropdown: styles.selectDropdown,
                root: styles.selectRoot,
                item: styles.itemSelect
              }}

            />
          </div>
          <div className="flex ltr:lg:ml-xl rtl:lg:mr-xl items-center lg:after:h-[1px] lg:after:grow lg:after:bg-grey lg:after:block grow pt-xl lg:pt-0 lg:pt-0 text-center lg:text-left">
            <h4 className="m-0 ltr:lg:pr-lg rtl:lg:pl-lg">
              <HTMLRender data={instrument?.description} />
            </h4>
          </div>
        </div>
        <div className="pt-lg lg:pt-0 hidden lg:block">
          <div
            className={`flex lg:items-center lg:text-center bg-[rgba(127,127,127,.2)] py-lg rounded-md ${styles[`table-row`]
              } flex-col lg:flex-row`}
          >
            <div>{gv('spread_as_low_as')}</div>
            <div>{gv('leverage')}</div>
            <div>{gv('trading_hours_gmt')}</div>
            <div>{gv('lot_size')}</div>
            <div>{gv('overnight_rollover_long')}</div>
            <div>{gv('overnight_rollover_short')}</div>
          </div>
          <div
            className={`flex lg:items-center lg:text-center lg:border-b border-b-[rgba(255,255,255,.3)] py-lg ${styles[`table-row`]
              } flex-col lg:flex-row`}
          >
            <div>{DEALING_DATA?.spreadPerUnit}</div>
            <div>{DEALING_DATA?.leverage}</div>
            <div>{DEALING_DATA?.tradingHours}</div>
            <div>{DEALING_DATA?.lotSize}</div>
            <div>{DEALING_DATA?.overnightInterestBuy}</div>
            <div>{DEALING_DATA?.overnightInterestSell}</div>
          </div>
        </div>

        <div
          className={`block lg:hidden ${styles[`mobile-table-row`]
            }`}
        >
          <div>
            <div>{gv('spread_as_low_as')}</div>
            <div>{DEALING_DATA?.spreadPerUnit}</div>
          </div>
          <div>
            <div>{gv('leverage')}</div>
            <div>{DEALING_DATA?.leverage}</div>
          </div>
          <div>
            <div>{gv('trading_hours_gmt')}</div>
            <div>{DEALING_DATA?.tradingHours}</div>
          </div>
          <div>
            <div>{gv('lot_size')}</div>
            <div>{DEALING_DATA?.lotSize}</div>
          </div>
          <div>
            <div>{gv('overnight_rollover_long')}</div>
            <div>{DEALING_DATA?.overnightInterestBuy}</div>
          </div>
          <div>
            <div>{gv('overnight_rollover_short')}</div>
            <div>{DEALING_DATA?.overnightInterestSell}</div>
          </div>
        </div>

        <p className="pt-xl text-inherit">
          <HTMLRender data={gv('spread_represents_long_text')} />
        </p>
      </div>
    </section>
  );
};

export default InstrumentTradingConditions;
