import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import HTMLRender from '@src/components/core/HTMLRender';
import Button from '@src/components/elements/Button';
import SectionTitle from '@src/components/elements/SectionTitle';
import styles from './PartnersCySteps.module.css';

const PartnersCySteps = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable();
  if (!data) return null;


  return (
    <div className='container' id={data?.section_layout?.section_id}>
      <SectionTitle data={data?.section_title} />
      <div className='flex flex-col lg:flex-row flex-wrap'>
        {data?.steps.map((step, index) => (
          <div key={index} className={`w-full lg:max-w-[calc(100%/3)] flex lg:justify-center border-b lg:border-b-0 mb-lg lg:mb-0 ${styles.outerCard}`}>
            <div className={styles.content}>
              <div className={` text-xl lg:text-2xl font-semibold lg:px-xl mb-0 lg:mb-xs ${data?.white_text ? `text-white` : `text-dark`}`}> <HTMLRender data={step?.title} /></div>
              <div className={styles.innerCard}>
                <div className='py-md text-sm md:text-base h-full flex flex-col justify-between'>
                  <HTMLRender data={step?.content} />
                  {step?.steps_button &&
                    <div className='pt-[1.5rem]'>
                      <Button data={step?.steps_button} />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='w-full flex justify-center items-center mt-xl pb-2xl lg:pb-lg'>
        <Button data={data?.button} />
      </div>
    </div>
  );
};

export default PartnersCySteps;
