import styles from "@styles/article-typography.module.css";
import { IoCalendarOutline } from "@react-icons/all-files/io5/IoCalendarOutline";
import dayjs from "dayjs";
import NextImage from "@components/elements/Image";
import HTMLRender from "@components/core/HTMLRender";
import Logo from "@components/elements/Logo";
import Link from "@components/elements/Link";
import { useRouter } from "next/router";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { GrFacebook } from "@react-icons/all-files/gr/GrFacebook";
import { GrLinkedin } from "@react-icons/all-files/gr/GrLinkedin";
import { GrTwitter } from "@react-icons/all-files/gr/GrTwitter";
import ArticleNavigation from "@components/custom/ArticleNavigation";
import ArticleSidebar from "@components/custom/ArticleSidebar";
import get from "lodash/get";
import { useStore } from "@lib/store";
import { Fragment, useEffect, useRef } from "react";
import useLocale from "@lib/store/hooks/useLocale";
import { Accordion } from "@mantine/core";
import { HiOutlineChevronDown } from '@react-icons/all-files/hi/HiOutlineChevronDown';
import { FAQPageJsonLd, NextSeo } from "next-seo";
import htmlToCleanText from "@utils/html-to-text";
import Image from "next/image";
import DifficultyLevel from "@src/components/elements/DifficultyLevel";
import flattenDeep from "lodash/flattenDeep";
import ArticleSidebarInstrument from "@src/components/custom/ArticleSidebarInstrument";
import ArticleAnalystBox from "@src/components/custom/ArticleAnalystBox";
import ArticleRating from "@src/components/custom/ArticleRating";
import ArticleExplore from "@src/components/custom/ArticleExplore";
import ArticleShareBox from "@src/components/custom/ArticleShareBox";
import { useIntersection } from "@mantine/hooks";


const formatDate = (date) => {
  return dayjs(date).format("DD MMMM YYYY");
};

export default function LayoutArticle({ pageData }) {
  const locale = useLocale();
  const router = useRouter();
  const { gv } = useGlobalVariable();
  const absoluteUrl = process.env.NEXT_PUBLIC_CURRENT_ENV + "/" + router.locale + router.asPath;
  const store = useStore((store) => ({
    locale: store.locale,
  }));

  let readingTime = pageData?.content ? Math.ceil(htmlToCleanText(pageData.content).split(' ').length / 238) : 0;
  if (readingTime === 1) {
    readingTime = 2;
  }
  const handleRatingSubmit = (data) => {
    console.log(`New rating: ${data.rating}`);
    console.log(`Comment: ${data.comment}`);
  };

  const containerRef = useRef();
  const [ref, observedEntry] = useIntersection({
    root: containerRef.current,
    threshold: 0,
  });
  const inView = observedEntry && observedEntry.isIntersecting;

  useEffect(() => {
    const body = document.querySelector('body');
    if (!inView) {
      body.classList.add('show-sticky');
    } else {
      body.classList.remove('show-sticky');
    }
  }, [inView])


  return (
    <>
      <NextSeo
        twitter={{
          handle: pageData?.global_analyst?.name || 'CAPEX.com',
          site: 'CAPEX.com',
          cardType: 'summary_large_image',
        }}
        openGraph={{
          images: [{
            url: pageData?.image?.url,
            width: pageData?.image?.width,
            height: pageData?.image?.height,
          }]
        }}
      />
      {pageData?.faq_list && pageData?.faq_list?.length > 0 &&
        <FAQPageJsonLd
          mainEntity={
            pageData?.faq_list?.map(item => (
              {
                questionName: htmlToCleanText(item.title),
                acceptedAnswerText: htmlToCleanText(item.content)
              }
            ))
          }
        />
      }

      <div className="pt-0 lg:pt-2xl text-white bg-[#111114] relative mb-[12rem] ">
        <div className="pt-[4.8888rem]" />
        <div className="hidden sm:block">
          <NextImage
            layout="fill"
            objectFit="cover"
            src="https://cdn.capex.com/uploads/article_hero_bg_8052bedc7f/article_hero_bg_8052bedc7f.png"
            priority
            alt="Article Hero"
            quality={100}
          />
        </div>
        <div className="block sm:hidden ">
          <NextImage
            layout="fill"
            objectFit="cover"
            src="https://cdn.capex.com/uploads/article_hero_bg_mobile_1760ee1b6e/article_hero_bg_mobile_1760ee1b6e.png"
            priority
            alt="Article Hero"
            quality={100}
          />
        </div>
        <div ref={ref} className="container relative">
          <div className="flex items-center justify-center lg:justify-between">
            <div className={`${styles[`article-breadcrumbs`]} hidden lg:flex`}>
              {pageData?.breadcrumb?.map((item, index) => (
                <Fragment key={index}>
                  <Link color="white" link={item.slug} text={item.name} />
                  <div className="mx-xs">/</div>
                </Fragment>
              ))}
              <div className="max-w-[18em]  truncate">{pageData?.title}</div>
            </div>

          </div>
          <div className="flex flex-col lg:flex-row  justify-between pt-lg lg:py-xl lg:pb-lg ">
            <h1 className="text-center lg:ltr:text-left lg:rtl:text-right  pb-xl lg:pb-0 px-0 lg:ltr:pr-lg lg:rtl:pl-lg m-0 lg:text-[2.22rem]">{pageData?.title}</h1>

          </div>
          <div className="-mx-md sm:mx-0">
            <div
              className={`${styles[`article-image`]
                } relative w-full overflow-hidden rounded-lg h-[12rem] -mb-[10.5rem] lg:h-[24rem] lg:-mb-[12rem] `}
            >
              <NextImage
                priority
                src={get(pageData, "image", undefined)}
                alt={get(pageData, "image.alternativeText") || get(pageData, "image.data.attributes.alternativeText")}
                width={get(pageData, "image.data.attributes.width", undefined)}
                height={get(pageData, "image.data.attributes.height", undefined)}
                quality={100}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center border-b border-lightGrey pt-0 lg:pt-lg pb-md">
            <div className="flex  items-center">
              {pageData?.global_analyst &&
                <>
                  <div className="w-[30px] h-[30px] rounded-full relative overflow-hidden bg-[#DEDEDE]">
                    <NextImage
                      priority
                      src={pageData?.global_analyst?.image_variant_a?.formats?.thumbnail?.url}
                      alt={pageData?.global_analyst?.name}
                      width={pageData?.global_analyst?.image_variant_a?.formats?.thumbnail?.width}
                      height={pageData?.global_analyst?.image_variant_a?.formats?.thumbnail?.height}
                    />
                  </div>
                  <span className=" ltr:pl-xs rtl:pr-xs text-darkGrey">{pageData?.global_analyst?.name}</span>
                </>
              }

            </div>
            <div className="flex  items-center mt-sm lg:mt-0">
              <IoCalendarOutline className="ltr:mr-xs rtl:ml-xs text-lg" />
              <span>{formatDate(pageData?.articles_category?.unique_key === "market_news_articles" ? pageData?.publishedAt : pageData?.updatedAt)}</span>
            </div>
          </div>
          <div className={`py-lg lg:py-xl ${styles[`article-text`]}`}>
            <div className="mb-xl text-[1.75rem]  leading-[1.2em] ltr:font-gilroy rtl:font-dubai">
              <div className={styles.shortDescription}>
                <HTMLRender data={pageData?.shortDescription} />
              </div>
            </div>
            <HTMLRender data={pageData?.content} />
          </div>
          {/* {pageData?.instruments?.length > 0 ? (

            pageData?.instruments?.map((instrument, index) => (
              <div key={index}>
                <ArticleSidebarInstrument
                  slug={instrument?.slug}
                  key={instrument?.display_name}
                  instrumentName={instrument?.display_name}
                  instrumentId={instrument?.api_id}
                  wide
                />
              </div>
            ))

          ) : null} */}
          <div className="pb-xl">
            <Accordion
              styles={{ icon: { color: '#000' } }}
              classNames={{
                control: styles.AccordionControl,
                root: styles.AccordionRoot,
                itemOpened: styles.itemOpened,
                label: styles.AccordionLabel,
                item: styles?.AccordionItem,
                contentInner: styles?.contentInner,
                icon: styles.borderAccordionIcon,
                content: styles?.AccordionContent,
              }}
              variant="unstyled"
              transitionDuration={500}
              icon={<HiOutlineChevronDown />}
              iconPosition="right"
            >
              {pageData?.faq_list?.map((question, index) => (
                <Accordion.Item key={index} label={<HTMLRender data={question?.title} />}>
                  <HTMLRender data={question?.content} />
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div className={`${styles[`article-disclaimer`]} text-justify italic text-grey pt-xl pb-2xl border-t border-lightGrey`}>
            <HTMLRender data={gv("disclaimers_articles")} />
          </div>

          <ArticleShareBox title={pageData?.title} />
          <ArticleRating onSubmit={handleRatingSubmit} />
          <ArticleExplore articleType={pageData?.articles_category?.unique_key} articleId={pageData?.id} />
          <ArticleAnalystBox data={pageData?.global_analyst} />



        </div>
      </div>
      <div className="pb-[4.8888rem]" />
    </>
  );
}
