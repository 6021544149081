import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';
import Link from '@components/elements/Link';
import SectionTitle from '@components/elements/SectionTitle';
import NextLink from 'next/link';
import styles from './HomepageDiscover.module.css';

const HomepageDiscover = (props) => {
  const data = props.data || props;
  // return null;
  let hasData = true;
  // for (const [key, value] of Object.entries(data)) {
  //   if (value === null && value.data === null) hasData = false;
  // }

  if (hasData) {
    return (
      <>
        <div className='container pt-[3.2rem]'>
          <div className='hidden md:block'>
            <SectionTitle data={data?.section_title} />
          </div>
          <div className='flex flex-col lg:flex-row items-center w-full'>
            <div className={styles[`column-left`]}>
              <div className={`${styles.richEditor}`}>
                <HTMLRender data={data?.content} />
              </div>
              <div className={`relative ${styles[`blue-box`]}`}>
                <NextLink href={data?.link?.link}>
                  <a className='absolute top-0 left-0 w-full h-full z-10 cursor-pointer'></a>
                </NextLink>
                <div className='hidden md:block absolute left-[1rem] bottom-0'>
                  <div className='relative min-w-[100px] min-h-[94px]'>
                    <NextImage src={data?.blue_box_image_desktop} />
                  </div>
                </div>
                <div className='md:hidden absolute left-0 bottom-0'>
                  <div className='relative min-w-[144px] min-h-[81px]'>
                    <NextImage src={data?.blue_box_image_mobile} />
                  </div>
                </div>
                <div className='pl-[8.66rem] md:pl-[6.86rem]'>
                  <h4 className='text-xl text-white font-light mb-0'>{data?.blue_box_text_upper}</h4>
                  <p className='font-light text-sm md:text-base  text-white'>{data?.blue_box_text_lower}</p>
                </div>
              </div>
              <div className="py-md">
                <Link data={data?.link} />
              </div>
            </div>
            <div className={styles[`column-right`]}>
              <div className={`relative min-h-[238px] my-0 mx-auto max-w-[60%] lg:max-w-full lg:min-h-[347px] w-full ${styles[`image-wrapper`]}`}>
                <NextImage objectFit src={data?.main_image} />
              </div>
            </div>
          </div>
        </div>
        </>
    )
  } return null;
};

export default HomepageDiscover;
