import HTMLRender from '@components/core/HTMLRender';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import './ExpirationDContent.module.css';
import get from 'lodash/get';
import styles from './ExpirationDContent.module.css';
import useSwrHook from '@lib/services/hooks/swr/useSwrHook';

const ExpirationDContent = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable()
  const { data: dataSwr = [] } = useSwrHook('getExpirationDates',{
    query: {}
  });
  // const expirationDates = get(data, 'expiration_list.data.0.json', []);
  const expirationDates = get(dataSwr, 'data.0.attributes.json', []);
  const dateOptions = { day: 'numeric', month: data?.with_all_columns ? 'short' : 'long' };

  const isDateInCurrentWeek = (dateString) => {
    // Convert the date string to a Date object
    const date = new Date(dateString);

    // Get the current date and time
    const now = new Date();

    // Get the start and end of the current week
    const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1);
    const endOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 7);
    // Check if the date falls within the current week
    return date >= startOfWeek && date <= endOfWeek;
  }

  const currentWeekDates = expirationDates.filter(date => isDateInCurrentWeek(date.rollover_date));
  const otherDates = expirationDates.filter(date => !isDateInCurrentWeek(date.rollover_date));


  if (!data) return null;



  return (
    <div className="container">
      <HTMLRender data={data?.content} />
      {expirationDates?.length > 0 && (
        <div className={!data?.with_all_columns ? `lg:max-w-[40rem] w-full mx-auto` : `text-xs lg:text-base`}>
          <div className="mt-xl flex flex-col md:mt-2xl">
            {currentWeekDates.length > 0 &&
              <div className={`${styles.table} pb-xl`}>
                <div className="w-full bg-primary py-sm rounded-lg text-center capitalize mb-md">
                  {data?.month && <p className="text-center text-white flex justify-center px-1"><span className="text-h4 font-bold block py-sm">{data?.month}</span></p>}
                  <div className="flex w-full justify-between bg-primary">
                    <p className="mb-0 w-full text-center text-white flex justify-center px-1"><span className={styles.tableHeadtext}>{gv("instrument")}</span></p>
                    <p className="mb-0 w-full text-center text-white flex justify-center px-1"><span className={styles.tableHeadtext}>{gv("current_week")}</span></p>
                    <p className="mb-0 w-full text-center text-white flex justify-center px-1"><span className={styles.tableHeadtext}>{gv("current_period")}</span></p>
                    {data?.with_all_columns &&
                      <>
                        <p className="mb-0 w-full text-center text-white flex justify-center px-1"><span className={styles.tableHeadtext}>{gv("automatic_rollover_differences")}</span></p>
                        <p className="mb-0 w-full text-center text-white flex justify-center px-1"><span className={styles.tableHeadtext}>{gv("currency")}</span></p>
                      </>
                    }
                  </div>
                </div>
                {currentWeekDates?.map((list, index) => (
                  <div key={index} className="items-center flex w-full justify-between even:bg-[#F8F8F8] ltr:font-gilroy rtl:font-dubai py-xs">
                    <p className="mb-0 w-full text-center text-dark">{list?.instrument}</p>
                    {/* iso date to human date */}
                    <p className="mb-0 w-full text-center text-dark" dir='ltr'>{new Date(list?.rollover_date).toLocaleDateString('en-GB', dateOptions)}</p>
                    <p className="mb-0 w-full text-center text-dark" dir='ltr'>{list?.current_period}</p>
                    {data?.with_all_columns &&
                      <>
                        <p className="mb-0 w-full text-center text-dark">{list?.automatic_rollover_differences}</p>
                        <p className="mb-0 w-full text-center text-dark">{list?.currency}</p>
                      </>
                    }
                  </div>
                ))}
              </div>
            }
            {otherDates.length > 0 &&
              <div className={`${styles.table} `}>
                <div className="w-full bg-primary py-sm rounded-lg text-center capitalize mb-md">
                  {data?.month && <p className="text-center text-white flex justify-center px-1"><span className="text-h4 font-bold block py-sm">{data?.month}</span></p>}
                  <div className="flex w-full justify-between bg-primary">
                    <p className="mb-0 w-full text-center text-white flex justify-center px-1"><span className={styles.tableHeadtext}>{gv("instrument")}</span></p>
                    <p className="mb-0 w-full text-center text-white flex justify-center px-1"><span className={styles.tableHeadtext}>{gv("rollover_date")}</span></p>
                    <p className="mb-0 w-full text-center text-white flex justify-center px-1"><span className={styles.tableHeadtext}>{gv("current_period")}</span></p>
                    {data?.with_all_columns &&
                      <>
                        <p className="mb-0 w-full text-center text-white flex justify-center px-1"><span className={styles.tableHeadtext}>{gv("automatic_rollover_differences")}</span></p>
                        <p className="mb-0 w-full text-center text-white flex justify-center px-1"><span className={styles.tableHeadtext}>{gv("currency")}</span></p>
                      </>
                    }
                  </div>
                </div>
                {otherDates?.map((list, index) => (
                  <div key={index} className="items-center flex w-full justify-between even:bg-[#F8F8F8] ltr:font-gilroy rtl:font-dubai py-xs">
                    <p className="mb-0 w-full text-center text-dark">{list?.instrument}</p>
                    {/* iso date to human date */}
                    <p className="mb-0 w-full text-center text-dark" dir='ltr'>{new Date(list?.rollover_date).toLocaleDateString('en-GB', dateOptions)}</p>
                    <p className="mb-0 w-full text-center text-dark" dir='ltr'>{list?.current_period}</p>
                    {data?.with_all_columns &&
                      <>
                        <p className="mb-0 w-full text-center text-dark">{list?.automatic_rollover_differences}</p>
                        <p className="mb-0 w-full text-center text-dark">{list?.currency}</p>
                      </>
                    }
                  </div>
                ))}
              </div>
            }


          </div>
        </div>
      )}
    </div>
  );
};

export default ExpirationDContent;
