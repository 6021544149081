import HTMLRender from "@components/core/HTMLRender";
import SectionTitle from "@components/elements/SectionTitle";
import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import { Tabs } from "@mantine/core";
import getAlphabet from "@utils/get-alphabet";
import { useState, useRef, useEffect, Children } from "react";
import styles from "./FinancialDTabs.module.css";
import { shallow } from "zustand/shallow";
import { useStore } from "@lib/store";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import Arrow from "@components/elements/Arrow";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import Loaders from "@components/elements/Loaders";
import Link from "next/link";
import useLocale from "@lib/store/hooks/useLocale";
import { useHash } from "@mantine/hooks";

const FinancialDTabs = (props) => {
  const data = props.data || props;
  const { riskWarningHeight } = useStore((state) => state, shallow);
  const [hash] = useHash();

  const [activeTab, setActiveTab] = useState({
    value: 0,
    action: "manual",
  });
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const { gv } = useGlobalVariable();
  const locale = useLocale();

  const alphabet = getAlphabet(locale);

  const store = useStore(
    (store) => ({
      riskWarningHeight: store.riskWarningHeight,
    }),
    shallow
  );

  useEffect(() => {
    if (activeTab.action === "manual") {
      window.location.hash = `#${alphabet[activeTab.value]?.toUpperCase()}`;
    }
  }, [activeTab]);

  useEffect(() => {
    try {
      const Alphabet = window.location.hash.replace("#", "");
      const index = alphabet.findIndex((item) => item.toUpperCase() === Alphabet.toUpperCase());

      if (activeTab.value !== index && index > -1) {
        setActiveTab({
          value: index,
          action: "auto",
        });
      }
    } catch (err) {}
  }, [hash]);

  if (!data) return null;

  const {
    data: financialDictionary,
    isValidating,
    error,
  } = useSwrHook("financialDictionary", {
    query: {
      fields: ["title", "content", "most_used", "essentials"],
      filters: {
        title: {
          $startsWith: alphabet[activeTab.value],
        },
      },
      sort: ["publishedAt:desc"],
    },
  });

  const {
    data: allFinancialDictionary,
    isValidating: loading,
    error: err,
  } = useSwrHook("financialDictionary", {
    query: {
      fields: ["title", "content", "most_used", "essentials"],
      sort: ["publishedAt:desc"],
      pagination: {
        pageSize: 500,
      },
    },
  });

  const essentials = allFinancialDictionary?.data?.filter((item) => item.attributes?.essentials === true);
  const most_used = allFinancialDictionary?.data?.filter((item) => item.attributes?.most_used === true);

  return (
    <>
      <div
        style={{ background: "LINEAR-GRADIENT(TO TOP,#F5F5F5,#F2F2F2,#F0F0F0,#EDEDED,#EBEBEB)" }}
        className="py-[2.888rem] lg:py-[4.888rem]"
      />
      <div id="tableHeader" className="container">
        {data?.section_title && <SectionTitle data={data?.section_title} />}
      </div>
      {/* MOBILE SLIDE FOR TABS HEADER */}
      <div className="financialDictionarySlide  flex items-center justify-between bg-white shadow-lg xl:hidden">
        <div
          ref={navigationPrevRef}
          className="flex h-full cursor-pointer items-center justify-center border-r border-r-[#f6f6f6] p-md text-[1.5rem] text-secondary transition-all duration-300"
        >
          <Arrow direction="left" />
        </div>
        <Swiper
          dir={store ? store.dir : "ltr"}
          modules={[Navigation]}
          navigation={{
            prevEl: navigationPrevRef.current ? navigationPrevRef.current : undefined,
            nextEl: navigationNextRef.current ? navigationNextRef.current : undefined,
          }}
          onSwiper={(swiper) => {
            // Delay execution for the refs to be defined
            setTimeout(() => {
              // Override prevEl & nextEl now that refs are defined
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
              // Re-init navigation
              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
          spaceBetween={0}
          slidesPerView={12}
          breakpoints={{
            320: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 12,
            },
          }}
          loop={false}
        >
          {alphabet.map((tab, index) => (
            <SwiperSlide
              key={index}
              onClick={() => {
                setActiveTab({
                  value: index,
                  action: "manual",
                });
              }}
            >
              <div>
                <p className={`uppercase ${activeTab.value === index ? "text-secondary" : "text-dark"} `}>{tab}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          ref={navigationNextRef}
          className="flex h-full cursor-pointer items-center justify-center border-l border-r-[#f6f6f6] p-md text-[1.5rem] text-secondary transition-all duration-300"
        >
          <Arrow direction="right" />
        </div>
      </div>
      <div className="mx-auto mt-md lg:mt-0 lg:w-[80%] xl:w-full">
        <Tabs
          active={activeTab.value}
          onTabChange={(value) =>
            setActiveTab({
              value,
              action: "manual",
            })
          }
          position="center"
          grow
          variant="unstyled"
          classNames={{
            tabsListWrapper: styles.wrapper,
            tabControl: styles.tabControl,
            tabActive: styles.tabActive,
            tabLabel: styles.tabLabel,
            tabsList: styles.tabsList,
          }}
        >
          {alphabet.map((tab, index) => (
            <Tabs.Tab key={index} label={tab}>
              <div className="container">
                <div className="mb-lg border-b border-b-[#dfdfe7] py-lg">
                  <p className="text-3xl font-[500] uppercase text-black">{alphabet[activeTab.value]}</p>
                </div>
                <div className="flex flex-col">
                  <div className="mb-[2.222rem] flex w-full flex-wrap items-start justify-between">
                    {!isValidating ? (
                      financialDictionary?.data?.map((item, index) => (
                        <div
                          key={index}
                          className="mb-[2.222rem] flex w-full flex-col transition-all duration-300 ease-in-out md:max-w-[calc(33.333%-1rem)]"
                        >
                          <h5 className="text-lg font-bold">{item?.attributes?.title}</h5>
                          <div>
                            <HTMLRender data={item?.attributes?.content} />
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex h-full min-h-[30rem] w-full items-center justify-center transition-all duration-300 ease-in-out">
                        <Loaders color="secondary" />
                      </div>
                    )}
                  </div>
                  <div></div>
                </div>
              </div>
            </Tabs.Tab>
          ))}
        </Tabs>
      </div>
      <div className="container flex flex-col justify-between md:flex-row">
        {essentials?.length > 0 && (
          <div className="flex w-full flex-col md:max-w-[calc(66.667%-1rem)]">
            <div className="mb-lg w-fit rounded bg-primary py-xs px-sm">
              <p className="mb-0 text-base uppercase text-white ">{gv("essentials")}</p>
            </div>
            <div className="flex flex-wrap justify-between">
              {Children.toArray(
                essentials?.map((item, index) => (
                  <Link href="#tableHeader">
                    <div
                      key={index}
                      className="mb-[2.222rem] flex w-full cursor-pointer flex-col md:max-w-[calc(50%-1rem)]"
                      onClick={() => {
                        setActiveTab({
                          value: alphabet.findIndex(
                            (el) => el === item?.attributes?.title.substring(0, 1).toLowerCase()
                          ),
                          action: "manual",
                        });
                      }}
                    >
                      <h5 className="text-lg font-bold">{item?.attributes?.title}</h5>
                      <div>
                        <HTMLRender data={item?.attributes?.content} />
                      </div>
                    </div>
                  </Link>
                ))
              )}
            </div>
          </div>
        )}
        {most_used?.length > 0 && (
          <div className="w-full rounded bg-[#f6f6f6] py-lg px-lg md:max-w-[calc(33.333%-1rem)]">
            <div className="flex w-full flex-col">
              <div className="mb-lg w-fit rounded bg-black py-xs px-sm">
                <p className="mb-0 text-base uppercase text-white ">{gv("most_used")}</p>
              </div>
              <div className="flex flex-col flex-wrap justify-between">
                {most_used?.map(
                  (item, index) =>
                    item?.attributes?.most_used && (
                      <div key={index} className="mb-md flex w-full flex-col">
                        <Link href="#tableHeader">
                          <a className="flex items-center text-secondary">
                            <Arrow direction="right" />
                            <p
                              className="w-fit cursor-pointer px-lg text-lg text-secondary"
                              onClick={() => {
                                setActiveTab({
                                  value: alphabet.findIndex(
                                    (el) => el === item?.attributes?.title.substring(0, 1).toLowerCase()
                                  ),
                                  action: "manual",
                                });
                              }}
                            >
                              {item?.attributes?.title}
                            </p>
                          </a>
                        </Link>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FinancialDTabs;
