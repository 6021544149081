import { useLocalStorage } from '@mantine/hooks';
import './NavigationScroll.module.css'

const NavigationScroll = (props) => {
  const data = props.data || props;
  const [about_us_tabs_clicked_index, setClickedIndex] = useLocalStorage({ key: 'about_us_tabs_clicked_index', defaultValue: 'null' });
  
  return (
      <div className="container">
        <ul className='flex justify-center'>
          {data?.nav_scroll_items.map((item, index) => (
            <li key={index} className="my-0 mx-[3px] lg:mx-sm" onClick={() => { setClickedIndex(index) }}>
              <a className='text-white text-xs md:text-base' href={`#${item?.section_scroll_id}`}>{item?.title}</a>
            </li>
          ))}
        </ul>
    </div>
  );
};

export default NavigationScroll;
