import DesktopAcademyOffers from './components/DesktopAcademyOffers';
import MobileAcademyOffers from './components/MobileAcademyOffers';

const AcademyOffers = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <>
      {/* desktop version */}
      <div className='container px-0 hidden lg:block'>
        <DesktopAcademyOffers data={data} />
      </div>
      {/* mobile version */}
      <div className='lg:hidden'>
        <MobileAcademyOffers data={data} />
      </div>
    </>
  );
};

export default AcademyOffers;
