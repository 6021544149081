import styles from './MobileHomeSlider.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Thumbs } from "swiper";
import "swiper/css";
import NextImage from '@components/elements/Image';
import { isMobileOnly } from 'react-device-detect';
import { AiOutlinePlusCircle } from "@react-icons/all-files/ai/AiOutlinePlusCircle";
import { IoIosCloseCircleOutline } from "@react-icons/all-files/io/IoIosCloseCircleOutline"
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import HTMLRender from '@components/core/HTMLRender';
import styleMapping from './StyleMapping';

const MobileHomeSlider = ({slides}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [mainSwiper, setMainSwiper] = useState({});
  const [thumbSwiper, setThumbSwiper] = useState({});
  const [pauseSwiper, setpauseSwiper] = useState(false);
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal(index) {
    setIsOpen(true)
    setActiveIndex(index)
  }

  if(isMobileOnly){
    return (
      <div className="relative md:hidden px-md overflow-hidden">
          <Swiper
              className={`${styles[`mobile-thumbanils`]}`}
              allowTouchMove={true}
              loop={true}
              autoplay={{
                delay: 6000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
              }}
              modules={[Thumbs, Autoplay]}
              preloadImages={false}
              slidesPerView={1.2}
              spaceBetween={18}
              onSlideChange={swiper => setActiveIndex(swiper.realIndex)}
              onSwiper={setThumbSwiper}
              onInit={(ev) => {
                setThumbSwiper(ev)
                swiper => setActiveIndex(swiper.realIndex)
              }}
            >
            {slides.map((slide, index) => (
              
                <SwiperSlide key={index}>
                    <div className={`relative ${styles[`thumbnail-slide`]}`} onClick={() => {openModal(index), setActiveIndex(thumbSwiper.realIndex)}}>
                      <div className={`
                      rounded-md overflow-hidden   h-[14em]  relative flex
                      ${styleMapping(slide.attributes.header_layout.color)}
                      ${styleMapping(slide.attributes.thumb_horizontal_align)}
                      ${styleMapping(slide.attributes.thumb_vertical_align)}
                      ${styleMapping(slide.attributes.thumb_text_align)}
                      `}> 
                          <div >
                          <NextImage src={slide?.attributes?.mobile_thumbnail}  alt={slide.attributes.mobile_thumbnail.data.attributes.alternativeText} />
                          </div>
                          <div className="text-container z-[1] relative py-sm px-xs">
                            <HTMLRender data={slide.attributes.thumb_text}/>
                          </div>
                      </div>
                      
                      <div className={`absolute bottom-[1rem] right-[1rem] text-3xl ${slide.attributes.header_layout.color == "dark" ? "text-dark" : "text-white"}`}>
                        <AiOutlinePlusCircle />
                      </div>
                    </div>
                </SwiperSlide>
              ))}
        </Swiper>


        <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="h-full text-center">
            
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full h-full max-w-md  overflow-hidden text-left align-middle transition-all transform bg-dark shadow-xl">

              <div  className={`${styles[`mobile-pagination`]} flex absolute top-[1rem] left-0 z-10 w-full px-1`} >
                {slides.map((slide, index) => {
                  return(
                    <div key={index} className="p-1 grow flex items-center relative">
                      <div  className={`${styles[`pagination-line`]} h-[2px] bg-white w-full relative`}>
                      <div className={`${styles[`progress-red`]}   absolute left-0 top-0 h-full bg-primary ${activeIndex == index ? "animate-grow-width" : "animate-none"}`} style={{animationDuration:`${slide.attributes.duration || 6000}ms`, animationPlayState: `${pauseSwiper ? "paused" : ""}`}}></div>
                      </div> 
                    </div>
                  )
                })}
              </div>
               
              <Swiper
                className={`${styles[`mobile-main-slider`]} h-full w-full`}
                preloadImages={false}
                spaceBetween={0}
                slidesPerView={1}
                initialSlide={thumbSwiper.realIndex}
                modules={[Autoplay]}
                onSlideChange={swiper => setActiveIndex(swiper.realIndex)}
                autoplay={{
                  delay: 6000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: false,
                  stopOnLastSlide: true
                }}
                onSwiper={setMainSwiper}
                onInit={(ev) => {
                  setMainSwiper(ev)
                  swiper => setActiveIndex(thumbSwiper.realIndex)
                }}
                onReachEnd={closeModal}
                onTouchStart={()=> {mainSwiper.autoplay.stop(), setpauseSwiper(true)}}
                onTouchEnd={()=> {mainSwiper.autoplay.start(), setpauseSwiper(false)}}
              >
              
                {slides.map((slide, index) => {
                  return(
                    <SwiperSlide key={index}  data-swiper-autoplay={slide.attributes.duration || 6000} className="h-full w-full flex flex-col relative overflow-hidden">
                      <div className={`
                      h-full w-full flex   relative overflow-hidden bg-dark ${styles[`mobile-slide`]}
                      ${styleMapping(slide.attributes.header_layout.color)}
                      ${styleMapping(slide.attributes.mobile_horizontal_align)}
                      ${styleMapping(slide.attributes.mobile_vertical_align)}
                      ${styleMapping(slide.attributes.mobile_text_align)}
                      
                      `}>
                        <div className="absolute top-0 left-0 h-full w-full">
                          <NextImage src={slide?.attributes?.mobile_background}  alt={slide.attributes.mobile_background.data.attributes.alternativeText} />
                        </div>
                        <div className="py-12 px-[1em] overflow-hidden  text-white  fixed top-0 left-0 w-full h-full">
                          <div className={`${styles[`custom-prev`]} absolute top-0 left-0   w-1/2 h-full`} onClick={() => mainSwiper.slidePrev()}></div>
                          <div className={`${styles[`custom-prev`]} absolute top-0 right-0   w-1/2 h-full`} onClick={() => mainSwiper.slideNext()}></div>
                            <HTMLRender data={slide.attributes.text}/>
                        </div>
                      </div>
                    </SwiperSlide>  
                    
                  )
                })}
              <SwiperSlide />{/*  Add empty slide to close Modal onReachEnd */}
              </Swiper>
              <IoIosCloseCircleOutline
                className="absolute bottom-10 left-1/2  transform -translate-x-2/4 h-10 w-10 text-white z-10"
                onClick={closeModal}
              />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      
      </div>
    )
  } return null
 
};

export default MobileHomeSlider;
