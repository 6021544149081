import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import InstrumentSlide from "@src/components/custom/InstrumentSlide";
import instrumentsApi from "@utils/instrumentsApi";
import { gainersAndLosers } from "@utils/instrumentsApi";
import styles from "../../TradeHomeInstruments.module.css";

const HomeGainersLoosers = (props) => {
  const { gainers, losers } = gainersAndLosers();
  const { data: verifyInstruments } = useSwrHook('getInstrumentData', {
    query: {
      filters: {
        api_id: {
          $in: [...gainers.map(i => i.id), ...losers.map(i => i.id)]
        }
      },
      pagination: {
        page: 1,
        pageSize: 200
      }
    }
  })


  return (
    <div className={`${styles.HomeGainersLoosers} relative lg:-mx-[4rem]`}>
      <InstrumentSlide instruments={gainers.filter(el => verifyInstruments?.data?.some(f => f.attributes.api_id === el.id))} type="gainers" />
      <InstrumentSlide instruments={losers.filter(el => verifyInstruments?.data?.some(f => f.attributes.api_id === el.id))} type="losers" />
    </div>
  );
};

export default HomeGainersLoosers;
