import HTMLRender from '@components/core/HTMLRender';
import AcademyCardDesktop from '@components/custom/AcademyCardDesktop';
import AcademyCardMobile from '@components/custom/AcademyCardMobile';
import GenericSlider from '@components/custom/GenericSlider';
import NextImage from '@components/elements/Image';
import Link from '@components/elements/Link';
import { SwiperSlide } from 'swiper/react';
import styles from './HomepageAcademy.module.css';

const HomepageAcademy = (props) => {
  const data = props.data || props;
  // Chunk Academy categories per 4 items
  const chunkSize = 4;
  let inputArr = data.academy_categories?.data;
  let chunkedAcademyCategoriesArr = inputArr?.reduce((chunkedArr, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize)

    if (!chunkedArr[chunkIndex]) {
      chunkedArr[chunkIndex] = [];
    }
    chunkedArr[chunkIndex].push(item)
    return chunkedArr
  }, [])

  let hasData = true;
  for (const [key, value] of Object.entries(data)) {
    if (value === null || value.data === null) {
      hasData = false
    }
  }
  if (hasData) {
    
    return (
      <>
        {/* Desktop Version */}
        <div className='hidden md:block w-full bg-whiteCream'>
          <div className='container py-[2.222rem]'>
            <div className='w-full flex justify-between items-center'>
              <div className='w-[45%] lg:w-[40%]'>
                <h2 className='mb-0'>{data.title}</h2>
                <h3 className='mb-0'><HTMLRender data={data.subtitle} /></h3>
                <p className='py-[2.222rem]'>{data.content}</p>
                <div>
                  <Link data={data.link} />
                </div>
              </div>
              <div className="hidden md:block v-swiper-academy w-1/2">
                <GenericSlider vertical pagination autoplay autoplayDelay={7000}>
                  <div className='h-full'>
                    {chunkedAcademyCategoriesArr?.map((cards, index) => (
                      <SwiperSlide key={index}>
                        {cards.map((card, index) => (
                          <div key={index} className='pb-xs'>
                            <AcademyCardDesktop data={card} available={data.available_text} />
                          </div>
                        ))}
                      </SwiperSlide>
                    ))}
                  </div>
                </GenericSlider>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile Version */}
        <div className='md:hidden pb-lg'>
          <div className='container pr-0'>
            <div>
              <GenericSlider autoplay autoplayDelay={5000} loop slidesPerView={2.2}>
                <SwiperSlide>
                  <div className="mr-xs">
                    <div className="pt-xl px-xs pb-lg relative h-[20rem] bg-secondary max-h-[20rem] w-full rounded-md lg:hidden overflow-hidden">
                      {data.mobile_first_slide && <NextImage src={data.mobile_first_slide} />}
                      {data.link.link && (
                        <Link href={`${data.link.link}`}>
                          <a className="absolute top-0 left-0 z-10 h-full w-full bg-transparent"></a>
                        </Link>
                      )}
                      <div className="z-1 relative flex h-full w-full flex-col items-center justify-between text-center text-white">
                        {data.title && <div className="uppercase h-[25%]">{data.title}</div>}
                        <div className={`${styles.white} flex flex-col items-center`}>
                          <HTMLRender data={data.subtitle} />
                        </div>
                        <div className="flex items-center">
                          <span><p className='text-white text-sm'>View Trading Lessons</p></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                {data.academy_categories?.data?.map((card, index) => (
                  <SwiperSlide key={index}>
                    <AcademyCardMobile data={card} available={data.available_text} />
                  </SwiperSlide>
                ))}
              </GenericSlider>
            </div>
          </div>
          <div className='container'>
            <div className='flex flex-col justify-between'>
              <p className='text-center'>{data.content}</p>
              <div className="flex justify-center">
                <Link data={data.link} />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  } return null;

};

export default HomepageAcademy;
