import AcademyLessonCardDesktop from "@components/custom/AcademyLessonCardDesktop";
import AcademyLessonCardMobile from "@components/custom/AcademyLessonCardMobile";
import Button from "@components/elements/Button";
import NextImage from "@components/elements/Image";
import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import chunk from "lodash/chunk";
import styles from "./FeaturedAcademy.module.css";
import get from "lodash/get";
import { useRegistrationLink } from "@lib/store/hooks/useActiveProductType";

function FeaturedAcademy(props) {
  const data = props.data || props;
  const { gv } = useGlobalVariable();
  const registrationLink = useRegistrationLink();
  const academyCategories = get(data, "academy_categories.data", []);
  if (!data) return null;

  const chunkedAcademyCategories = chunk(academyCategories, 3);

  return (
    <div className="container">
      {/* mobile version */}
      <div className="relative mb-xl block w-full overflow-hidden rounded-md border border-[#ebebeb] md:hidden">
        <div className="p-md">
          {academyCategories?.map((academyCategory, index) => (
            <AcademyLessonCardMobile data={academyCategory} index={index} key={index}/>
          ))}
        </div>
      </div>
      {/* desktop version */}
      {/* get first three categories */}
      <div className="hidden flex-wrap justify-between md:flex">
        {chunkedAcademyCategories &&
          chunkedAcademyCategories[0]?.map((category, index) => (
            <div key={index} className="mb-xl max-w-[calc(33.33%-0.5em)]">
              <AcademyLessonCardDesktop data={category} />
            </div>
          ))}
      </div>
      {/* blue box */}
      <div className="pb-[3.8rem]">
        <div className="flex flex-col items-center justify-between rounded-lg bg-secondary py-lg px-lg text-center text-white md:flex-row ltr:md:text-left rtl:md:text-right">
          <h4 className="md:mb-0">{data?.blue_box_text}</h4>
          <div>
            <Button type="white-borderless" size="lg" label={gv("create_account", "raw")} link={registrationLink} />
          </div>
        </div>
      </div>
      {/* display the remaining categs */}
      <div className="hidden flex-wrap justify-between md:flex">
        {academyCategories &&
          academyCategories?.slice(3, academyCategories?.data?.length)?.map((category, index) => (
            <div
              key={index}
              className={`mb-xl max-w-[calc(33.33%-0.5em)] ${academyCategories?.length === 7 && styles.wideCard}`}
            >
              <AcademyLessonCardDesktop data={category} />
            </div>
          ))}
      </div>
      {/* essentials section */}
      <div className="flex flex-col items-center justify-between py-lg first:pt-0 last:pb-0 md:flex-row md:pt-0">
        <div className="order-3 w-full text-center md:order-1 md:w-[calc(50%-2rem)] ltr:md:text-left rtl:md:text-right">
          <h2>{data?.essentials_title}</h2>
          <div className="pt-xs">
            <p>{data?.essentials_content}</p>
          </div>
          <div className="mt-lg">
            <Button type="white" arrow="right" size="lg" label={data?.essentials_button_text} link={registrationLink} />
          </div>
        </div>
        <div className="order-2 w-full pb-xl md:w-[calc(50%-2rem)] md:pb-0">
          <NextImage
            src={data?.essentials_image}
            height={data?.essentials_image?.data?.attributes?.height}
            width={data?.essentials_image?.data?.attributes?.width}
          />
        </div>
      </div>
    </div>
  );
}

export default FeaturedAcademy;
