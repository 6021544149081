import styles from './PlatformsHero.module.css';
import HTMLRender from '@components/core/HTMLRender';
import Divider from '@components/elements/Divider';
import { CgMouse } from '@react-icons/all-files/cg/CgMouse';

const PlatformsHero = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <>
      <div className='container'>
        <div className=" flex flex-col justify-center">
          {data?.hero_text &&
            <div className={`${styles[`hero-text`]} text-center pb-md`}>
              <HTMLRender data={data?.hero_text} />
            </div>
          }

          <div className="bg-lightBorderGrey py-xl mt-xl px-md md:px-lg text-center text-grey -mx-md md:mt-xl">
            {data?.grey_box_text &&
              <HTMLRender data={data?.grey_box_text} />
            }

            {data?.grey_box_platform_1 &&
              <div className="m-0 py-lg">
                <HTMLRender data={data?.grey_box_platform_1} />
              </div>
            }

            {data?.divider &&
              <Divider type={data?.divider?.type} text={data?.divider?.text} size={data?.divider?.size} />
            }
            {data?.grey_box_platform_2 &&
              <div className="m-0 pt-lg">
                <HTMLRender data={data?.grey_box_platform_2} />
              </div>
            }
          </div>
        </div>
        <div className="text-center pt-md 2xl:pt-2xl hidden xl:flex justify-center">
          <a href='#webtrader'>
            <CgMouse className='text-grey text-3xl cursor-pointer text-center animate-bounce mt-xl' />
          </a>
        </div>
      </div>
    </>
  );
};

export default PlatformsHero;
