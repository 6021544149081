import Button from "@components/elements/Button";
import SectionTitle from "@components/elements/SectionTitle";
import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, EffectCreative, Pagination } from "swiper";
import "swiper/css/effect-creative";
import Image from "next/image";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";
import NextImage from "@src/components/elements/Image";
import styles from "./TradeHomeAcademyV2.module.css";
import HTMLRender from "@src/components/core/HTMLRender";

const CardComponent = ({ card }) => {
  const { gv } = useGlobalVariable();
  return (
    <div
      className={`${styles.slideBox} mx-auto flex max-w-[80%] cursor-pointer flex-col items-center px-md py-lg md:max-w-[50%] lg:max-w-[60%] lg:px-md ltr:lg:mr-xl rtl:lg:ml-xl`}
    >
      <div className="relative h-[7.883rem] w-[10.777rem]">
        <NextImage src={card?.image} alt={card?.title} />
      </div>
      <div className="pt-md text-center">
        <p className="pb-0 font-bold">{card?.title}</p>
        <div className={`${styles.description}`}>
          <HTMLRender data={card?.content} />
        </div>
        <div className="mt-lg">
          <Button type="white-darkblue" size="sm" arrow="right" label={gv("learn_more")} link={card?.button_url} />
        </div>
      </div>
    </div>
  );
};

const TradeHomeAcademyV2 = (props) => {
  const data = props.data || props;
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const store = useStore(
    (store) => ({
      dir: store.direction,
    }),
    shallow
  );

  if (!data) return null;

  return (
    <div className="container">
      <div className="flex w-full flex-col justify-between lg:flex-row">
        <div className={`flex w-full flex-col lg:max-w-[calc(100%/2-1rem)] ${styles.title} pb-xl md:pb-0`}>
          <div className="rounded-3xl bg-[#F5F5F7] px-sm pt-xl pb-[14.37500rem] lg:bg-transparent lg:px-0 lg:pt-0 lg:pb-0">
            <SectionTitle data={data?.section_title} />
          </div>
          <div className="mt-lg hidden lg:block">
            <Button data={data?.button} />
          </div>
        </div>
        <div
          className={`relative  ${styles.shadowContainer} academySlider -mt-[15.37500rem] w-full md:-mt-[12rem] lg:mt-0 lg:max-w-[calc(100%/2-1rem)]`}
        >
          <Swiper
            dir={store ? store.dir : "ltr"}
            modules={[Navigation, EffectCreative, Pagination]}
            navigation={{
              prevEl: navigationPrevRef.current ? navigationPrevRef.current : undefined,
              nextEl: navigationNextRef.current ? navigationNextRef.current : undefined,
            }}
            effect="creative"
            creativeEffect={{
              prev: {
                shadow: false,
                translate: [0, 0, -400],
              },
              next: {
                translate: ["100%", 0, 0],
              },
            }}
            onSwiper={(swiper) => {
              // Delay execution for the refs to be defined
              setTimeout(() => {
                // Override prevEl & nextEl now that refs are defined
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
                // Re-init navigation
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              });
            }}
            pagination
            spaceBetween={5}
            slidesPerView={1}
            loop
          >
            {data?.cards?.map((card, index) => (
              <SwiperSlide key={index}>
                <CardComponent card={card} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {/* swiper nav arrows */}
        <div className="hidden flex-col items-center justify-center lg:flex">
          <div
            ref={navigationPrevRef}
            className={`${styles.animationArrowLeft} mt-[0.5%] cursor-pointer py-xs text-[1.5rem] text-[#777] transition-all duration-300 ltr:ml-0 rtl:mr-0`}
          >
            <NextImage src="https://cdn.capex.com/uploads/nav_icon_left_2102847053/nav_icon_left_2102847053.png" width={45} height={45} alt="nav-icon" />
          </div>
          <div
            ref={navigationNextRef}
            className={`${styles.animationArrowRight} mt-[0.5%] rotate-180 cursor-pointer py-xs text-[1.5rem] text-[#777] transition-all duration-300 ltr:mr-0 rtl:ml-0`}
          >
            <NextImage src="https://cdn.capex.com/uploads/nav_icon_right_f77b699709/nav_icon_right_f77b699709.png" width={45} height={45} alt="nav-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeHomeAcademyV2;
