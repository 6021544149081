import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';
import Link from '@components/elements/Link';
import styles from './InvestMarketsPortfolio.module.css';
import { Accordion } from '@mantine/core';
import Arrow from '@components/elements/Arrow';
import { useState, useRef, useEffect } from 'react';
import { useIntersection } from '@mantine/hooks';
import { getStrapiMedia } from '@utils/media';
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { useRegistrationLink } from '@lib/store/hooks/useActiveProductType';

//  Accordion label component
const AccLabel = ({ data }) => (
  <div>
    <div className='flex items-center justify-between w-full'>
      <div className="w-full flex items-center">
        <NextImage src={`https://cdn.capex.com/public/assets/flags/${data?.iso}.svg`} width={41} height={27} alt={data?.name} />
        <p className="w-full text-[#737373] ltr:ml-sm rtl:mr-sm">{data?.name}</p>
      </div>
      <p className="w-full text-[#737373] ltr:ml-sm rtl:mr-sm">{data?.url}</p>
    </div>
  </div>
)

const InvestMarketsPortfolio = (props) => {
  const { gv } = useGlobalVariable()
  const activeRegistrationLink = useRegistrationLink();
  //  Observe when the platform video enters the viewport and play the video
  const [isInViewport, setIsInViewport] = useState(false);
  const containerRef = useRef();
  const videoRef = useRef(null);
  const [ref, observedEntry] = useIntersection({
    root: containerRef.current,
    threshold: 0,
  });

  const inView = observedEntry && observedEntry.isIntersecting;
  const [flag, setFlag] = useState(true);

  useEffect(() => {
    if (inView && flag) {
      setIsInViewport(true);
      videoRef.current.play();
      setFlag(false)
    }
  }, [inView])
  //  End observe video Entry functions

  const data = props.data || props;
  if (!data) return null;
  return (
    <div className='overflow-hidden'>
      <div className='container'>
        <div className="flex flex-row justify-between mb-[4.444rem] relative z-10">
          <div ref={ref} className={`hidden relative lg:block w-full max-w-[calc(100%/2-5.555rem)] ${styles.video}`}>
            <video muted ref={videoRef} playsInline preload="none" width="485" height="423" style={{ maxWidth: '130%' }}>
              <source src={getStrapiMedia(data?.platform_video?.data?.attributes?.url)} type="video/mp4" />
              <source src={getStrapiMedia(data?.platform_video?.data?.attributes?.url)} type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className={`flex flex-col w-full lg:max-w-[calc(100%/2-1.455rem)] pt-2xl ${styles.content}`}>
            <HTMLRender data={data?.right_content_title} />
            <div className="block  py-xl lg:hidden">
              <NextImage src={data?.platform_image_mobile} width={data?.platform_image_mobile?.data?.attributes?.width} height={data?.platform_image_mobile?.data?.attributes?.height} />
            </div>
            <HTMLRender data={data?.right_content} />
            <div className="mt-xl">
              <Link color="blue" arrow="right" text={gv("give_it_a_try", 'raw')} link={activeRegistrationLink} />
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between">
          <div className={`flex flex-col w-full lg:max-w-[calc(100%/2-1.455rem)] pt-0 pb-lg lg:pb-[3.5em] lg:pt-xl ${styles.content} ${styles.contentLeftMobile}`}>
            <HTMLRender data={data?.left_content_title} />
            <HTMLRender data={data?.left_content} />
            <div className="mt-xl hidden lg:block">
              <Link color="blue" arrow="right" text={gv("open_account", 'raw')} link={activeRegistrationLink} />
            </div>
          </div>
          <div className="relative flex flex-col lg:flex-row items-center w-full lg:max-w-[calc(100%/2-1.455rem)]">
            <div className="absolute z-[-1] bottom-0 left-1/2 -translate-x-1/2 w-[180%] md:w-[100%] lg:w-[120%] text-center">
              <NextImage src={data?.licence_bg_image} width={data?.licence_bg_image?.data?.attributes?.width} height={data?.licence_bg_image?.data?.attributes?.height} />
            </div>
            <div className='w-full mb-xl lg:my-0 relative max-w-[85%] mx-auto'>
              <Accordion iconPosition="right" icon={<div className="text-secondary"><Arrow direction="down" /></div>}>
                {data?.licences.map((licence, index) => (
                  <Accordion.Item
                    key={index}
                    label={<AccLabel data={licence} />}
                    classNames={{
                      control: styles.accordionControl,
                      accordionLabel: styles.accordionLabel,
                      itemOpened: styles.itemOpened,
                      label: styles.accordionLabel,
                      item: styles.accordionItem
                    }}
                  >
                    <HTMLRender data={licence.description} />
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="mt-0 relative pb-2xl text-center block lg:hidden">
              <Link color="blue" arrow="right" text={gv("open_account", 'raw')} link={activeRegistrationLink} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestMarketsPortfolio;

