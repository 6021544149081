import HTMLRender from "@components/core/HTMLRender";
import NextImage from "@components/elements/Image";
import instrumentsApi from "@utils/instrumentsApi";
import { useState } from "react";
import Arrow from "@components/elements/Arrow";
import Button from "@components/elements/Button";
import Link from "next/link";

// swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/autoplay";
import styles from "./InvestHomeEtfs.module.css";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";

const InvestHomeEtfs = (props) => {
  const data = props.data || props;

  const store = useStore(
    (store) => ({
      dir: store.direction,
    }),
    shallow
  );

  const { gv } = useGlobalVariable();

  //  get instruments description
  const instrumentDescription = data?.instruments?.data?.map((item) => item.description);
  //  get instruments name
  const instrumentSymbol = data?.instruments?.data?.map((item) => item.symbol);
  //  get instruments slugs for the sliding cards
  const instrumentLink = data?.instruments?.data?.map((item) => item.slug);
  //  insert instrument apis into an array
  const instrumentsList = data?.instruments?.data?.length > 0 && data.instruments.data.map((item) => item.api_id);
  //  use the instruments array to make call to instrumentsApi
  const instrumentData = instrumentsApi(instrumentsList, 1000);

  const [isHovered, setIsHovered] = useState({ value: true, index: 0 });

  if (!data) return null;

  return (
    <div className={styles.mobileContainer}>
      <div className="container-large container relative overflow-hidden rounded-[1.222rem] bg-[#1B1616]">
        <div className="absolute right-1/2 left-auto hidden h-full w-full max-w-[75%] translate-x-1/2 lg:block">
          <NextImage
            src="https://cdn.capex.com/uploads/desktop_X_etfs_828985e210/desktop_X_etfs_828985e210.png"
            objectFit="cover"
            objectPosition="50% 10%"
            layout="fill"
            alt="nav-icon"
          />
        </div>
        <div className="absolute right-0 top-[10%] h-full w-full lg:hidden">
          <NextImage
            src="https://cdn.capex.com/uploads/mobile_X_etfs_e707509de5/mobile_X_etfs_e707509de5.png"
            objectFit="cover"
            layout="fill"
            objectPosition="50% 50%"
            alt="nav-icon"
          />
        </div>
        <div className="absolute top-0 -z-[1] h-full w-full ltr:left-0 rtl:right-0">
          <NextImage
            src={data?.bg_image?.data?.attributes?.url}
            width={data?.bg_image?.data?.attributes?.width}
            height={data?.bg_image?.data?.attributes?.height}
          />
        </div>
        <div className="relative flex flex-col py-xl lg:py-[3.5rem]">
          {/* title and subtitle */}
          <div className="pb-lg text-center lg:pb-xl">
            <h2 className="mb-0 pb-lg text-[1.77rem] font-bold leading-[1.2] text-white md:pb-0 md:text-h2">
              {data?.upper_title}
            </h2>
            <h2 className="mb-0 text-[1.777rem] leading-[1.2] text-white md:text-h1">{data?.lower_title}</h2>
            <div className={`${styles.subtitle} mx-auto items-center lg:max-w-[88%] lg:pt-lg`}>
              <HTMLRender data={data?.subtitle} />
            </div>
          </div>
          {/* content columns */}
          <div className="flex flex-col justify-between lg:flex-row">
            {/* benefits of etfs left col */}
            <div className="order-2 flex w-full flex-col ltr:border-r-white ltr:border-opacity-[15%] rtl:border-l-white rtl:border-opacity-[15%] lg:order-1 lg:max-w-[35%] ltr:lg:border-r-[2px] rtl:lg:border-l-[2px]">
              <div className="mx-auto flex max-w-[60%] justify-center pb-lg text-center text-white lg:pb-[4rem] ltr:lg:text-left rtl:lg:text-right">
                <h3 className="text-3xl font-bold rtl:leading-[1.35]">{data?.etfs_title}</h3>
              </div>
              <div className="-mx-md flex flex-row flex-wrap justify-between lg:mx-0 lg:block">
                {data?.etfs_benefits?.map((benefit, index) => (
                  <div
                    key={index}
                    onMouseEnter={() => setIsHovered({ value: true, index })}
                    onMouseLeave={() => setIsHovered({ value: false, index: null })}
                    className="relative flex w-full max-w-[calc(100%/2-0rem)] cursor-pointer flex-row justify-center border-grey pt-xs pb-xs ltr:odd:border-r rtl:odd:border-l lg:max-w-full lg:items-center lg:justify-start lg:pt-0 lg:pb-[1.3888rem] lg:odd:border-none"
                  >
                    <div
                      className={`-mx-md hidden h-[1px] bg-white transition-all duration-300 ease-in-out lg:block ${isHovered.value && isHovered.index === index
                        ? "w-[4.75rem] opacity-100 ltr:mr-[3rem] rtl:ml-[3rem]"
                        : "w-[3.75rem] opacity-50 ltr:mr-[3rem] rtl:ml-[3rem]"
                        }`}
                    />
                    <p
                      className={`mb-0 px-xs text-center font-light text-white transition-all duration-300 ease-in-out lg:max-w-[40%] lg:px-0 lg:leading-[1.667rem] ltr:lg:text-left rtl:lg:text-right ${isHovered.value && isHovered.index === index
                        ? "text-sm opacity-50 lg:scale-[1.25] lg:font-semibold lg:opacity-100"
                        : "text-sm font-light opacity-50 lg:scale-[1] lg:text-base"
                        }`}
                    >
                      {benefit?.title}
                    </p>
                    <div
                      className={`absolute top-1/2 hidden -translate-y-1/2 transition-all duration-300 ease-in-out ltr:right-[5%] rtl:left-[5%] lg:block ${isHovered.value && isHovered.index === index
                        ? "max-w-[25%] opacity-100"
                        : "max-w-[0%] opacity-0"
                        }`}
                    >
                      <NextImage
                        src={benefit?.icon?.data?.attributes?.url}
                        width={benefit?.icon?.data?.attributes?.width}
                        height={benefit?.icon?.data?.attributes?.height}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* instrument slider right col */}
            <div className="etfs-slider order-1 -mx-md min-h-[24rem] lg:order-2 lg:mx-auto lg:min-h-[30rem] lg:w-full lg:max-w-[65%]">
              <div className={`relative w-full lg:top-1/2 lg:-translate-y-1/2 ${styles.shadowContainer}`}>
                <Swiper
                  dir={store ? store.dir : "ltr"}
                  modules={[Autoplay]}
                  spaceBetween={0}
                  autoplay
                  loop
                  speed={800}
                  centeredSlides
                  slidesPerView={3}
                  breakpoints={{
                    320: {
                      slidesPerView: 2.1,
                    },
                    1025: {
                      slidesPerView: 3,
                    },
                  }}
                  coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 10,
                    modifier: 0,
                    slideShadows: false,
                  }}
                >
                  {instrumentsList &&
                    instrumentsList?.map((instrument, index) => {
                      const currentInstrumentObject = instrumentData?.currentData?.[instrument];
                      return (
                        <SwiperSlide key={index}>
                          {({ isActive }) => (
                            <div
                              className={`relative ${isActive
                                ? "scale-[1] border bg-[#241824] px-xs pt-lg shadow-lg lg:px-sm"
                                : "scale-[0.6] bg-transparent px-xs"
                                } flex flex-col items-center rounded-lg border-[#886792] border-opacity-[40%]  transition-all duration-700 ease-in-out`}
                            >
                              {/* add link on card to it's instrument, for mobile only, desktop cards have CTA */}
                              <div className="lg:hidden">
                                <Link href={instrumentLink[index]}>
                                  <a className="absolute top-0 z-30 h-full w-full ltr:left-0 rtl:right-0" />
                                </Link>
                              </div>
                              {/* top border when not active */}
                              <div
                                className={`${!isActive ? "max-h-[1rem] scale-[1]" : "max-h-0 scale-[0]"} ${styles.inactiveShadow
                                  } duration-900 mb-lg flex h-[1px] w-[3.333rem] justify-center bg-[#9542FF] transition-all ease-in-out`}
                              />
                              {/* top small box */}
                              <div
                                className={`${isActive ? "max-h-[5rem] scale-[1]" : "max-h-0 scale-[0]"
                                  } absolute -top-[15%] w-full max-w-fit overflow-hidden rounded-lg transition-all duration-700 ease-in-out lg:-top-[10%]`}
                              >
                                <div
                                  className={`flex w-full items-center justify-center bg-[#867693] py-xs px-md ${styles.descriptionShort}`}
                                >
                                  <HTMLRender data={instrumentDescription[index].split(" ")[0]} />
                                </div>
                                <div className="flex w-full items-center justify-center bg-[#D9D9D9] p-[3px]">
                                  <p className="text-xs font-bold text-black ltr:font-gilroy rtl:font-dubai">
                                    {instrumentSymbol[index]}
                                  </p>
                                </div>
                              </div>
                              {/* instrument name */}
                              <div
                                className={`${isActive ? styles.descriptionLongActive : styles.descriptionLongInactive
                                  } pb-lg lg:py-lg`}
                              >
                                <HTMLRender data={instrumentDescription[index]} />
                              </div>
                              {/* instrument price and change */}
                              <div
                                className={`${isActive ? "max-h-[5rem] scale-[1]" : "max-h-0 scale-[0]"
                                  } flex flex-col items-center transition-all duration-700 ease-in-out`}
                              >
                                <p
                                  className={`mb-0 text-2xl font-[600] opacity-100 ltr:font-gilroy rtl:font-dubai ${currentInstrumentObject?.change.replace(/[^\d.-]/g, "") >= 0
                                    ? `text-greenCandle`
                                    : `text-primary`
                                    }`}
                                >
                                  {currentInstrumentObject?.price}
                                </p>
                                <div
                                  className={`flex items-center text-xl ${currentInstrumentObject?.change.replace(/[^\d.-]/g, "") >= 0
                                    ? `text-greenCandle`
                                    : `text-primary`
                                    }`}
                                >
                                  <Arrow
                                    direction={
                                      currentInstrumentObject?.change.replace(/[^\d.-]/g, "") >= 0 ? "up" : "down"
                                    }
                                  />
                                  <p
                                    className={`px-sm text-lg ltr:font-gilroy rtl:font-dubai  ${currentInstrumentObject?.change.replace(/[^\d.-]/g, "") >= 0
                                      ? `text-greenCandle`
                                      : `text-primary`
                                      }`}
                                  >{`(${currentInstrumentObject?.change})`}</p>
                                </div>
                              </div>
                              {/* CTA */}
                              <div
                                className={`hidden lg:block ${styles.buttonText} ${isActive ? "mt-lg max-h-[5rem]  scale-[1]" : "mt-0 max-h-0 scale-[0]"
                                  } transition-all duration-700 ease-in-out`}
                              >
                                <Button
                                  type="transparent-purple"
                                  size="sm"
                                  arrow="right"
                                  label={gv("invest_now")}
                                  link={instrumentLink[index]}
                                />
                              </div>
                              {/* bottom border when not active */}
                              <div
                                className={`${!isActive ? "max-h-[1rem] scale-[1] opacity-100" : "max-h-0 scale-[0]"} ${styles.inactiveShadow
                                  } duration-900 mt-lg flex h-[1px] w-[3.333rem] justify-center bg-[#9542FF] transition-all ease-in-out`}
                              />
                            </div>
                          )}
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestHomeEtfs;
