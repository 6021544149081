import NextImage from '../Image';
import Link from '@components/elements/Link'
import { useState, useEffect } from 'react'
import instrumentsApi from '../../../../utils/instrumentsApi'
import styles from './FeaturedInstrument.module.css'
import Loaders from '@components/elements/Loaders';
import flattenDeep from 'lodash/flattenDeep';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import useSwrHook from '@lib/services/hooks/swr/useSwrHook';
import get from 'lodash/get';

const definePulseColor = (buy, sell) => {
  const checkColor = ({ oldValue, newValue }) => {
    if (parseFloat(oldValue) < parseFloat(newValue)) {
      return 'pump';
    } else if (parseFloat(oldValue) > parseFloat(newValue)) {
      return 'dump';
    }
    return '';
  }
  const pulseColors = {
    buy: checkColor(buy),
    sell: checkColor(sell)
  }
  return pulseColors;
}

const FeaturedInstrument = (props) => {
  const data = props?.data || props
  const { data: nestedPageUrl } = useSwrHook('getNestedPageUrl', {
    query: { filters: { collectionAPI: 'instrument' } },
  });
  const parentUrl = get(nestedPageUrl,'data.0.attributes.alternative_page_slug', '/instruments');
  const {gv} = useGlobalVariable()
  const query = flattenDeep(data?.map((instrument) => (instrument?.attributes?.api_id))) || '';
  const instrumentData = instrumentsApi(query,750)
  if (data) {
    return (
      <ul className='w-full flex items-center justify-between flex-col md:flex-row flex-wrap'>
        {data?.map((instrument, index) => {
          const currentInstrumentObject = instrumentData?.currentData?.[instrument?.attributes?.api_id]
          const previousInstrumentObject = instrumentData?.previousData?.[instrument?.attributes?.api_id]
          const [cleanColor, setCleanColor] = useState(true);
          const [pulseColors, setPulseColors] = useState(definePulseColor({
            newValue: currentInstrumentObject?.buy,
            oldValue: previousInstrumentObject?.buy
          }, {
            newValue: currentInstrumentObject?.sell,
            oldValue: previousInstrumentObject?.sell
          }));

          useEffect(() => {
            setPulseColors(definePulseColor({
              newValue: currentInstrumentObject?.buy,
              oldValue: previousInstrumentObject?.buy
            }, {
              newValue: currentInstrumentObject?.sell,
              oldValue: previousInstrumentObject?.sell
            }));
            setCleanColor(true);
          }, [currentInstrumentObject?.buy, currentInstrumentObject?.sell]);

          useEffect(() => {
            let timer1 = setTimeout(() => { setCleanColor(false) }, 1500);
            return () => {
              clearTimeout(timer1);
            };
          }, [pulseColors])

          return (
            <li key={index} className="w-full md:w-[calc(100%/3-1rem)] pb-[4.444rem] hover:opacity-80 transition-all duration-300 ease-in-out">
              <div className='relative rounded-md overflow-hidden'>
                <NextImage src={instrument?.attributes?.image} height={instrument?.attributes?.image?.data?.attributes?.height} width={instrument?.attributes?.image?.data?.attributes?.width} />
                <div className={styles.link}>
                  <Link href={`${parentUrl}${instrument?.attributes?.slug}`} />
                </div>
              </div>
              <div className='text-center md:text-left my-md lg:my-md'>
                <p className='uppercase text-dark'>
                  {instrument?.attributes?.display_name}
                </p>
              </div>
              {!instrumentData ? <div className="flex justify-center"><Loaders color="primary" /></div> : <div className='flex justify-between items-center'>
                <span className={`${instrumentData?.currentData?.[instrument?.attributes?.api_id]?.change.replace(/[^\d.-]/g, '') >= 0 ? `text-greenCandle` : `text-primary`}`}>{instrumentData?.currentData?.[instrument?.attributes?.api_id]?.change}</span>
                <div className='flex'>
                  <span className='mx-1'>{gv('buy')}: </span>
                  <span className={`${styles[`${cleanColor && pulseColors.buy}`]}`}>{instrumentData?.currentData?.[instrument?.attributes?.api_id]?.buy}</span>
                </div>
                <div className='flex'>
                  <span className='mx-1'>{gv('sell')}: </span>
                  <span className={`${styles[`${cleanColor && pulseColors.sell}`]}`}>{instrumentData?.currentData?.[instrument?.attributes?.api_id]?.sell}</span>
                </div>
              </div>}
            </li>
          )
        })}
      </ul>
    )
  } return null;
};

export default FeaturedInstrument;
