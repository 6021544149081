import EssentialArticles from './components/EssentialArticles';
import LatestArticle from './components/LatestArticle';
import LatestLinks from './components/LatestLinks';

const AcademyFeaturedLessons = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='lg:container'>
      <div className="flex flex-col lg:flex-row lg:justify-between">
        {/* essential links */}
        <EssentialArticles data={data} />
        {/* latest article card */}
        <LatestArticle data={data} />
      </div>
      <div className='mt-[3.88rem] hidden lg:flex justify-between'>
        {/* latest articles */}
        <LatestLinks data={data} />
      </div>
    </div>
  );
};

export default AcademyFeaturedLessons;
