import styles from './index.module.css';
import { Select } from '@mantine/core';
import { useEffect, useState } from 'react';
import get from "lodash/get";
import { getStrapiMedia } from '@utils/media';
import NextImage from '@components/elements/Image';
import Arrow from '@src/components/elements/Arrow';
import Image from 'next/image';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import Link from '@src/components/elements/Link';
import { useRouter } from 'next/router';

export default function LegalDocuments({ pageData }) {
  // get latest element from array
  const [url, setUrl] = useState(get(pageData, `versions[${pageData?.versions?.length - 1}].file.url`, ""));
  const [dropdownOneOpened, setDropdownOneOpened] = useState(false);
  const [versionName, setVersionName] = useState(pageData?.versions[pageData?.versions?.length - 1].name);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");
  const [iframeKey, setIframeKey] = useState(0);
  const [stateEmbedded, setStateEmbedded] = useState(false);


  const iframeModifyUrl = (iframeUrl) => {
    if (iframeUrl && typeof iframeUrl === "string" && iframeUrl.includes(".pdf")) {
      const fixProtocol = iframeUrl.startsWith("//") ? "https:" : "";
      const newUrl = new URL(fixProtocol + iframeUrl);
      const hashParameters = "#statusbar=0&view=FitH";
      const modifiedUrl =
        newUrl?.protocol + "//" + newUrl?.hostname + newUrl?.pathname + newUrl?.search + hashParameters;
      return modifiedUrl;
    }
    return iframeUrl;
  };


  useEffect(() => {
    setIframeSrc(`https://docs.google.com/gview?embedded=true&url=${iframeModifyUrl(getStrapiMedia(url))}`);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!iframeLoaded) {
        setIframeSrc(`https://docs.google.com/gview?embedded=true&url=${iframeModifyUrl(getStrapiMedia(url))}`);
        setIframeKey(prevKey => prevKey + 1);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [iframeLoaded]);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };


  const router = useRouter();
  // const { embedded } = router.query;



  useEffect(() => {
    const queryKey = 'embedded';
    let embedded = false;
    try {
      embedded = router.query[queryKey] || router.asPath.match(new RegExp(`[&?]${queryKey}=(.*?)(&|$)`))?.[1];
      if (embedded === 'true') {
        embedded = true;
      } else {
        embedded = false;
      }
    } catch (error) {
      embedded = false;
    }
    if (embedded !== stateEmbedded) {
      setStateEmbedded(embedded)
    }

  }, [router.query, router.asPath]);


  if (stateEmbedded === false) {
    return (
      <>
        <div className="relative bg-[#f5f5f5] pt-[10rem] pb-[4rem]">
          <div className="absolute top-0 h-full w-full ltr:left-0 rtl:right-0">
            <NextImage
              layout="fill"
              objectFit="cover"
              src="https://cdn.capex.com/uploads/featured_background_1d5d4beabb/featured_background_1d5d4beabb.png"
              priority
              alt="ipo-getStrapiMedia(url)-hero.webp"
            />
          </div>
          {/* inline items */}
          <div className="relative z-10 container text-center">
            <div>
              <NextImage
                src="https://cdn.capex.com/uploads/capex_com_logo_red2_0ee277e17c/capex_com_logo_red2_0ee277e17c.svg"
                width="540"
                height="169"
              />
              <h1 className='mb-0 pt-md text-dark'>
                {pageData?.title || ''}
              </h1>
            </div>
          </div>
        </div>
        {
          pageData?.versions?.length > 0 &&
          <div className="container pt-xl flex justify-center">
            <div className='w-full md:max-w-[40%] mx-auto'>
              <Select
                className={styles['mantine-NativeSelect-wrapper']}
                data={pageData?.versions?.map((el) => el.name)}
                value={versionName}
                onChange={(value) => {
                  const index = pageData?.versions?.findIndex(
                    (version) => version.name === value,
                    setVersionName(value)
                  );
                  setUrl(pageData?.versions[index].file.url);
                }}
                required
                onDropdownOpen={() => setDropdownOneOpened(true)}
                onDropdownClose={() => setDropdownOneOpened(false)}
                rightSection={
                  <div className={`${dropdownOneOpened ? 'text-white' : 'text-secondary'}`}>
                    <Arrow direction="down" />
                  </div>
                }
                classNames={{
                  wrapper: styles.wrapper,
                  input: dropdownOneOpened ? styles.selectInputOpened : styles.selectInputClosed,
                  root: styles.selectRoot,
                  rightSection: styles.rightSection,
                  item: styles.itemSelect,
                  dropdown: styles.dropdown
                }}
              />
            </div>
          </div>
        }
        <div className='container mt-xl pb-[6rem] md:pb-[8rem] lg:pb-[10rem] relative'>
          {!iframeLoaded && <div className="loader absolute left-1/2 top-1/2 -mt-[15px] -ml-[15px] md:hidden" />}
          <div className="hidden md:block">
            <iframe title={pageData?.title} src={getStrapiMedia(url)} type="application/pdf"
              width="100%"
              height="769px" />
          </div>
          <div className={`block md:hidden  ${!iframeLoaded && `opacity-0`}`}>
            <iframe title={pageData?.title} key={iframeKey} onLoad={handleIframeLoad} onError={handleIframeLoad} src={iframeSrc} type="application/pdf"
              width="100%"
              height="500px" />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className={`relative  ${styles.embedded}`}>
        <div className="hidden md:block">
          <iframe title={pageData?.title} src={getStrapiMedia(url)} type="application/pdf"
            width="100%" height="100%" />

        </div>
        <div className={`block md:hidden  ${!iframeLoaded ? `opacity-0` : `opacity-100`}`}>
          <iframe title={pageData?.title} key={iframeKey} onLoad={handleIframeLoad} onError={handleIframeLoad} src={iframeSrc} type="application/pdf"
            width="100%" height="100%" />
        </div>
        {!iframeLoaded && <div className="loader absolute left-1/2 top-1/2 -mt-[15px] -ml-[15px] md:hidden" />}
      </div>
    );
  }


}
