import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import './AboutUsSponsors.module.css';

const AboutUsSponsors = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <>
      <div className='container'>
        <SectionTitle data={data?.section_title} />
        {
          data?.sponsor_card.map((card, index) => (
            <div key={index} className='flex flex-col lg:flex-row justify-between items-center w-full mb-xl'>
              <div className='flex justify-center lg:justify-start items-center w-full lg:max-w-[35%]'>
                <NextImage src={card?.image} width={card?.image?.data?.attributes?.width} height={card?.image?.data?.attributes?.height} />
              </div>
              <div className="flex flex-col lg:flex-row justify-between w-full  lg:max-w-[60%] items-start lg:items-center">
                <div className='flex justify-center items-center lg:items-start my-lg w-full max-w-full lg:max-w-[25%] '>
                  <NextImage src={card?.logo} width={card?.logo?.data?.attributes?.width} height={card?.logo?.data?.attributes?.height} />
                </div>
                <div className='flex flex-col justify-start  w-full lg:max-w-[62%]'>
                  <h3 className=''>{card?.title}</h3>
                  <HTMLRender data={card?.content} />
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </>
  );
};

export default AboutUsSponsors;
