import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import NextImage from '@src/components/elements/Image';
import List from '@src/components/elements/List';
import SectionTitle from '@src/components/elements/SectionTitle';
import './AccountTypeVip.module.css';

const AccountTypeVip = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable()
  if (!data) return null;

  return (
    <div className="container">
      <div className='relative bg-[#f6f6f6] rounded-xl'>
        <div className='flex flex-col lg:flex-row items-center justify-end'>
          <div className='max-w-[50%] lg:max-w-full lg:absolute ltr:left-[15%] rtl:right-[15%] top-0'>
            <NextImage src={data?.image} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
          </div>
          <div className='lg:w-[50%] pt-xl lg:pt-lg px-md lg:px-lg'>
            <SectionTitle data={data?.section_title} />
            <div className='py-lg lg:py-xl'>
              <List type="checked" border list_items={data?.list} />
            </div>
            <div>
              <p className='uppercase text-secondary pb-lg'>{gv('ask_representative')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountTypeVip;
