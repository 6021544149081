import styles from "./FooterSocials.module.css";
import NextLink from "next/link";
import NextImage from "@components/elements/Image";

const FooterSocials = (props) => {
  const { data } = props;
  const socials = data[0]?.socials?.data;

  return (
    <div className="flex w-full justify-center  lg:flex-wrap">
      {socials?.map((social_app, index) => (
        <div key={index} className={`relative m-xs w-fit cursor-pointer ${styles.hoverIcon}`}>
          <NextLink href={`${social_app?.link}`}>
            <a target="_blank" className="absolute top-0 left-0 z-10 h-full w-full bg-transparent" />
          </NextLink>
          <NextImage
            src={social_app?.logo?.url}
            width={social_app?.logo?.width}
            height={social_app?.logo?.height}
            alt={social_app?.logo?.alternativeText}
          />
        </div>
      ))}
    </div>
  )
};

export default FooterSocials;
