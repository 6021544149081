import SectionTitle from '@src/components/elements/SectionTitle';
import styles from './PromotionsHero.module.css';

const PromotionsHero = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      {data?.section_title &&
        <div className={`${styles.title} mb-lg pb-3xl md:pb-0`}>
          <SectionTitle data={data?.section_title} />
        </div>
      }
    </div>
  );
};

export default PromotionsHero;
