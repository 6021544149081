import SmallChart from "@src/components/elements/SmallChart";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Link from "@components/elements/Link";
import { IoIosArrowBack } from "@react-icons/all-files/io/IoIosArrowBack";
import { IoIosArrowForward } from "@react-icons/all-files/io/IoIosArrowForward";
import { useRef, useEffect } from "react";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";
import styles from "./InstrumentSlide.module.css";
import Arrow from "@src/components/elements/Arrow";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import NextImage from '@src/components/elements/Image';

const InstrumentSlide = (props) => {
  const store = useStore(
    (store) => ({
      dir: store.direction,
    }),
    shallow
  );

  const isIframe = useStore((s) => s.page_key, shallow)?.includes("iframe");
  const urlParams =
    typeof window !== "undefined" && window?.location?.search && isIframe ? window?.location?.search : "";

  const { gv } = useGlobalVariable();
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  if (props.instruments) {
    const instruments = props.instruments;
    return (
      <div className="pt-lg first:pt-0">
        <div className="flex justify-start ltr:pl-[2rem] rtl:pr-[2rem] ltr:lg:pl-[4rem] rtl:lg:pr-[4rem] ">
          <div className="mb-lg border-b border-[#5B5B5B] pb-sm font-bold">
            {props.type === "losers" ? (
              <div className="text-primary">{gv("top_losers", "raw")}</div>
            ) : (
              <div className="text-greenCandle">{gv("top_gainers", "raw")}</div>
            )}
          </div>
        </div>
        <div className=" instrument-slide relative px-[2rem] lg:px-[4rem]">
          <div
            ref={navigationPrevRef}
            className="absolute top-1/2 left-0 -translate-y-1/2 cursor-pointer text-2xl hover:text-secondaryHover lg:left-[1rem] lg:text-[3rem] xl:left-0"
          >
            <IoIosArrowBack />
          </div>
          <div
            ref={navigationNextRef}
            className="absolute top-1/2 right-0 -translate-y-1/2 cursor-pointer text-2xl hover:text-secondaryHover lg:right-[1rem] lg:text-[3rem] xl:right-0"
          >
            <IoIosArrowForward />
          </div>
          <Swiper
            spaceBetween={50}
            dir={store ? store.dir : "ltr"}
            slidesPerView={1}
            loop={false}
            modules={[Navigation]}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {instruments?.map((instrument, index) => {
              return (
                <SwiperSlide key={instrument}>
                  <Link blank={isIframe} href={`/instruments/${instrument?.id}${urlParams}`}>
                    <div
                      className={`relative flex h-full flex-col justify-between overflow-hidden rounded-lg border bg-[#FBFBFB] text-dark shadow-none transition-shadow duration-200 ease-in-out hover:shadow-xl ${instrument?.change?.replace(/[^\d.-]/g, "") < 0 ? `border-[#E4C7C7]` : `border-[#CDE3C6]`
                        }`}
                    >
                      <div className="py-md px-sm lg:px-md">
                        <div className="flex  justify-between">
                          <div className="flex ">
                            <div
                              className={`relative top-[-0.25rem] h-[2rem] w-[2rem] flex-[0_0_2rem] ltr:mr-[0.25rem] rtl:ml-[0.25rem] ltr:lg:mr-xs rtl:lg:ml-xs`}
                            >
                              <NextImage
                                src={
                                  props.type === "losers"
                                    ? `https://cdn.capex.com/uploads/red_candles_e60829503f/red_candles_e60829503f.png`
                                    : `https://cdn.capex.com/uploads/green_candles_6676a6587b/green_candles_6676a6587b.png`
                                }
                                layout="fill"
                                objectFit="cover"
                              />
                            </div>
                            <div className={styles.instrumentName}>{instrument?.display}</div>
                          </div>
                          <div className="relative font-semibold  ltr:mr-[1.2em] rtl:mr-[1.8rem] ltr:md:pl-xs">
                            {instrument?.price}{" "}
                            <small className="absolute left-full -top-[0.35em] pl-[0.2em] text-[0.7em]">
                              {gv("usd", "raw")}
                            </small>
                          </div>
                        </div>
                        <div className="flex items-center justify-center pt-lg text-[2.5rem]">
                          <div
                            className={`
                      ${instrument?.change?.replace(/[^\d.-]/g, "") >= 0 ? `text-greenCandle` : `text-primary`}`}
                          >
                            <Arrow direction={instrument?.change?.replace(/[^\d.-]/g, "") >= 0 ? `up` : `down`} />
                          </div>
                          <div className="ltr:pl-md rtl:pr-md">{instrument?.change}</div>
                        </div>
                      </div>
                      <div className="relative -mx-[2px] mb-lg h-[3em]">
                        <SmallChart
                          color={instrument?.change?.replace(/[^\d.-]/g, "") >= 0 ? `green` : `red`}
                          instrumentId={instrument.id}
                        />
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default InstrumentSlide;
