import ProductTypeTabs from './components/ProductTypeTabs';
import styles from './ProductTabs.module.css';

import classNames from "classnames";

const ProductTabs = ({ color = '', product='' }) => {
  return (
    <div
      className={classNames(
        "flex rounded-lg",
        "product-tabs",
        styles[`product-tabs`],
        color === "white" && "shadow-[inset_0_0_0_1px_#fff]",
        color !== "white" && "shadow-[inset_0_0_0_1px_#acacac]",
        styles[product]
      )}
    >
      <ProductTypeTabs />
    </div>
  );
};

export default ProductTabs;
