/* eslint-disable arrow-body-style */
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { MultiSelect } from "@mantine/core";
import { IoSearchOutline } from "@react-icons/all-files/io5/IoSearchOutline";
import { useEffect, useState } from "react";
import { uniqBy } from "lodash";
import styles from "../../../../TradeHomeInstruments.module.css";
import useInstrumentSearch from "./useInstrumentSearch";

export default function SearchInstruments({ category, search, setSearch }) {
  const { gv } = useGlobalVariable();
  const [data, setData] = useState([]);
  const [searchValue, onSearchChange] = useState("");
  const { data: suggestedData } = useInstrumentSearch(searchValue, category);

  useEffect(() => {
    if (!suggestedData) return;
    if (suggestedData.length === 0) return;
    const currentData = [...data, ...suggestedData];
    const uniqueData = uniqBy(currentData, "value");
    if (uniqueData.length > data.length) setData(uniqueData.reverse());
  }, [suggestedData]);

  return (
    <div className={styles.searchWrapper}>
      <MultiSelect
        data={data}
        placeholder={gv("instrument_search_example")}
        icon={<IoSearchOutline />}
        rightSection={<div />}
        onSearchChange={onSearchChange}
        onChange={setSearch}
        value={search}
        clearable
        limit={10}
        searchable
        styles={{
          icon: "width: 50px"
        }}
      />
    </div>
  );
}
