import styles from './FreeShareIframe.module.css';
import NextImage from "@components/elements/Image";
import instrumentsApi from "@utils/instrumentsApi";
import SectionTitle from '@src/components/elements/SectionTitle';
import { useState, useEffect } from 'react';
import Button from '@src/components/elements/Button';
import HTMLRender from '@src/components/core/HTMLRender';

// instrument card component
const InstrumentCard = ({ instrument, bgColor, index, currentInstrumentObject, label, activeCard, setActiveCard }) => {
  const icon = instrument?.image;
  const price = currentInstrumentObject?.price
  const displayName = instrument.display_name;
  const urlParams = typeof window !== 'undefined' && window?.location?.search ? window?.location?.search : "";
  const urlParamsWithoutQuestionMark = urlParams.replace("?", "");
  const finalUrlParams = urlParamsWithoutQuestionMark!==''? `&${urlParamsWithoutQuestionMark}` : '';
  

  const [isHovered, setIsHovered] = useState({ value: false, index: 0 });

  return (
    // desktop version
    <>
      <div
        onMouseEnter={() => setIsHovered({ value: true, index })}
        onMouseLeave={() => setIsHovered({ value: false, index: null })}
        onClick={() => setActiveCard({ value: true, index })}
        className={`hidden w-full flex-col items-center justify-center lg:flex ${styles[`card-box-animation`]}
        ${
          (isHovered.value && isHovered.index === index) || (activeCard.value && activeCard.index === index)
            ? `${styles.hoverScale}`
            : `${styles.unHoverScale}}`
        }
      `}
      >
        <div
          style={{ backgroundColor: `${bgColor}` }}
          className={`relative flex h-[5.33rem] w-[8.66rem] items-center rounded-lg 
          ${isHovered.value && isHovered.index === index ? `${styles.hoverCard}` : `${styles.notHoverCard}`}
      `}
        >
          <div
            className={`first-letter first-letter absolute h-[3.888rem] w-[3.888rem] rounded-lg bg-white p-sm shadow-lg
          ${
            activeCard.value && activeCard.index === index
              ? `top-0 left-1/2 -translate-y-1/2 -translate-x-1/2`
              : `top-1/2 left-0 -translate-y-1/2 -translate-x-1/2`
          }`}
          >
            {icon && (
              <div className="pt-[4px]">
                <NextImage src={icon?.url} width={icon?.width} height={icon?.height} />
              </div>
            )}
          </div>
          <div
            className={`flex w-full  flex-col py-xs
            ${
              activeCard.value && activeCard.index === index
                ? `mt-lg items-center justify-center`
                : `items-start pl-[2.5rem]`
            }
          `}
          >
            <p className="mb-0 text-sm font-bold text-white">{displayName}</p>
            <p className="mb-0 text-white">${price}</p>
          </div>
        </div>
        <div className="mt-md">
          <Button
            type={
              (isHovered.value && isHovered.index === index) || (activeCard.value && activeCard.index === index)
                ? "primary"
                : "disabled"
            }
            size="sm"
            label={label}
            link={`/registration-invest?intent_instrument=${instrument?.api_id}${finalUrlParams}`}
          />
        </div>
      </div>
      {/* mobile version */}
      <div
        onClick={() => setActiveCard({ value: true, index })}
        className={`flex w-full flex-col items-center justify-center lg:hidden ${styles[`card-box-animation`]}
          ${activeCard.value && activeCard.index === index ? `${styles.forceScale}` : ``}
        `}
      >
        <div
          style={{ backgroundColor: `${bgColor}` }}
          className={`relative flex  w-[75%] flex-col items-center  rounded-lg 
            ${
              activeCard.value && activeCard.index === index
                ? `h-full min-h-[11rem] pb-md transition-all duration-300 ease-in-out`
                : `h-full min-h-[5.3rem] justify-center transition-all duration-300 ease-in-out`
            }
          `}
        >
          <div className="first-letter first-letter absolute top-[15%] left-0 h-[3.888rem] w-[3.888rem] -translate-x-1/2 rounded-lg bg-white p-sm shadow-lg">
            {icon && (
              <div className="pt-[4px]">
                <NextImage src={icon?.url} width={icon?.width} height={icon?.height} />
              </div>
            )}
          </div>
          <div
            className={`flex h-full w-full items-center justify-between
            ${activeCard.value && activeCard.index === index ? `mt-0 pt-md` : `mt-0`}
          `}
          >
            <div
              className={`flex h-full w-full max-w-[80%] flex-col items-start py-xs pl-[2.5rem]
            ${
              activeCard.value && activeCard.index === index
                ? `justify-center transition-all duration-300 ease-in-out`
                : `items-start transition-all duration-300 ease-in-out`
            }
            `}
            >
              <p className="mb-0 font-bold text-white">{displayName}</p>
              <p className="mb-0 text-white">${price}</p>
            </div>
            <div className="flex w-full max-w-[30%] justify-end pr-[1.5rem]">
              {activeCard.value && activeCard.index === index ? (
                <NextImage
                  src="https://cdn.capex.com/uploads/mobile_check_2f1c2a8c67/mobile_check_2f1c2a8c67.png?updated_at=2023-01-17T08:25:37.087Z"
                  width={46}
                  height={46}
                />
              ) : (
                <NextImage
                  src="https://cdn.capex.com/uploads/click_here_icon_9087009436/click_here_icon_9087009436.png?updated_at=2023-01-17T08:25:37.097Z"
                  width={46}
                  height={46}
                />
              )}
            </div>
          </div>
          <div
            className={`absolute flex w-full justify-center pt-md
            ${
              activeCard.value && activeCard.index === index
                ? `bottom-[10%] z-10 opacity-100 transition-all duration-300 ease-in-out`
                : `z-[-1] h-0 opacity-5`
            }
          `}
          >
            <Button
              type="primary"
              size="lg"
              label={label}
              link={`/registration-invest?intent_instrument=${instrument?.api_id}${finalUrlParams}`}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const FreeShareIframe = (props) => {
  const data = props.data || props;
  const [activeCard, setActiveCard] = useState({ value: true, index: 1 });

  const instrumentsList = data?.free_shares?.length > 0 && data?.free_shares?.map((share) => share?.instrument?.data?.map((instrument) => instrument.api_id));
  // use the instruments array to make call to instrumentsApi
  const instrumentData = instrumentsApi(instrumentsList, 1000);

  // send body height to parent
  useEffect(() => {
    if (typeof window !== "undefined" && window && parent) {
      //add listener for resize event OR interval time,  and send the size to parent
      const interval = setInterval(() => {
        parent.postMessage(
          JSON.stringify({
            name: "iframe-free-share",
            height: document.querySelector(".free-share-container").scrollHeight,
          }),
          "*"
        );
      }, 250);
      return () => clearInterval(interval);
    }
  }, []);

  if (!data) return null;

  return (
    <div className='container free-share-container'>
      {data?.section_title &&
        <SectionTitle data={data?.section_title} />
      }
      <div dir="ltr" className='flex flex-col lg:flex-row items-center justify-center lg:mt-lg'>
        {data?.free_shares?.map((share, index) => {
          const currentInstrumentObject = instrumentData?.currentData?.[share?.instrument?.data[0]?.api_id];
          return (
            <div key={index} className="mx-lg w-full lg:max-w-[calc(100%/5)] cursor-pointer mb-lg lg:mb-0">
              <InstrumentCard activeCard={activeCard} setActiveCard={setActiveCard} index={index} instrument={share?.instrument?.data[0]} currentInstrumentObject={currentInstrumentObject} label={data?.button_text} bgColor={share?.backgroundColor || '#0995dd'} />
            </div>
          )
        })}
      </div>
      {
        data?.disclaimer &&
        <div className='flex justify-center lg:justify-end w-full mt-lg lg:mt-2xl'>
          <HTMLRender data={data?.disclaimer} />
        </div>
      }
    </div>
  );
};

export default FreeShareIframe;
