import SectionTitle from '@components/elements/SectionTitle';
import styles from './WebtraderDescription.module.css';
import ColumnImage from '@components/elements/ColumnImage';

const WebtraderDescription = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      <SectionTitle data={data?.section_title} />
      <div className={`${styles[`webtrader-description-columns`]} text-center md:text-left`}>
        <ColumnImage apps={data?.apps} data={data?.column_image} />
      </div>
    </div>
  );
};

export default WebtraderDescription;