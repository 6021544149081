import HTMLRender from "@components/core/HTMLRender";
import GenericSlider from "@components/custom/GenericSlider";
import Button from "@components/elements/Button";
import NextImage from "@components/elements/Image";
import { SwiperSlide } from "swiper/react";
import styles from "./PartnersStart.module.css";

const PartnersStart = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className={`container pb-[8rem] md:pt-[5rem] ${styles?.strip}`}>
      <div className="mb-xl block pt-lg md:hidden">
        <div className={styles.heroContent}>
          <HTMLRender data={data?.content_from_hero} />
        </div>
        <div className="mt-lg flex w-full justify-center">
          <Button data={data?.cards_button} />
        </div>
      </div>
      <div className="hidden items-center justify-between md:flex">
        <div className="flex max-w-[calc(33.33%-1.11rem)] flex-col">
          <div className={`${styles?.content}`}>
            <HTMLRender data={data?.section_title?.subtitle} />
          </div>
          <div className={`${styles?.howToCard} mt-[4.666rem] p-md md:p-[2.222rem]`}>
            <div>
              <div className="flex items-center justify-between">
                <div>
                  <NextImage
                    src={data?.icon_text[0].icon}
                    width={data?.icon_text[0].icon?.data?.attributes?.width}
                    height={data?.icon_text[0].icon?.data?.attributes?.height}
                  />
                </div>
                <div>
                  <p className="ltr:font-gilroy rtl:font-dubai text-[5.556rem] ltr:font-black rtl:font-bold text-[#e7e7e7]">01</p>
                </div>
              </div>
              <div className="pt-lg">
                <h5 className="mb-0 pb-sm font-bold text-dark ">{data?.icon_text[0].title}</h5>
                <div className="border-t-bg-[#0d0d0d] mb-xl max-w-[75%] border-t pt-sm">
                  <HTMLRender data={data?.icon_text[0].content} />
                </div>
              </div>
              <div>
                <Button data={data?.cards_button} />
              </div>
            </div>
          </div>
        </div>
        {data?.icon_text.slice(1, data?.icon_text.length).map((card, index) => (
          <div key={index} className="max-w-[calc(33.33%-1.11rem)] last:self-start">
            <div className={`${styles?.howToCard} p-md md:p-[2.222rem]`}>
              <div>
                <div className="flex items-center justify-between">
                  <div>
                    <NextImage
                      src={card?.icon}
                      width={card?.icon?.data?.attributes?.width}
                      height={card?.icon?.data?.attributes?.height}
                    />
                  </div>
                  <div>
                    <p className="ltr:font-gilroy rtl:font-dubai text-[5.556rem] ltr:font-black rtl:font-bold text-[#e7e7e7]">{`0${index + 2}`}</p>
                  </div>
                </div>
                <div className="pt-lg">
                  <h5 className="mb-0 pb-sm font-bold text-dark ">{card?.title}</h5>
                  <div className="border-t-bg-[#0d0d0d] mb-xl max-w-[75%] border-t pt-sm">
                    <HTMLRender data={card?.content} />
                  </div>
                </div>
                <div>
                  <Button data={data?.cards_button} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col md:hidden">
        <div className={`${styles?.content}`}>
          <HTMLRender data={data?.section_title?.subtitle} />
        </div>
        <div className="mt-lg ltr:-mr-md rtl:-ml-md">
          <GenericSlider loop pagination slidesPerView={1.3} spaceBetween={25}>
            {data?.icon_text.map((card, index) => (
              <SwiperSlide key={index}>
                <div className="w-full last:self-start">
                  <div className={`${styles?.howToCard} p-md md:p-[2.222rem]`}>
                    <div>
                      <div className="flex items-center justify-between">
                        <div>
                          <NextImage
                            src={card?.icon}
                            width={card?.icon?.data?.attributes?.width}
                            height={card?.icon?.data?.attributes?.height}
                          />
                        </div>
                        <div>
                          <p className="ltr:font-gilroy rtl:font-dubai text-[5.556rem] ltr:font-black rtl:font-bold text-[#e7e7e7]">{`0${index + 1}`}</p>
                        </div>
                      </div>
                      <div className="pt-lg">
                        <h5 className="mb-0 pb-sm font-bold text-dark ">{card?.title}</h5>
                        <div className="border-t-bg-[#0d0d0d] mb-xl max-w-[75%] border-t pt-sm">
                          <HTMLRender data={card?.content} />
                        </div>
                      </div>
                      <div>
                        <Button data={data?.cards_button} />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </GenericSlider>
        </div>
      </div>
      <div className="border-bg-[#f1f1f1] relative mt-[3.777rem] w-full rounded-lg border bg-white p-[2.222rem] md:mt-[9.777rem]">
        <div className="absolute top-auto bottom-1/2 max-w-[40%] ltr:right-0 rtl:left-0 md:bottom-0">
          <NextImage
            src={data?.money_image}
            width={data?.money_image?.data?.attributes?.width}
            height={data?.money_image?.data?.attributes?.height}
          />
        </div>
        <div className="max-w-[55%]">
          <HTMLRender data={data?.money_content} />
          <div className="mt-lg">
            <Button data={data?.cards_button} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnersStart;
