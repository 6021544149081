import "./AcademyLessonCardDesktop.module.css";
import { TiLockClosed } from "@react-icons/all-files/ti/TiLockClosed";
import { TiLockOpen } from "@react-icons/all-files/ti/TiLockOpen";
import NextImage from "@components/elements/Image";
import NextLink from "@src/components/elements/Link";

function AcademyLessonCardDesktop(props) {
  const data = props?.data || props;

  if (!data) return null;

  return (
    <div className="relative flex h-full w-full flex-col justify-between rounded border border-[#ebebeb]  text-dark">
      <div className="h-auto">
        <NextImage
          src={data?.image}
          alt="Academy Image"
          width={data?.image?.width}
          height={data?.image?.height}
          objectFit="cover"
        />
      </div>
      <div className="flex grow flex-col p-lg pt-sm pb-sm">
        <div>
          <p className="mb-md pt-sm text-base uppercase text-secondary">{data?.title}</p>
          <p className="text-base">{data?.description}</p>
        </div>
        <div className="mt-md h-auto max-h-[12.6875em] overflow-hidden">
          <div
            className={`h-full ltr:pr-sm rtl:pl-sm ${data?.academy_subjects?.length > 4 ? "overflow-y-scroll" : "overflow-hidden"
              }`}
          >
            {data?.academy_subjects?.map((lesson, index) => (
              <div
                key={index}
                className="relative w-full overflow-hidden whitespace-nowrap border-b border-b-[#e2e2e2] border-opacity-[0.5] py-sm px-0"
              >
                <NextLink href={lesson?.slug}>
                  <a
                    className={`relative inline-block w-full overflow-hidden overflow-ellipsis whitespace-nowrap align-middle opacity-100 transition-all duration-200 ease-in-out hover:opacity-70 ltr:pr-md rtl:pl-md ${!lesson?.locked ? "text-secondary" : "pointer-events-none"
                      }`}
                  >
                    <span>{lesson?.title}</span>
                    <i
                      className={` ${lesson?.locked && `opacity-50`
                        } absolute top-0 mt-[0.55em] inline-block h-full -translate-y-[50%] align-middle text-[1.3125rem] ltr:right-0 ltr:ml-[0.75em] rtl:left-0 rtl:mr-[0.75em]`}
                    >
                      {!lesson?.locked ? <TiLockOpen /> : <TiLockClosed />}
                    </i>
                  </a>
                </NextLink>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcademyLessonCardDesktop;
