/* eslint-disable react/jsx-no-useless-fragment */
import Arrow from "@components/elements/Arrow";
import styles from "./HeaderMenu.module.css";
import NextLink from "@components/elements/Link";

const NavigationItem = ({ item, children, locale }) => {
  return (
    <>
      {item?.hasSlug === false ? (
        <span className="flex select-none whitespace-nowrap">{children}</span>
      ) : (
        <NextLink className="flex whitespace-nowrap" href={item?.slug}>
          {children}
        </NextLink>
      )}
    </>
  );
};

const RenderMenu = ({ menu = [], level = 0, locale }) => {
  if (level === 0) {
    const menuColumns = menu.map((el) => {
      const newEl = el?.children
        ?.map((el2, index) => {
          return el2?.children?.length > 0 ? true : null;
        })
        .filter((el3) => el3 != null);
      if (newEl?.length == 0) return null;
      return el;
    });
    menu = menu.map((el) => {
      if (el?.id && menuColumns.some((childItem) => childItem?.id === el.id)) {
        return {
          ...el,
          isMenuColumns: true,
        };
      }
      return {
        ...el
      }
    });
  }

  return menu.map((item, index) => (
    <>
      {item ?
        <li className="flex items-center" key={parseInt(Math.random() * 1000000000) + index}>
          {item?.slug ? (
            <NavigationItem item={item} locale={locale}>
              {level > 1 && item?.hasArrow ? (
                <div className="pr-sm">
                  <Arrow direction="right" />
                </div>
              ) : null}
              <span>{item?.title}</span>
              {item?.children?.length > 0 && level === 0 ? (
                <div className="flex items-center justify-center ltr:ml-sm rtl:mr-sm">
                  <div className="parent-arrow">
                    <Arrow direction="down" />
                  </div>
                </div>
              ) : null}
            </NavigationItem>
          ) : (
            <>
              {level > 1 ? (
                <div className="pr-sm">
                  <Arrow direction="right" />
                </div>
              ) : null}
              <span>{item?.title}</span>
              {item?.children?.length > 0 && level === 0 ? (
                <div className="ltr:ml-sm rtl:mr-sm">
                  <Arrow direction="down" />
                </div>
              ) : null}
            </>
          )}
          {item?.children?.length !== 0 ? (
            <ul className={` ${item?.isMenuColumns ? styles.columnMenu : ``}`}>
              <RenderMenu menu={item?.children} level={level + 1} locale={locale} />
            </ul>
          ) : null}
        </li>
        : null}
    </>
  ));
};

export default RenderMenu;
