import HTMLRender from "@components/core/HTMLRender";
import ColumnImage from "@components/elements/ColumnImage";
import { useStore } from "@lib/store";
import Link from "@src/components/elements/Link";
import { shallow } from "zustand/shallow";
// import { useSwiper } from 'swiper/react';
import styles from "./IntegratedTSlide.module.css";

const IntegratedTSlide = (props) => {
  const data = props.data || props;

  // const swiper = useSwiper();
  // if (!data) return null;

  const store = useStore(
    (store) => ({
      sections: store.sections,
    }),
    shallow
  );

  return (
    <div className="container justify-center pb-[4.8rem] pt-[4.8rem] md:pb-0 xl:justify-between">
      <div className="flex flex-col justify-center ">
        {/* we use section id to get the id of the first slide to set a custom class - smaller image than the rest */}
        <div
          className={`${styles.column} ${data?.dark_bg && styles.columnDark} ${
            styles[`${data?.section_layout?.section_id}`]
          }`}
        >
          <ColumnImage data={data?.column_image} />
        </div>
        <div className={`mt-[2rem] hidden text-center md:block 2xl:mt-[5rem] ${styles.disclaimer}`}>
          <HTMLRender data={data?.disclaimer} />
        </div>
      </div>
      <div className="hidden md:block xl:mt-lg">
        <ul className="flex">
          {data?.navigations?.map((navTool, index) => (
            <li
              key={index}
              className={`${styles.columnList} ${data?.dark_bg && styles.columnListDark} ${
                navTool?.active && styles.activeText
              }`}
            >
              <HTMLRender data={navTool?.name} />
              {!navTool?.active && (
                <div className={`${styles.link}`}>
                  <a href={`#sections.integrated-t-slide_id_${store.sections[index + 1].id}`}>
                    <HTMLRender data={navTool?.link} />
                  </a>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default IntegratedTSlide;
