import aboutuscommunity from './sections/about-us-community';
import aboutusglobal from './sections/about-us-global';
import aboutussponsors from './sections/about-us-sponsors';
import aboutustabs from './sections/about-us-tabs';
import academyfeaturedlessons from './sections/academy-featured-lessons';
import academyhero from './sections/academy-hero';
import academyherov2 from './sections/academy-hero-v2';
import academylessonsv2 from './sections/academy-lessons-v2';
import academyoffers from './sections/academy-offers';
import academyscopes from './sections/academy-scopes';
import academyshowcase from './sections/academy-show-case';
import accounttypehero from './sections/account-type-hero';
import accounttypetable from './sections/account-type-table';
import accounttypevip from './sections/account-type-vip';
import affiliateshero from './sections/affiliates-hero';
import affiliatesmodel from './sections/affiliates-model';
import affiliatesreasons from './sections/affiliates-reasons';
import articlesshowcase from './sections/articles-showcase';
import awardsshowcase from './sections/awards-showcase';
import bondsintro from './sections/bonds-intro';
import bondssteps from './sections/bonds-steps';
import careersbenefits from './sections/careers-benefits';
import careerscv from './sections/careers-cv';
import careershero from './sections/careers-hero';
import careerslocations from './sections/careers-locations';
import careersreviews from './sections/careers-reviews';
import careersslider from './sections/careers-slider';
import clienttabs from './sections/client-tabs';
import cmdtsfactors from './sections/cmdts-factors';
import columnimages from './sections/column-images';
import columns from './sections/columns';
import commerciallicence from './sections/commercial-licence';
import cryptohero from './sections/crypto-hero';
import cryptopopular from './sections/crypto-popular';
import cryptopromo from './sections/crypto-promo';
import culocations from './sections/cu-locations';
import disclaimer from './sections/disclaimer';
import economicciframe from './sections/economic-c-iframe';
import expirationdcontent from './sections/expiration-d-content';
import expirationdhero from './sections/expiration-d-hero';
import faqmainpage from './sections/faq-mainpage';
import faqsinglesection from './sections/faq-single-section';
import featuredacademy from './sections/featured-academy';
import featuredacademyv2 from './sections/featured-academy-v2';
import financialdtabs from './sections/financial-d-tabs';
import Footer from './sections/Footer';
import footerapps from './sections/footer-apps';
import footercta from './sections/footer-cta';
import footerdisclaimer from './sections/footer-disclaimer';
import footerentity from './sections/footer-entity';
import footerlogo from './sections/footer-logo';
import footermenu from './sections/footer-menu';
import footerpayments from './sections/footer-payments';
import footerpress from './sections/footer-press';
import footersocials from './sections/footer-socials';
import forexgraph from './sections/forex-graph';
import forexhours from './sections/forex-hours';
import forexintro from './sections/forex-intro';
import forexpairs from './sections/forex-pairs';
import forexsteps from './sections/forex-steps';
import forextrading from './sections/forex-trading';
import freeshareiframe from './sections/free-share-iframe';
import generalhero from './sections/general-hero';
import generichero from './sections/generic-hero';
import Header from './sections/Header';
import historytimeline from './sections/history-timeline';
import homepageslider from './sections/home-page-slider';
import homepageacademy from './sections/homepage-academy';
import homepagecompanyfeatures from './sections/homepage-company-features';
import homepagediscover from './sections/homepage-discover';
import homepageintegratedtools from './sections/homepage-integrated-tools';
import homepagelicences from './sections/homepage-licences';
import homepageplatforms from './sections/homepage-platforms';
import indicesintro from './sections/indices-intro';
import indicesmajors from './sections/indices-majors';
import indicessteps from './sections/indices-steps';
import instrumenttable from './sections/instrument-table';
import instrumentsicons from './sections/instruments-icons';
import integratedtslide from './sections/integrated-t-slide';
import investetfscards from './sections/invest-et-fs-cards';
import investetfshero from './sections/invest-et-fs-hero';
import investetfstable from './sections/invest-et-fs-table';
import investetfstypes from './sections/invest-et-fs-types';
import investglobalmarkets from './sections/invest-global-markets';
import investhomeetfs from './sections/invest-home-etfs';
import investhomehero from './sections/invest-home-hero';
import investhometools from './sections/invest-home-tools';
import investinstrumentsslider from './sections/invest-instruments-slider';
import investmarketscards from './sections/invest-markets-cards';
import investmarketsfaq from './sections/invest-markets-faq';
import investmarketshero from './sections/invest-markets-hero';
import investmarketsinvest from './sections/invest-markets-invest';
import investmarketsportfolio from './sections/invest-markets-portfolio';
import ipohero from './sections/ipo-hero';
import ipoprevious from './sections/ipo-previous';
import ipoupcoming from './sections/ipo-upcoming';
import landrcountries from './sections/l-and-r-countries';
import landrlicence from './sections/l-and-r-licence';
import latestacademycategories from './sections/latest-academy-categories';
import latestarticles from './sections/latest-articles';
import latestpress from './sections/latest-press';
import legaldocumentsqualityofexecution from './sections/legal-documents-quality-of-execution';
import legaldocumentsview from './sections/legal-documents-view';
import legalkeyinformationdocuments from './sections/legal-key-information-documents';
import licencemodal from './sections/licence-modal';
import marketspagehero from './sections/markets-page-hero';
import marketsshowcase from './sections/markets-show-case';
import multiboxes from './sections/multi-boxes';
import navigationscroll from './sections/navigation-scroll';
import onboardingfaq from './sections/onboarding-faq';
import onboardingfeatures from './sections/onboarding-features';
import onboardinghero from './sections/onboarding-hero';
import overviewheroanalyst from './sections/overview-hero-analyst';
import overviewshowcase from './sections/overview-show-case';
import partnertabs from './sections/partner-tabs';
import partnerscalendar from './sections/partners-calendar';
import partnerscyhero from './sections/partners-cy-hero';
import partnerscymodels from './sections/partners-cy-models';
import partnerscysteps from './sections/partners-cy-steps';
import partnerscywhy from './sections/partners-cy-why';
import partnersdeals from './sections/partners-deals';
import partnersdifference from './sections/partners-difference';
import partnerspayments from './sections/partners-payments';
import partnerssheet from './sections/partners-sheet';
import partnerssoon from './sections/partners-soon';
import partnersstart from './sections/partners-start';
import partnershipmodels from './sections/partnership-models';
import pastwebinars from './sections/past-webinars';
import paymentmethods from './sections/payment-methods';
import pdfviewer from './sections/pdfviewer';
import pepcards from './sections/pep-cards';
import platformsfuture from './sections/platforms-future';
import platformshero from './sections/platforms-hero';
import platformsmt5 from './sections/platforms-mt-5';
import platformswebtrader from './sections/platforms-webtrader';
import podcastsepisodes from './sections/podcasts-episodes';
import podcastshero from './sections/podcasts-hero';
import podcastshosts from './sections/podcasts-hosts';
import pressshowcase from './sections/press-show-case';
import productfeatures from './sections/product-features';
import promotionsboxes from './sections/promotions-boxes';
import promotionshero from './sections/promotions-hero';
import promotionspromos from './sections/promotions-promos';
import referralbox from './sections/referral-box';
import referraldeposit from './sections/referral-deposit';
import referralguide from './sections/referral-guide';
import referraltable from './sections/referral-table';
import regulationstable from './sections/regulations-table';
import reusablecomponent from './sections/reusable-component';
import richeditor from './sections/rich-editor';
import safetyboxes from './sections/safety-boxes';
import safetycontact from './sections/safety-contact';
import safetyhero from './sections/safety-hero';
import safetysteps from './sections/safety-steps';
import sectiondivider from './sections/section-divider';
import sharestabs from './sections/shares-tabs';
import sharestools from './sections/shares-tools';
import sitemapcontent from './sections/sitemap-content';
import smallboxeddisclaimer from './sections/small-boxed-disclaimer';
import socialbox from './sections/social-box';
import sponsorshipshero from './sections/sponsorships-hero';
import sponsorshipsslide from './sections/sponsorships-slide';
import stoxfaq from './sections/stox-faq';
import stoxsolutions from './sections/stox-solutions';
import stoxwhy from './sections/stox-why';
import thematixchart from './sections/thematix-chart';
import thematixfaq from './sections/thematix-faq';
import thematixhero from './sections/thematix-hero';
import thematixintro from './sections/thematix-intro';
import thematixwhytrade from './sections/thematix-why-trade';
import thematxintro from './sections/thematx-intro';
import titleandimage from './sections/title-and-image';
import titledicons from './sections/titled-icons';
import titlediconscolumn from './sections/titled-icons-column';
import tradeawards from './sections/trade-awards';
import tradeherolicences from './sections/trade-hero-licences';
import tradehomeacademy from './sections/trade-home-academy';
import tradehomeacademyv2 from './sections/trade-home-academy-v2';
import tradehomeapps from './sections/trade-home-apps';
import tradehomearticles from './sections/trade-home-articles';
import tradehomeetfs from './sections/trade-home-etfs';
import tradehomefeatures from './sections/trade-home-features';
import tradehomehero from './sections/trade-home-hero';
import tradehomeinstruments from './sections/trade-home-instruments';
import tradehomereviews from './sections/trade-home-reviews';
import tradehomestox from './sections/trade-home-stox';
import tradehomethematix from './sections/trade-home-thematix';
import tradehometools from './sections/trade-home-tools';
import tradeindices from './sections/trade-indices';
import tradingchero from './sections/trading-c-hero';
import tradingcinstruments from './sections/trading-c-instruments';
import tradingconditions from './sections/trading-conditions';
import tradingviewcta from './sections/trading-view-cta';
import tradingviewhero from './sections/trading-view-hero';
import tradingviewmarkets from './sections/trading-view-markets';
import tradingviewsteps from './sections/trading-view-steps';
import tradingviewtools from './sections/trading-view-tools';
import trdcntfeatures from './sections/trd-cnt-features';
import upcomingwebinar from './sections/upcoming-webinar';
import versionfile from './sections/version-file';
import webinarhero from './sections/webinar-hero';
import webinarherosingle from './sections/webinar-hero-single';
import webinarty from './sections/webinar-ty';
import webtraderdescription from './sections/webtrader-description';
import webtraderfeatures from './sections/webtrader-features';
import webtraderhero from './sections/webtrader-hero';
import zachkingsection from './sections/zach-king-section';

const obj = {
	"sections.about-us-community": aboutuscommunity,
	"sections.about-us-global": aboutusglobal,
	"sections.about-us-sponsors": aboutussponsors,
	"sections.about-us-tabs": aboutustabs,
	"sections.academy-featured-lessons": academyfeaturedlessons,
	"sections.academy-hero": academyhero,
	"sections.academy-hero-v2": academyherov2,
	"sections.academy-lessons-v2": academylessonsv2,
	"sections.academy-offers": academyoffers,
	"sections.academy-scopes": academyscopes,
	"sections.academy-show-case": academyshowcase,
	"sections.account-type-hero": accounttypehero,
	"sections.account-type-table": accounttypetable,
	"sections.account-type-vip": accounttypevip,
	"sections.affiliates-hero": affiliateshero,
	"sections.affiliates-model": affiliatesmodel,
	"sections.affiliates-reasons": affiliatesreasons,
	"sections.articles-showcase": articlesshowcase,
	"sections.awards-showcase": awardsshowcase,
	"sections.bonds-intro": bondsintro,
	"sections.bonds-steps": bondssteps,
	"sections.careers-benefits": careersbenefits,
	"sections.careers-cv": careerscv,
	"sections.careers-hero": careershero,
	"sections.careers-locations": careerslocations,
	"sections.careers-reviews": careersreviews,
	"sections.careers-slider": careersslider,
	"sections.client-tabs": clienttabs,
	"sections.cmdts-factors": cmdtsfactors,
	"sections.column-images": columnimages,
	"sections.columns": columns,
	"sections.commercial-licence": commerciallicence,
	"sections.crypto-hero": cryptohero,
	"sections.crypto-popular": cryptopopular,
	"sections.crypto-promo": cryptopromo,
	"sections.cu-locations": culocations,
	"sections.disclaimer": disclaimer,
	"sections.economic-c-iframe": economicciframe,
	"sections.expiration-d-content": expirationdcontent,
	"sections.expiration-d-hero": expirationdhero,
	"sections.faq-mainpage": faqmainpage,
	"sections.faq-single-section": faqsinglesection,
	"sections.featured-academy": featuredacademy,
	"sections.featured-academy-v2": featuredacademyv2,
	"sections.financial-d-tabs": financialdtabs,
	"sections.Footer": Footer,
	"sections.footer-apps": footerapps,
	"sections.footer-cta": footercta,
	"sections.footer-disclaimer": footerdisclaimer,
	"sections.footer-entity": footerentity,
	"sections.footer-logo": footerlogo,
	"sections.footer-menu": footermenu,
	"sections.footer-payments": footerpayments,
	"sections.footer-press": footerpress,
	"sections.footer-socials": footersocials,
	"sections.forex-graph": forexgraph,
	"sections.forex-hours": forexhours,
	"sections.forex-intro": forexintro,
	"sections.forex-pairs": forexpairs,
	"sections.forex-steps": forexsteps,
	"sections.forex-trading": forextrading,
	"sections.free-share-iframe": freeshareiframe,
	"sections.general-hero": generalhero,
	"sections.generic-hero": generichero,
	"sections.Header": Header,
	"sections.history-timeline": historytimeline,
	"sections.home-page-slider": homepageslider,
	"sections.homepage-academy": homepageacademy,
	"sections.homepage-company-features": homepagecompanyfeatures,
	"sections.homepage-discover": homepagediscover,
	"sections.homepage-integrated-tools": homepageintegratedtools,
	"sections.homepage-licences": homepagelicences,
	"sections.homepage-platforms": homepageplatforms,
	"sections.indices-intro": indicesintro,
	"sections.indices-majors": indicesmajors,
	"sections.indices-steps": indicessteps,
	"sections.instrument-table": instrumenttable,
	"sections.instruments-icons": instrumentsicons,
	"sections.integrated-t-slide": integratedtslide,
	"sections.invest-et-fs-cards": investetfscards,
	"sections.invest-et-fs-hero": investetfshero,
	"sections.invest-et-fs-table": investetfstable,
	"sections.invest-et-fs-types": investetfstypes,
	"sections.invest-global-markets": investglobalmarkets,
	"sections.invest-home-etfs": investhomeetfs,
	"sections.invest-home-hero": investhomehero,
	"sections.invest-home-tools": investhometools,
	"sections.invest-instruments-slider": investinstrumentsslider,
	"sections.invest-markets-cards": investmarketscards,
	"sections.invest-markets-faq": investmarketsfaq,
	"sections.invest-markets-hero": investmarketshero,
	"sections.invest-markets-invest": investmarketsinvest,
	"sections.invest-markets-portfolio": investmarketsportfolio,
	"sections.ipo-hero": ipohero,
	"sections.ipo-previous": ipoprevious,
	"sections.ipo-upcoming": ipoupcoming,
	"sections.l-and-r-countries": landrcountries,
	"sections.l-and-r-licence": landrlicence,
	"sections.latest-academy-categories": latestacademycategories,
	"sections.latest-articles": latestarticles,
	"sections.latest-press": latestpress,
	"sections.legal-documents-quality-of-execution": legaldocumentsqualityofexecution,
	"sections.legal-documents-view": legaldocumentsview,
	"sections.legal-key-information-documents": legalkeyinformationdocuments,
	"sections.licence-modal": licencemodal,
	"sections.markets-page-hero": marketspagehero,
	"sections.markets-show-case": marketsshowcase,
	"sections.multi-boxes": multiboxes,
	"sections.navigation-scroll": navigationscroll,
	"sections.onboarding-faq": onboardingfaq,
	"sections.onboarding-features": onboardingfeatures,
	"sections.onboarding-hero": onboardinghero,
	"sections.overview-hero-analyst": overviewheroanalyst,
	"sections.overview-show-case": overviewshowcase,
	"sections.partner-tabs": partnertabs,
	"sections.partners-calendar": partnerscalendar,
	"sections.partners-cy-hero": partnerscyhero,
	"sections.partners-cy-models": partnerscymodels,
	"sections.partners-cy-steps": partnerscysteps,
	"sections.partners-cy-why": partnerscywhy,
	"sections.partners-deals": partnersdeals,
	"sections.partners-difference": partnersdifference,
	"sections.partners-payments": partnerspayments,
	"sections.partners-sheet": partnerssheet,
	"sections.partners-soon": partnerssoon,
	"sections.partners-start": partnersstart,
	"sections.partnership-models": partnershipmodels,
	"sections.past-webinars": pastwebinars,
	"sections.payment-methods": paymentmethods,
	"sections.pdfviewer": pdfviewer,
	"sections.pep-cards": pepcards,
	"sections.platforms-future": platformsfuture,
	"sections.platforms-hero": platformshero,
	"sections.platforms-mt-5": platformsmt5,
	"sections.platforms-webtrader": platformswebtrader,
	"sections.podcasts-episodes": podcastsepisodes,
	"sections.podcasts-hero": podcastshero,
	"sections.podcasts-hosts": podcastshosts,
	"sections.press-show-case": pressshowcase,
	"sections.product-features": productfeatures,
	"sections.promotions-boxes": promotionsboxes,
	"sections.promotions-hero": promotionshero,
	"sections.promotions-promos": promotionspromos,
	"sections.referral-box": referralbox,
	"sections.referral-deposit": referraldeposit,
	"sections.referral-guide": referralguide,
	"sections.referral-table": referraltable,
	"sections.regulations-table": regulationstable,
	"sections.reusable-component": reusablecomponent,
	"sections.rich-editor": richeditor,
	"sections.safety-boxes": safetyboxes,
	"sections.safety-contact": safetycontact,
	"sections.safety-hero": safetyhero,
	"sections.safety-steps": safetysteps,
	"sections.section-divider": sectiondivider,
	"sections.shares-tabs": sharestabs,
	"sections.shares-tools": sharestools,
	"sections.sitemap-content": sitemapcontent,
	"sections.small-boxed-disclaimer": smallboxeddisclaimer,
	"sections.social-box": socialbox,
	"sections.sponsorships-hero": sponsorshipshero,
	"sections.sponsorships-slide": sponsorshipsslide,
	"sections.stox-faq": stoxfaq,
	"sections.stox-solutions": stoxsolutions,
	"sections.stox-why": stoxwhy,
	"sections.thematix-chart": thematixchart,
	"sections.thematix-faq": thematixfaq,
	"sections.thematix-hero": thematixhero,
	"sections.thematix-intro": thematixintro,
	"sections.thematix-why-trade": thematixwhytrade,
	"sections.thematx-intro": thematxintro,
	"sections.title-and-image": titleandimage,
	"sections.titled-icons": titledicons,
	"sections.titled-icons-column": titlediconscolumn,
	"sections.trade-awards": tradeawards,
	"sections.trade-hero-licences": tradeherolicences,
	"sections.trade-home-academy": tradehomeacademy,
	"sections.trade-home-academy-v2": tradehomeacademyv2,
	"sections.trade-home-apps": tradehomeapps,
	"sections.trade-home-articles": tradehomearticles,
	"sections.trade-home-etfs": tradehomeetfs,
	"sections.trade-home-features": tradehomefeatures,
	"sections.trade-home-hero": tradehomehero,
	"sections.trade-home-instruments": tradehomeinstruments,
	"sections.trade-home-reviews": tradehomereviews,
	"sections.trade-home-stox": tradehomestox,
	"sections.trade-home-thematix": tradehomethematix,
	"sections.trade-home-tools": tradehometools,
	"sections.trade-indices": tradeindices,
	"sections.trading-c-hero": tradingchero,
	"sections.trading-c-instruments": tradingcinstruments,
	"sections.trading-conditions": tradingconditions,
	"sections.trading-view-cta": tradingviewcta,
	"sections.trading-view-hero": tradingviewhero,
	"sections.trading-view-markets": tradingviewmarkets,
	"sections.trading-view-steps": tradingviewsteps,
	"sections.trading-view-tools": tradingviewtools,
	"sections.trd-cnt-features": trdcntfeatures,
	"sections.upcoming-webinar": upcomingwebinar,
	"sections.version-file": versionfile,
	"sections.webinar-hero": webinarhero,
	"sections.webinar-hero-single": webinarherosingle,
	"sections.webinar-ty": webinarty,
	"sections.webtrader-description": webtraderdescription,
	"sections.webtrader-features": webtraderfeatures,
	"sections.webtrader-hero": webtraderhero,
	"sections.zach-king-section": zachkingsection
};

export default obj;