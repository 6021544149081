import Button from '@components/elements/Button';
import ColoredBox from '@components/elements/ColoredBox';
import NextImage from '@components/elements/Image';
import List from '@components/elements/List';
import SectionTitle from '@components/elements/SectionTitle';

const ForexSteps = (props) => {
  const data = props.data || props;

  if (!data) {
    return null;
  } return (
      <div className="container">
        <SectionTitle data={data?.section_title} />
        <div className="flex-col lg:flex-row flex justify-between items-center">
          <div className='relative lg:w-[calc(50%-2.222rem)] pb-lg lg:pb-0'>
            <NextImage src={data?.image} height={data?.image?.data?.attributes?.height} width={data?.image?.data?.attributes?.width} />
          </div>
          <div className='lg:w-[calc(50%-2.222rem)]'>
            <div className="flex flex-col">
            <div className='mb-xl'>
              <List type="arrow" list_items={data.list_items} />
            </div>
              <div><ColoredBox data={data.box} /></div>
            </div>
          </div>
        </div>
        <div className='pt-xl lg:pt-[6.67rem]  flex justify-center'>
          <Button data={data.button} />
        </div>
      </div>
  );
};

export default ForexSteps;
