import SectionTitle from '@src/components/elements/SectionTitle';
import styles from './PartnersCyModels.module.css';
import { Tabs } from '@mantine/core';
import { useState } from 'react';
import NextImage from '@src/components/elements/Image';
import HTMLRender from '@src/components/core/HTMLRender';

function TabComponent({ icon, title, subtitle }) {
  return (
    <div className='flex flex-row lg:flex-col justify-between lg:justify-center items-center'>
      <div className='lg:pb-md w-full max-w-[15%] lg:max-w-full'>
        <NextImage src={icon} width={icon?.data?.attributes?.width} height={icon?.data?.attributes?.height} />
      </div>
      <div className='flex flex-col w-full max-w-[75%] lg:max-w-full'>
        <p className='text-secondary text-lg lg:text-2xl font-semibold ltr:text-left rtl:text-right lg:ltr:text-center lg:rtl:text-center mb-xs'>{title}</p>
        <p className='text-sm lg:text-base text-[#737373] ltr:text-left rtl:text-right lg:ltr:text-center lg:rtl:text-center mb-0'>{subtitle}</p>
      </div>
    </div>
  )
}

const PartnersCyModels = (props) => {
  const data = props.data || props;
  const [activeTab, setActiveTab] = useState(0);
  if (!data) return null;

  return (
    <div className='container' id={data?.section_layout?.section_id}>
      <SectionTitle data={data?.section_title} />
      <div className=''>
        <Tabs
          active={activeTab}
          onTabChange={setActiveTab}
          position='center'
          grow
          variant="unstyled"
          classNames={{
            tabsListWrapper: styles.wrapper,
            tabControl: styles.tabControl,
            tabActive: styles.tabActive,
            tabLabel: styles.tabLabel,
            tabsList: styles.tabsList,
          }}>
          {
            data?.tabs?.map((tab, index) => (
              <Tabs.Tab key={index} label={<TabComponent icon={tab?.icon} title={tab?.title} subtitle={tab?.subtitle} />}>
                <div className={`${styles.content} hidden lg:block`}>
                  <HTMLRender data={tab?.content} />
                </div>
              </Tabs.Tab>
            ))
          }
        </Tabs>
      </div>
    </div>
  );
};

export default PartnersCyModels;
