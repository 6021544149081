import './FaqSingleSection.module.css';

const FaqSingleSection = (props) => {
  const data = props.data || props;
  if(!data) return null;
  
  return (
    <>
      <div className='container'>
        FaqSingleSection
      </div>
    </>
  );
};

export default FaqSingleSection;
