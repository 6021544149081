import Accordion from '@components/elements/Accordion';
import SectionTitle from '@components/elements/SectionTitle';
import useSwrHook from '@lib/services/hooks/swr/useSwrHook';
import { IoCalendarSharp } from '@react-icons/all-files/io5/IoCalendarSharp'
import styles from './PastWebinars.module.css'
import dayjs from 'dayjs';
import { useMemo } from 'react';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';

//format date to be readable using dayjs
const advancedFormat = require('dayjs/plugin/advancedFormat')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

const formatDate = (date) => {
  return dayjs(date).utc(true).local().format('DD MMMM YYYY, HH:mm z')
}

const PastWebinars = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable()


  const CURRENTDATE = useMemo(() => new Date().toISOString(), []);
  const { data: pastWebinars, isValidating, error } = useSwrHook('getWebinars', {
    query: {
      populate: ['areas_of_focus'],
      fields: ['date', 'title'],
      sort: ['date:desc'],
      filters: {
        date: {
          $lt: CURRENTDATE
        }
      },
      pagination: {
        start: 0,
        limit: 3,
      },
    }
  });

  if (!pastWebinars) {
    return null;
  }

  return (
    pastWebinars?.data.length > 0 && <div className="container">
      <SectionTitle data={data?.section_title} />
      <ul className='flex flex-col lg:flex-row justify-between items-start'>
        {pastWebinars?.data?.map((webinar, index) => (
          <li key={index} className={`${styles.card}  w-full mb-lg lg:w-[calc(33.33%-10px)]`} >
            <div>
              <div className='flex items-center rtl:text-right' style={{ direction: 'ltr' }}>
                <IoCalendarSharp className='text-grey' />
                <p className='px-xs'>{formatDate(webinar?.attributes?.date)}</p>
              </div>
              <p>{webinar?.attributes?.title}</p>
            </div>
            <div className={`mt-md w-full ${styles.accordion}`}>
              <Accordion type="grey" icon="plus" accordion_items={[{
                title: `${gv('more', 'raw')}`,
                content: `${webinar?.attributes?.areas_of_focus?.map((area) => (
                  area.content
                )).join("")}`
              }]} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PastWebinars;
