import DesktopAcademyLessonsV2 from "./components/DesktopAcademyLessonsV2";
import MobileAcademyLessonsV2 from "./components/MobileAcademyLessonsV2";

const AcademyLessonsV2 = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <>
      {/* desktop version  */}
      <div className="hidden lg:block">
        <DesktopAcademyLessonsV2 data={data} />
      </div>
      {/* mobile version */}
      <div className="lg:hidden">
        <MobileAcademyLessonsV2 data={data} />
      </div>
    </>
  );
};

export default AcademyLessonsV2;
