import styles from './ForexHours.module.css';
import dayjs from 'dayjs';
import SectionTitle from '@components/elements/SectionTitle';
import Button from '@components/elements/Button';
import { useEffect, useState, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import Draggable from 'react-draggable';

const advancedFormat = require('dayjs/plugin/advancedFormat')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
const isBetween = require('dayjs/plugin/isBetween')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(isBetween)


const ForexHours = (props) => {
  const data = props.data || props;
  if(!data) return null;
  
  const [localHour, setLocalHour] = useState("12:00:00");
  const [graphOffset, setGraphOffset] = useState(0);
  const containerRef = useRef(null); 
  const [hourWidth, setHourWidth] = useState(0);
  let  segmentNr = 24;

  if(isMobile){
    segmentNr = 12;
  } 

    // Calculate the difference in hours between local time and stock market timezone
    let now = dayjs();
    let localOffset = now.utcOffset();
    let nyOffset = now.tz('America/New_York').utcOffset();
      
    let diffNY = (localOffset - nyOffset);
    let syOffset = now.tz('Australia/Sydney').utcOffset();
    let diffSY = (localOffset - syOffset);
  
    let tokyoOffset = now.tz('Asia/Tokyo').utcOffset();
    let diffTO = (localOffset - tokyoOffset);
  
    let londonOffset = now.tz('Europe/London').utcOffset();
    let diffLO = (localOffset - londonOffset);

    let timeLondon = dayjs().utc().tz('Europe/London');
    const londonOpen = dayjs(timeLondon.hour(data.london_open.substring(0,2)).minute(data.london_open.substring(3,5)));
    const londonClose = dayjs(timeLondon.hour(data.london_close.substring(0,2)).minute(data.london_close.substring(3,5)));
    const londonHours = londonClose.diff(londonOpen, 'minutes')/60;
    let isLondonOpen =  timeLondon.isBetween(londonOpen, londonClose);

    
    let timeNY = dayjs().utc().tz('America/New_York');
    const nyOpen = dayjs(timeNY.hour(data.ny_open.substring(0,2)).minute(data.ny_open.substring(3,5)));
    const nyClose = dayjs(timeNY.hour(data.ny_close.substring(0,2)).minute(data.ny_close.substring(3,5)));
    const nyHours = nyClose.diff(nyOpen, 'minutes')/60;
    let isNYOpen =  timeNY.isBetween(nyOpen, nyClose);

    let timeSydney = dayjs().utc().tz('Australia/Sydney');
    const sydneyOpen = dayjs(timeSydney.hour(data.sydney_open.substring(0,2)).minute(data.sydney_open.substring(3,5)));
    const sydneyClose = dayjs(timeSydney.hour(data.sydney_close.substring(0,2)).minute(data.sydney_close.substring(3,5)));
    const sydneyHours = sydneyClose.diff(sydneyOpen, 'minutes')/60;
    let isSydneyOpen =  timeSydney.isBetween(sydneyOpen, sydneyClose);

    let timeTokyo = dayjs().utc().tz('Asia/Tokyo');
    const tokyoOpen = dayjs(timeTokyo.hour(data.tokyo_open.substring(0,2)).minute(data.tokyo_open.substring(3,5)));
    const tokyoClose = dayjs(timeTokyo.hour(data.tokyo_close.substring(0,2)).minute(data.tokyo_close.substring(3,5)));
    const tokyoHours = tokyoClose.diff(tokyoOpen, 'minutes')/60;
    let isTokyoOpen =  timeTokyo.isBetween(tokyoOpen, tokyoClose);

    
    function updateSize() {
      setHourWidth(containerRef.current.clientWidth/segmentNr);
    }

    useEffect(() => {
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    
    useEffect(() => {
      const interval = setInterval(() => {
        setLocalHour(dayjs().format('HH:mm:ss'));
        setGraphOffset(((-dayjs.utc(dayjs().diff(dayjs().hour('12').minute('00').second('00'))) / 60000) * hourWidth) / 60)
      }, 1000);
      return () => {
        clearInterval(interval);
      }
      
    }, [localHour]);



    

  return (
    <>
      <div className='container '>
        <SectionTitle data={data?.section_title} />
        <div className='-mt-md lg:-mt-xl'>
          <div className="text-center pb-lg text-secondary relative ">{localHour}</div>
          <div className={` relative overflow-hidden pt-xl ${styles[`graph-container`]}`} ref={containerRef}>
          <Draggable
        axis="x"
        handle=".handle"
        defaultPosition={{x: 0, y: 0}}
        position={{x: 0, y: 0}}
        resetState>
           <div className='handle cursor-grab'>
           <div style={{transform: `translateX(${graphOffset}px)`}} className="text-center z-10 relative justify-center  flex w-[100%]"> 
            {Array(3).fill(0).map((el, firstIndex) =>
            <div className="flex relative" key={firstIndex}>
                <div className='flex'>
                    {Array(24).fill(0).map((el, secondIndex) =>
                      <div key={secondIndex} className={`${styles[`line`]} h-[200px]  relative`} style={{width: `${hourWidth}px`}}>
                        <div className="absolute bottom-full mb-sm left-full -translate-x-1/2 text-grey">
                          {secondIndex+1}
                        </div>
                      </div>
                    )}
                </div>
               {  (firstIndex === 0 && (dayjs().day() == 0 || dayjs().day() == 1  )) 
               || (firstIndex === 1 && (dayjs().day() == 6 || dayjs().day() == 0  )) 
               || (firstIndex === 2 && (dayjs().day() == 5 || dayjs().day() == 6  )) 
                ?
                 <></>
               :
               <div>
                  <div style={{marginLeft: `${diffNY/60*hourWidth}px`, width: `${hourWidth*nyHours}px`, left: `${(nyOpen.minute()/60+nyOpen.hour())*hourWidth}px`}} className={`${styles[`city-segment`]} bottom-0 ${isNYOpen  && firstIndex==1 ? 'bg-secondary text-white' : 'bg-lightBorderGrey text-dark'}`}>
                    New York 
                  </div>
                  <div style={{marginLeft: `${diffLO/60*hourWidth}px`, width: `${hourWidth*londonHours}px`, left: `${(londonOpen.minute()/60+londonOpen.hour())*hourWidth}px`}} className={`${styles[`city-segment`]} bottom-1/4 ${isLondonOpen  && firstIndex==1 ? 'bg-secondary text-white' : 'bg-lightBorderGrey text-dark'}`}>
                    London
                  </div>
                  <div style={{marginLeft: `${diffTO/60*hourWidth}px`, width: `${hourWidth*tokyoHours}px`, left: `${(tokyoOpen.minute()/60+tokyoOpen.hour())*hourWidth}px`}} className={`${styles[`city-segment`]} bottom-1/2 ${isTokyoOpen  && firstIndex==1 ? 'bg-secondary text-white' : 'bg-lightBorderGrey text-dark'}`}>
                    Tokyo
                  </div>
                  <div style={{marginLeft: `${diffSY/60*hourWidth}px`, width: `${hourWidth*sydneyHours}px`, left: `${(sydneyOpen.minute()/60+sydneyOpen.hour())*hourWidth}px`}} className={`${styles[`city-segment`]} bottom-3/4 ${isSydneyOpen && firstIndex==1 ? 'bg-secondary text-white' : 'bg-lightBorderGrey text-dark'}`}>
                    Sydney
                  </div>
                </div>
               }
            </div>
            )} 
              
            </div>
            <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-[2px] h-[250px] bg-secondary"></div>
           </div>
           </Draggable>
          
              
          </div>
          <div>
            
          </div>
        </div>
        <div className="flex justify-center pt-xl">
          <Button data={data.button} />
        </div>
      </div>
    </>
  );
};

export default ForexHours;
