import HTMLRender from '@src/components/core/HTMLRender';
import SectionTitle from '@src/components/elements/SectionTitle';
import './PromotionsBoxes.module.css';

const PromotionsBoxes = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      <SectionTitle data={data?.section_title} />
      <div className='w-full flex flex-wrap justify-between'>
        {
          data?.boxes?.map((box, index) => (
            <div key={index} className='bg-secondary rounded-lg p-sm w-full max-w-[calc(100%/2-0.5rem)] lg:max-w-[calc(100%/5-0.5rem)] flex items-center justify-center text-center mb-md'>
              <HTMLRender data={box?.content} />
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default PromotionsBoxes;
