/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styles from "./LanguageSelector.module.css";
// import Flag from 'react-world-flags'
import { Fragment, useEffect, useRef, useState } from "react";
import Arrow from "@components/elements/Arrow";
import NextLink from "next/link";
import flagMapping from "./flagMapping";
import { shallow } from "zustand/shallow";
import { useStore } from "@lib/store";
import { Menu, Divider, Text, Portal, Title } from "@mantine/core";
import { useRouter } from "next/router";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { GrLanguage } from "@react-icons/all-files/gr/GrLanguage";
import { getStrapiMedia } from "@utils/media";
import useModalHook from "@lib/modals";

import useLocale, { useLocaleObject } from "@lib/store/hooks/useLocale";
import Link from "@src/components/elements/Link";
import NextImage from "@src/components/elements/Image";

const handleLocaleLicenseChange = ({
  currentLocaleLicense,
  destinationLocaleLicense,
  destinationUrl,
  modal,
  router,
}) => {
  const currentLicense = currentLocaleLicense?.company_id;
  const nextLicense = destinationLocaleLicense?.company_id;
  if (currentLicense === nextLicense) {
    router.push(destinationUrl);
  } else {
    modal.open("ChangeLicenseWarning", {
      innerProps: {
        currentLocaleLicense,
        destinationLocaleLicense,
        destinationUrl,
      },
    });
  }

  if (document.body.classList.contains("mobile-open")) {
    document.body.classList.remove("mobile-open");
  }
};

const LanguageSelector = ({
  containerId = undefined,
  langSelectorId = undefined,
  grid = false,
  placement = "end",
  websiteLanguages = [],
  websiteLanguagesPerRegion = [],
  triggerText = "",
  triggerBg = false,
}) => {
  const { gv } = useGlobalVariable();
  const modal = useModalHook();
  const router = useRouter();
  const locale = useLocale();
  const localeObject = useLocaleObject();
  const store = useStore(
    (store) => ({
      localizationsPage: store.localizationsPage,
      localizations: store.localizations,
    }),
    shallow
  );
  // if current language is 'za' , 'ae', 'ae-ar', then do not render the language selector
  if (locale === "za" || locale === "ae" || locale === "ae-ar") {
    return null;
  }

  if (websiteLanguages?.data?.length > 0) {
    return (
      <div className="z-10 inline-flex h-full items-center lg:relative">
        <div className="flex h-full items-center">
          <Menu
            placement={placement}
            position="bottom"
            withinPortal={false}
            gutter={0}
            radius={0}
            classNames={{
              body: styles.menuBody,
              item: styles.menuItem,
              root: styles.menuRoot,
              itemLabel: styles.itemLabel,
            }}
            transition="scale-y"
            transitionDuration={600}
            transitionTimingFunction="cubic-bezier(0.68, -0.6, 0.32, 1.6)"
            control={
              <div id={langSelectorId} className="flex h-full items-center ltr:pl-sm rtl:pr-sm">
                <div
                  className={`flex h-[1.75rem] h-[14px] w-[24px] items-center justify-center text-[1.25rem] ${
                    styles[`language-trigger`]
                  }`}
                >
                  <NextImage
                    src={
                      localeObject?.flag?.data?.attributes?.url ||
                      `https://cdn.capex.com/public/assets/flags/${flagMapping(locale)}.svg`
                    }
                    alt={localeObject?.display_name}
                    title={localeObject?.display_name}
                    priority
                    width={24}
                    height={14}
                  />
                  {/* <GrLanguage /> */}
                </div>
                <div className="ltr:pl-sm ltr:pr-md rtl:pl-md rtl:pr-sm">
                  <Arrow direction="down" />
                </div>
              </div>
            }
          >
            {/* Menu items */}
            <Menu.Item>
              <div className="rtl:text-right">
                <div id={containerId}>
                  {!["en", "ar", "lat", "vn"].includes(locale) && (
                    <h6 className="mb-md border-b border-darkGrey pb-md text-h5 font-normal capitalize text-white rtl:text-right">
                      {" "}
                      {gv("european", "raw")}
                    </h6>
                  )}
                  {!["en", "ar", "lat", "vn"].includes(locale) && (
                    <div className={`${grid ? "grid grid-cols-2 gap-4 lg:grid-cols-3 lg:gap-6" : "grid gap-2"}`}>
                      {websiteLanguagesPerRegion["european"]?.map((languageObject, index) => {
                        const language = languageObject?.attributes;
                        const localizations = store?.localizations?.data || [];
                        const pageLocalized = localizations.find(
                          (currentPageLanguages) => language.locale === currentPageLanguages?.attributes?.locale
                        );
                        const slug = `/${language.locale}${pageLocalized?.attributes?.slug || ""}`;
                        const filteredLocaleUrl = slug?.includes(`/${language.locale}/${language.locale}`)
                          ? slug.replace(`/${language.locale}/${language.locale}/`, `/${language.locale}/`)
                          : slug;
                        return (
                          <div key={index} className={`${styles[`language-item`]}`}>
                            <NextLink href={filteredLocaleUrl} className="pointer-events-none">
                              <a
                                id={`language_menu_item_${language?.locale}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLocaleLicenseChange({
                                    currentLocaleLicense: localeObject,
                                    destinationLocaleLicense: language,
                                    destinationUrl: filteredLocaleUrl,
                                    modal,
                                    router,
                                  });
                                }}
                                className="inline-flex transition duration-200 ease-in-out hover:text-secondaryHover"
                              >
                                <div className="relative h-[14px]  w-[24px] shrink-0">
                                  {language?.flag?.data?.attributes?.url && (
                                    <NextImage
                                      src={getStrapiMedia(language?.flag?.data?.attributes?.url || "")}
                                      alt={language.display_name}
                                      title={language.display_name}
                                      layout="fill"
                                    />
                                  )}
                                </div>
                                {/* .split(' ')[1] */}
                                <span className="inline-block grow align-middle ltr:pl-sm rtl:pr-sm">
                                  {language.display_name}
                                </span>
                              </a>
                            </NextLink>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {localeObject && localeObject?.hide_global_languages !== true && (
                    <>
                      <h6 className="mb-md border-b border-darkGrey pb-md pt-lg text-h5 font-normal capitalize text-white rtl:text-right">
                        {" "}
                        {gv("global", "raw")}
                      </h6>
                      <div className={`${grid ? "grid grid-cols-2 gap-4 lg:grid-cols-3 lg:gap-6" : "grid gap-2"}`}>
                        {websiteLanguagesPerRegion["global"]
                          ?.filter((languageObject) =>
                            ["en", "ar", "lat", "vn"].includes(languageObject?.attributes.locale)
                          )
                          .map((languageObject, index) => {
                            const language = languageObject?.attributes;
                            const localizations = store?.localizations?.data || [];
                            const pageLocalized = localizations.find(
                              (currentPageLanguages) => language.locale === currentPageLanguages?.attributes?.locale
                            );
                            const slug = `/${language.locale}${pageLocalized?.attributes?.slug || ""}`;
                            const filteredLocaleUrl = slug?.includes(`/${language.locale}/${language.locale}`)
                              ? slug.replace(`/${language.locale}/${language.locale}/`, `/${language.locale}/`)
                              : slug;
                            return (
                              <div key={index} className={`${styles[`language-item`]}`}>
                                <NextLink href={filteredLocaleUrl}>
                                  <a
                                    id={`language_menu_item_${language?.locale}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleLocaleLicenseChange({
                                        currentLocaleLicense: localeObject,
                                        destinationLocaleLicense: language,
                                        destinationUrl: filteredLocaleUrl,
                                        modal,
                                        router,
                                      });
                                    }}
                                    className="inline-flex transition  duration-200 ease-in-out hover:text-secondaryHover"
                                  >
                                    <div className="relative h-[14px]  w-[24px] shrink-0">
                                      {language?.flag?.data?.attributes?.url && (
                                        <NextImage
                                          src={getStrapiMedia(language?.flag?.data?.attributes?.url || "")}
                                          alt={language.display_name}
                                          title={language.display_name}
                                          width={24}
                                          height={14}
                                        />
                                      )}
                                    </div>
                                    <span className="inline-block align-middle ltr:pl-sm rtl:pr-sm">
                                      {language.display_name}
                                    </span>
                                  </a>
                                </NextLink>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  )}
                </div>
                {/* {localeObject && localeObject?.hide_global_languages !== true && (
                  <div
                    id="language_selector_disclaimer"
                    className={`mt-md border-t border-darkGrey pt-md ltr:leading-[1.3] rtl:leading-[1.5] ${styles.languageBottom}`}
                  >
                    {gv("language_selector_bottom", { type: "html" })}
                  </div>
                )} */}
              </div>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    );
  }
  return null;
};

export default LanguageSelector;
