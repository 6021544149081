import HTMLRender from '@components/core/HTMLRender';
import { Accordion } from '@mantine/core';
import Arrow from '../Arrow';
import { BiPlus } from '@react-icons/all-files/bi/BiPlus';
import { BiMinus } from '@react-icons/all-files/bi/BiMinus';
import styles from './MantineAccordion.module.css'
import { useState } from 'react';

const MantineAccordion = (props) => {
  const data = props?.data || props;
  // console.log(`mantine accordion data`, data)

  if (!data) return null;

  const items = data?.accordion_items?.map((item, index) => (
    <Accordion.Item label={<HTMLRender data={item?.title} />} key={index}>
      <HTMLRender data={item?.content} />
    </Accordion.Item>
  ));

  function changeStyles(type) {
    switch (type) {
      case "grey":
        return {
          control: styles.grayAccordionControl,
          itemOpened: styles.grayItemOpened,
          label: styles.grayAccordionLabel,
          item: styles?.grayAccordionItem,
          contentInner: styles?.grayContentInner,
          icon: styles.borderAccordionIcon,
        }
      case "border":
        return {
          control: styles.borderAccordionControl,
          itemOpened: styles.borderItemOpened,
          label: styles.borderAccordionLabel,
          icon: styles.borderAccordionIcon,
          contentInner: styles?.contentInner,
        }
      case "shadow":
        return {
          control: styles.shadowAccordionControl,
          itemOpened: styles.shadowItemOpened,
          label: styles.borderAccordionLabel,
          item: styles?.shadowAccordionItem,
          contentInner: styles?.contentInner,
          icon: styles.borderAccordionIcon,
        }
      default:
        return {
          control: styles.borderAccordionControl,
          itemOpened: styles.borderItemOpened,
          label: styles.borderAccordionLabel,
          icon: styles.borderAccordionIcon,
          contentInner: styles?.contentInner,
        }
    }
  }

  function changeIcon(icon) {
    switch (icon) {
      case "arrow":
        return <Arrow direction="down" />
      case "plus":
        return <BiPlus />;
      default:
        return null;
    }
  }


  return (
    <Accordion
      styles={{ icon: { color: '#0995DD' } }}
      classNames={changeStyles(data?.type)}
      variant="unstyled"
      transitionDuration={500}
      icon={changeIcon(data.icon)}
      iconPosition={data?.iconPosition || 'right'}
      multiple={data?.multiple || false}
      initialItem={data?.initialItem || null}
    >
      {items}
    </Accordion>
  );
};

export default MantineAccordion;
