import useSetProductType from "@lib/store/hooks/useActiveProductType";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { Children } from "react";
import { useStore } from "@lib/store";
import styles from "../../ProductTabs.module.css";
import ProductTab from "./ProductTab";

export default function ProductTypeTabs() {
  const { changeProductType } = useSetProductType();
  const { gv } = useGlobalVariable();

  const productTypes = useStore((state) => state.productTypes);
  const currentProductTypeKey = useStore((state) => state.currentProductTypeKey);

  const sortedProductTypes = productTypes.sort((a, b) => {
    const orderA = a.attributes?.order || 9999; // Default value to 9999 if "order" key does not exist
    const orderB = b.attributes?.order || 9999; // Default value to 9999 if "order" key does not exist

    return orderA - orderB;
  });

  return (
    <div id="prod_types_container" className={styles[`product-types-inner`]}>
      {Children.toArray(
        sortedProductTypes?.map((product) =>
          product?.attributes?.availability_status === "soon" ? (
            <span
              id={`prod_type_${product?.attributes?.product_type}`}
              className={`pointer-events-none relative ${product.attributes.product_type === currentProductTypeKey ? styles.active : ``
                }`}
            >
              <span className="relative">
                <span className="opacity-80">{product?.attributes?.website_name}</span>
                <div className="soon-tag absolute -top-[0.25em] rounded-sm bg-primary p-[2px] text-[10px] leading-[1] text-white ltr:-right-[1.75em] rtl:-left-[1.75em]">
                  {gv("soon")}
                </div>
              </span>
            </span>
          ) : (
            <ProductTab
              id={`prod_type_${product?.attributes?.product_type}`}
              handleSwitch={changeProductType}
              product={product}
              productType={currentProductTypeKey}
              styles={styles}
            />
          )
        )
      )}
    </div>
  );
}
