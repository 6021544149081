import { useState, useCallback } from 'react';
import styles from './Accordion.module.css';
import Link from '@components/elements/Link';
import HTMLRender from '@components/core/HTMLRender'

const Accordion = (props) => {
  const data = props.data || props;
  const acc_items = data?.accordion_items
  const [activeIndex, setActiveIndex] = useState(parseInt(props.opened ? props.opened : data?.opened));
  const [height, setHeight] = useState(0);
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);
  return (
    <>
        <div>
          {acc_items?.map((item, index) => {
            const isActive = index === activeIndex;
            return (
              <div
                key={index}
                className={`
                  ${styles['accordion-item']}
                  ${styles[`${data.icon}`]} ${styles[`${data.type}`]}
                  ${isActive ? `${styles.active}` : ''}
                  ${data.center && `${styles['center']}`}
                  `}
              >
                <div
                  className={`${styles[`accordion-title`]} ${data.icon != 'none' ? `${styles['with-icon']}` : ''}`}
                  onClick={isActive ? () => setActiveIndex() : () => setActiveIndex(index)}
                >
                  <p className="mb-0"><HTMLRender data={item.title} /></p>
                  <div className={`${styles[`accordion-icon`]}`}></div>
                </div>
                <div
                  style={{ maxHeight: `${isActive ? `${height}px` : '0px'}` }}
                  className={`${styles['accordion-content']}`}
                >
                  <div
                    ref={isActive ? measuredRef : null}
                    className={`${styles[`accordion-content-inner`]} ${data.fullContent ? 'p-0' : 'p-md pt-0'}`}
                  >
                    <ul>
                      {item.sublinks
                        ? item.sublinks.map((item, index) => (
                          <li key={index}>
                            {item.link ? (
                              <div className="py-xs">
                                <Link color="grey" arrow="none" text={item.name} link={item.link} />
                                {item.subItems ? (
                                  <ul className="pt-xs">
                                    {item.subItems.map((subItem, index) => (
                                      <li key={index} className="py-xs text-primary text-base last:pb-0">
                                        <Link arrow="left" color="grey" text={subItem.name} link={subItem.href} />
                                      </li>
                                    ))}
                                  </ul>
                                ) : null}
                              </div>
                            ) : (
                                <div className="py-xs"> <HTMLRender data={item.title} /> </div>
                            )}
                          </li>
                        ))
                        : <HTMLRender data={item.content} />}
                      {item?.children?.map((item, index) => (
                        <li key={index} className="pb-0">
                          <div className='py-xs'>
                            <Link color="grey" link={item.value} text={item?.title} />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
    </>
  );
}
export default Accordion;
