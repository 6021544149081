import "./SmallBoxedDisclaimer.module.css";
import HTMLRender from "@src/components/core/HTMLRender";

const SmallBoxedDisclaimer = ({ text = "" }) => (
  <div className="container">
    <div className="bg-[#f6f6f6] p-md rounded-lg md:py-[2rem] md:px-[5rem] text-center">
      <HTMLRender data={text} />
    </div>
  </div>
);

export default SmallBoxedDisclaimer;
