import styles from './ThematixIntro.module.css';
import SectionTitle from '@components/elements/SectionTitle';
import Button from '@components/elements/Button';

const ThematixIntro = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <>
      <div className='container'>
        {data?.section_title &&
          <SectionTitle data={data?.section_title} />
        }
        {data?.tagline &&
          <h2 className="text-center py-xl m-0 ltr:font-gilroy rtl:font-dubai ltr:font-black rtl:font-bold">{data?.tagline}</h2>
        }

        <div className="flex-col md:flex-row flex justify-center items-center">
          {data?.button1 &&
            <div className="pb-lg md:px-md md:pb-0">
              <Button type={data?.button1?.type} size={data?.button1?.size} arrow={data?.button1?.arrow} label={data?.button1?.label} link={data?.button1?.link} />
            </div>
          }
          {data?.button2 &&
            <div className="md:px-md">
              <Button type={data?.button2?.type} size={data?.button2?.size} arrow={data?.button2?.arrow} label={data?.button2?.label} link={data?.button2?.link} />
            </div>
          }
        </div>

      </div>
    </>
  );
};

export default ThematixIntro;
