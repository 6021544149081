import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './GeneralHero.module.css'


const GeneralHero = (props) => {
  const data = props.data || props;

  if (!data) {
    return null;
  }
  return (
    <div className="container">
      {data?.image?.data &&
        <div className="max-w-[70%] lg:max-w-[30rem] m-auto w-full h-full flex justify-center pt-lg lg:pt-xl">
          <NextImage src={data?.image} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
        </div>
      }
      <div className={`mt-lg text-dark ${styles.title}`}>
        <SectionTitle data={data.section_title} />
      </div>
    </div>
  );
};

export default GeneralHero;
