import styles from './CareersLocations.module.css';
import SectionTitle from '@components/elements/SectionTitle';
import IframeResizer from 'iframe-resizer-react'


const CareersLocations = (props) => {
  const data = props.data || props;

  if (!data) return null;

  return (
    <>
      <div className='container'>
        <SectionTitle data={data?.section_title} />
        <div className="mb-lg w-full min-h-[20rem]">
          <div class={`${styles.iframeContainer}`}>
            <IframeResizer
              src="https://portal.dynamicsats.com/JobListing/347022b3-2e4e-48d2-9dac-9f7d78675080"
              allow="encrypted-media"
              style={{ minWidth: '100%' }}
              allowfullscreen
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CareersLocations;
