import { calculateRevalidateTime, fetchPaginationArticles, validateContext } from "@utils/staticPageProps";
import { getLayoutSharedData, getUrlPatterns } from "@utils/api";
import { memoize, memoizeTimeout } from "@utils/memoize";
import { PHASE_PRODUCTION_BUILD } from "next/constants";
import fs from 'fs/promises';
import find from "lodash/find";
import path from 'path';
import Layout from "@src/layouts";
import qs from "qs";
import { useRouter } from "next/router";
import Seo from "@components/elements/Seo";


const api = {
  cache: {
    get: async ({ slug, locale }) => {
      try {
        const data = await fs.readFile(path.join(process.cwd(), `./cache/page-props/${locale}-pages.db`))
        const pages = JSON.parse(data)
        const page = find(pages, (p) => p.params.slug === slug)
        // console.log('found page inside get', page)
        if (page?.params?.pageProps) {
          return page?.params?.pageProps
        }
        return false
      } catch (err) {
        return false;
      }
    },
    set: async ({ pages, locale }) => {
      // create dir /cached-props if doesn't exist
      const dirExists = await fs.readdir(path.join(process.cwd(), `./cache/page-props`)).catch((err) => false);
      if (!dirExists) await fs.mkdir(path.join(process.cwd(), `./cache/page-props`), { recursive: true });
      return await fs.writeFile(
        path.join(process.cwd(), `./cache/page-props/${locale}-pages.db`),
        JSON.stringify(pages)
      )
    },
  },
}

export const memoizedPatterns = memoizeTimeout(getUrlPatterns, 10 * 6 * 1000);
export const memoizedSharedData = memoize(getLayoutSharedData);

export async function getStaticPaths(locale) {
  if (typeof locale !== "string") {
    return {
      paths: [], // indicates that no page needs be created at build time
      fallback: "blocking", // indicates the type of fallback
    };
  }
  if (process?.env?.NEXT_PUBLIC_FAST_BUILD === "true" || process?.env?.NEXT_PUBLIC_FAST_BUILD === true) {
    return {
      paths: [],
      fallback: "blocking",
    };
  }
  // const _cacheUrlPatterns = await memoizedPatterns(locale);
  // const _cacheSharedData = await memoizedSharedData(locale);

  let pathsWithProps;
  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD && false) {
    // read from folder ./build-data/${locale}.json
    const data = await fs.readFile(path.join(process.cwd(), `./build-data/${locale}-pages.json`));
    pathsWithProps = JSON.parse(data);
  } else {
    pathsWithProps = await fetch(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/pages/path?locale=${locale}`)
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return [];
      });
  }


  // paths without props to pass to the getStaticProps
  const onlyPaths = pathsWithProps.map((slug) => ({
    params: {
      slug: slug?.params?.page[0].split("/").filter((p) => p !== ""),
    },
  }));

  // paths with props to store in the cache file
  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD && false) {
    pathsWithProps = pathsWithProps.map((slug) => {
      let tempSlug = slug?.params?.page[0].split("/").filter((p) => p !== "");
      tempSlug = tempSlug.filter((el) => el !== "undefined");
      tempSlug = tempSlug === undefined ? "" : tempSlug.join("/");

      return {
        params: {
          slug: tempSlug,
          id: slug?.params.id,
          pageProps: slug?.params?.pageProps,
        },
      };
    });

    await api.cache.set({ pages: pathsWithProps, locale });
  }

  return { paths: onlyPaths, fallback: "blocking" };

}

export async function getStaticProps(context) {
  const validationResponse = await validateContext(context);
  if (validationResponse.redirect) {
    return validationResponse;
  }
  const { locale, direction, htmlLang, slug, urlPatterns } = validationResponse;

  const query = qs.stringify({
    filters: {
      page: urlPatterns.page,
      reg: urlPatterns.r,
    },
    locale,
  });

  let layoutSharedData;
  
  let pageData;
  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD && false) {
    pageData = await api.cache.get({ slug, locale });
  }
  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD){
    // read from file ./build-data/${locale}-layout-shared-data.json
    layoutSharedData = await fs.readFile(path.join(process.cwd(), `./build-data/${locale}-layout-shared-data.json`));
    try {
      layoutSharedData = JSON.parse(layoutSharedData);
    } catch (error) {
      console.error("Error parsing layout shared data:", error);
      return [];
    }
  } else {
    layoutSharedData = await memoizedSharedData(locale);
  }
  const currentLanguage = layoutSharedData?.currentLanguage?.data?.[0]?.attributes;
  if (!pageData) {
    pageData = await fetch(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/page?${query}`)
      .then((res) => res.json())
      .catch(() => null);
  }

  if (!pageData || pageData?.error) return { redirect: { destination: `/${locale}`, permanent: false } };

  const { page } = pageData;
  const { props, ...otherPageProps } = page;

  let paginationProps = {};
  if (urlPatterns?.page?.pagination) {
    const paginationResult = await fetchPaginationArticles(locale, slug, urlPatterns.page, props.data.attributes);
    if (paginationResult.redirect) {
      return paginationResult;
    }
    paginationProps = paginationResult;
  }
  if (props) {
    const revalidate = calculateRevalidateTime(urlPatterns, props?.data?.attributes, paginationProps,otherPageProps);
    return {
      props: {
        page: {
          ...otherPageProps,
          ...props.data.attributes,
          paginationProps,
          id: props.data.id,
          fallbackVariables: page?.props?.fallbackVariables || [12312312312],
          globalSeo: layoutSharedData.globalData?.data?.website?.seo || {},
          fullSlug: `https://capex.com/${locale}${slug?.length > 0 ? `/${slug}` : ""}`,
        },
        app: {
          locale,
          direction,
          languages: currentLanguage,
          htmlLang,
        },
        fallback: {
          layoutSharedData
        },
      },
      ...(revalidate && { revalidate }),
    };
  } else {
    return { redirect: { destination: `/${locale}`, permanent: false } };
  }
}

function DynamicPage({ page, app, fallback }) {
  const router = useRouter();
  if (router.isFallback) return false;
  return (
    <>
      <Seo page={page} app={app} />
      <Layout pageType={page?.layout_type || "page"} pageData={page || {}} fallback={fallback}/>
    </>
  );
}

export default DynamicPage;
