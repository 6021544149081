import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import useActiveProductType from "@lib/store/hooks/useActiveProductType";
import useLocale, { useLocaleObject } from "@lib/store/hooks/useLocale";
import { Select, Tabs } from "@mantine/core";
import Arrow from "@src/components/elements/Arrow";
import { Children, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "../../TradingCInstruments.module.css";
import InstrumentMT5Row from "../InstrumentMT5Row";
import { useStore } from "@lib/store";

const ParseCompanyId = (value) => value?.split("_")?.[0] || null;

export default function PlatformMT5({ platform }) {
  const currentProductTypeKey = useStore((state) => state.currentProductTypeKey);
  const [categories, setCategories] = useState([]);
  const [categoriesTranslated, setCategoriesTranslated] = useState([]);
  const { company_id } = useLocaleObject();
  const COMPANY = ParseCompanyId(company_id);
  const locale = useLocale();
  const [category, setCategory] = useState(0);
  const { data: platformMT5, isValidating } = useSwrHook("getDealingDataPlatformMT5", {
    query: {
      licence: COMPANY,
      type: "mt5",
      ...(category && { category: categories[category] }),
    },
    locale,
  });

  useEffect(() => {
    if (platformMT5 && categories.length === 0) {
      setCategories(Object.keys(platformMT5.categories[COMPANY]));
      setCategoriesTranslated(Object.values(platformMT5.categories[COMPANY]));
      setCategory(0);
    }
  }, [platformMT5]);

  const TableHeaders = useMemo(() => {
    try {
      const HeaderKeys = Object.keys(
        platformMT5?.data?.filter((el) => el.licence === COMPANY)?.[0].json[categories[category]]?.[0]
      ).filter((el) => el !== "Instrument");
      HeaderKeys.unshift("Instrument");
      return [
        Object.assign(
          {},
          ...HeaderKeys.map((el) => ({
            [el]: el,
          }))
        ),
      ];
    } catch (err) {
      return [];
    }
  }, [COMPANY, categories, category, isValidating, platformMT5]);

  return (
    <>
      <div className="hidden lg:block">
        {categories.length && (
          <Tabs
            value={category}
            onTabChange={setCategory}
            variant="unstyled"
            classNames={{
              tabsList: styles.tabLists,
              tabLabel: styles.tabLabel,
              tabActive: styles.tabActive,
              tabControl: styles.tabControl,
            }}
          >
            {categories.map((value, index) => (
              <Tabs.Tab key={index} label={categoriesTranslated[index]} />
            ))}
            <div className="mt-md flex w-full items-center justify-between bg-[#fafafa] text-sm font-bold">
              {Children.toArray(
                Array.isArray(TableHeaders) &&
                  TableHeaders?.map((row) => (
                    <InstrumentMT5Row
                      cssParent="flex justify-between items-center w-full text-sm border-b border-b-[#eeeff2]"
                      productType={currentProductTypeKey}
                      platform={platform}
                      category={categories[category]}
                      headers={TableHeaders}
                      cssClass="instrumentStyles"
                      rowData={row}
                    />
                  ))
              )}
            </div>
            {Children.toArray(
              platformMT5?.data
                ?.filter((el) => el.licence === COMPANY)?.[0]
                .json[categories[category]]?.map((row) => (
                  <InstrumentMT5Row
                    cssParent="flex justify-between items-center w-full text-sm border-b border-b-[#eeeff2]"
                    productType={currentProductTypeKey}
                    platform={platform}
                    category={categories[category]}
                    headers={TableHeaders}
                    cssClass="instrumentStyles"
                    rowData={row}
                  />
                ))
            )}
          </Tabs>
        )}
      </div>
      <div className="block text-secondary  lg:hidden">
        <Select
          data={categories?.map((name, index) => ({
            value: index.toString(),
            label: platformMT5?.categories[COMPANY][name],
          }))}
          placeholder="Select Category"
          value={category.toString()}
          onChange={setCategory}
          rightSection={<Arrow direction="down" />}
          rightSectionWidth={30}
          dropdownPosition="bottom"
          maxDropdownHeight="100%"
          classNames={{
            label: styles.selectLabel,
            input: styles.selectInput,
            root: styles.mobileSelectRoot,
            dropdown: styles.selectDropdown,
            rightSection: styles.rightSectionMobile,
          }}
        />

        <div className="w-full">
          <div className="flex w-full">
            <div className="mt-md flex justify-between bg-[#fafafa]  font-bold">
              {Array.isArray(TableHeaders) &&
                TableHeaders?.map((row, index) => (
                  <InstrumentMT5Row
                    key={index}
                    headers={TableHeaders}
                    category={categories[category]}
                    cssParent="flex flex-col justify-between items-center w-full"
                    productType={currentProductTypeKey}
                    platform={platform}
                    cssClass="instrumentStylesMobile"
                    rowData={row}
                  />
                ))}
            </div>
            <div className="flex w-full overflow-x-scroll">
              {platformMT5?.data
                ?.filter((el) => el.licence === COMPANY)?.[0]
                .json[categories[category]]?.map((row, index) => (
                  <InstrumentMT5Row
                    key={index + category}
                    cssParent="mt-md flex-col justify-between items-center w-full"
                    productType={currentProductTypeKey}
                    category={categories[category]}
                    platform={platform}
                    headers={TableHeaders}
                    cssClass="instrumentStylesMobile"
                    rowData={row}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
