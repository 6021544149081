import HTMLRender from '@components/core/HTMLRender';
import styles from './CryptoHero.module.css';
import NextImage from '@components/elements/Image';
import Button from '@components/elements/Button';

const CryptoHero = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='relative overflow-hidden pt-lg lg:pb-[10rem] lg:-mb-[10rem] lg:pt-[20vh]'>
      <div className='container'>
        <div className="flex rtl:lg:flex-row-reverse flex-col lg:flex-row justify-between">
          <div className='flex-[0_0_70%] relative z-10 text-left'>
            <h1 className='m-0 ltr:font-gilroy rtl:font-dubai ltr:font-black rtl:font-bold text-[2em] lg:text-[4.2em] 2xl:text-[4.2em] uppercase'>
              {data.yellow_title &&
                <span className="block text-[#C0DF00] lg:text-cryptoYellow text-outer-border-fixed lg:text-outer-border">{data.yellow_title}</span>
              }
              {data.yellow_title &&
                <span className="block">{data.black_title}</span>
              }
            </h1>
            {data.subtitle &&
              <div className={`pt-md lg:pt-xl ${styles.subtitle} font-semibold rtl:flex rtl:flex-row-reverse`}>
                <h2 className='max-w-[10em] lg:max-w-[14em] leading-[1.2] text-[1.2em] lg:text-h4 2xl:text-h3 font-bold lg:font-medium'>{data.subtitle}</h2>
              </div>
            }
            <div className="flex lg:hidden justify-center pb-lg pt-[5%]  mt-[100%] sm:mt-[16rem] md:mt-[16rem]" >
              <Button data={data.mobile_button} />
            </div>
            <div className='p-xl lg:p-[2.4em] 2xl:p-xl bg-cryptoYellow rounded-[1.1rem] mt-0 lg:mt-[10vh]'>
              {data?.values_title &&
                <h2 className='text-center ltr:lg:text-left rtl:lg:text-right  text-[1.8em] lg:text-[2.2em] 2xl:text-[2.88em] font-bold mb-[2rem] 2xl:mb-xl'>{data.values_title}</h2>
              }
              <div className="flex flex-col lg:flex-row">
                {data?.values_column?.map((item, index) => (
                  <div key={index} className='grow basis-0 border-b lg:border-b-0 lg:ltr:border-r lg:rtl:border-l last:border-none border-[#576024] py-lg first:pt-0 last:pb-0 lg:py-0 px-0 lg:px-md text-center flex flex-col'>
                    {item.title &&
                      <h3>{item.title}</h3>
                    }
                    {item.content &&
                      <div className={styles.columnText}>
                        <HTMLRender data={item.content} />
                      </div>
                    }
                  </div>
                ))}
              </div>


            </div>
          </div>
          <div className='block lg:hidden absolute top-0 w-[170%] -right-[40%]  sm:w-[35rem] sm:-right-[2rem]'>
            <NextImage src="https://cdn.capex.com/uploads/couch_crypto_mobile_2394548adc/couch_crypto_mobile_2394548adc.png" width={1339} height={1282} />
            <div className="absolute w-[18%] h-[23%] top-[38%] right-[31.2%] glowing-phone opacity-100">
              <NextImage src="https://cdn.capex.com/uploads/glowing_phone_1596f04ed5/glowing_phone_1596f04ed5.png" layout="fill" />
            </div>
            <div className="absolute w-[18%] h-[18%] top-[43%] right-[32.4%] rotate-[29deg] opacity-60">
              <NextImage src="https://cdn.capex.com/uploads/crypto_scroll_f248a5efaf/crypto_scroll_f248a5efaf.gif" layout="fill" />
            </div>
          </div>
          <div className='hidden lg:flex flex-[0_0_30%]'>
            <div className={styles.rightShape}>
              <div className={`absolute w-[50%] 2xl:w-[55%] -right-[2rem]  bottom-[32%] ${styles.mainImage}`}>
                <NextImage src="https://cdn.capex.com/uploads/couch_crypto_bb5f1c9674/couch_crypto_bb5f1c9674.png" width={1920} height={1353} />
                <div className="absolute w-[21%] h-[27%] top-[20%] right-[29.8%] glowing-phone opacity-100">
                  <NextImage src="https://cdn.capex.com/uploads/glowing_phone_1596f04ed5/glowing_phone_1596f04ed5.png" layout="fill" />
                </div>
                <div className="absolute w-[16%] h-[22%] top-[24%] right-[33.4%] rotate-[29deg] opacity-60">
                  <NextImage src="https://cdn.capex.com/uploads/crypto_scroll_f248a5efaf/crypto_scroll_f248a5efaf.gif" layout="fill" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CryptoHero;
