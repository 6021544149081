import HTMLRender from '@components/core/HTMLRender';
import GenericSlider from '@components/custom/GenericSlider';
import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import { SwiperSlide } from 'swiper/react';
import styles from './PartnersSheet.module.css';

const PartnersSheet = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable()

  if (!data) return null;

  return (
    <div className="container">
      <div
        className={`mb-lg w-full md:max-w-[30%] ${styles.content}`}
      >
        <HTMLRender data={data?.section_title?.subtitle} />
      </div>
      <div className="hidden w-full flex-wrap justify-between md:flex">
        {data?.fact_sheets.map((sheet, index) => (
          <div
            key={index}
            className={`${styles.group}  mb-lg border-[##d3d3d3] hover:bg-primary relative w-full max-w-[calc(100%/3-1rem)] cursor-pointer flex-wrap rounded-lg border bg-white p-[2.22rem] hover:shadow-lg flex flex-col justify-between`}
          >
            <a
              className="absolute top-0 left-0 z-10 h-full w-full"
              href={sheet?.document?.data?.attributes?.url}
              download
              target="_blank"
            ></a>
            <div className="flex w-full justify-between">
              <div className={`${styles.sheetContent}`}>
                <HTMLRender data={sheet?.content} />
              </div>
              <NextImage
                src={data?.pdf_icon}
                width={data?.pdf_icon?.data?.attributes?.width}
                height={data?.pdf_icon?.data?.attributes?.height}
              />
            </div>
            <div
              className={`${styles.button} mt-xl flex items-center justify-end`}
            >
              <div className={styles.buttonArrow} />
              <Button
                type="white-borderless"
                size="sm"
                label={gv('download')}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="md:hidden">
        <GenericSlider
          autoplay
          slidesPerView={1.3}
          spaceBetween={15}
          pagination
        >
          {data?.fact_sheets.map((sheet, index) => (
            <SwiperSlide>
              <div
                key={index}
                className={`${styles.group} mb-lg border-[##d3d3d3] p-md hover:bg-primary relative w-full cursor-pointer flex-wrap rounded-lg border bg-white hover:shadow-lg`}
              >
                <a
                  className="absolute top-0 left-0 z-10 h-full w-full"
                  href={sheet?.document?.data?.attributes?.url}
                  download
                  target="_blank"
                ></a>
                <div className="flex w-full justify-between">
                  <div className={`${styles.sheetContent}`}>
                    <HTMLRender data={sheet?.content} />
                  </div>
                  <NextImage
                    src={data?.pdf_icon}
                    width={data?.pdf_icon?.data?.attributes?.width}
                    height={
                      data?.pdf_icon?.data?.attributes?.height
                    }
                  />
                </div>
                <div
                  className={`${styles.button} mt-xl flex items-center justify-end`}
                >
                  <div className={styles.buttonArrow} />
                  <Button
                    type="white-borderless"
                    size="sm"
                    label={gv('download')}
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </GenericSlider>
      </div>
    </div>
  );
};

export default PartnersSheet;
