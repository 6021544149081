import styles from './PlatformsFuture.module.css';
import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';
import Button from '@components/elements/Button';
import { Controller, Scene } from "react-scrollmagic";
import { Tween } from "react-gsap";

const PlatformsFuture = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <Controller>
      <div className={`${styles[`future-section`]} relative lg:pt-[3%] xl:pt-[7%]`}>
        <Scene triggerHook="1" duration={1500}>
          <Tween
            from={{ opacity: 0, transform: "scale(0.6, 0.6)" }}
            to={{ opacity: 1, transform: "scale(1,1)" }}
          >
            <div className='absolute bottom-0 w-full left-0'>
              <div className={`${styles[`image-wrapper`]} absolute bottom-0 left-1/2 -translate-x-1/2 w-full lg:w-auto`}>
                <NextImage src={data?.image} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
              </div>
            </div>
          </Tween>
        </Scene>
        <div className="container relative">
          {data?.future_text &&
            <div>
              <HTMLRender data={data?.future_text} />
            </div>
          }
          {data?.button &&
            <div className="pt-lg pb-3xl flex justify-center">
              <Button type={data?.button?.type} size={data?.button?.size} arrow={data?.button?.arrow} label={data?.button?.label} link={data?.button?.link} />
            </div>
          }
        </div>
      </div>
    </Controller>
  );
};

export default PlatformsFuture;