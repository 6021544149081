import styles from './IPOCard.module.css';
import Link from '@components/elements/Link';
import { IoCalendarSharp } from '@react-icons/all-files/io5/IoCalendarSharp';
import { ImHourGlass } from '@react-icons/all-files/im/ImHourGlass';
import { GiPriceTag } from '@react-icons/all-files/gi/GiPriceTag';
import NextImage from '@components/elements/Image';
import HTMLRender from '@components/core/HTMLRender';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';

const IPOCard = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable()
  if (!data) {
    return null;
  }

  const STATUS = {
    available: gv('ipo_status_available', 'raw'),
    upcoming: gv('ipo_status_upcoming', 'raw'),
  }

  return (
    <>
      {/* desktop version */}
      <div className={`hidden lg:flex ${styles[`card-inner`]} ltr:md:max-w-[40%] rtl:md:max-w-[45%] group`}>
        <div className={`${styles[`link-wrapper`]} hover:shadow-inner`}>
          <Link href={data?.slug} />
        </div>
        <div className={`${styles[`next-ipo`]}`}>{gv('next_ipo', 'raw')}</div>
        <div className={`${styles[`left-side`]}`}>
          <div className="w-full">
            <NextImage src={data?.image} width={data?.image?.width || data?.image?.data?.attributes?.width} height={data?.image?.height || data?.image?.data?.attributes?.height} />
          </div>
        </div>
        <div className={`px-sm grow max-w-[60%]`}>
          <h4 className="text-secondary m-0 text-[1.25em] pt-sm">{data?.name}</h4>
          <div className={styles.description}><HTMLRender data={data?.industry} /></div>
          <ul className={`${styles.list} py-md`}>
            <li>
              <ImHourGlass className={`${styles.icon}`} />
              <span>{gv('status', 'raw')} </span>
              {STATUS[`${data?.status}`] || '-'}
            </li>
            <li>
              <IoCalendarSharp className={`${styles.icon}`} />
              <span>{gv('ipo_date', 'raw')} </span>
              {data?.ipo_date || '-'}
            </li>
            <li>
              <GiPriceTag className={`${styles.icon}`} />
              <span>{gv('ipo_price', 'raw')} </span>
              {data?.price || '-'}
            </li>
          </ul>
          <Link arrow="right" text={gv('read_full_ipo_report', 'raw')} link={data?.slug} />
        </div>
      </div>
      {/* mobile version */}
      <div className={`${styles[`mobile-card-inner`]} flex lg:hidden`}>
        <div className={`${styles[`link-wrapper`]} hover:shadow-inner`}>
          <Link href={data?.slug} />
        </div>
        <div className={`${styles[`next-ipo`]}`}>{gv('next_ipo', 'raw')}</div>
        <div className={`${styles[`mobile-top`]}`}>
          <div className={`${styles['mobile-left-side']}`}>
            <div className="w-full">
              <NextImage src={data?.image} width={data?.image?.width || data?.image?.data?.attributes?.width} height={data?.image?.height || data?.image?.data?.attributes?.height} />
            </div>
          </div>
          <div className={`${styles['mobile-right-side']}`}>
            <div>
              <h4 className="text-secondary m-0 text-[1.25em]">{data?.name}</h4>
              <div className={styles.description}><HTMLRender data={data?.industry} /></div>
            </div>
            <div className="pt-lg">
              <Link arrow="right" text={gv('read_full_ipo_report', 'raw')} link={data?.slug} />
            </div>
          </div>
        </div>
        <div className={`px-sm`}>
          <ul className={`${styles['mobile-list']} pt-md pb-0 w-full`}>
            <li>
              <ImHourGlass className={`${styles.icon}`} />
              <span>{gv('status', 'raw')} </span>
              {STATUS[`${data?.status}`] || '-'}
            </li>
            <li>
              <IoCalendarSharp className={`${styles.icon}`} />
              <span>{gv('ipo_date', 'raw')} </span>
              {data?.ipo_date || '-'}
            </li>
            <li>
              <GiPriceTag className={`${styles.icon}`} />
              <span>{gv('ipo_price', 'raw')} </span>
              {data?.price || '-'}
            </li>
          </ul>
        </div>
      </div>
    </>
  )
};

export default IPOCard;
