import HTMLRender from "@components/core/HTMLRender";
import GenericSlider from "@components/custom/GenericSlider";
import Button from "@components/elements/Button";
import NextImage from "@components/elements/Image";
import instrumentsApi from "@utils/instrumentsApi";
import NextLink from "@components/elements/Link";
import { SwiperSlide } from "swiper/react";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import styles from "./InvestHomeHero.module.scss";

const InvestHomeHero = (props) => {
  const { gv } = useGlobalVariable();
  const data = props.data || props;
  if (!data) return null;


  // get instruments slugs for the sliding cards
  const instrumentLink = data?.instruments?.data?.map((item) => item?.slug);
  // get instruments icons for the sliding cards
  const instrumentIcon = data?.instruments?.data?.map((item) => item?.icon);
  // insert instrument apis into an array
  const instrumentsList = data?.instruments?.data?.length > 0 && data?.instruments?.data.map((item) => item?.api_id);
  // use the instruments array to make call to instrumentsApi
  const instrumentData = instrumentsApi(instrumentsList, 1000);

  return (
    <div className="overflow-hidden -mt-[2rem] lg:-mt-[3rem] 2xl:mt-[-1rem]">
      <div className=" container">
        <div className="hero-top relative flex">
          {/* left-col */}
          <div className=" relative flex  flex-col w-full lg:w-[60%] 2xl:w-[70%] pb-sm z-10">
            <div className="flex flex-col pt-[4rem]  lg:pt-[5rem] 2xl:pt-[8.5rem]">
              <div className={styles.investHeroTitle}>
                <div className="hidden lg:block">
                  <HTMLRender data={data?.title} />
                </div>
                <div className="block lg:hidden">
                  <HTMLRender data={data?.mobile_title} />
                </div>
              </div>
            </div>


            <div className="mb-md lg:my-lg flex flex-row items-center rtl:justify-end lg:ltr:items-start lg:rtl:items-center  rtl:flex-row-reverse">
              <div>
                <p className="ltr:w-[76%] rtl:w-[60%] lg:w-full mb-0 ltr:font-gilroy rtl:font-dubai text-[1.625rem] leading-[1.4] lg:leading-[1.1] rtl:leading-[1.5] text-black  xl:text-[1.8rem] 2xl:text-[2.1rem] mt-[1rem] lg:-mt-[0.15rem] lg:text-[#99A2B6]">
                  {data?.subtitle}
                </p>
              </div>
            </div>
            <div className={`mb-lg flex  ${styles.disclaimer}`}>
              <HTMLRender data={data?.disclaimer} />
            </div>
            <div className=" 2xl:mb-md flex justify-center md:justify-start">
              <Button data={data?.button} />
            </div>

            <p className="mt-lg lg:mt-xl ltr:font-gilroy rtl:font-dubai font-bold text-dark ">{gv("popular_stocks")}</p>
            <div className="heroInsturmentsSlider relative mt-sm lg:mt-lg  -mx-md lg:mx-0">
              <div className={styles.shadowContainer}>
                <GenericSlider
                  pauseOnMouseEnter
                  grabCursor
                  speed={2600}
                  loop
                  freeMode
                  autoplay
                  autoplayDelay={1}
                  breakpoints={{
                    320: {
                      slidesPerView: 1.6,
                      spaceBetween: 20
                    },
                    768: {
                      slidesPerView: 2.6,
                      spaceBetween: 50
                    },
                  }}
                  slidesPerView={2.6}
                  spaceBetween={50}
                >
                  {instrumentsList &&
                    instrumentsList?.map((instrument, index) => {
                      const currentInstrumentObject = instrumentData?.currentData?.[instrument];
                      return (
                        <SwiperSlide key={index}>
                          {/* instrument card */}
                          <div className="relative">
                            <div className={styles.link}>
                              <NextLink link={`${instrumentLink[index]}`} />
                            </div>
                            <div className={`flex flex-row items-center rounded-lg py-xs lg:py-md ${styles.customBackground}`}>
                              <div className="relative mx-xs lg:mx-lg h-[3rem] w-[3rem] rounded-full  bg-white shadow-lg">
                                <div className={`relative top-1/2 flex overflow-hidden  -translate-y-1/2 justify-center ${styles.roundedImage}`}>
                                  <NextImage
                                    src={instrumentIcon[index]?.url}
                                    width={35}
                                    height={35}
                                  />
                                </div>
                              </div>
                              <div className="flex flex-col justify-center ltr:pl-md rtl:pr-md ltr:lg:pl-0 rtl:lg:pr-0">
                                <p className="mb-0 lg:pb-xs text-lg font-bold text-black">
                                  {currentInstrumentObject?.display}
                                </p>
                                <div className="flex flex-row">
                                  <p className="mb-0">${currentInstrumentObject?.price}</p>
                                  <p
                                    className={`mb-0 px-sm ${currentInstrumentObject?.change.replace(/[^\d.-]/g, "") >= 0
                                      ? `text-greenCandle`
                                      : `text-primary`
                                      }`}
                                  >
                                    {currentInstrumentObject?.change}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </GenericSlider>
              </div>
            </div>
          </div>
          {/* right-col */}
          <div className="grow rtl:pt-[4rem] rtl:lg:pt-[6rem] ltr:pt-[4rem] h-full  top-0 w-full lg:h-auto lg-w-full absolute lg:relative ltr:left-[45%] rtl:right-[45%] lg:ltr:left-0 lg:rtl:right-0">
            <div className="bg-[#E5E8EC] relative h-[calc(100%-7rem)] lg:h-full w-[85%] md:w-1/2 lg:w-full xl:w-[110%] 2xl:w-[170%] rounded-xl">
              <div className="absolute w-[200%] max-w-[60rem] lg:max-w-none lg:w-[275%] left-1/2 top-0 lg:top-1/2 -translate-x-1/2 -translate-y-[20%] lg:-translate-y-1/2">
                <NextImage
                  src={data?.image}
                  width={data?.image?.data?.attributes?.width}
                  height={data?.image?.data?.attributes?.height}
                />
              </div>
            </div>
          </div>


        </div>
        <div className={styles.heroBottom}>
          <div>
            <h4 className={` ${styles.discover_title}`}>
              <HTMLRender data={data?.discover_title} />
            </h4>
          </div>
          <div className="hero-cards grid grid-cols-2 lg:grid-cols-4 lg:flex-row">
            {data?.benefits_list?.map((benefit, index) => (
              <div className={styles.heroCard} key={index}>
                <h5 className={`${styles.textBlue} pb-0 lg:pb-5 text-base lg:text-h5`}>{benefit?.title}</h5>
                <HTMLRender className="ondesktop hidden lg:block" data={benefit?.content} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestHomeHero;
