import HTMLRender from '@components/core/HTMLRender';
import Arrow from '@components/elements/Arrow';
import { Accordion } from '@mantine/core';
import styles from './InvestMarketsCards.module.css';

const InvestMarketsCards = (props) => {
  const data = props.data || props;
  if(!data) return null;
  
  return (
    <>
      <div className='container'>
        <div className="hidden md:flex flex-col md:flex-row justify-between ">
          {data?.cards.map((card, index) => (
            <div key={index} className="w-full max-w-[calc(100%/2-0.5rem)] rounded-lg py-[2.222rem] px-[1.5rem] bg-[#f8f8f8]">
              <p className="text-secondary mb-lg text-[1.222rem]">{card?.box_text}</p>
              <div className={`${styles.description}`}>
                <HTMLRender data={card?.box_description} />
              </div>
            </div>
          ))}
        </div>
        <div className="block md:hidden">
          <Accordion
            iconPosition="right"
            icon={<div className="text-secondary"><Arrow direction="down" /></div>}>
            {data?.cards.map((card, index) => (
              <Accordion.Item
                key={index}
                label={card?.box_text}
                classNames={{
                  control: styles.accordionControl,
                  accordionLabel: styles.accordionLabel,
                  itemOpened: styles.itemOpened,
                  label: styles.accordionLabel,
                  item: styles.accordionItem,
                  icon: styles.icon,
                  contentInner: styles.contentInner,
                }}
              >
                <HTMLRender data={card.box_description} />
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default InvestMarketsCards;
