import HTMLRender from "@components/core/HTMLRender";
import Link from "@components/elements/Link";
import Button from "@components/elements/Button";
import NextImage from "@components/elements/Image";
import styles from "./ColumnImage.module.css";
import useMobileLinks from "@lib/store/hooks/useMobileLinks";

const ColumnImage = (props) => {
  const { useGetMobileLink } = useMobileLinks();
  const data = props.data || props;

  return (
    <>
      {data.map((column, index) => (
        <div
          key={index}
          className="flex flex-col items-center justify-between py-lg first:pt-0 last:pb-0 md:flex-row md:py-xl"
        >
          <div className="order-3 w-full text-center md:order-1 md:w-[calc(50%-2rem)] ltr:md:text-left rtl:md:text-right 2xl:w-[calc(50%-2rem)]">
            <HTMLRender data={column.content} />
            {column?.table_columns?.length > 0 && (
              <div className="mb-[3.33rem] flex flex-col justify-between md:mb-[6.67rem] md:flex-row">
                {column?.table_columns.map((table, index) => (
                  <div
                    key={index}
                    className="flex w-full max-w-full flex-col items-center justify-start first:mb-lg  md:max-w-[calc(50%-0.5rem)] md:first:mb-0"
                  >
                    <div className={`w-full ${styles.tableHeader}`}>
                      <HTMLRender data={table.title} />
                    </div>
                    <div className={`h-full min-h-[11rem] w-full ${styles.tableBody}`}>
                      <HTMLRender data={table.content} />
                    </div>
                  </div>
                ))}
              </div>
            )}
            {props?.apps?.data && (
              <div className="flex justify-center py-xl lg:justify-start">
                {props?.apps?.data?.map((app) => (
                  <div
                    className={`relative cursor-pointer ltr:mr-md ltr:last:m-0 rtl:ml-md rtl:last:m-0 ${styles.appsDownloadButtons}`}
                    key={app.link}
                  >
                    <a
                      className="absolute top-0 left-0 z-10 h-full w-full"
                      target="_blank"
                      href={useGetMobileLink(app?.link)}
                      rel="nofollow noreferrer"
                    ></a>
                    <NextImage src={app?.image?.url} width={app?.image?.width} height={app?.image?.height} />
                  </div>
                ))}
              </div>
            )}

            <div className="pt-xs">
              {column?.link && <Link data={column?.link} />}
              {column?.button && <Button data={column?.button} />}
            </div>
          </div>
          <div
            className={`w-full md:w-[calc(50%-2rem)] 2xl:w-[calc(50%-2rem)] order-${column.image_position == "left" ? "0" : "2"
              } pb-xl md:pb-0`}
          >
            {column?.image?.data && (
              <NextImage
                src={column?.image}
                height={column?.image?.data?.attributes?.height}
                width={column?.image?.data?.attributes?.width}
              />
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default ColumnImage;
