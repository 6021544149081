import './ArticleExplore.module.css'
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { shallow } from "zustand/shallow";
import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import { useStore } from "@lib/store";
import ArticleExploreCard from "@components/custom/ArticleExploreCard";

const ArticleExplore = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable();

  const store = useStore(
    (store) => ({
      locale: store.locale,
    }),
    shallow
  );
  const { data: exploreData, isValidating } = useSwrHook("getArticles", {
    query: {
      locale: store.locale,
      pagination: {
        pageSize: 3,
      },
      sort: {
        updatedAt: "desc",
      },
      populate: {
        global_analyst: {
          fields: ["name"],
        },
        image: {
          fields: ["url", "width", "height"],
        },
      },

      fields: ["title", "slug", "updatedAt", "show_date"],

      filters: {
        id: {
          $ne: data?.articleId,
        },
        articles_category: {
          unique_key: {
            $eq: data?.articleType,
          },
        },
      },
    },
  });


  if (!data) {
    return null;
  }
  return (
    <div className="mb-2xl">
      <h2 className='text-center mb-xl'>{gv('read_more')}</h2>

      <div className='grid-cols-1 lg:grid-cols-3 gap-4 grid'>
        {exploreData &&
          exploreData?.data?.map((card, index) => (
            <div key={index} className='h-full'>
              <ArticleExploreCard slug={`/${store.locale}`} data={card} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ArticleExplore;
