import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import { memo } from "react";
import { Sparklines, SparklinesLine } from "react-sparklines";
import styles from "./SmallChart.module.css";

function LinearGradientFill({ color, instrumentId }) {
  return (
    <linearGradient id={instrumentId} x1="0%" y1="40%" x2="0%" y2="100%">
      <stop offset="10%" stopColor={color} stopOpacity="1" />
      <stop offset="100%" stopColor={color} stopOpacity="0" />
    </linearGradient>
  );
}

const SmallChart = ({ color, instrumentId, detailed = false }) => {
  let { data: chartData } = useSwrHook("getInstrumentChart", {
    query: {
      instrument: instrumentId,
      timeframe: detailed ? "15m" : "1h",
    },
    customConfig: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  });

  const sampleSize = detailed ? "96" : "24";

  if (!chartData) return null;

  chartData = chartData.slice(-sampleSize);
  const simpleChart = [];
  for (let i = 0; i < sampleSize; i += 1) simpleChart.push(chartData[i][1] || []);

  return (
    <div className={`${styles.SmallChart} h-full w-full`}>
      <Sparklines data={simpleChart || []}>
        <svg>
          <defs>
            <LinearGradientFill color={color} instrumentId={instrumentId} />
          </defs>
        </svg>
        <SparklinesLine
          style={{
            strokeWidth: 1,
            fill: `url(#${instrumentId})`,
          }}
          color="#000"
        />
      </Sparklines>
    </div>
  );
};

export default SmallChart;

export const MemoSmallChart = memo(SmallChart, function (prevProps, nextProps) {
  return prevProps.color === nextProps.color;
});
