import HTMLRender from '@src/components/core/HTMLRender';
import styles from './TradingViewCta.module.css';
import Button from '@src/components/elements/Button';

const TradingViewCta = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      <div className='bg-[#0995DD] rounded-lg lg:rounded-[30px] text-white px-lg py-xl'>
        <div className={styles.content}>
          <HTMLRender data={data?.content} />
        </div>
        <div className='flex justify-center mt-lg lg:mt-xl'>
          <Button data={data?.cta} />
        </div>
      </div>
    </div>
  );
};

export default TradingViewCta;
