import Button from '@src/components/elements/Button';
import NextImage from '@src/components/elements/Image';
import Link from '@src/components/elements/Link';
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import './PepCard.module.css'

const PepCard = ({ card }) => {
  const { gv } = useGlobalVariable();
  if (!card) return null;
  return (
    <div className="bg-[#f4f4f4] px-lg py-lg relative rounded-lg h-full overflow-hidden">
      <div className="absolute top-0 left-0">
        <NextImage
          objectFit
          src={card?.flag?.data?.attributes?.url}
          width={card?.flag?.data?.attributes?.width}
          height={card?.flag?.data?.attributes?.height}
        />
      </div>
      <div className="relative">
        <div className='text-right py-lg flex ltr:justify-end'>
          <div className='text-h5 pt-xs border-t border-[#ddd] mt-lg min-w-[38%] md:min-w-[50%]'>
            {card.licence}
          </div>
        </div>
        <div className='justify-center hidden lg:flex'>
          <Button label={gv("download_document")} type="white" size="lg" link={card?.pdf?.data?.attributes?.url} />
        </div>
        <div className='flex ltr:justify-end lg:hidden'>
          <Link text={gv("download_document")} arrow="left" link={card?.pdf?.data?.attributes?.url} newTab />
        </div>
      </div>
    </div>
  );
};

export default PepCard;
