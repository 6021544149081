import styles from "./PodcastsHosts.module.css";
import SectionTitle from "@components/elements/SectionTitle";
import NextImage from "@components/elements/Image";
import HTMLRender from "@components/core/HTMLRender";
import { GrTwitter } from "@react-icons/all-files/gr/GrTwitter";
import { GrLinkedin } from "@react-icons/all-files/gr/GrLinkedin";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";

const PodcastsHosts = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable()

  if (!data) return null;

  return (
    <div className="container">
      <SectionTitle data={data?.section_title} />
      <div className="flex justify-center flex-col lg:flex-row">
        {data?.analysts?.data?.map((item, index) => (
          <div
            key={index}
            className="pb-xl last:pb-0 lg:pb-0 lg:w-1/3 text-center relative px-md flex flex-col justify-between items-center"
          >
            <div>
              <div className="flex justify-center">
                <div className="w-[8.75em] h-[8.75em] lg:w-[14em] lg:h-[14em]">
                  <NextImage
                    objectFit
                    src={
                      item?.attributes?.image_podcasts?.data?.attributes
                        ?.url
                    }
                    width={
                      item?.attributes?.image_podcasts?.data?.attributes
                        ?.width
                    }
                    alt={item.attributes.name}
                    height={
                      item?.attributes?.image_podcasts?.data?.attributes
                        ?.height
                    }
                  />
                </div>
              </div>

              <h3 className="m-0 py-lg">{item?.attributes?.name}</h3>
              <div className="text-secondary uppercase pb-lg">
                {gv("market_expert", 'raw')}
              </div>
              {item?.attributes?.short_description && (
                <p className="pb-lg">
                  <HTMLRender data={item?.attributes?.short_description} />
                </p>
              )}
            </div>
            <div className="flex justify-center">
              {item?.attributes?.twitter_url && (
                <a
                  href={item?.attributes?.twitter_url}
                  target="_blank"
                  className="mx-md text-[#1DA1F2]" rel="noreferrer"
                >
                  <GrTwitter className="text-3xl" />
                </a>
              )}
              {item?.attributes?.linkedin_url && (
                <a
                  href={item?.attributes?.linkedin_url}
                  target="_blank"
                  className="mx-md text-[#0A66C2]" rel="noreferrer"
                >
                  <GrLinkedin className="text-3xl" />
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PodcastsHosts;