import './FlagCard.module.css';
import Image from 'next/image';
import Link from 'next/link';
import NextImage from '@components/elements/Image';

const FlagCard = (props) => {
  const data = props.data || props;
  return (
    <>
      <div className="bg-whiteCream pb-sm pt-2xl md:pt-xl md:pb-lg px-sm relative min-h-[13.25rem] flex h-full md:min-h-[16.25rem] flex-col md:justify-end overflow-hidden rounded-2xl">
        {data.licence_link && (
          <Link href={`${data.licence_link}`}>
              <a target="_blank" className="absolute top-0 left-0 z-10 block h-full w-full opacity-0"></a>
            </Link>
        )}
            <div className="absolute left-0 top-0 block h-full w-full max-w-full">
          <NextImage src={data?.image || data.image} alt="Picture of the author" width={400} height={450} objectFit="cover" />
        </div>
        <div className="z-1 relative flex h-full justify-end flex-col">
            <div className="self-end text-right">
            <p className="font-light mb-0">{data.text_upper}</p>
            <h3 className="pb-[2px] font-[400] text-xl md:pb-md -mt-[5px] md:mt-[0.3125rem] uppercase">{data.licence_name}</h3>
            </div>
          <div className={`pt-[2px] md:pt-md  ${props.divider && 'border-t border-[#f0f0f0]'} `}>
            <p className="text-secondary text-right md:text-left text-sm md:text-base font-light">{data.licence_full_name}</p>
          </div>
          </div>
      </div>
    </>
  );
};

export default FlagCard;
