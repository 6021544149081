import styles from './Hamburger.module.css'

const Hamburger = ({color="primary"}) => {

  const toggleOpen = () => document.body.classList.toggle('mobile-open');
  
  return (
    <div 
    onClick={toggleOpen} 
    className={`hamburger`}>
      <span className={`bg-${color}`}></span>
      <span className={`bg-${color}`}></span>
      <span className={`bg-${color}`}></span>
    </div>
  );
};

export default Hamburger;
