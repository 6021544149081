import HTMLRender from "@components/core/HTMLRender";
import NextImage from "@components/elements/Image";
import SectionTitle from "@components/elements/SectionTitle";
import Button from "@src/components/elements/Button";

const AffiliatesReasons = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className="container">
      {data?.section_title && <SectionTitle data={data?.section_title} />}
      <div className="flex flex-col justify-between md:flex-row">
        {data?.icon_text.slice(0, 2).map((arr, index) => (
          <div key={index} className="mb-[4rem] flex w-full items-center md:w-[40%]">
            <div>
              <div className="w-[3.667rem]">
                <NextImage
                  src={arr?.icon}
                  width={arr?.icon?.data?.attributes?.width}
                  height={arr?.icon?.data?.attributes?.height}
                />
              </div>
            </div>
            <div className="flex flex-col justify-between ltr:pl-lg rtl:pr-lg">
              <h3>{arr?.title}</h3>
              <HTMLRender data={arr?.content} />
            </div>
          </div>
        ))}
      </div>
      <div className="relative mb-[4rem] flex flex-col rounded-lg bg-[#f6f6f6] md:flex-row">
        <div className="w-full py-xl px-md md:w-[60%] md:p-xl">
          <HTMLRender data={data?.banner_content} />
        </div>
        <div className="bottom-0 mx-auto max-w-[90%] ltr:-right-[1rem] rtl:left-[1rem] md:absolute md:max-w-[40%]">
          <NextImage
            src={data?.image}
            width={data?.image?.data?.attributes?.width}
            height={data?.image?.data?.attributes?.height}
          />
        </div>
      </div>
      <div className="flex flex-col justify-between md:flex-row">
        {data?.icon_text.slice(2, data?.icon_text.length).map((arr, index) => (
          <div key={index} className="mb-[4rem] flex w-full items-center md:w-[48%] lg:w-[40%]">
            <div>
              <div className="w-[3.667rem]">
                <NextImage
                  src={arr?.icon}
                  width={arr?.icon?.data?.attributes?.width}
                  height={arr?.icon?.data?.attributes?.height}
                />
              </div>
            </div>
            <div className="flex flex-col justify-between ltr:pl-lg rtl:pr-lg">
              <h3>{arr?.title}</h3>
              <HTMLRender data={arr?.content} />
            </div>
          </div>
        ))}
      </div>
      <div className="mt-lg flex w-full justify-center md:mt-xl">
        <Button data={data?.button} />
      </div>
    </div>
  );
};

export default AffiliatesReasons;
