import useModalHook from "@lib/modals";
import CloseButton from "@src/components/elements/CloseButton";
import useFormHandlers from "@src/components/elements/Forms/hooks/useFormHandlers";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import HTMLRender from "@src/components/core/HTMLRender";
import { useEffect, useState } from "react";
import ErrorLabel from "@src/components/elements/Forms/components/ErrorLabel";
import { getCookie } from "cookies-next";
import Button from "@src/components/elements/Button";
import { shallow } from "zustand/shallow";
import { useStore } from "@lib/store";
import { Calendar } from "@mantine/dates";
import { Indicator } from "@mantine/core";
import dayjs from "dayjs";
import styles from "./FormCalendarPartnersRetailIB.module.css";
import useAppointmentTime from "./hooks";
import "dayjs/locale/ar";

YupPassword(Yup);

const FormCalendarPartnersRetailIB = ({ data }) => {
  const [submitResponse, setSubmitResponse] = useState(null);
  const modal = useModalHook();
  const store = useStore(
    (s) => ({
      dir: s.direction,
    }),
    shallow
  );
  const use = useAppointmentTime();
  const handler = useFormHandlers();
  const { gv } = useGlobalVariable();
  const initialValues = {};

  const CalendarValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(<ErrorLabel err="forms_errors_firstname_required" />)
      .matches(/^[^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/, {
        message: <ErrorLabel err="forms_errors_rule_characters_not_allowed" />,
        when: (value) => !/^[^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/.test(value),
      })
      .min(2, <ErrorLabel err="forms_errors_rule_min_length" value="2" />)
      .max(50, <ErrorLabel err="forms_errors_rule_max_length" />),
    last_name: Yup.string()
      .required(<ErrorLabel err="forms_errors_lastname_required" />)
      .matches(/^[^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/, {
        message: <ErrorLabel err="forms_errors_rule_characters_not_allowed" />,
        when: (value) => !/^[^!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/.test(value),
      })
      .min(2, <ErrorLabel err="forms_errors_rule_min_length" value="2" />)
      .max(50, <ErrorLabel err="forms_errors_rule_max_length" />),
    email: Yup.string()
      .email(<ErrorLabel err="forms_errors_email_required" />)
      .required(<ErrorLabel err="forms_errors_email_required" />),
  });
  const currentUserCountry = getCookie("regulation_country");
  useEffect(() => {
    if (submitResponse && submitResponse.status == 400) {
      setTimeout(() => {
        setSubmitResponse(null);
      }, 8000);
    }
  }, [submitResponse]);
  return (
    <div className="rounded-xl bg-white">
      <Formik
        validationSchema={CalendarValidationSchema}
        enableReinitialize
        initialValues={{}}
        onSubmit={async (values, { setSubmitting }) => {
          const date = dayjs(use.appointmentTime);
          const formattedDate = date.format("HH:mm | DD-MM-YYYY"); // format it for the success modal
          const response = await handler
            .onSubmitPartnersBICalendar({
              ...values,
              date: use.appointmentTime,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
            .catch((err) => {
              ``;
            });
          if (response?.status == "ok") {
            setSubmitResponse({
              ...response,
              statusCode: 200,
            });
            modal.open("PartnersRetailIBCalendarResponse", {
              type: "SUCCESS",
              data: {
                response: gv("forms_response_retailib_calendar_appointment", {
                  template: {
                    appointment_time: formattedDate,
                  },
                }),
                bodyResponse: response,
              },
            });
          } else {
            setSubmitResponse({
              statusCode: 400,
            });
          }
        }}
      >
        {({ handleSubmit, isSubmitting, errors, submitCount }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              display: submitResponse && submitResponse?.statusCode == 200 ? "none" : "block",
            }}
          >
            <div className={`mb-lg w-full ${styles.content}`}>
              <HTMLRender data={data?.section_title?.subtitle} />
            </div>
            <div className="w-full">
              <Calendar
                disableOutsideEvents
                allowLevelChange={false}
                fullWidth
                locale={store && store.dir === "rtl" && "ar"}
                classNames={{
                  outside: styles.calendarOutside,
                  calendarHeader: styles.calendarHeader,
                  selected: styles.calendarSelected,
                  monthPickerControlActive: styles.calendarMonthPickerControlActive,
                  yearPickerControlActive: styles.calendarYearPickerControlActive,
                  calendarHeaderLevel: styles.calendarHeaderLevel,
                  calendarHeaderControl: styles.calendarHeaderControl,
                  calendarBase: styles.calendarBase,
                  cell: styles.callendarCell,
                  weekday: styles.calendarWeekday,
                  weekdayCell: styles.calendarWeekdayCell,
                }}
                minDate={use.minDate}
                value={use.date}
                onChange={use.setDate}
                firstDayOfWeek="sunday"
                excludeDate={(_date) =>
                  _date.getDay() === 0 ||
                  _date.getDay() === 6 ||
                  (_date.getTime() < new Date().getTime() &&
                    !(_date.getDate() === new Date().getDate() && _date.getMonth() === new Date().getMonth()))
                }
                renderDay={(_date) => {
                  const day = _date.getDate();
                  return (
                    <Indicator
                      size={6}
                      color="#ff4848"
                      offset={8}
                      disabled={
                        !(
                          day === new Date().getDate() &&
                          _date.getMonth() === new Date().getMonth() &&
                          _date.getYear() === new Date().getYear()
                        )
                      }
                    >
                      <div>{day}</div>
                    </Indicator>
                  );
                }}
              />
            </div>
            <div className="mt-xl flex items-center justify-start text-sm">
              <p className="mb-0 font-[600] text-dark">{data?.meeting_text}</p>
              <div className={`text-sm ${styles.timePickerWrapper}`}>
                <span
                  role="presentation"
                  className={styles.counters}
                  onClick={use.handleDec.bind(this)}
                  onKeyDown={use.handleDec.bind(this)}
                >
                  -
                </span>
                <span>{`${use.hour}:00`}</span>
                <span
                  role="presentation"
                  className={styles.counters}
                  onClick={use.handleInc.bind(this)}
                  onKeyUp={use.handleInc.bind(this)}
                >
                  +
                </span>
              </div>
            </div>
            <div>
              <div
                name="schedule"
                id="schedule"
                encType="application/json"
                method="post"
                noValidate="novalidate"
                className={`justiy-center flex flex-col flex-wrap pt-lg md:flex-row md:justify-between ${styles.formWrapper}`}
              >
                <input type="text" name="date" id="schedule_date" hidden />
                <input type="text" name="timezone" id="schedule_timezone" hidden />
                <input type="text" name="license" id="schedule_license" hidden />
                <input type="text" name="language" id="schedule_language" hidden />
                <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                  <Field
                    type="text"
                    name="first_name"
                    id="schedule_first_name"
                    placeholder="First Name"
                    className="error"
                    aria-invalid="true"
                  />
                  {errors?.first_name && submitCount > 0 && errors?.first_name}
                </div>
                <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                  <Field type="text" name="last_name" id="schedule_last_name" placeholder="Last Name" />
                  {errors?.last_name && submitCount > 0 && errors?.last_name}
                </div>
                <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                  <Field type="email" name="email" id="schedule_email" placeholder="Email Address" />
                  {errors?.email && submitCount > 0 && errors?.email}
                </div>
                <div className="margin-0 relative w-full pb-md md:w-[calc(50%-1rem)]">
                  <div className="button-wrapper">
                    {/* submit formik when button is clicked */}
                    <Button data={{ ...data?.button, disabled: isSubmitting, onClick: handleSubmit }} />
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <CloseButton onClick={() => modal.openCloseAll()} />
      {submitResponse && submitResponse.statusCode == 200 && (
        <div className={`center overflow-hidden p-md lg:p-10 `}>
          <HTMLRender data={gv("affiliate_form_succes_message")} />
        </div>
      )}
      {submitResponse && submitResponse.statusCode != 200 && (
        <div className={`center overflow-hidden p-md lg:p-10 `}>
          <HTMLRender data={gv("forms_response_errors_something_went_wrong")} />
        </div>
      )}
    </div>
  );
};
export default FormCalendarPartnersRetailIB;
