import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import './SafetyHero.module.css';

const SafetyHero = (props) => {
  const data = props.data || props;
  if (!data) return null;
  
  return (
    <>
      <div className='container'>
        {data?.section_title &&
          <div>
            <SectionTitle data={data?.section_title} />
          </div>
        }
        <div className="w-full md:w-[75%] lg:w-full mx-auto text-center">
          <NextImage src={data?.image} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
        </div>
        <div className="flex flex-col md:flex-row justify-between">
          {data?.steps_list.map((step, index) => (
            <div key={index} className="w-full md:w-[calc(100%/4-1.111rem)] mb-md lg:mb-lg bg-white rounded-lg py-lg px-md">
              <HTMLRender data={step?.content} />
            </div>
          ))}
        </div>
        {data?.footer_text &&
          <div className='mt-md lg:mt-xl flex flex-col lg:flex-row justify-between items-start '>
            {data?.footer_text?.map((text, index) => (
              <div key={index} className="text-center ltr:lg:text-left rtl:lg:text-right w-full lg:w-[calc(100%/2-1.111rem)] mb-md lg:mb-0">
                <HTMLRender data={text?.content} />
              </div>
            ))}
          </div>
        }
      </div>
    </>
  );
};

export default SafetyHero;
