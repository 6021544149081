/* eslint-disable no-underscore-dangle */
import generatedDictionary from "./components_dictionary";
import SectionLayout from "./elements/SectionLayout";
import { SwiperSlide } from "swiper/react";
import FullPageSlider from "./custom/FullPageSlider";
import { QuickEditSection } from "@components/custom/QuickEdit";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useIntersection } from "@mantine/hooks";
import { isEqual } from "lodash";
import get from 'lodash/get';

const CheckPageTypeLayout = React.memo(
  ({ pageType = "default", name, sections, pageId }) => {
    const firstSectionRef = useRef(null);
    useEffect(() => {
      const updateBodyClass = () => {
        const { body } = document;
        const scrollPosition = window.scrollY;
        const firstSectionHeight = firstSectionRef.current?.offsetHeight || 0;

        if (scrollPosition > firstSectionHeight) {
          body.classList.add("show-sticky");
        } else {
          body.classList.remove("show-sticky");
        }
      };

      if (typeof window !== "undefined") {
        window.addEventListener("scroll", updateBodyClass);
      }

      return () => {
        if (typeof window !== "undefined") {
          window.removeEventListener("scroll", updateBodyClass);
        }
      };
    }, []);

    if (pageType === "fullPageSlides")
      return (
        <>
          <div className="v-swiper-fullPage hidden md:block">
            <FullPageSlider vertical fullPage observer mousewheelControl pagination speed={800}>
              {sections.map((section, index) => (
                <Fragment key={`${section.__component}${index}`}>
                  <SwiperSlide>
                    <SectionLayout
                      data={section?.section_layout || get(section, 'qd_reusable_component.data[0].sections[0]') || []}
                      sectionKey={section.__component}
                      sectionId={section.id}
                    >
                      <Section style={{ paddingTop: "1.11em" }} sectionData={section} name={name} />
                    </SectionLayout>
                  </SwiperSlide>
                </Fragment>
              ))}
            </FullPageSlider>
          </div>
          <div className="md:hidden">
            {sections.map((section, index) => (
              <Fragment key={`${section.__component}${index}`}>
                <SwiperSlide>
                  <SectionLayout
                    data={section?.section_layout || get(section, 'qd_reusable_component.data[0].sections[0]') || []}
                    sectionKey={section.__component}
                    sectionId={section.id}
                  >
                    <Section style={{ paddingTop: "1.11em" }} sectionData={section} name={name} />
                  </SectionLayout>
                </SwiperSlide>
              </Fragment>
            ))}
          </div>
        </>
      );
    return (
      <>
        {sections.map((section, index) => (
          <Fragment key={`${section.__component}${index}`}>
            <SectionLayout
              data={section?.section_layout || get(section, 'qd_reusable_component.data[0].sections[0]') || []}
              sectionName={section.__component}
              reference={index === 0 ? firstSectionRef : null}
              sectionId={section.id}
            >
              <QuickEditSection
                sectionKey={section.__component}
                pageId={pageId}
                reusableSectionId={section?.reusable_component?.data?.attributes?.sections[0]?.__component || ""}
                reusableContentTypeId={section?.reusable_component?.data?.id || false}
              />
              <Section style={{ paddingTop: "1.11em" }} name={name} sectionData={section} />
            </SectionLayout>
          </Fragment>
        ))}
      </>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
);

CheckPageTypeLayout.displayName = "CheckPageTypeLayout";

const Section = React.memo(
  (props) => {
    const UseComponent = generatedDictionary[props.sectionData.__component];
    if (!UseComponent) return null;
    return <UseComponent {...props.sectionData} />;
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
);

Section.displayName = "Section";

// initialize context in the sections component
const Sections = ({ pageType, name, sections, pageId }) => (
  <div className="flex flex-col">
    <CheckPageTypeLayout name={name} sections={sections} pageType={pageType} pageId={pageId} />
  </div>
);

export default Sections;
