import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './CareersHero.module.css';

const CareersHero = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='pt-[27rem] md:py-[10rem] lg:py-[20rem] relative'>
      <div className='absolute top-0 left-0 w-full h-full'>
        <div className="hidden md:block relative h-full">
          <NextImage src={data?.image} />
        </div>
        <div className='block md:hidden relative h-full'>
          <NextImage src={data?.mobile_image} />
        </div>
      </div>
      <div className='container'>
        <div className='flex flex-col md:flex-row justify-between items-center'>
          <div className="flex flex-col w-full">
            <div className={styles.title}>
              {data?.section_title?.title &&
                <h1 className='relative'>{data?.section_title?.title}</h1>
              }
              {data?.section_title?.subtitle &&
                <div className={`${styles.subtitle} hidden md:block`}>
                  <HTMLRender data={data?.section_title?.subtitle} />
                </div>
              }
            </div>
            {data?.button &&
              <div className={`${styles.button} hidden md:block`}>
                <Button data={data?.button} />
              </div>
            }
          </div>

        </div>
      </div>
    </div>
  );
};

export default CareersHero;
