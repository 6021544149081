import { useState, useEffect } from 'react'
import NextImage from '@components/elements/Image';
import { Tabs } from '@mantine/core';
import SectionTitle from '@components/elements/SectionTitle';
import List from '@components/elements/List';
import Button from '@components/elements/Button';
import { useLocalStorage } from '@mantine/hooks';
import { useRegistrationLink } from "@lib/store/hooks/useActiveProductType";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import styles from './AboutUsTabs.module.css'

function AboutUsTabs(props) {
  const data = props.data || props;
  const [about_us_tabs_clicked_index, _] = useLocalStorage({ key: 'about_us_tabs_clicked_index', defaultValue: 'null' });
  const [activeTab, setActiveTab] = useState(0);

  const registrationLink = useRegistrationLink();
  const { gv } = useGlobalVariable();

  useEffect(() => setActiveTab(about_us_tabs_clicked_index === 3 ? 0 : 1), [about_us_tabs_clicked_index])

  if (!data) return null;
  return (
    <div className="container">
      <div style={{ scrollMarginTop: '7rem', visibility: 'hidden', height: '0px' }} id="academy-section" />
      <div style={{ scrollMarginTop: '7rem', visibility: 'hidden', height: '0px' }} id="technology-section" />
      <Tabs
        active={activeTab} onTabChange={setActiveTab}
        position='center' grow variant="unstyled"
        classNames={{
          tabsListWrapper: styles.wrapper,
          tabControl: styles.tabControl,
          tabActive: styles.tabActive,
          tabLabel: styles.tabLabel,
        }}>
        {
          data?.au_tabs.map((tab, index) => (
            <Tabs.Tab key={index} label={tab?.tab_name}>
              <div id={tab?.tab_id} className='mt-[3rem] md:mt-[5.55rem] flex flex-col md:flex-row justify-between items-center'>
                <div className='w-full md:w-[calc(50%-1rem)] mb-xl md:mb-0'>
                  <NextImage src={tab?.image} width={tab?.image?.data?.attributes?.width} height={tab?.image?.data?.attributes?.height} />
                </div>
                <div className='w-full md:w-[calc(50%-1.5rem)] lg:w-[calc(50%-3rem)]'>
                  <div className={styles.title}>
                    <SectionTitle data={tab?.section_title} />
                  </div>
                  <List type="checked" border list_items={tab?.list_items} />
                </div>
              </div>
            </Tabs.Tab>
          ))
        }
      </Tabs>
      <div className='flex justify-center mt-xl'>
        <Button link={registrationLink} type="primary" size="lg" arrow="right" label={gv("start_trading_now", "raw")} />
      </div>
    </div>
  );
}

export default AboutUsTabs;
