import HTMLRender from '@components/core/HTMLRender';
import GenericSlider from '@components/custom/GenericSlider';
import Steps from '@components/custom/Steps';
import StepSlider from '@components/custom/StepSlider';
import Button from '@components/elements/Button';
import SectionTitle from '@components/elements/SectionTitle';
import { SwiperSlide } from 'swiper/react';
import styles from './BondsSteps.module.css';

const BondsSteps = (props) => {
  const data = props.data || props

  if (!data) {
    return null;
  } return (
    <div className="container">
      <div className={styles?.title}>
        <SectionTitle data={data?.section_title} />
      </div>
      {data.steps_title &&
        <div className='flex justify-center text-center mb-xl'>
          <p className='text-dark text-xl'>{data?.steps_title}</p>
        </div>
      }
      {/* Desktop Steps */}
      <div className="hidden md:flex justify-between mb-[2.77rem]">
        {data?.text?.map((step, index) => (
          <div key={index} className={`w-[calc(100%/${data.text.length})]`}>
            <Steps stepColor="secondary" data={data.text} text={step.content} index={index} />
          </div>
        ))}
      </div>
      {/* Mobile Steps */}
      <div className='md:hidden mb-[2.77rem]'>
        <GenericSlider navigation pagination autoplay>
          {data?.text?.map((step, index) => (
            <SwiperSlide key={index}>
              <StepSlider stepColor="secondary" data={data.text} text={step.content} index={index} />
            </SwiperSlide>
          ))}
        </GenericSlider>
      </div>
      <div className='flex flex-col lg:flex-row w-full justify-between items-center py-md lg:py-lg'>
        <div className={styles.lower_content}>
          <HTMLRender data={data?.lower_content} />
        </div>
        <div className="pt-xl lg:py-0">
          <Button data={data?.button} />
        </div>
      </div>
    </div>
  );
};

export default BondsSteps;
