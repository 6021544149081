import useLocale from "@lib/store/hooks/useLocale";
import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import { usePersistentStore, useStore } from "@lib/store";

export default function useLayoutSharedData() {
  const locale = useStore((state) => state.locale);
  const setPageStoreToWindow = useStore((state) => state.setPageStoreToWindow);
  const setPersistentStoreToWindow = usePersistentStore((state) => state.setPersistentStoreToWindow);
  const { data, isValidating, error } = useSwrHook("getLayoutSharedData", {
    query: {
      locale,
    },
  });
  if (typeof window !== "undefined") {
    window.layoutSharedData = data;
    setPersistentStoreToWindow();
    setPageStoreToWindow();
  }
  
  return {
    layoutSharedData: data,
    locale,
    isLoading: isValidating,
    error,
  };
}
