import SectionTitle from '@src/components/elements/SectionTitle';
import styles from './TradingViewTools.module.css';
import NextImage from '@src/components/elements/Image';
import HTMLRender from '@src/components/core/HTMLRender';
import Button from '@src/components/elements/Button';

const TradingViewTools = (props) => {
  const data = props.data || props;
  if (!data) return null;

  const featuresChunkOne = data?.tools_features_list?.slice(0, 4);
  const featuresChunkTwo = data?.tools_features_list?.slice(4, data?.tools_features_list?.length);

  return (
    <div className='container'>
      <div className={styles.title}>
        <SectionTitle data={data?.section_title} />
      </div>
      <div className='flex flex-col lg:flex-row justify-between'>
        <ul className='w-full bg-[#F9F9F9] flex flex-col justify-center p-lg lg:max-w-[calc(100%/3-0.5rem)] rounded-[10px]'>
          {featuresChunkOne?.map((feature, index) => (
            <li className='pt-lg first:pt-0 list-disc mx-lg' key={index}>
              <HTMLRender data={feature?.content} />
            </li>
          ))}
        </ul>
        <div className='w-full text-center lg:max-w-[calc(100%/3-0.5rem)] my-lg lg:my-0'>
          <NextImage src={data?.image?.data?.attributes?.url} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
        </div>
        <ul className='w-full bg-[#F9F9F9] flex flex-col justify-center p-lg lg:max-w-[calc(100%/3-0.5rem)] rounded-[10px]'>
          {featuresChunkTwo?.map((feature, index) => (
            <li className='pt-lg first:pt-0 list-disc mx-lg' key={index}>
              <HTMLRender data={feature?.content} />
            </li>
          ))}
        </ul>
      </div>
      <div className='mt-xl text-center'>
        <Button data={data?.button} />
      </div>
      <p className="text-center mt-md text-sm italic text-black">{data?.disclaimer}</p>
    </div>
  );
};

export default TradingViewTools;
