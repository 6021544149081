import HTMLRender from '@components/core/HTMLRender';
import Accordion from '@components/elements/Accordion';
import Button from '@components/elements/Button';
import SectionTitle from '@components/elements/SectionTitle';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import './SafetyContact.module.css';

function openChat() {
  if (window && window?.zE) {
    try {
      window?.zE("webWidget", "open");
    } catch (err) {
      console.warn('[Zendesk] WebWidget is not present on this license.');
    }
    try {
      window?.zE("messenger", "open");
    } catch (err) {
      console.warn('[Zendesk] messenger is not present on this license.');
    }
  }
}


const SafetyContact = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable()
  return (
    <div className='container'>
      <SectionTitle data={data?.section_title} />
      <Accordion type="border" accordion_items={data?.accordion_items} icon="plus" />
      <div className='w-full text-center mt-xl'>
        <HTMLRender data={data?.above_button_text} />
      </div>
      <div className='flex justify-center mt-lg'>
        <div className='mx-sm md:mx-md'>
          <Button type="secondary" size="lg" link={gv('contact_us_url')} label={data?.contact_us_button_text} />
        </div>
        <div className='mx-sm md:mx-md'>
          <Button type="secondary" size="lg" label={data?.live_chat_button_text} onClick={openChat} />
        </div>
      </div>
    </div>
  );
};

export default SafetyContact;
