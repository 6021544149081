import SectionTitle from '@components/elements/SectionTitle';
import MantineAccordion from '@components/elements/MantineAccordion';
import './ThematixFaq.module.css';

const ThematixFaq = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      {data?.section_title &&
        <SectionTitle data={data?.section_title} />
      }
      {data?.accordion_item.length > 0 &&
        <div className="border-t border-[#dee2e6]">
          <MantineAccordion type="border" icon="arrow" accordion_items={data?.accordion_item} />
        </div>
      }
    </div>
  );
};

export default ThematixFaq;
