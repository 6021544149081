import Button from '@components/elements/Button';
import List from '@components/elements/List';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './PartnershipModels.module.css';

const PartnershipModels = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container pt-sm' id={data?.section_layout?.section_id}>
      <SectionTitle data={data?.section_title} />
      <div className={` ${styles?.modelList} flex flex-col md:flex-row justify-between w-full`}>
        {data?.model_list.map((model, index) => (
          <div key={index} className="w-full md:max-w-[calc(50%-1rem)] 2xl:md:max-w-[calc(50%-2rem)] flex flex-col justify-between">
            <div>
              <p className='text-2xl text-dark px-md'>{model?.title}</p>
              <div className={`${styles?.list} hidden md:block`}>
                <List type="checked-right" list_items={model?.list_items} border />
              </div>
              <div className={`${styles?.list} md:hidden`}>
                <List type="checked" list_items={model?.list_items} border />
              </div>
            </div>
            <div className={`mt-xl flex ${styles?.button}`}>
              <Button data={model?.button} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnershipModels;
