import useMobileLinks from "@lib/store/hooks/useMobileLinks";
import HTMLRender from "@src/components/core/HTMLRender";
import NextImage from "@src/components/elements/Image";
import { motion } from "framer-motion";
import styles from "./FooterApps.module.css";

const FooterApps = (props) => {
  const data = props.data[0] || props;
  const locale = props?.locale;
  const { useGetMobileLink } = useMobileLinks();

  if (!data || ["eu", "ro", "de", "cz", "it", "el", "es", "ae", "ae-ar", "za"].includes(locale)) return null;

  return (
    <div className="h-[14rem] max-h-[14rem] overflow-hidden rounded-2xl border border-[#999999] p-lg pb-0">
      <div className="flex w-full justify-between">
        <div className="w-full max-w-[60%]">
          <motion.div animate={{ y: [-15, 10, -15] }} transition={{ type: "tween", duration: 4, repeat: Infinity }}>
            <NextImage
              src={data?.image}
              alt={data?.image?.data?.attributes?.alternativeText}
              width={data?.image?.data?.attributes?.width}
              height={data?.image?.data?.attributes?.height}
            />
          </motion.div>
        </div>
        <div className="flex w-full max-w-[40%] flex-col items-center justify-start">
          <div className="mb-lg text-center text-sm">
            <HTMLRender data={data?.text} />
          </div>
          {data?.apps?.data?.map((app, index) => (
            <div
              className={`relative top-0 mx-auto mb-md max-w-[100%] cursor-pointer transition-all duration-300 last:mb-0 hover:-top-[0.15rem] ${styles.appsDownloadButtons}`}
              key={index}
            >
              <a
                className="absolute top-0 left-0 z-10 h-full w-full"
                target="_blank"
                rel="nofollow noreferrer"
                href={useGetMobileLink(app?.link)}
              ></a>
              <NextImage
                src={app?.image}
                alt={app?.image?.alternativeText}
                width={app?.image?.width}
                height={app?.image?.height}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FooterApps;
