import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-creative";
import { IoIosArrowUp } from "@react-icons/all-files/io/IoIosArrowUp";
import { IoIosArrowDown } from "@react-icons/all-files/io/IoIosArrowDown";
import { Autoplay, Navigation } from "swiper";
import { useRef } from "react";
import HTMLRender from "@components/core/HTMLRender";
import NextImage from "@components/elements/Image";
import Button from "@components/elements/Button";
import GenericSlider from "@components/custom/GenericSlider";
import { useStore } from "@lib/store";

import styles from "./TradeHomeThematix.module.css";


const TradeHomeThematix = (props) => {
  const getPageUrl = useStore((state) => state.getPageUrl);
  const thematixSlug = getPageUrl("thematix");
  const data = props.data || props;
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const currentProductTypeKey = useStore((state) => state.currentProductTypeKey);
  const dir = useStore((store) => store.direction);

  if (!data) return null;
  return (
    <>
      <div className={`hidden overflow-hidden bg-body lg:block ${styles.thematixWrapper}`}>
        <div className="container">
          <div className={`flex items-center justify-between `}>
            <div className=" relative  flex h-[90vh] w-[66%] items-center tall:h-[45rem]">
              <div className={styles.halfCircle}></div>
              <div className="absolute right-0 top-1/2 -translate-y-1/2">
                <div ref={navigationNextRef} className="mb-sm cursor-pointer text-3xl hover:text-secondaryHover">
                  <IoIosArrowUp />
                </div>
                <div ref={navigationPrevRef} className="cursor-pointer text-3xl hover:text-secondaryHover">
                  <IoIosArrowDown />
                </div>
              </div>
              <div className="home-thematix-swiper">
                <Swiper
                  direction="vertical"
                  autoplay={{
                    reverseDirection: true,
                  }}
                  loop={true}
                  modules={[Navigation, Autoplay]}
                  speed={1000}
                  slideToClickedSlide={true}
                  draggable={false}
                  navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  }}
                  onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationNextRef.current;
                    swiper.params.navigation.nextEl = navigationPrevRef.current;
                  }}
                >
                  {data?.thematix_card?.map((card, index) => (
                    <SwiperSlide key={index}>
                      {({ isActive, isPrev, isNext }) => (
                        <div
                        className={`relative flex   h-[30vh] w-[30em]   -translate-x-[20rem] items-center bg-body transition-all  duration-700 ease-easeOutCubic tall:h-[15rem] ${isNext || isPrev ? `translate-x-[0]` : ``} ${isActive ? `z-10 translate-x-[10rem] scale-100` : ``} ${!isActive ? `scale-75` : ``} `}
                        >
                          <div
                            className={` flex w-full  items-center justify-between rounded-xl border border-[#8C8C8C] bg-white p-lg opacity-0 shadow-lg  transition-all  duration-700 ease-easeOutCubic ${isActive ? `border-transparent  shadow-lg` : "py-xl  shadow-none"} ${isNext || isPrev ? `opacity-[0.5]` : ``} ${isActive ? `z-10 opacity-[100]` : ``} ${dir === "rtl" && styles.thematixInnerArCard} `}
                          >
                            <div className={`w-[20%] `}>
                              <div
                                className={`absolute -left-[2%] top-1/2 w-[50%] -translate-y-1/2 -translate-x-1/2  transition-all duration-700 ease-easeOutCubic ${isActive ? "max-w-full" : "max-w-[30%]"}`}
                              >
                                <NextImage
                                  src={card?.icon}
                                  width={card?.icon?.data?.attributes?.width}
                                  height={card?.icon?.data?.attributes?.height}
                                />
                              </div>
                            </div>
                            <div className="w-[80%]">
                              <h5 className="m-0 font-bold">{card?.title}</h5>
                              <div
                                className={`${!isActive ? `max-h-0` : `max-h-[20em]`} overflow-hidden transition-all duration-1000 ease-easeOutCubic`}
                              >
                                <div className="2xl:text-md my-md text-[0.9rem] leading-[1.35] 2xl:leading-[1.5]">
                                  <HTMLRender data={card?.content} />
                                </div>
                                <Button
                                  data={{
                                    ...data?.card_button,
                                    ...(currentProductTypeKey === "invest" && { link: thematixSlug }),
                                  }}
                                />
                                {/* {thematixSlug} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>

            <div className={`w-[30%] ${dir === "rtl" && styles.thematixInnerAr}`}>
              <div className="relative max-w-[18em]">
                <NextImage
                  src={data?.thematix_logo}
                  width={data?.thematix_logo?.data?.attributes?.width}
                  height={data?.thematix_logo?.data?.attributes?.height}
                />
              </div>
              <h2 className="my-lg font-bold leading-[1.2]  ltr:font-gilroy rtl:font-dubai">{data?.main_title}</h2>
              <div className="mb-lg">
                <HTMLRender data={data?.main_text} />
              </div>
              <Button data={{ ...data?.button, ...(currentProductTypeKey === "invest" && { link: thematixSlug }) }} />
              <div className={`mt-[1rem] hidden text-sm lg:block ${styles.disclaimer}`}>
                <HTMLRender data={data?.disclaimer} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="thematix-mobile-slide block overflow-hidden lg:hidden">
        <div className="container mb-6">
          <div className="flex justify-center">
            <div className="relative max-w-[13em]">
              <NextImage
                src={data?.thematix_logo}
                width={data?.thematix_logo?.data?.attributes?.width}
                height={data?.thematix_logo?.data?.attributes?.height}
              />
            </div>
          </div>
          <h2 className="m-0 my-sm text-center text-[1.777rem] font-bold leading-[1.2] ltr:font-gilroy rtl:font-dubai md:text-h2 lg:my-8">
            {data?.main_title}
          </h2>
        </div>
        <GenericSlider autoplay={true} loop={true}>
          {data?.thematix_card?.map((card, index) => (
            <SwiperSlide key={index}>
              <div className="container pt-md">
                <div className="flex items-center px-lg">
                  <div className="relative z-10 w-[50%] ltr:pr-md rtl:pl-md">
                    <h3 className="m-0 text-h5 font-bold">{card?.title}</h3>
                  </div>
                  <div className="relative m-auto w-[50%] max-w-[15rem] lg:max-w-none">
                    <NextImage
                      src={card?.icon}
                      width={card?.icon?.data?.attributes?.width}
                      height={card?.icon?.data?.attributes?.height}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </GenericSlider>
        <div className="container">
          <div className="-mt-md mb-4 flex justify-center">
            <Button data={data?.button} />
          </div>
          <div className={` flex justify-center ${styles.disclaimer}`}>
            <HTMLRender data={data?.disclaimer} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TradeHomeThematix;
