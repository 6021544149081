import HTMLRender from '@src/components/core/HTMLRender';
import './DifficultyLevel.module.css'
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";

const DifficultyLevel = ({ level }) => {
  const { gv } = useGlobalVariable();

  if (!level) {
    return null;
  }

  return (
    <div className="flex items-center">
      <div className={`flex-shrink-0 w-[0.55rem] h-[0.55rem] rounded-full ${level === 'beginner' ? 'bg-greenCandle' : level === 'intermediate' ? 'bg-yellow' : 'bg-primary'}`} />
      <div className='ltr:pl-xs rtl:pr-xs capitalize'><HTMLRender data={level === 'beginner' ? gv("beginner") : level === 'intermediate' ? gv("intermediate") : gv("advanced")} /></div>
    </div>
  );
};

export default DifficultyLevel;
