import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';
import Link from '@components/elements/Link';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './ColumnImages.module.css';

const ColumnImages = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container' id={data?.section_layout?.section_id || ''}>
      {
        data?.section_title &&
        <div className="w-full max-w-[95%] lg:mx-auto">
          <SectionTitle data={data?.section_title} />
        </div>
      }
      <div className='flex-col md:flex-row flex justify-between items-center py-lg md:py-xl first:pt-0 last:pb-0'>
        <div className='w-full md:w-[calc(50%-2rem)]  order-3 md:order-1 text-center ltr:md:text-left rtl:md:text-right'>
          <HTMLRender data={data.content} />
          {
            data?.table_columns?.length > 0 &&
            <div className='flex flex-col md:flex-row justify-between mt-xl mb-lg md:mb-xl'>
              {
                data?.table_columns.map((table, index) => (
                  <div key={index} className='w-full flex flex-col max-w-full md:max-w-[calc(50%-0.5rem)] first:mb-lg md:first:mb-0  justify-start items-center'>
                    <div className={`w-full ${styles.tableHeader}`}><HTMLRender data={table.title} /></div>
                    <div className={`w-full h-full min-h-[11rem] ${styles.tableBody}`}>
                      <HTMLRender data={table.content} />
                    </div>
                  </div>
                ))
              }
            </div>
          }
          {
            data?.link &&
            <div className='pt-xs'>
              <Link data={data?.link} />
            </div>
          }
          {
            data?.button &&
            <div>
              <Button data={data?.button} />
            </div>
          }
        </div>
        <div className={`w-full md:w-[calc(50%-2rem)]  order-${data.image_position == 'left' ? '0' : '2'} pb-xl md:pb-0`}>
          {data?.image?.data && <NextImage src={data?.image} height={data?.image?.data?.attributes?.height} width={data?.image?.data?.attributes?.width} />}
        </div>
      </div>
      {
        data?.extra_text &&
        <div className='flex justify-center pt-lg md:pt-0'>
          <div className="text-center md:w-[40%] mx-auto">
            <HTMLRender data={data?.extra_text} />
          </div>
        </div>
      }
      {
        data?.extra_button &&
        <div className='flex justify-center pt-lg'>
          <Button data={data.extra_button} />
        </div>
      }
    </div>
  );
};

export default ColumnImages;
