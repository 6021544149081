/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import HTMLRender from "@components/core/HTMLRender";
import Button from "@components/elements/Button";
import NextImage from "@components/elements/Image";
import { FiPlay } from "@react-icons/all-files/fi/FiPlay";
import { HiOutlineChevronDown } from "@react-icons/all-files/hi/HiOutlineChevronDown";
import { AiOutlineSound } from "@react-icons/all-files/ai/AiOutlineSound";
import { useEffect, useState } from "react";
import { isIOS, isMobile } from "react-device-detect";
import { useDeviceDetectWithoutDefault } from "@utils/hooks";
import ReactPlayer from "react-player";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import styles from "./TradeHomeHero.module.css";

const TradeHomeHero = (props) => {
  const data = props.data || props;
  const device = useDeviceDetectWithoutDefault();
  const [activeVideo, setActiveVideo] = useState(data.active_video - 1 || 0);
  const [autoPlayStarted, setAutoPlayStarted] = useState(true);
  const [soundOff, setSoundOff] = useState(true);

  useEffect(() => {
    setSoundOff(true);
  }, [activeVideo]);

  if (!data) return null;

  const handleActiveVideo = (index) => {
    if (index !== activeVideo) {
      setActiveVideo(index);
    }
  };

  // filter the sliders array and only show those who do not have hide_on_production set to true
  const filteredHeroSliders = data?.trade_hero_slide?.filter((slide) =>
    process.env.NEXT_PUBLIC_APP_ENV === "production" ? slide.hide_on_production !== true : slide
  );

  return (
    <>
      {/* desktop version */}
      <div className="hidden rounded-br-[50px] rounded-bl-[50px] bg-[#111114] pt-[5rem] lg:block 2xl:pt-[7rem]">
        <div className="2xl:container-large container">
          <div className="flex w-full flex-row justify-between">
            <div className="flex w-full max-w-[52%] flex-col 2xl:max-w-[54%] 3xl:max-w-[41%]">
              {/* active slider content - left side */}
              {filteredHeroSliders?.map((slide, index) => (
                <div key={index} className={`${activeVideo === index ? "flex" : "hidden"} mt-lg  h-full flex-col`}>
                  <div className="flex h-full flex-col text-white">
                    <div className={`mb-[2rem] ${styles.title}`}>
                      <HTMLRender data={slide?.title} />
                    </div>
                    <div className={`${styles.subtitle} mb-[2rem]`}>
                      <HTMLRender data={slide?.subtitle} />
                    </div>
                    <div className={`${styles.content} mb-[2rem]`}>
                      <HTMLRender data={slide?.content} />
                    </div>
                    {slide.disclaimer && (
                      <div className={`${styles.disclaimer} mb-[2rem]`}>
                        <HTMLRender data={slide?.disclaimer} />
                      </div>
                    )}
                    <div className={`flex grow flex-col items-start ${styles.heroButton}`}>
                      <Button
                        type="primary"
                        label={slide?.cta_text}
                        link={slide?.cta_link}
                        size="lg"
                        actionType="LINK"
                        arrow="right"
                      />
                      {/* scroll icon */}
                      <div className="relative hidden grow 2xl:block">
                        <div className="mx-2xl mt-sm h-full min-h-[8.5rem] w-[1px] bg-[#313135]" />
                        <div
                          className={`absolute top-[53%] left-[50%] flex w-[1.6rem] -translate-y-1/2 -translate-x-1/2 flex-col items-center bg-[#111114]  py-sm text-lg  ${styles.mouseAnimate} `}
                        >
                          <NextImage
                            src={data?.scroll_icon}
                            width={data?.scroll_icon?.data?.attributes?.width}
                            height={data?.scroll_icon?.data?.attributes?.height}
                          />
                          <div className="-mb-[0.75rem] -mt-[0.2rem] pt-xs font-black text-[#919191]">
                            <HiOutlineChevronDown className={`animate-hide relative `} />
                            <HiOutlineChevronDown
                              className={`animate-hide relative mt-[-0.45rem] ${styles.animationDelay}`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={`active-video-container flex w-full max-w-[45%] flex-col items-end  2xl:max-w-[45%] 3xl:max-w-[56.8%] ${styles.videoSize}`}
            >
              {/* active slider video - right side */}
              {filteredHeroSliders?.map((slide, index) =>
                activeVideo === index && (
                  <div className="relative w-full pb-[106.17%]" key={index}>
                    <div className="absolute left-0 top-0 h-full w-full flex-row justify-end" key={index}>
                      <div
                        className="h-full w-full overflow-hidden rounded-xl"
                        onClick={() => handleActiveVideo(index)}
                      >
                        <div className="absolute top-0 left-0 h-full w-full">
                          {slide?.sound_option && slide?.video_stream_link && (
                            <div
                              className={`absolute top-[0.5rem] left-1/2 z-10 -translate-x-1/2 cursor-pointer text-2xl text-white ${soundOff ? `opacity-50` : `opacity-100`}`}
                              onClick={() => setSoundOff(!soundOff)}
                            >
                              {soundOff && (
                                <div className="absolute left-0 top-1/2 h-[2px] w-full -translate-y-1/2 rotate-[35deg] bg-white " />
                              )}
                              <AiOutlineSound className="" />
                            </div>
                          )}
                          {!isMobile && slide?.video_stream_link ? (
                            <ReactPlayer
                              className="react-player"
                              playing
                              width="100%"
                              height="100%"
                              muted={soundOff}
                              playsinline
                              loop
                              url={slide?.video_stream_link}
                              id={`desktop-hero-video${index}`}
                              config={{
                                file: {
                                  hlsOptions: {
                                    maxBufferSize: 2 * 1000 * 1000,
                                    maxBufferLength: 7,
                                  },
                                },
                              }}
                              onStart={() => {
                                console.log("Video started desktop");
                              }}
                              onEnded={() => {
                                console.log("Video Ended desktop");
                              }}
                              onError={() => {
                                setAutoPlayStarted(false);
                              }}
                            />
                          ) : (
                            <div className='rounded-lg overflow-hidden'>
                              <NextImage
                                src={slide?.mobile_video}
                                width={slide?.mobile_video?.data?.attributes?.width}
                                height={slide?.mobile_video?.data?.attributes?.height}
                                alt="Slide Image"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}

              {/* non-active videos */}
              <div
                className={`relative -mb-[4rem] flex -translate-y-1/2 flex-row overflow-visible  ltr:-translate-x-[8%] rtl:translate-x-[8%] 2xl:-mb-[5rem] 3xl:-mb-[6rem] ${styles.previewWrapper} w-full`}
              >
                {filteredHeroSliders?.map((slide, index) => (
                  <div
                    key={index}
                    className={`${activeVideo !== index ? "flex" : "hidden"} relative mx-xs w-full max-w-[calc(100%/3-2rem)] scale-100 cursor-pointer flex-row rounded-xl px-[5px] transition-all duration-300 ease-in-out hover:z-10  2xl:max-w-[calc(100%/3-2.5rem)] 3xl:max-w-[calc(100%/3-0.5rem)]`}
                  >
                    <div
                      className={`relative z-10 overflow-hidden rounded-xl ${styles.hoverAnimationPreviewCards}`}
                      onClick={() => handleActiveVideo(index)}
                    >
                      <div className="overflow-hidden rounded-xl shadow-[inset_0_0_0_1px_#818181] transition-all duration-[400ms] ease-InOutBackSlower">
                        <div className="absolute top-0 left-0 z-10 h-full w-full bg-black opacity-0 transition-all duration-[400ms] ease-InOutBackSlower" />
                        <NextImage
                          src={slide?.image}
                          width={slide?.image?.data?.attributes?.width}
                          height={slide?.image?.data?.attributes?.height}
                        />
                      </div>
                      <div className="absolute top-1/2 left-1/2 z-20 hidden -translate-y-1/2 -translate-x-1/2 rounded-xl border-[1px] border-white bg-transparent p-xs text-white transition-all duration-[400ms] ease-InOutBackSlower">
                        <FiPlay className="text-base" />
                      </div>
                    </div>
                    <div
                      className={`absolute top-[10%] left-1/2 -z-10 w-full max-w-[85%] -translate-x-1/2 blur-sm 2xl:top-[12%] ${styles.blurPreview}`}
                    >
                      <NextImage
                        src={slide?.image}
                        width={slide?.image?.data?.attributes?.width}
                        height={slide?.image?.data?.attributes?.height}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* mobile version */}
      <div
        className={`relative h-[calc(90vh-4.5rem)] w-screen overflow-hidden rounded-br-[20px] rounded-bl-[20px] bg-[#111114] pb-[3rem] lg:hidden ${styles.radiusWrapper}`}
      >
        {/* active mobile video */}
        {(device === "mobile" || device === "tablet") && (
          <>
            <div className="homepage-video-slide-mobile">
              {
                <Carousel
                  showThumbs={false}
                  preventMovementUntilSwipeScrollTolerance={true}
                  swipeScrollTolerance={25}
                  verticalSwipe="standard"
                  infiniteLoop={true}
                  showIndicators={false} // Add this line
                  showStatus={false} // Add this line
                  showArrows={false} // Add this line
                  onChange={(index) => setActiveVideo(index)}
                  selectedItem={activeVideo}
                >
                  {filteredHeroSliders?.map((slide, index) => (
                    <div key={index} className="relative h-[calc(90vh-4.5rem)] w-screen">
                      <div key={index} className="absolute top-0 left-0 h-[calc(90vh-4.5rem)] w-screen">
                        <div className="absolute z-10 h-full w-full bg-black opacity-40" />
                        <div className={`${styles.activeVideoMobile}`}>
                          {activeVideo === index && (
                            slide?.video_stream_link ? (
                              <ReactPlayer
                                className="react-player2"
                                playing={true}
                                muted={soundOff}
                                url={slide?.video_stream_link}
                                width="900px"
                                playsinline
                                type="application/x-mpegURL"
                                height="800px"
                                style={{ minWidth: "100%", minHeight: "100vh" }}
                                id={`desktop-hero-video-mobile${index}`}
                                onStart={() => {
                                  console.log("Video started - mobile");
                                  setAutoPlayStarted(true);
                                }}
                                onEnded={() => {
                                  console.log("Video Ended - mobile");
                                  setActiveVideo((activeVideo + 1) % filteredHeroSliders.length);
                                }}
                                onError={() => {
                                  setAutoPlayStarted(false);
                                }}
                                config={{
                                  file: {
                                    forceVideo: true,
                                    hlsOptions: {
                                      maxBufferSize: 1 * 1000 * 1000,
                                      maxBufferLength: 7,
                                    },
                                    ...(!isIOS && { forceHLS: true }),
                                  },
                                }}
                              />
                            ) : (
                              <NextImage
                                src={slide?.image_mobile}
                                layout='fill'
                              />
                            )
                          )}
                        </div>

                      </div>
                      <div className="container relative z-20 flex h-full w-full flex-col justify-end pb-lg">
                        {/* active content */}
                        <div
                          className={`${activeVideo === index ? "flex" : "hidden"
                            }  z-20 h-full flex-col items-center justify-end text-center`}
                        >
                          {slide?.sound_option && (
                            <div
                              className={`absolute bottom-[0.85rem] right-[1rem] z-10 cursor-pointer text-2xl text-white ${soundOff ? `opacity-50` : `opacity-100`
                                } z-1000`}
                              onClick={() => setSoundOff(!soundOff)}
                            >
                              {soundOff && (
                                <div className="absolute left-0 top-1/2 h-[2px] w-full -translate-y-1/2 rotate-[35deg] bg-white " />
                              )}
                              <AiOutlineSound className="" />
                            </div>
                          )}
                          <div className="relative flex flex-col text-white">
                            <div className={`${styles.mobileTitle} mb-md `}>
                              <HTMLRender data={slide?.title} />
                            </div>
                            <div className={`${styles.mobileSubtitle}`}>
                              <HTMLRender data={slide?.subtitle} />
                            </div>
                            <div className="my-lg">
                              <Button
                                type="primary"
                                label={slide?.cta_text}
                                link={slide?.cta_link}
                                size="lg"
                                actionType="LINK"
                                arrow="right"
                              />
                            </div>
                            <div className={`${styles.content} ${styles.disclaimer}`}>
                              <HTMLRender data={slide?.disclaimer} />
                            </div>
                          </div>
                        </div>
                      </div>
                      {!autoPlayStarted && (
                        <div
                          className="absolute top-0 left-0 z-50 h-full w-full"
                          onClick={() => {
                            document?.querySelector("#desktop-hero-video-mobile" + activeVideo + ">video")?.play();
                            setAutoPlayStarted(true);
                          }}
                        >
                          <div className="absolute top-0 left-0 h-full w-full bg-black opacity-40" />
                          <div
                            className={`absolute top-1/2 left-1/2 z-50 -translate-y-1/2 -translate-x-1/2 rounded-xl border-[1px] border-white bg-transparent p-xs text-white transition-all duration-[400ms] ease-InOutBackSlower ${styles.hoverAnimationPreviewCards}`}
                          >
                            <FiPlay className="text-base" />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </Carousel>
              }
            </div>
            {/* mobile thumbs */}
            <div className="homepage-video-slide-mobile-thumbs absolute top-[5rem]  z-[999] ltr:-right-[0.25rem] rtl:-left-[0.25rem] md:top-1/2 md:-translate-y-1/2">
            {filteredHeroSliders?.map((slide, index) => (
              <div 
                key={index} 
                className="slide-thumb p-3" 
                onClick={() => setActiveVideo(index)} // Add this line
              >
                <div className="relative flex justify-end">
                  {/* progress background */}
                  <div
                    className={`${activeVideo === index
                        ? "top-[-3.15%] h-[5.5rem] rounded-full"
                        : "top-[-10%] h-[3.4rem] rounded-full bg-transparent"
                      } absolute left-[-0.8%] w-[3.25rem] transition-all duration-700 ease-in-out `}
                  />
                  {/* icons */}
                  <div
                    className={`${activeVideo === index
                        ? "h-[4.5rem]  rounded-full border-primary"
                        : "h-[2.25rem] rounded-full border-[#808189]"
                      } relative  mt-[2px]  w-[2.25rem] scale-100 cursor-pointer overflow-hidden border-[2px] transition-all duration-700 ease-in-out `}
                  >
                    <div className="relative z-10 h-full">
                      <NextImage src={slide?.image_mobile?.data?.attributes?.url} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TradeHomeHero;
