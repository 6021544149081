import NextImage from '@src/components/elements/Image';
import style from './ArticleAnalystBox.module.css'
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import HTMLRender from '@src/components/core/HTMLRender';
import { GrTwitter } from "@react-icons/all-files/gr/GrTwitter";
import { GrLinkedin } from "@react-icons/all-files/gr/GrLinkedin";

const ArticleAnalystBox = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable();

  if (!data) {
    return null;
  }
  return (
    <div className="bg-secondary text-white px-md py-lg lg:p-xl rounded-lg mb-2xl">
      <div className='flex items-center lg:items-start'>
        <div className="flex-shrink-0 w-[2.75rem] h-[2.75rem] lg:w-[8rem] lg:h-[8rem] rounded-full relative overflow-hidden bg-[#DEDEDE]">
          <NextImage
            priority
            src={data?.image_variant_b?.formats?.thumbnail?.url}
            alt={data?.name}
            width={data?.image_variant_b?.formats?.thumbnail?.width}
            height={data?.image_variant_b?.formats?.thumbnail?.height}
          />
        </div>
        <div className="grow  ltr:pl-md rtl:pr-md lg:ltr:pl-xl lg:rtl:pr-xl">
          <div>
            <div className="flex items-center justify-between pb-xs lg:pb-md">
              <div className="text-lg font-bold ">
                {data?.name}
              </div>
              <div className=" items-center text-2xl -mx-xs hidden lg:flex">
                <a href={data?.twitter_url} target="_blank" className='px-xs hover:opacity-70 block text-white hover:text-white'><GrTwitter /></a>
                <a href={data?.linkedin_url} target="_blank" className='px-xs hover:opacity-70 block text-white hover:text-white'><GrLinkedin /></a>
              </div>
            </div>
            <div className='lg:pb-lg'>{gv('financial_writer')}</div>
            <div className='hidden lg:block'>
              <div className={style.description}><HTMLRender data={data?.short_description} /></div>
            </div>
          </div>
        </div>
      </div>
      <div className='block lg:hidden pt-md'>
        <div className={style.description}><HTMLRender data={data?.short_description} /></div>
      </div>
      <div className=" items-center text-2xl -mx-xs flex justify-center pt-md lg:hidden">
        <a href={data?.twitter_url} target="_blank" className='px-xs hover:opacity-70 block text-white hover:text-white'><GrTwitter /></a>
        <a href={data?.linkedin_url} target="_blank" className='px-xs hover:opacity-70 block text-white hover:text-white'><GrLinkedin /></a>
      </div>
    </div>
  );
};

export default ArticleAnalystBox;
