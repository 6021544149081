import NextImage from '@src/components/elements/Image';
import styles from './OnboardingFeatures.module.css';
import SectionTitle from '@src/components/elements/SectionTitle';
import HTMLRender from '@components/core/HTMLRender';
import { Accordion } from '@mantine/core';
import Arrow from '@src/components/elements/Arrow';

const OnboardingFeatures = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      <div className='flex flex-col md:flex-row justify-between'>
        <div className='w-full md:max-w-[calc(100%/2-3rem)] order-1 md:order-[0]'>
          <div className={styles.title}>
            <SectionTitle data={data?.section_title} />
          </div>
          <div className='md:hidden w-full my-xl xs:my-[4rem]'>
            <NextImage src={data?.image} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
          </div>
          <div className='md:mt-lg mb-lg md:mb-0'>
            <Accordion
              styles={{ icon: { color: '#000' } }}
              classNames={{
                control: styles.AccordionControl,
                root: styles.AccordionRoot,
                itemOpened: styles.itemOpened,
                label: styles.AccordionLabel,
                item: styles?.AccordionItem,
                contentInner: styles?.contentInner,
                icon: styles.AccordionIcon,
                content: styles?.AccordionContent
              }}
              variant="unstyled"
              transitionDuration={500}
              icon={<Arrow direction="right" />}
              initialItem={data?.initialItem || null}
            >
              {data?.features_accordion?.map((feature, index) => (
                <Accordion.Item key={index} label={<HTMLRender data={feature?.title} />}>
                  <HTMLRender data={feature?.content} />
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
        <div className='hidden md:block w-full md:max-w-[calc(100%/2-3rem)]'>
          <NextImage src={data?.image} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
        </div>
      </div>
    </div>
  );
};

export default OnboardingFeatures;
