import NewsCard from "@components/custom/NewsCard";
import SectionTitle from "@components/elements/SectionTitle";
import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import { useStore } from "@lib/store";
import useLocale from "@lib/store/hooks/useLocale";
import { Skeleton } from "@mantine/core";
import { shallow } from "zustand/shallow";
import get from "lodash/get";
import "./LatestArticles.module.css";

function getRandomWidth(min, max) {
  return Math.random() * (max - min) + min;
} // Generate random widths for the skeleton.

const LatestArticles = (props) => {
  const data = props.data || props;
  const locale = useLocale();
  const categoryId = get(data, "latest_articles.data.0.id");
  const {
    data: article,
    isValidating,
    error,
  } = useSwrHook("getArticles", {
    query: {
      locale,
      populate: {
        global_analyst: {
          fields: ["name"],
        },
        image: {
          fields: ["url", "width", "height"],
        },
      },
      filters: {
        articles_category: {
          id: {
            $eq: categoryId,
          },
        },
      },
      fields: ["title", "slug", "show_date", "updatedAt"],
      sort: ["updatedAt:desc"],
      pagination: {
        start: 0,
        limit: 3,
      },
    },
  });
  if (!article || !article?.data?.length === 0) {
    return null;
  }
  return (
    <div className="container">
      <SectionTitle data={data.section_title} />
      <div className="flex flex-col justify-between  md:flex-row ">
        {article?.data?.map((newsCard, index) => (
          <div className="mb-[3.33rem] last:mb-0 md:w-[calc(33.3%-0.5rem)] lg:mb-0" key={index}>
            {isValidating ? (
              <>
                <Skeleton height={190} mb="xl" />
                <Skeleton height={8} mt={6} width={`${getRandomWidth(20, 100)}%`} radius="xl" />
                <Skeleton height={8} mt={6} radius="xl" width={`${getRandomWidth(20, 100)}%`} mb="xl" />
                <Skeleton height={8} mt={6} width={`${getRandomWidth(20, 80)}%`} radius="xl" />
              </>
            ) : (
              <NewsCard data={{ ...newsCard?.attributes }} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LatestArticles;
