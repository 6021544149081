import { useEffect, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";

// Got from https://usehooks.com/useLockBodyScroll/
export function useLockBodyScroll() {
  useEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;

    // Prevent scrolling on mount
    document.body.style.overflow = "hidden";

    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, []); // Empty array ensures effect is only run on mount and unmount
}

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

function useScrollDirection() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [direction, setDirection] = useState('up');

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      if (currentScrollPosition > scrollPosition) {
        setDirection('down');
      } else {
        setDirection('up');
      }
      setScrollPosition(currentScrollPosition);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollPosition]);

  return direction;
}

export default useScrollDirection;

/**
 * Returns a string representing the device
 *
 * desktop, mobile or tablet
 *
 * @returns  {String} {ex: "desktop"}
 */

export function useDeviceDetect() {
  const [device, setDevice] = useState("desktop");
  const desktop = useMediaQuery("(min-width: 1024px)", false);
  const tablet = useMediaQuery("(min-width: 768px)", false);
  const mobile = useMediaQuery("(max-width: 767px)", false);
  let deviceType = device;
  useEffect(() => {
    switch (true) {
      case desktop:
        setDevice("desktop");
        deviceType = "desktop";
        break;
      case tablet:
        setDevice("tablet");
        deviceType = "tablet";
        break;
      case mobile:
        setDevice("mobile");
        deviceType = "mobile";
        break;
      default:
        setDevice("desktop");
        deviceType = "desktop";
        break;
    }
  }, [desktop, tablet, mobile]);

  return deviceType;
}
export function useDeviceDetectWithoutDefault() {
  const [device, setDevice] = useState(false);
  const desktop = useMediaQuery("(min-width: 1024px)", false);
  const tablet = useMediaQuery("(min-width: 768px)", false);
  const mobile = useMediaQuery("(max-width: 767px)", false);
  useEffect(() => {
    switch (true) {
      case desktop:
        setDevice("desktop");
        break;
      case tablet:
        setDevice("tablet");
        break;
      case mobile:
        setDevice("mobile");
        break;
      default:
        break;
    }
  }, [desktop, tablet, mobile]);

  return device;
}
