import GenericSlider from '@components/custom/GenericSlider';
import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';
import useModalHook from '@lib/modals';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import HTMLRender from '@src/components/core/HTMLRender';
import { SwiperSlide } from 'swiper/react';
import styles from './WebinarHero.module.css'

const WebinarHero = (props) => {
  const { gv } = useGlobalVariable();
  const data = props.data || props;
  const featuredAnalysts = data?.global_analysts?.data.slice(0, 2) || [];
  const modal = useModalHook();
  if (!data) {
    return null;
  }


  return (
    <>
      {/* Desktop Version */}
      <div className="container">
        <div className="hidden flex-row-reverse justify-between lg:flex">
          {featuredAnalysts.map((analyst, index) => (
            <div
              key={index}
              className={`relative flex w-1/2 basis-[50%] ${featuredAnalysts.length > 1
                ? `first:justify-end last:justify-start`
                : `justify-end`
                }`}
            >
              <NextImage
                src={analyst?.attributes?.image_variant_b}
                width={
                  analyst?.attributes?.image_variant_b?.data
                    ?.attributes?.width
                }
                height={
                  analyst?.attributes?.image_variant_b?.data
                    ?.attributes?.height
                }
              />
              <div
                className={`${styles.textWrapper} ${index === 0 ? `ltr:left-auto ltr:right-[52%] rtl:right-auto rtl:left-[45%]` : `ltr:left-[40%] rtl:right-[55%]`
                  }`}
              >
                <h3 className="text-dark">
                  {analyst?.attributes?.name}
                </h3>
                <Button
                  onClick={() => {
                    modal.open('analystModal', {
                      innerProps: analyst,
                    });
                  }}
                  arrow="none"
                  label={gv('read_more_about', {
                    template: {
                      about:
                        analyst?.attributes?.name.split(' ')[0] || '',
                    },
                  })}
                  size="sm"
                  type="link"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Mobile Version */}
      <div className="webinar-slider block lg:hidden">
        <GenericSlider pagination loop slidesPerView="1">
          {featuredAnalysts.map((analyst, index) => (
            <SwiperSlide key={index}>
              <div className="h-[10rem] md:h-[15rem]">
                <div className="container relative flex h-full flex-col items-start justify-end">
                  <p className={`text-secondary h-full w-full max-w-[60%] basis-[60%] ${styles.shortDescription}`}>
                    <HTMLRender data={analyst?.attributes?.short_description} />
                  </p>
                  <div className="absolute bottom-[-15%] top-auto ltr:right-[5px] rtl:left-[5px] h-full  max-w-[50%] md:max-w-[35%]">
                    <NextImage
                      src={analyst?.attributes?.image_variant_b}
                      width={
                        analyst?.attributes?.image_variant_b?.data
                          ?.attributes?.width
                      }
                      height={
                        analyst?.attributes?.image_variant_b?.data
                          ?.attributes?.height
                      }
                    />
                  </div>
                </div>
                <div
                  className={`${styles.buttonReadMore} bg-secondary py-md container pb-[2rem]`}
                >
                  <Button
                    onClick={() => {
                      modal.open('analystModal', {
                        innerProps: analyst,
                      });
                    }}
                    label={gv('read_more_about', {
                      template: {
                        about:
                          analyst?.attributes?.name.split(' ')[0] || '',
                      },
                    })}
                    size="sm"
                    type="link"
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </GenericSlider>
      </div>
    </>
  );
};

export default WebinarHero;
