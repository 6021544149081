import SectionTitle from '@src/components/elements/SectionTitle';
import styles from './TradingViewHero.module.css';
import NextImage from '@src/components/elements/Image';
import Button from '@src/components/elements/Button';
import { getStrapiMedia } from '@utils/media';

const TradingViewHero = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      <div className='flex flex-col lg:flex-row justify-between items-center'>
        <div className={`w-full lg:max-w-[calc(100%/2-5rem)] ${styles.title}`}>
          <SectionTitle data={data?.section_title} />
          <div className='hidden lg:flex justify-start'>
            <Button data={data?.button} />
          </div>
        </div>
        <div className='w-full text-center mt-0 video-no-border'>
          <video className='h-auto lg:h-[434px] max-w-full lg:max-w-none ' muted playsInline autoPlay preload="none" loop >
            <source src={getStrapiMedia(data?.video?.data?.attributes?.url)} type="video/mp4" />
            <source src={getStrapiMedia(data?.video?.data?.attributes?.url)} type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className='flex lg:hidden justify-center mt-lg'>
          <Button data={data?.button} />
        </div>
      </div>
    </div>
  );
};

export default TradingViewHero;
