import SectionTitle from '@src/components/elements/SectionTitle';
import styles from './TradingViewSteps.module.css';
import Button from '@src/components/elements/Button';
import NextImage from '@src/components/elements/Image';
import HTMLRender from '@src/components/core/HTMLRender';
import Image from 'next/image';

const TradingViewSteps = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      <SectionTitle data={data?.section_title} />
      <div className='flex flex-col lg:flex-row justify-between'>
        {data?.steps?.map((step, index) => (
          <div key={index} className='flex flex-col items-center w-full lg:max-w-[calc(100%/4-1rem)] text-center relative mb-2xl last:mb-0 lg:mb-0 trade-view-steps'>
            <div className='absolute top-[1rem] ltr:-right-[1.5rem] rtl:-left-[1.5rem] z-10 hidden lg:block steps-arrow rtl:rotate-180 h-[8px] leading-[5px]'>
              <NextImage
                src="https://cdn.capex.com/uploads/arrow_left_grey_7d9294e2aa/arrow_left_grey_7d9294e2aa.png"
                width={33}
                height={8}
              />
            </div>
            <NextImage src={step?.icon?.data?.attributes?.url} width={44} height={44} />
            <p className='font-bold text-lg py-md mb-0 px-[2rem]'>{step?.title}</p>
            <HTMLRender data={step?.content} />
            <div className='absolute -bottom-[3.5rem] left-1/2 -translate-x-1/2 z-10 lg:hidden steps-arrow rotate-90 h-[8px] leading-[5px]'>
              <NextImage
                src="https://cdn.capex.com/uploads/arrow_left_grey_7d9294e2aa/arrow_left_grey_7d9294e2aa.png"
                width={33}
                height={8}
              />
            </div>
          </div>
        ))}
      </div>
      <div className='flex justify-center mt-[3rem] lg:mt-[5rem]'>
        <Button data={data?.cta} />
      </div>
    </div>
  );
};

export default TradingViewSteps;
