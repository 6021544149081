import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import { useMemo, useState } from "react";
import InstrumentRow from "../InstrumentRow";
import styles from "../../TradingCInstruments.module.css";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { useLocalStorage } from "@mantine/hooks";
import InstrumentDMARow from "../InstrumentDMARow";

export default function InvestTradingConditions({ productType }) {
  const [activeCategory, setActiveCategory] = useState(0);
  const [page, setPage] = useState(1);
  const { gv } = useGlobalVariable();
  const {
    data: tradingConditionData,
    isValidating,
    error,
  } = useSwrHook("getDealingDataPlatformDMA", {
    query: {
      populate: "*",
      filters: {
        type: "dma_trading_conditions",
      },
      locale: "all",
      sort: ["publishedAt:desc"],
    },
  });

  const TableHeaders = useMemo(() => {
    try {
      // const HeaderKeys = Object.keys(tradingConditionData?.data?.[0]?.attributes?.json[0]);
      const HeaderKeys = ["country", "trading_hours", "commissions"];
      return [
        Object.assign(
          {},
          ...HeaderKeys.map((el) => ({
            [el]: el,
          }))
        ),
      ];
    } catch (err) {
      return [];
    }
  }, [isValidating, tradingConditionData]);

  return (
    <>
      <div className="trading-conditions-invest-container-desktop -mt-lg hidden flex-col lg:block">
        <div className="trading-conditions-invest-header mt-md flex w-full items-center justify-between bg-[#fafafa] text-sm font-bold">
          <p className={styles.listHeader}>{gv("country")}</p>
          <p className={styles.listHeader}>{gv("trading_hours_gmt")}</p>
          <p className={styles.listHeader}>{gv("comission")}</p>
        </div>
        {tradingConditionData?.data?.[0]?.attributes?.json?.map((instrument, index) => (
          <InstrumentDMARow
            cssParent="instrument-row-invest-desktop flex justify-between items-center w-full text-sm border-b border-b-[#eeeff2]"
            productType={productType}
            headers={TableHeaders}
            cssClass="instrumentStyles"
            key={index}
            rowData={instrument}
          />
        ))}
      </div>
      <div className="trading-conditions-invest-container-mobile block lg:hidden">
        <div className="flex">
          <div className="sticky block h-full items-center bg-[#fafafa]">
            <p className={styles.listHeaderMobile}>{gv("country")}</p>
            <p className={styles.listHeaderMobile}>{gv("trading_hours_gmt")}</p>
            <p className={styles.listHeaderMobile}>{gv("comission")}</p>
          </div>
          <div className="flex overflow-x-scroll">
            {tradingConditionData?.data?.[0]?.attributes?.json?.map((instrument, index) => (
              <InstrumentDMARow
                cssParent="instrument-row-invest-mobile  flex flex-col justify-between items-center w-full"
                productType={productType}
                headers={TableHeaders}
                cssClass="instrumentStylesMobile"
                key={index}
                rowData={instrument}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
