import HTMLRender from '@components/core/HTMLRender';
import Link from '@components/elements/Link';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './ForexPairs.module.css';

const ForexPairs = (props) => {
  const data = props.data || props

  if (!data) {
    return null;
  } return (

    <div className="container">
      <SectionTitle data={data?.section_title} />
      <div className='flex flex-col md:flex-row justify-between items-start mb-xl'>
        {data.simple_column.map((column, index) => (
          <div key={index} className='w-full mb-[2.222rem] lg:w-[calc(33.33%-1.111rem)]'>
            <div className="relative mb-lg md:mb-[2.777rem]">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-start">
                <h3 className="ltr:pr-2 rtl:pl-2 md:text-[2rem] bg-white text-[#b8b8b8] ltr:font-black rtl:font-bold">{column.title}</h3>
              </div>
            </div>
            <div>
              <HTMLRender data={column.content} />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.link}>
        <Link data={data.link} />
      </div>
    </div>
  );
};

export default ForexPairs;
