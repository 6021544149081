import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';
import { useDeviceDetect } from '@utils/hooks';
import './IconTextColumn.module.css'

const IconTextColumn = (props) => {
  const data = props.data || props;
  const length = props.length || 0;
  const device = useDeviceDetect();

  if (!data) {
    return null;
  }
  const styles = {
    width: `${device == 'mobile' ? '100%' : length < 3 ? 'calc(50% - 2rem)' : length >= 3 ? 'calc(33.33% - 1rem)' : '100%'}`,
  }
  return (
    <div style={styles} className={`flex flex-col items-center  flex-wrap h-full mb-xl last:mb-0 ${props?.align ? `` : `md:items-start`}`}>
      <div>
        <NextImage src={data?.icon} width={data?.icon?.data?.attributes?.width} height={data?.icon?.data?.attributes?.height} />
      </div>
      {
        data?.title &&
        <h3 className='mt-md mb-0'>{data?.title}</h3>
      }
      <div className="mt-[1.667rem]">
        <HTMLRender data={data?.content} />
      </div>
    </div>
  );
};

export default IconTextColumn;
