import styles from './SponsorshipsHero.module.css';
import HTMLRender from '@components/core/HTMLRender';
import { FiChevronDown } from '@react-icons/all-files/fi/FiChevronDown';

const SponsorshipsHero = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <>
      <div className='container relative h-full'>
        <div className={`${styles.title} lg:max-w-[80%] text-center mx-auto flex flex-col h-full justify-center items-center`}>
          <HTMLRender data={data?.content} />
          <a href='#juventusSponsorship'>
            <FiChevronDown className='text-dark text-3xl cursor-pointer text-center animate-bounce mt-xl' />
          </a>
        </div>
      </div>
    </>
  );
};

export default SponsorshipsHero;
