import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import { GiCheckMark } from '@react-icons/all-files/gi/GiCheckMark';
import styles from './AboutUsGlobal.module.css';

const AboutUsGlobal = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <>
      <div className='flex justify-center md:-mt-[3rem] lg:-mt-[8rem]'><NextImage src={data?.image} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} /></div>
      <div className='container'>
        <SectionTitle data={data?.section_title} />
        <div className="flex flex-col md:flex-row justify-between items-start">
          <div className='mb-lg md:mb-0 w-full md:max-w-[calc(50%-1rem)]'>
            <HTMLRender data={data?.content} />
          </div>
          <div className='w-full md:max-w-[calc(50%-1rem)]'>
            <ul>
              {
                data?.check_list.map((item, index) => (
                  <li className="bg-[#f8f8f8] rounded-md py-sm px-md flex mt-md first:mt-0" key={index}>
                    <GiCheckMark className="text-secondary ltr:mr-[1em] rtl:ml-[1em] mt-[0.5em] text-[0.9em]" />
                    <div className={styles.list_content}><HTMLRender data={item?.content} /></div>
                  </li>
                )
                )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsGlobal;
