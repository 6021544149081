import dynamic from 'next/dynamic'

const DynamicThematixChart = dynamic(() => import('./components/CustomThematixChart'), {
  ssr: true,
})

export default function ThematixChart(props){
  return <DynamicThematixChart {...props} />
}

