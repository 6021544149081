import IPOBanner from '@components/custom/IPOBanner';
import SectionTitle from '@components/elements/SectionTitle';
import GenericSlider from '@src/components/custom/GenericSlider';
import { SwiperSlide } from 'swiper/react';
import './IpoUpcoming.module.css';

const IpoUpcoming = (props) => {
  const data = props.data || props;

  if (!data) {
    return null;
  }
  return (
    <>
      <SectionTitle data={data?.section_title} />
      <div className="container">
        {
          data?.ipos?.data?.map((ipo, index) => (
            <div className='hidden lg:block' key={index}>
              <IPOBanner data={ipo} />
            </div>
          ))
        }
        <div className="lg:hidden">
          <GenericSlider
            loop
            navigation
            pagination
            // autoplay
            slidesPerView={1}
            spaceBetween={20}
            autoplayDelay={7000}
          >
            {data?.ipos?.data?.map((ipo, index) => (
              <SwiperSlide key={index}>
                <IPOBanner data={ipo} />
              </SwiperSlide>
            ))}
          </GenericSlider>
        </div>
      </div>
    </>
  );
};

export default IpoUpcoming;
