import styles from "./InstrumentInfo.module.css";
import Button from "@components/elements/Button";
import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { useRegistrationLink } from "@lib/store/hooks/useActiveProductType";

const InstrumentInfo = (props) => {
  const instrument = props.data || props;
  const { gv } = useGlobalVariable();
  const registrationLink = useRegistrationLink();
  const { data, error } = useSwrHook(!instrument ? null : "getDealingData", {
    query: { instrument: instrument?.api_id },
  });
  if (!data || error) return null;
  const DEALING_DATA = data?.[instrument?.api_id];
  if (!instrument) {
    return null;
  }
  return (
    <section>
      <div className="container">
        <div className={`${styles[`instrument-spread`]}`}>
          <div className="grid gap-8 pt-xl pb-xl lg:grid-cols-3 lg:pt-2xl">
            <div className={`${styles[`instrument-spread-column`]}`}>
              {DEALING_DATA?.spreadPerUnit && (
                <div>
                  <div>{gv("spread_per_unit")}</div>
                  <div className="font-bold text-dark ltr:font-gilroy rtl:font-dubai">{DEALING_DATA.spreadPerUnit}</div>
                </div>
              )}
              {DEALING_DATA?.overnightInterestBuy && (
                <div>
                  <div>{gv("overnight_rollover_long")}</div>
                  <div className="font-bold text-dark ltr:font-gilroy rtl:font-dubai">
                    {DEALING_DATA.overnightInterestBuy}
                  </div>
                </div>
              )}
              {DEALING_DATA?.initialMargin && (
                <div>
                  <div>{gv("initial_margin")}</div>
                  <div className="font-bold text-dark ltr:font-gilroy rtl:font-dubai">{DEALING_DATA.initialMargin}</div>
                </div>
              )}
            </div>
            <div className={`${styles[`instrument-spread-column`]}`}>
              {DEALING_DATA?.leverage && (
                <div>
                  <div>{gv("leverage")}</div>
                  <div className="font-bold text-dark ltr:font-gilroy rtl:font-dubai">{DEALING_DATA.leverage}</div>
                </div>
              )}
              {DEALING_DATA?.overnightInterestSell && (
                <div>
                  <div>{gv("overnight_rollover_short")}</div>
                  <div className="font-bold text-dark ltr:font-gilroy rtl:font-dubai">
                    {DEALING_DATA.overnightInterestSell}
                  </div>
                </div>
              )}

              {DEALING_DATA?.maintenanceMargin && (
                <div>
                  <div>{gv("maintenance_margin")}</div>
                  <div className="font-bold text-dark ltr:font-gilroy rtl:font-dubai">
                    {DEALING_DATA.maintenanceMargin}
                  </div>
                </div>
              )}
            </div>
            <div className="flex items-center justify-center pt-lg lg:justify-end lg:pt-0">
              <Button
                type="white"
                link={registrationLink}
                labelPos="below"
                size="lg"
                arrow="right"
                label={gv("start_trading_now")}
                subLabel={gv("create_account_and_trade")}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InstrumentInfo;
