import styles from "@styles/article-typography.module.css";
import { IoCalendarOutline } from "@react-icons/all-files/io5/IoCalendarOutline";
import dayjs from "dayjs";
import NextImage from "@components/elements/Image";
import HTMLRender from "@components/core/HTMLRender";
import Logo from "@components/elements/Logo";
import Link from "@components/elements/Link";
import { useRouter } from "next/router";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { GrFacebook } from "@react-icons/all-files/gr/GrFacebook";
import { GrLinkedin } from "@react-icons/all-files/gr/GrLinkedin";
import { GrTwitter } from "@react-icons/all-files/gr/GrTwitter";
import ArticleNavigation from "@components/custom/ArticleNavigation";
import ArticleSidebar from "@components/custom/ArticleSidebar";
import get from "lodash/get";
import { useStore } from "@lib/store";
import { Fragment, useState, useEffect, useRef } from "react";
import useLocale from "@lib/store/hooks/useLocale";
import { Accordion } from "@mantine/core";
import { RiArrowDownSFill } from "@react-icons/all-files/ri/RiArrowDownSFill";
import { FAQPageJsonLd, NextSeo } from "next-seo";
import htmlToCleanText from "@utils/html-to-text";
import Image from "next/image";
import DifficultyLevel from "@src/components/elements/DifficultyLevel";

import flattenDeep from "lodash/flattenDeep";
import ArticleSidebarInstrument from "@src/components/custom/ArticleSidebarInstrument";
import ArticleAnalystBox from "@src/components/custom/ArticleAnalystBox";
import ArticleRating from "@src/components/custom/ArticleRating";
import AcademyExplore from "@src/components/custom/AcademyExplore";
import ArticleShareBox from "@src/components/custom/ArticleShareBox";
import useActiveProductType from "@lib/store/hooks/useActiveProductType";
import NextLink from "@components/elements/Link";
import { HiOutlineChevronDown } from "@react-icons/all-files/hi/HiOutlineChevronDown";
import { useIntersection } from "@mantine/hooks";

const formatDate = (date) => {
  return dayjs(date).format("DD MMMM YYYY");
};

export default function LayoutArticle({ pageData }) {
  const locale = useLocale();
  const router = useRouter();
  const { gv } = useGlobalVariable();
  if (!pageData?.table_of_contents) {
    pageData.table_of_contents = "sidebar";
  }
  const absoluteUrl = process.env.NEXT_PUBLIC_CURRENT_ENV + "/" + router.locale + router.asPath;
  const store = useStore((store) => ({
    locale: store.locale,
    tableOfContents: store.tableOfContents,
    headerHeight: store.headerHeight,
  }));
  const currentProductTypeKey = useStore((store) => store.currentProductTypeKey);

  const containerRef = useRef();
  const [ref, observedEntry] = useIntersection({
    root: containerRef.current,
    threshold: 0,
  });
  const inView = observedEntry && observedEntry.isIntersecting;

  useEffect(() => {
    const body = document.querySelector("body");
    if (!inView) {
      body.classList.add("show-sticky");
    } else {
      body.classList.remove("show-sticky");
    }
  }, [inView]);

  let readingTime = pageData?.content ? Math.ceil(htmlToCleanText(pageData.content).split(" ").length / 238) : 0;
  if (readingTime === 1) {
    readingTime = 2;
  }

  return (
    <>
      <NextSeo
        twitter={{
          handle: pageData?.global_analyst?.name || 'CAPEX.com',
          site: 'CAPEX.com',
          cardType: 'summary_large_image',
        }}
        openGraph={{
          images: [{
            url: pageData?.image?.url,
            width: pageData?.image?.width,
            height: pageData?.image?.height,
          }]
        }}
      />
      {pageData?.faq_list && pageData?.faq_list?.length > 0 && (
        <FAQPageJsonLd
          mainEntity={pageData?.faq_list?.map((item) => ({
            questionName: htmlToCleanText(item.title),
            acceptedAnswerText: htmlToCleanText(item.content),
          }))}
        />
      )}

      <div className="relative mb-[12rem] bg-[#111114] pt-0 text-white lg:pt-2xl">
        <div className="pt-[4.8888rem]" />
        {/* eslint-disable-next-line */}
        {currentProductTypeKey === "invest" ? (
          <>
            <div className="hidden sm:block">
              <NextImage
                layout="fill"
                objectFit="cover"
                src="https://cdn.capex.com/uploads/invest_hero_bg_0f55a91363/invest_hero_bg_0f55a91363.png"
                priority
                alt="Article Hero"
                quality={100}
              />
            </div>
            <div className="block sm:hidden">
              <NextImage
                layout="fill"
                objectFit="cover"
                src="https://cdn.capex.com/uploads/invest_hero_bg_mobile_f48322fdcd/invest_hero_bg_mobile_f48322fdcd.png"
                priority
                alt="Article Hero"
                quality={100}
              />
            </div>
          </>
        ) : currentProductTypeKey === "crypto" ? (
          <>
            <div className="hidden sm:block">
              <NextImage
                layout="fill"
                objectFit="cover"
                src="https://cdn.capex.com/uploads/crypto_hero_bg_d461a79c2a/crypto_hero_bg_d461a79c2a.png"
                priority
                alt="Article Hero"
                quality={100}
              />
            </div>
            <div className="block sm:hidden">
              <NextImage
                layout="fill"
                objectFit="cover"
                src="https://cdn.capex.com/uploads/crypto_hero_bg_mobile_124e0019e0/crypto_hero_bg_mobile_124e0019e0.png"
                priority
                alt="Article Hero"
                quality={100}
              />
            </div>
          </>
        ) : (
          <>
            <div className="hidden sm:block">
              <NextImage
                layout="fill"
                objectFit="cover"
                src="https://cdn.capex.com/uploads/trade_hero_bg_1b9c248b2a/trade_hero_bg_1b9c248b2a.png"
                priority
                alt="Article Hero"
                quality={100}
              />
            </div>
            <div className="block sm:hidden">
              <NextImage
                layout="fill"
                objectFit="cover"
                src="https://cdn.capex.com/uploads/trade_hero_bg_mobile_7245f455ce/trade_hero_bg_mobile_7245f455ce.png"
                priority
                alt="Article Hero"
                quality={100}
              />
            </div>
          </>
        )}
        <div ref={ref} className="container relative">
          <div className="flex items-center justify-center lg:justify-between">
            <div className={`${styles[`article-breadcrumbs`]} hidden lg:flex`}>
              {pageData?.breadcrumb?.map((item, index) => (
                <Fragment key={index}>
                  <Link color="white" link={item.slug} text={item.name} />
                  <div className="mx-xs">/</div>
                </Fragment>
              ))}
              <div className="max-w-[18em]  truncate">{pageData?.title}</div>
            </div>
          </div>
          <div className="flex flex-col justify-between  pt-lg lg:flex-row lg:py-xl lg:pb-lg">
            <h1 className="m-0 px-0 pb-[2.2rem] text-center font-bold lg:pb-0 lg:text-[2.22rem] lg:ltr:pr-lg lg:ltr:text-left lg:rtl:pl-lg lg:rtl:text-right">
              {pageData?.title}
            </h1>
            <div className="flex flex-shrink-0 items-start justify-between pb-lg lg:justify-start lg:pb-0 lg:pt-[0.3rem]">
              <div className="lowercase">
                {readingTime} {gv("minutes")}
              </div>
              {pageData?.difficulty_level && (
                <div className="ltr:pl-lg rtl:pr-lg">
                  <DifficultyLevel level={pageData?.difficulty_level} />
                </div>
              )}
            </div>
          </div>
          <div className="-mx-md sm:mx-0">
            <div
              className={`${styles[`article-image`]
                } relative -mb-[10.5rem] h-[12rem] w-full overflow-hidden rounded-lg lg:-mb-[12rem] lg:h-[24rem] `}
            >
              <NextImage
                priority
                src={get(pageData, "image", undefined)}
                alt={get(pageData, "image.alternativeText") || get(pageData, "image.data.attributes.alternativeText")}
                width={get(pageData, "image.data.attributes.width", undefined)}
                height={get(pageData, "image.data.attributes.height", undefined)}
                quality={100}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <div className="flex flex-col items-start justify-between border-b border-lightGrey pb-md pt-0 lg:flex-row lg:items-center lg:pt-lg">
            <div className="flex  items-center">
              {pageData?.global_analyst && (
                <>
                  <div className="relative h-[30px] w-[30px] overflow-hidden rounded-full bg-[#DEDEDE]">
                    <NextImage
                      priority
                      src={pageData?.global_analyst?.image_variant_a?.formats?.thumbnail?.url}
                      alt={pageData?.global_analyst?.name}
                      width={pageData?.global_analyst?.image_variant_a?.formats?.thumbnail?.width}
                      height={pageData?.global_analyst?.image_variant_a?.formats?.thumbnail?.height}
                    />
                  </div>
                  <span className=" text-darkGrey ltr:pl-xs rtl:pr-xs">{pageData?.global_analyst?.name}</span>
                </>
              )}
            </div>
            <div className="mt-sm  flex items-center lg:mt-0">
              <IoCalendarOutline className="text-lg ltr:mr-xs rtl:ml-xs" />
              <span>{formatDate(pageData?.updatedAt)}</span>
            </div>
          </div>

          <div className={`relative py-xl ${styles[`article-text`]}`}>
            {pageData?.table_of_contents === "sidebar" && (
              <div style={{ top: store.headerHeight + 'px' }} className="sticky left-0   hidden h-0 w-[14rem] ltr:-translate-x-full rtl:translate-x-full 2xl:block">
                <div className="relative ltr:right-[1.111rem] rtl:left-[1.111rem]">
                  {store?.tableOfContents?.length > 0 && (
                    <div className="mb-lg rounded-lg bg-[#F8F6F6] px-md py-lg">
                      <h5 className={`mt-0 font-semibold ${styles.noMarginTop}`}> {gv("table_of_contents")}</h5>
                      {store?.tableOfContents?.map((title, index) => (
                        <div className="relative flex py-xs text-sm" key={index}>
                          <div className={styles.redCircle} />
                          <a className={`ltr:pl-sm rtl:pr-sm  ${styles.tocLink}`} href={"#toc_title_" + (index + 1)}>
                            {title}
                          </a>
                        </div>
                      ))}
                    </div>
                  )}
                  <ArticleShareBox academy small title={pageData?.title} />
                </div>
              </div>
            )}

            <div className="mb-xl text-[1.75rem]  leading-[1.2em] ltr:font-gilroy rtl:font-dubai">
              <div className={styles.shortDescription}>
                <HTMLRender data={pageData?.shortDescription} />
              </div>
            </div>
            {store?.tableOfContents?.length > 0 &&
              pageData?.table_of_contents !== "none" &&
              pageData?.table_of_contents !== null && (
                <div
                  className={`-mx-md mb-xl block sm:mx-0 ${pageData?.table_of_contents === "sidebar" ? `2xl:hidden` : ``
                    }`}
                >
                  <Accordion
                    styles={{ icon: { color: "#000" } }}
                    classNames={{
                      control: styles.AccordionControl,
                      root: styles.AccordionRoot,
                      itemOpened: styles.itemOpened,
                      label: styles.AccordionLabel,
                      item: styles?.AccordionItem,
                      contentInner: styles?.contentInner,
                      icon: styles.borderAccordionIcon,
                      content: styles?.AccordionContent,
                    }}
                    variant="unstyled"
                    transitionDuration={500}
                    icon={<RiArrowDownSFill className="text-xl text-secondary" />}
                    iconPosition="right"
                  >
                    <Accordion.Item label={gv("table_of_contents")}>
                      {store?.tableOfContents?.map((title, index) => (
                        <div className="py-xs" key={index}>
                          <a className={`${styles.tocLink} hover:font-bold`} href={"#toc_title_" + (index + 1)}>
                            {title}
                          </a>
                        </div>
                      ))}
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}
            <HTMLRender ToC data={pageData?.content} />
          </div>

          {/* {pageData?.instruments?.length > 0
            ? pageData?.instruments?.map((instrument, index) => (
              <div key={index}>
                <ArticleSidebarInstrument
                  slug={instrument?.slug}
                  key={instrument?.display_name}
                  instrumentName={instrument?.display_name}
                  instrumentId={instrument?.api_id}
                  wide
                />
              </div>
            ))
            : null} */}

          <div className="pb-xl">
            <Accordion
              styles={{ icon: { color: "#000" } }}
              classNames={{
                control: styles.AccordionControl,
                root: styles.AccordionRoot,
                itemOpened: styles.itemOpened,
                label: styles.AccordionLabel,
                item: styles?.AccordionItem,
                contentInner: styles?.contentInner,
                icon: styles.borderAccordionIcon,
                content: styles?.AccordionContent,
              }}
              variant="unstyled"
              transitionDuration={500}
              icon={<HiOutlineChevronDown />}
              iconPosition="right"
            >
              {pageData?.faq_list?.map((question, index) => (
                <Accordion.Item key={index} label={<HTMLRender data={question?.title} />}>
                  <HTMLRender data={question?.content} />
                </Accordion.Item>
              ))}
            </Accordion>
          </div>

          <div
            className={`${styles[`article-disclaimer`]
              } border-t border-lightGrey pb-2xl pt-xl text-justify italic text-grey`}
          >
            <HTMLRender data={gv("disclaimers_academy")} />
          </div>

          <div className={pageData?.table_of_contents === "sidebar" ? `2xl:hidden` : ``}>
            <ArticleShareBox academy title={pageData?.title} />
          </div>
          <ArticleRating />
          <AcademyExplore articleType={pageData?.academy_categ?.unique_key} articleId={pageData?.id} />
          <ArticleAnalystBox data={pageData?.global_analyst} />
        </div>
      </div>
      <div className="pb-[4.8888rem]" />
    </>
  );
}
