import { useState } from 'react';
import Link from '@components/elements/Link';
import { TiLockClosed } from '@react-icons/all-files/ti/TiLockClosed';
import { TiLockOpen } from '@react-icons/all-files/ti/TiLockOpen';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import style from './AcademyLessonCardMobile.module.css';

const AcademyLessonCardMobile = ({ data }, index) => {
  const [showLessons, setShowLessons] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const { gv } = useGlobalVariable()

  function showLessonsHandler() {
    setShowLessons(!showLessons);
    setActiveIndex(index);
  }
  function hideLessonsHandler() {
    setShowLessons(!showLessons);
  }

  return (
    <div key={index} className="static border-0 p-0">
      <div className="static p-0">
        <div className="static p-0">
          <div
            onClick={() => showLessonsHandler()}
            className={`${style['lessons-presentation']} ${data.length === index + 1 && 'last:border-0'}
              ${showLessons ? `${style['lessons-presentation-hidden']}` : ''}
              `}
          >
            <p className="text-dark m-0 inline-block text-lg uppercase">{data?.title}</p>
            <p className="text-base text-[#bbb]">{`(${data?.academy_subjects?.length} ${gv('lessons')})`}</p>
            <div className={`${style['lessons-arrow-right']}`} />
          </div>
        </div>
        <div className={`${style['lessons-wrapper']} ${showLessons ? `${style['lessons-wrapper-shown']}` : ''}`}>
          <div
            onClick={() => hideLessonsHandler()}
            className="py-xs ltr:pl-lg rtl:pr-lg relative block cursor-pointer border-b border-[#cdcdcd]"
          >
            <p className="text-dark m-0 inline-block uppercase">{data?.title}</p>
            <p className="text-base text-[#bbb]">{`(${data?.academy_subjects?.length} ${gv('lessons')})`}</p>
            <div className={`${style['lessons-arrow-left']}`} />
          </div>
          <div
            className={`h-[calc(100%-5rem)] w-[calc(100%+1rem)] ltr:pr-[1.0625rem] rtl:pl-[1.0625rem] ${data?.academy_subjects?.length > 4 ? 'overflow-y-scroll' : 'overflow-hidden'
              }`}
          >
            {data?.academy_subjects?.map((item) => (
              <div
                key={parseInt(Math.random() * 10000)}
                className={`${!item?.locked ? 'text-secondary' : 'pointer-events-none'} py-md relative  w-full overflow-hidden overflow-ellipsis  whitespace-nowrap border-b border-b-[#e2e2e2] border-opacity-[0.5] ltr:pr-lg rtl:pl-lg`}
              >
                <Link href={item?.slug}>
                  <a className={` academy-lesson`}>
                    <span>
                      {item?.title}
                    </span>
                    <i className="absolute top-1/2 ltr:right-0 rtl:left-0 ltr:ml-[0.75em] rtl:mr-[0.75em] inline-block h-[1.25em] -translate-y-[50%] align-middle text-[1.3125rem]">
                      {!item?.locked ? <TiLockOpen /> : <TiLockClosed />}
                    </i>
                  </a>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcademyLessonCardMobile;
