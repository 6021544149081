import styles from "../../TradingCInstruments.module.css";

export default function InstrumentMT5Row({ cssParent, headers, cssClass, rowData, productType, platform, category }) {
  return (
    <div key={productType} className={cssParent}>
      {Object.keys(headers[0]).map((key) => (
        <p key={key} className={styles[cssClass]}>
          {rowData[key]}
          {platform === "cosmos" && key.toLowerCase().includes("swap") && " %"}
          {platform === "mt5" && category === "shares" && key.toLowerCase().includes("swap") && " %"}
        </p>
      ))}
    </div>
  );
}
