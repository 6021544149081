import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import { motion } from "framer-motion"
import styles from './TradeHomeFeatures.module.css';

const TradeHomeFeatures = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='overflow-hidden pt-[6rem] pb-[5rem] relative'>
      <div className={`absolute w-[45%]  2xl:w-[70%] ltr:-right-[5%] rtl:-left-[5%] ltr:2xl:-right-[30%] rtl:2xl:-left-[30%] bottom-[-40%] opacity-10 ${styles.backgroundImg}`}>
        <NextImage src="https://cdn.capex.com/uploads/red_x_bb9e333d22/red_x_bb9e333d22.png" width={800} height={1000} alt="CAPEX X" />
      </div>
      <div className='relative z-10'>
        <div className="container text-white ">
          <div className={styles.wrapper}>
            <div className="container flex-row justify-between ">
              <div className='relative flex flex-col  justify-center w-full max-w-[40%] 2xl:max-w-[45%]'>
                <div className={styles.dotsWrapper}>
                  <div className='absolute ltr:-left-[10%] rtl:-right-[10%] w-[40%] top-[33%]'>
                    <NextImage src="https://cdn.capex.com/uploads/grey_x_759eecf86c/grey_x_759eecf86c.png" width={408} height={488} alt="CAPEX X" />
                  </div>
                </div>
                <div className='w-full relative z-10'>
                  <HTMLRender data={data?.section_title} />
                </div>
              </div>
              <div className='relative flex flex-wrap w-full max-w-[60%] 2xl:max-w-[55%]  z-10 top-[-1.5rem]'>
                {data?.features?.map((feature, index) => (
                  <div key={index} className={`w-1/2 p-lg even:top-[3rem] relative ${styles.card}`}>
                    <div className='border border-[#BD8D8D] rounded-xl pb-lg pt-xl px-md relative h-full flex flex-col justify-end'>
                      <div className='w-[4.5rem] h-[4.5rem] flex justify-center items-center bg-[#141514] absolute z-[1] top-0 ltr:right-0 rtl:left-0 ltr:translate-x-1/3 rtl:-translate-x-1/4 -translate-y-1/3'>
                        <div className='relative w-[90%] h-[90%]'>
                          <NextImage src={feature?.icon} width={feature?.icon?.data?.attributes?.width} height={feature?.icon?.data?.attributes?.height} />
                        </div>
                      </div>
                      <h3>{feature?.title}</h3>
                      <HTMLRender data={feature?.content} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeHomeFeatures;
