import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';
import styles from './StoxWhy.module.css';

const StoxWhy = (props) => {
  const data = props.data || props;
  
  if (!data) {
    return null;
  } return (
    <>
      <div className={styles.section}>
        <div className="container">
          <div className='flex-col md:flex-row flex justify-between'>
            <div className='flex flex-col items-center md:items-start justify-between w-full md:w-[calc(100%-2.222rem)]'>
              <div className='mb-xl md:mb-0'>
                <h3 className='mb-xs'>
                  {data.title}
                </h3>
                <NextImage src={data?.image} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
              </div>
              <div className='hidden md:block'>
                <Button data={data.button} />
              </div>
            </div>
            <div className='w-full md:w-[calc(100%-2.222rem)]'>
              <ul>
                {data.icon_text.map((item, index) => (
                  <li key={index} className='flex-col flex md:flex-row justify-start items-center mb-lg last:mb-0'>
                    <div className={`${styles.imageStyles} mb-md md:mb-0`}>
                      <NextImage src={item?.icon} width={item?.icon?.data?.attributes?.width} height={item?.icon?.data?.attributes?.height} />
                    </div>
                    <div className={styles.content}>
                      <HTMLRender data={item.content} />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoxWhy;
