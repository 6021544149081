import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './ReferralGuide.module.css';

const ReferralGuide = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      <SectionTitle data={data?.section_title} />
      <div className="flex flex-col md:flex-row justify-between flex-wrap">
        {data?.icon_text.map((card, index) => (
          <div key={index} className="flex flex-col mb-lg w-full md:max-w-[calc(33.333%-1rem)]">
            <div>
              <NextImage src={card?.icon} width={card?.icon?.data?.attributes?.width} height={card?.icon?.data?.attributes?.height} />
            </div>
            <div className="mt-md md:mt-xl text-center ltr:md:text-left rtl:md:text-right">
              <HTMLRender data={card?.content} />
            </div>
          </div>
        ))}
      </div>
      <div className='mt-lg md:mt-xl flex justify-center'>
        <Button data={data?.button} />
      </div>
    </div>
  );
};

export default ReferralGuide;
