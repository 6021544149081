import styles from "./IPOPast.module.css";
import Link from "@components/elements/Link";
import Button from "@components/elements/Button";
import NextImage from "@components/elements/Image";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { useRegistrationLink } from "@lib/store/hooks/useActiveProductType";
import instrumentsApi from "@utils/instrumentsApi";

const IPOPast = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable();
  const registrationLink = useRegistrationLink();

  // accepts both swr and custom query global_instrument data objects
  const global_instrument_api_id = data?.global_instrument?.api_id || data?.global_instrument?.data?.attributes?.api_id
  const global_instrument_slug = data?.global_instrument?.slug || data?.global_instrument?.data?.attributes?.slug

  // get instrument data if listed and assigned to the ipo
  const instrumentPrice = data?.global_instrument && instrumentsApi(global_instrument_api_id, 1000 * 60 * 10);
  const sell = instrumentPrice?.currentData?.[`${global_instrument_api_id}`]?.sell;
  const buy = instrumentPrice?.currentData?.[`${global_instrument_api_id}`]?.buy;

  if (!data) {
    return null;
  }

  return (
    <div className={`${styles[`card-inner`]}`}>
      <div className={`${styles[`card-top`]}`}>
        <div className={`${styles[`left-side`]} relative`}>
          <Link arrow="none" href={data?.slug} />
          <div className={`${styles[`img-wrapper`]} min-w-full`}>
            <NextImage
              src={data?.image}
              width={data?.image?.width || data?.image?.data?.attributes?.width}
              height={data?.image?.height || data?.image?.data?.attributes?.height}
            />
          </div>
        </div>
        <div className={`flex flex-col justify-between ${styles[`right-side`]}`}>
          <div>
            <p className="mb-0 text-xl text-black">{data?.name}</p>
            <p className="mb-sm">
              <Link arrow="none" text={gv("available_for_trading", "raw")} link={global_instrument_slug ? `/instruments${global_instrument_slug}` : data?.slug} />
            </p>
          </div>
          <div>
            <p className="mb-0 text-sm">
              <span>{gv("ipo_buy", "raw")} </span>
              <span>{buy || "-"}</span>
            </p>
            <p className="mb-0 text-sm">
              <span>{gv("ipo_sell", "raw")} </span>
              <span>{sell || "-"}</span>
            </p>
          </div>
        </div>
      </div>
      <div className={`${styles[`card-bottom`]}`}>
        <div className="pb-md lg:max-w-[60%] lg:pb-0">
          <Link arrow="left" text={gv("read_full_ipo_report", "raw")} link={data?.slug} />
        </div>
        <Button arrow="none" label={gv("trade_now", "raw")} size="sm" type="primary" link={registrationLink} />
      </div>
    </div>
  );
};

export default IPOPast;
