import NextImage from "@components/elements/Image";
import NextLink from "@components/elements/Link";
import map from "lodash/map";
import get from "lodash/get";
import styles from "./InstrumentsIcons.module.css";

function InstrumentsIcons(props) {
  const instrumentCategories = get(props, "instrument_categories.data", false);
  if (!instrumentCategories) return null;
  return (
    <div className="container -mt-[3rem]">
      <div className="flex w-full flex-wrap items-center justify-between md:flex-nowrap lg:py-0">
        {map(instrumentCategories, (item, index) => (
          <div key={index} className="w-[50%] py-md md:w-auto md:py-0">
            <div className="relative flex flex-col items-center justify-center text-center">
              <NextLink href={`${item?.category_page?.slug || ""}`} className="cursor-pointer">
                <div className={`relative h-[3rem] w-[3rem] mx-auto ${styles.filter}`}>
                  <NextImage src={item?.icon} height={item?.icon?.height} width={item?.icon?.width} />
                </div>
                <p className="pt-md text-lg font-normal lg:pt-xs lg:text-2xl">{item?.display_name}</p>
              </NextLink>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InstrumentsIcons;
