import HTMLRender from '@components/core/HTMLRender';
import styles from './ForexTrading.module.css';

const ForexTrading = (props) => {
  const data = props.data || props;

  if (!data) {
    return null;
  } return (
      <div className="bg-whiteCream">
        <div className='lg:container'>
          <div className='flex flex-col md:flex-row'>
            <div className={styles.left_column}>
              <HTMLRender data={data?.left_column?.content} />
            </div>
            <div className={styles.right_column}>
              <HTMLRender data={data?.right_column?.content} />
            </div>
          </div>
        </div>
      </div>
  );
};

export default ForexTrading;
