import styles from './PodcastsEpisodes.module.css';
import SectionTitle from '@components/elements/SectionTitle';
import useSwrHook from '@lib/services/hooks/swr/useSwrHook';
import useLocale from '@lib/store/hooks/useLocale';
import HTMLRender from '@components/core/HTMLRender';
import { useState } from 'react';
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";

// TODO Add global variables & Language filter for podcasts

const PodcastsEpisodes = (props) => {
  const data = props.data || props;
  if (!data) return null;
  const locale = useLocale();
  const { gv } = useGlobalVariable()

  const [activeIndex, setActiveIndex] = useState(null)
  const [activeLang, setActiveLang] = useState(null)

  const { data: podcastsEpisodes, loadingEp, errorEp } = useSwrHook(`podcastsEpisodes`, {
    query: {
      populate:
        "*"
      ,
      fields: ['url', 'description', 'language'],
      sort: ['publishedAt:desc'],
      locale: locale
    }
  });




  const episodesLanguages = [];

  {
    podcastsEpisodes?.data?.map((item, index) => {

      if (!episodesLanguages.includes(item?.attributes?.language) && item?.attributes?.language) {
        episodesLanguages.push(item?.attributes?.language)

      }
    })
  }

  return (
    <div className='pb-2xl'>
      <div className='container'>
        <SectionTitle data={data?.section_title} />

        <div className='flex ltr:justify-end'>
          <div className='py-xs bg-[#f0f0f0] mb-lg flex rounded-md ltr:font-gilroy rtl:font-dubai'>
            <div onClick={() => setActiveLang(null)} className={`px-sm rtl:border-l ltr:border-r border-white last:border-non uppercase cursor-pointer  ${!activeLang ? `text-secondary font-bold` : `text-grey`}`}>All</div>
            {episodesLanguages.map((item, index) => (
              <div key={index} onClick={() => setActiveLang(item)} className={`px-sm rtl:border-l ltr:border-r border-white last:border-none uppercase cursor-pointer ${activeLang == item ? `text-secondary font-bold` : `text-grey`}`}>{item}</div>
            ))}
          </div>
        </div>

        {podcastsEpisodes?.data?.map((item, index) => {
          const isActive = index === activeIndex
          if (activeLang == item.attributes.language || !activeLang) {
            return (
              <div
                key={index}
                className='pb-xl lg:pb-2xl last:pb-0'
              >
                <iframe src={item?.attributes?.url} frameborder="0" scrolling="no" width="100%" height="150"></iframe>
                {item?.attributes?.mp3.data &&
                  <div className='pt-md'>
                    <a href={item?.attributes?.mp3.data.attributes.url} className='underline uppercase' download>
                      {gv('download', 'raw')}
                    </a>
                  </div>
                }

                <div className='py-md flex'>{gv('subscribe', 'raw')}: &nbsp;
                  {data?.podcast_platforms?.data?.map((item, index) => {
                    return (
                      <span key={index}>
                        <a href={item?.attributes?.url} target="_blank" className='underline'>
                          {item?.attributes?.name}
                        </a> &nbsp;
                      </span>
                    )
                  })}
                </div>

                <div>
                  <div className={`${!isActive ? `${styles.trimText}` : ''} ${item?.attributes?.language === 'ar' ? 'text-right' : ''}`}>
                    <HTMLRender data={item?.attributes?.description} />
                  </div>
                  {!isActive &&
                    <span onClick={isActive ? () => setActiveIndex() : () => setActiveIndex(index)} className='text-secondary hover:text-secondaryHover cursor-pointer'>{gv('more', 'raw')}</span>
                  }
                </div>
              </div>
            )
          }
        })}
        <div>
          <HTMLRender data={gv('podcasts_disclaimer')} />
        </div>
      </div>
    </div>
  );
};

export default PodcastsEpisodes;
