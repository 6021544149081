import FlagCard from '@components/custom/FlagCard';
import GenericSlider from '@components/custom/GenericSlider';
import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import NextLink from 'next/link';
import { SwiperSlide } from 'swiper/react';

const HomepageLicences = (props) => {
  const data = props.data || props;
  let hasData = true;
  for (const [key, value] of Object.entries(data)) {
    if (value === null || value.data === null) hasData = false;
  }

  if (hasData) {
    return (
      <>
        <div className='container'>
          {/* Desktop Version */}
          <div className='hidden md:flex'>
            <SectionTitle data={data.section_title} />
          </div>
          <div className='hidden md:flex justify-center flex-wrap lg:flex-nowrap w-auto m-0'>
            {data.licence_cards.map((card, index) => (
              <div key={index} className='w-[35%] lg:w-[20%] py-xs lg:py-0 px-xs first:pl-0 last:pr-0 last:hidden lg:last:block'>
                <FlagCard divider data={card} />
              </div>
            ))}
          </div>
        </div>
        {/* Mobile Version */}
        <div className='md:hidden'>
          <div className='container -mt-lg'>
            <div className='relative w-full bg-whiteCream p-md rounded-xl mb-md'>
              <NextLink href={data.licence_cards[0].licence_link}>
                <a target="__blank" className='absolute z-20 top-0 left-0 h-full w-full bg-transparent'></a>
              </NextLink>
              <div className='relative z-10 flex flex-col items-end justify-end text-right max-w-[70%] ml-auto'>
                <h3 className='text-xl mb-xs font-bold'>{data.licence_cards[0].text_upper}{' '}{data.licence_cards[0].licence_name}</h3>
                <p className='mb-0 text-secondary font-light text-sm'>{data.licence_cards[0].licence_full_name}</p>
              </div>
              <NextImage src={data.cysec_mobile_large_image} objectFit />
            </div>
          </div>
          <div className="container pr-0">
            <GenericSlider autoplay loop slidesPerView={2.1}>
              {data.licence_cards.slice(1, data.licence_cards.length).map((card, index) => (
                <SwiperSlide key={index}>
                  <div className='pr-sm'>
                    <FlagCard divider data={card} />
                  </div>
                </SwiperSlide>
              ))}
            </GenericSlider>
          </div>
        </div>
      </>
    )
  } return null;

};

export default HomepageLicences;
