import styles from './FullPageSlider.module.css'
import { Swiper } from 'swiper/react';
import { useState, useRef, useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/mousewheel';
import { Navigation, Pagination, Autoplay, Mousewheel } from 'swiper';
import { useIntersection } from '@mantine/hooks';

const FullPageSlider = (props) => {
  const [fullPageSwiper, setFullPageSwiper] = useState({})

  // Mantine Observer Hook to check when the graph is in the client's viewport
  const containerRef = useRef();
  const [isDisabled, setIsDisabled] = useState(false);
  const [ref, observedEntry] = useIntersection({
    root: containerRef.current,
    threshold: 1,
  });
  const inView = observedEntry && observedEntry.isIntersecting;
  // End Mantine Observer Hook

  async function checkSwiperEnabling() {
    useEffect(() => {
      if (!inView) {
        setIsDisabled(true);
        fullPageSwiper.mounted && fullPageSwiper.disable();
      } else {
        setIsDisabled(false);
        fullPageSwiper.mounted && fullPageSwiper.enable();
      }
    }, [inView])
  }
  checkSwiperEnabling();

  //Enable swiper on last slide when slider is back fully in view
  function enableSwiper() {
    if (inView && isDisabled) {
      fullPageSwiper.enable();
    }
  }
  enableSwiper();
  //End Enable swiper when slider is fully in view


  if (props.children) {
    return (
      <div ref={ref}>
        <Swiper
          onSwiper={setFullPageSwiper}
          observer={props.observer ? true : false}
          onReachEnd={(swiper) => {
            // On last slide, disable swiper to be able to scroll to footer 
            if (props.fullPage) {
              setTimeout(() => {
                swiper.disable();
              }, props?.speed || 800);
              setIsDisabled(true);
            }
            // End On last slide, disable swiper to be able to scroll to footer
          }}
          simulateTouch={false}
          autoHeight={props.autoHeight && true}
          preventInteractionOnTransition={true}
          modules={[Navigation, Pagination, Autoplay, Mousewheel]}
          slidesPerView='1'
          direction='vertical'
          mousewheel={props.mousewheelControl ? true : false}
          navigation={props.navigation ? true : false}
          pagination={props.pagination ? { clickable: true } : false}
          speed={props.speed ? props.speed : 600}
        >
          {props.children}
        </Swiper>
      </div>
    );
  }
  return null;
};



export default FullPageSlider;
