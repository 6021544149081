import NextImage from "@components/elements/Image";
import FormCalendarPartnersRetailIB from "@src/components/elements/Forms/templates/FormCalendarPartnersRetailIB";
import styles from "./PartnersCalendar.module.css";

const PartnersCalendar = (props) => {
  const data = props.data || props;

  if (!data) return null;

  return (
    <div className={`container ${styles.strip}`}>
      <div className="flex items-start justify-between">
        <div className="flex flex-col justify-between w-full md:max-w-[49%]">
          <div className="w-full">
            <FormCalendarPartnersRetailIB data={data} />
          </div>
        </div>

        <div className="hidden md:block md:max-w-[49%]">
          <NextImage
            src={data?.image}
            width={data?.image?.data?.attributes?.width}
            height={data?.image?.data?.attributes?.height}
          />
        </div>
      </div>
    </div>
  );
};

export default PartnersCalendar;
