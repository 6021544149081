import HTMLRender from "@components/core/HTMLRender";
import { useLocalStorage } from "@mantine/hooks";
import useActiveProductType from "@lib/store/hooks/useActiveProductType";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import Link from "@components/elements/Link";
import SelectPlatform from "./components/SelectPlatform";
import { PLATFORM } from "./shared/constants";
import PlatformMT5 from "./components/PlatformMT5";
import PlatformCosmos from "./components/PlatformCosmos";
import styles from "./TradingCInstruments.module.css";
import InvestTradingConditions from "./components/InvestTradingConditions";
import { useEffect, useState } from "react";
import PlatformCosmosV2 from "./components/PlatformCosmosV2";
import { useStore } from "@lib/store";

const TradingCInstruments = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable();
  const [platform, setPlatform] = useState(PLATFORM.COSMOS);
  const currentProductTypeKey = useStore((state) => state.currentProductTypeKey);
  const [currentActiveProductType, setCurrentActiveProductType] = useState(false);

  useEffect(() => {
    setCurrentActiveProductType(currentProductTypeKey);
  }, [currentProductTypeKey]);

  if (!data) return null;
  if (!currentActiveProductType) return null;

  return (
    <div className="container" id="table">
      {data?.with_product_switch && (
        <div className="flex -translate-y-1/2 justify-center lg:mb-lg">
          <div className={`${styles.productTab} ${currentActiveProductType === "trade" && styles.active}`}>
            <div className={styles.platformLink}>
              <Link href={gv("trading_conditions_trade_url", "raw")} />
            </div>

            <div className="text-xs text-secondary">{gv("trading_conditions_for")}</div>
            <HTMLRender data={gv("trading_conditions_trade_label")} />
          </div>
          <div
            className={`${styles.productTab} ${
              currentActiveProductType === "invest" && styles.active
            } ltr:text-right rtl:text-left`}
          >
            <div className={styles.platformLink}>
              <Link href={gv("trading_conditions_invest_url", "raw")} />
            </div>
            <div className="text-xs text-secondary">{gv("trading_conditions_for")}</div>
            <HTMLRender data={gv("trading_conditions_invest_label")} />
          </div>
        </div>
      )}
      {currentActiveProductType === "trade" && (
        <>
          <SelectPlatform platform={platform} setPlatform={setPlatform} productType={currentActiveProductType} />
          {platform === PLATFORM.MT5 && <PlatformMT5 platform={platform} />}
          {/* {platform === PLATFORM.COSMOS && (
            <PlatformCosmos instruments={data?.qd_global_instrument_types?.data} platform={platform} />
          )} */}
          {platform === PLATFORM.COSMOS && <PlatformCosmosV2 platform={platform} />}
        </>
      )}
      {currentActiveProductType === "invest" && <InvestTradingConditions />}
      <div className={`${styles.disclaimer} mt-xl`}>
        <HTMLRender data={props?.disclaimer} />
      </div>
    </div>
  );
};

export default TradingCInstruments;
