import dayjs from "dayjs";
import { useEffect, useState } from "react";

export default function useAppointmentTime() {
  const [date, setDate] = useState(new Date());
  const [hour, setHour] = useState(10);
  const [appointmentTime, setAppointmentTime] = useState(`${dayjs(date).format("YYYY-MM-DD")}T${hour}:00:00.000`);

  const minDate = dayjs(new Date()).startOf("month").toDate();

  useEffect(() => {
    setAppointmentTime(`${dayjs(date).format("YYYY-MM-DD")}T${hour}:00:00.000`);
  }, [date, hour]);

  function handleInc() {
    if (hour === 23) {
      return setHour(0);
    }
    return setHour(hour + 1);
  }

  function handleDec() {
    if (hour === 0) {
      return setHour(23);
    }
    return setHour(hour - 1);
  }

  return {
    minDate,
    appointmentTime,
    date,
    setDate,
    hour,
    setHour,
    handleInc,
    handleDec,
  };
}
