import DesktopHomeSlider from '@components/custom/DesktopHomeSlider';
import MobileHomeSlider from '@components/custom/MobileHomeSlider';
import useSwrHook from '@lib/services/hooks/swr/useSwrHook';
import { useStore } from '@lib/store';
import styles from './HomePageSlider.module.css';

import { useViewportSize } from '@mantine/hooks';
const HomePageSlider = (props) => {
  const storeValues = useStore((store) => store.slides);
  const {width} = useViewportSize();
  if (storeValues === undefined) return null;
  
  return (
    <div className={`w-full ${styles[`homapage-slider`]}`}>
        {
          width < 767 ?
          <MobileHomeSlider slides={storeValues} />
          :
          <DesktopHomeSlider slides={storeValues} />
        }
    </div>
  );
};

export default HomePageSlider;
