import styles from "./PlatformsMt5.module.css";
import HTMLRender from "@components/core/HTMLRender";
import NextImage from "@components/elements/Image";
import List from "@components/elements/List";
import Button from "@components/elements/Button";
import { Controller, Scene } from "react-scrollmagic";
import { Tween } from "react-gsap";

const PlatformsMt5 = (props) => {
  const data = props.data || props;
  if (!data) return null;

  const devices = data?.device_icons.data;

  return (
    <div className={`${styles[`mt5-section`]} relative`}>
      <div className="hidden lg:block">
        <Controller>
          <div className="container">
            <div className="flex rtl:flex-row-reverse">
              <div className="w-[60%] 2xl:w-1/2 md:pr-xl text-center ltr:md:text-left rtl:text-right">
                <Scene triggerHook="1" duration={1500} offset={-200}>
                  <Tween
                    from={{ opacity: 0, transform: "translateY(500px)" }}
                    to={{ opacity: 1, transform: "translateY(0%)" }}
                    stagger={0.1}
                  >
                    {data?.mt5_header && (
                      <div className={`${styles[`mt5-header`]} pb-xl`}>
                        <HTMLRender data={data?.mt5_header} />
                      </div>
                    )}
                    {data?.mt5_text_1 && (
                      <div className="pb-lg">
                        <HTMLRender data={data?.mt5_text_1} />
                      </div>
                    )}
                    <div className="block lg:hidden py-xl">
                      <NextImage
                        src={data?.mobile_image}
                        width={data?.mobile_image?.data?.attributes?.width}
                        height={data?.mobile_image?.data?.attributes?.height}
                      />
                    </div>
                    {devices && (
                      <div className="items-center pb-xl hidden lg:flex">
                        {devices.map((image, index) => {
                          return (
                            <div
                              key="index"
                              className="mr-xl last:mr-0 relative w-[3.111rem] h-[3.111rem] flex items-center"
                            >
                              <NextImage
                                objectFit
                                src={image.attributes?.url}
                                width={image.attributes?.width}
                                height={image.attributes?.height}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {data?.mt5_text_2 && (
                      <div>
                        <HTMLRender data={data?.mt5_text_2} />
                      </div>
                    )}
                    {data?.mt5_list && (
                      <div className={`pt-xl ${styles[`mt5-list`]} text-left`}>
                        <List
                          type="checked"
                          border
                          list_items={data?.mt5_list}
                        />
                      </div>
                    )}
                    {data?.button && (
                      <div className="pt-xl">
                        <Button
                          type={data?.button?.type}
                          size={data?.button?.size}
                          arrow={data?.button?.arrow}
                          label={data?.button?.label}
                          link={data?.button?.link}
                        />
                      </div>
                    )}
                  </Tween>
                </Scene>
              </div>

              <div className="w-[40%] w-1/2 hidden lg:block relative">
                <div>
                  <Scene triggerHook="0" duration={"70%"} pin>
                    <div className="h-full scene2">
                      <Controller>
                        <Scene
                          triggerHook="0"
                          duration={500}
                          offset="-500"
                          triggerElement=".scene2"
                        >
                          <Tween
                            from={{ opacity: 0, transform: "scale(0.6, 0.6)" }}
                            to={{ opacity: 1, transform: "scale(1,1)" }}
                          >
                            <div className="relative">
                              <div className="pt-2xl">
                                <NextImage
                                  src={data?.mt5_image}
                                  width={
                                    data?.mt5_image?.data?.attributes?.width
                                  }
                                  height={
                                    data?.mt5_image?.data?.attributes?.height
                                  }
                                />
                              </div>
                            </div>
                          </Tween>
                        </Scene>
                      </Controller>
                    </div>
                  </Scene>
                </div>
              </div>
            </div>
          </div>
        </Controller>
      </div>
      <div className="block lg:hidden">

        <div className="container">
          <div className="flex">
            <div className="lg:w-1/2 lg:pr-xl text-center lg:text-left">

              {data?.mt5_header && (
                <div className={`${styles[`mt5-header`]} pb-xl`}>
                  <HTMLRender data={data?.mt5_header} />
                </div>
              )}
              {data?.mt5_text_1 && (
                <div className="pb-lg">
                  <HTMLRender data={data?.mt5_text_1} />
                </div>
              )}
              <div className="block lg:hidden py-xl">
                <NextImage
                  src={data?.mobile_image}
                  width={data?.mobile_image?.data?.attributes?.width}
                  height={data?.mobile_image?.data?.attributes?.height}
                />
              </div>
              {devices && (
                <div className="items-center pb-xl hidden lg:flex">
                  {devices.map((image, index) => {
                    return (
                      <div
                        key="index"
                        className="ltr:mr-xl ltr:last:mr-0 rtl:ml-xl rtl:last:ml-0 relative w-[3.111rem] h-[3.111rem] flex items-center"
                      >
                        <NextImage
                          objectFit
                          src={image.attributes?.url}
                          width={image.attributes?.width}
                          height={image.attributes?.height}
                        />
                      </div>
                    );
                  })}
                </div>
              )}

              {data?.mt5_text_2 && (
                <div>
                  <HTMLRender data={data?.mt5_text_2} />
                </div>
              )}
              {data?.mt5_list && (
                <div className={`pt-xl ${styles[`mt5-list`]} text-left`}>
                  <List
                    type="checked"
                    border
                    list_items={data?.mt5_list}
                  />
                </div>
              )}
              {data?.button && (
                <div className="pt-xl">
                  <Button
                    type={data?.button?.type}
                    size={data?.button?.size}
                    arrow={data?.button?.arrow}
                    label={data?.button?.label}
                    link={data?.button?.link}
                  />
                </div>
              )}

            </div>


          </div>
        </div>

      </div>
    </div>
  );
};

export default PlatformsMt5;
