import HTMLRender from '@components/core/HTMLRender';
import styles from './TimelineCard.module.css'

const TimelineCard = (props) => {
  const data = props.data || props;

  if (!data) {
    return null
  }
  return (
    <div className='h-full'>
      <div className={`${styles.yearWrapper} flex justify-between items-center pr-sm pt-[0.2rem]`}>
        <div className='text-[2.667rem] text-secondary pr-sm'>{data?.year}</div>
      </div>
      <div className={styles.content}>
        <HTMLRender data={data?.content} />
      </div>
    </div>
  );
};

export default TimelineCard;
