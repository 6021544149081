import SectionTitle from '@components/elements/SectionTitle/index';

const MarketsPageHero = (props) => {
  const data = props.data || props;
  if (!data) {
    return null;
  } return (
      <div className='container'>
        <SectionTitle data={data?.section_title} />
    </div>
  );
};

export default MarketsPageHero;
