import GenericSlider from '@components/custom/GenericSlider';
import SectionTitle from '@components/elements/SectionTitle';
import useSwrHook from '@lib/services/hooks/swr/useSwrHook';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './TradeHomeArticles.module.css';
import dayjs from 'dayjs'
import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';
import { Fragment, useEffect, useState } from 'react';
import chunk from "lodash/chunk";
import get from 'lodash/get';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import { Modal } from '@mantine/core';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';

const ArticleCardComponent = ({ index, article }) => {
  const { gv } = useGlobalVariable();
  const pressType = article?.press_type || 'external_url';
  const externalUrl = article?.link_url || '';
  const pdfUrl = article?.pdf_file?.url || '';

  // modal state
  const [showModal, setShowModal] = useState(false);

  return (
    <div
      key={index}
      className={`${styles.card} flex  h-full hover:shadow-xl transition-all duration-500 ease-in-out cursor-pointer`}>
      {/* add link to external source if press type is external_url and link_url is set */}
      {pressType === 'external_url' && externalUrl && <a href={article?.link_url} target="_blank" className={styles.linkUrl} />}
      {/* add iframe for pdf viewer pdf if press type is pdf file and pdf file is set */}
      {pressType === 'pdf_file' && pdfUrl && <div onClick={() => setShowModal(true)} className={styles.linkUrl} />}
      {/* add pdf download link for mobile devices */}
      {pressType === 'pdf_file' && pdfUrl && <a href={pdfUrl} target="_blank" className={`${styles.linkUrl} lg:hidden`} />}
      <Modal
        opened={showModal}
        onClose={() => setShowModal(false)}
        size="70%"
        centered
      >
        <iframe title="Press Article" src={pdfUrl} className="w-full h-[70vh]" />
      </Modal>
      <div className='ltr:pr-lg rtl:pl-lg grow-1 flex flex-col'>
        <div>
          <h3 className={`font-bold m-0 ${styles.clamp}`}><HTMLRender data={article.title} /></h3>
          <div className='text-sm text-[#1B1B1B] py-xs mb-md'>{dayjs(article.publication_date).format("DD MMMM YYYY")}</div>
        </div>
        <div>
          <HTMLRender className={`${styles.articleDescription} ${styles.clamp}`} data={article.description} />
          <div className={styles.readMore}>
            {gv('read_more')}
          </div>
        </div>
      </div>
      {article?.image?.url &&
        <div className={`flex-[0_0_25%] relative overflow-hidden ${styles.imgWrapper}`}>
          <NextImage
            src={article?.image?.url}
          />
        </div>
      }
    </div>
  )
}



const TradeHomeArticles = (props) => {
  const data = props.data || props;
  const chunkedPressPublishers = chunk(data?.press_articles?.data?.filter(publisher => publisher?.presses?.length > 0), 8);
  const [activePublisher, setActivePublisher] = useState(null);
  const { gv } = useGlobalVariable();
  const allArticles = data?.press_articles?.data?.flatMap(publisher => publisher.presses);
  allArticles?.sort((a, b) => {
    const dateA = new Date(a.publication_date);
    const dateB = new Date(b.publication_date);
    return dateB - dateA;
  })
  const [initialSlide, setInitialSlide] = useState(0);
  const [swiper, setSwiper] = useState(null);

  if (!data) return null;

  return (
    <div className="container hidden lg:flex">
      <SectionTitle data={data?.section_title} />

      <div className="flex -mx-md pt-lg">
        <div className='px-md w-2/3 v-swiper-press'>
          {/* selected publisher slider  */}
          {activePublisher &&
            <Swiper
              pagination={{
                clickable: true
              }}
              slidesPerView={2}
              slidesPerGroup={2}
              direction="vertical"
              initialSlide={initialSlide}
              modules={[Pagination]}
              onSwiper={(swiper) => { setSwiper(swiper); }}
            >
              {data?.press_articles?.data?.filter(el => el.publisher_id === activePublisher).map((publisher, index) => (
                publisher.presses.map((article) => (
                  <SwiperSlide key={index}>
                    <ArticleCardComponent index={index} article={article} />
                  </SwiperSlide>
                ))
              ))}
            </Swiper>
          }
          {/* all presses swiper, no publisher selected */}
          {!activePublisher &&
            <GenericSlider
              pagination
              slidesPerView={2}
              slidesPerGroup={2}
              vertical
              initialSlide={initialSlide}
            >

              {allArticles?.slice(0, 25).map((article, index) => (
                <SwiperSlide key={index}>
                  <ArticleCardComponent index={index} article={article} />
                </SwiperSlide>
              ))}
            </GenericSlider>
          }
        </div>
        {/* publishers slider  */}
        <div className='px-md w-1/3 '>
          <div className={`${styles.card} h-full transition-none`}>
            <div className='-mx-lg swiper-publishers'>
              <h2 className='text-[2.66rem] text-center font-bold pt-md'>{gv('as_seen_in')}</h2>
              <GenericSlider
                pagination
              >
                {chunkedPressPublishers?.map((chunk, index) => (
                  <SwiperSlide key={index} >
                    <div className='grid grid-cols-2 gap-4  px-md'>
                      {chunk?.map((publisher) => {
                        if (publisher?.presses?.length > 0) {
                          return (
                            <div key={publisher?.name} className={activePublisher === publisher?.publisher_id ? styles.activePublisherLogo : styles.publisherLogo} onClick={() => { setActivePublisher(activePublisher === publisher.publisher_id ? null : publisher.publisher_id); setInitialSlide(0); swiper?.slideTo(0, 0) }}>
                              <NextImage
                                src={publisher?.logo?.url}
                                width={publisher?.logo?.width}
                                height={publisher?.logo?.height}
                                alt={publisher?.name}
                              />
                            </div>
                          )
                        } return null

                      })}
                    </div>
                  </SwiperSlide>
                ))}
              </GenericSlider>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default TradeHomeArticles;
