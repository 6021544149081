import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import { IoCalendarSharp } from "@react-icons/all-files/io5/IoCalendarSharp";
import { GiEarthAmerica } from "@react-icons/all-files/gi/GiEarthAmerica";
import styles from "./UpcomingWebinar.module.css";
import Button from "@components/elements/Button";
import HTMLRender from "@components/core/HTMLRender";
import dayjs from "dayjs";
import { useMemo } from "react";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { useRouter } from "next/router";
import { useStore } from "@lib/store";

//format date to be readable using dayjs
const advancedFormat = require("dayjs/plugin/advancedFormat");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
const formatDate = (date) => dayjs(date).utc(true).local().format("DD MMMM YYYY, HH:mm z");

const UpcomingWebinar = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable();
  const router = useRouter();

  const store = useStore();

  if (!data) {
    return null;
  }

  // return null;
  const CURRENTDATE = useMemo(() => new Date().toISOString(), []);
  const {
    data: upcomingWebinars,
    loading,
    error,
  } = useSwrHook("getUpcomingWebinars", {
    query: {
      populate: {
        global_analysts: {
          fields: ["name"],
        },
        areas_of_focus: { fields: ["content"] },
      },
      fields: ["date", "title", "language", "slug"],
      sort: ["date:asc"],
      filters: {
        date: {
          $gt: CURRENTDATE,
        },
      },
    },
  });

  if (!upcomingWebinars) {
    return null;
  }

  return (
    upcomingWebinars?.data?.length > 0 &&
    <div className="container">
      <h1 className="text-center">{store?.display_name}</h1>
      {upcomingWebinars?.data?.map((webinar, index) => (
        <div key={index} className={styles.webinarWrapper}>
          <div className={styles.leftSide}>
            <p className="mb-0">{gv("webinar", "raw")}</p>
            <h3 className="mb-0 text-2xl">{webinar?.attributes?.title}</h3>
            <p className="mb-0">
              {gv("with", "raw")}: <strong>{webinar?.attributes?.global_analysts?.data?.[0]?.attributes?.name}</strong>
            </p>
            <div className="py-lg px-0">
              <div className="flex items-center">
                <IoCalendarSharp className="text-grey" />
                <p className="px-sm">{formatDate(webinar?.attributes?.date)}</p>
              </div>
              <div className="flex items-center">
                <GiEarthAmerica className="text-grey" />
                <p className="px-sm">
                  {gv("language")}: {webinar?.attributes?.language}
                </p>
              </div>
            </div>
            <div>
              <Button
                arrow="right"
                label={gv("register_webinar", "raw")}
                size="lg"
                type="white"
                link={webinar?.attributes?.slug}
              />
            </div>
          </div>
          <div className={styles.rightSide}>
            <p>{gv("main_areas_focus")}:</p>
            <ul>
              {webinar?.attributes?.areas_of_focus?.map((item) => (
                <li key={index} className={styles.focusList}>
                  <HTMLRender data={item.content} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UpcomingWebinar;
