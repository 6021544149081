import HTMLRender from "@components/core/HTMLRender";
import styles from "./SectionTitle.module.css";

const SectionTitle = (props) => {
  const data = props.data || props;

  if (!data) {
    return null;
  }
  return (
    <div
      className={`${!data?.nospacing ? `mb-[3.33rem] lg:mb-[5rem]` : ``} flex  flex-col text-${data?.position} ${styles[`${data?.position}`] ||'' }`}
    >
      {data?.heading === "h1" ? (
        <h1
          style={{ color: `${data?.title_color}` }}
          className={`m-0 ltr:font-gilroy rtl:font-dubai ${data?.title_bold ? `font-bold` : 'font-normal'} text-h2 leading-[1.2] ${data?.large_title ? "text-[1.777rem] md:text-h1":""} ${data?.className || ""}`}
        >
          {data?.title}
        </h1>
      ) : (
        <h2
          style={{ color: `${data?.title_color}` }}
          className={`m-0 ltr:font-gilroy rtl:font-dubai ${data?.title_bold ? `font-bold` : 'font-normal'} leading-[1.2] ${data?.large_title? "text-[1.777rem] md:text-h1":""} ${data?.className || ""}`}
        >
          {data?.title}
        </h2>
      )}
      {data?.subtitle ? (
        <div className={`mt-lg w-full ${data?.large_title?  'text-[1.125] lg:text-[1.222rem]' : ''} max-w-full ${data?.position === "right" ? "text-right" : ""}`}>
          <HTMLRender data={data?.subtitle} />
        </div>
      ) : null}
    </div>
  );
};

export default SectionTitle;
