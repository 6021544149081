import styles from "./PaginationWithoutUrlChange.module.css";
import { useEffect, useState } from "react";

/* This example requires Tailwind CSS v2.0+ */
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { BsFillCaretRightFill } from "@react-icons/all-files/bs/BsFillCaretRightFill";
import { BsFillCaretLeftFill } from "@react-icons/all-files/bs/BsFillCaretLeftFill";
import { parse } from "path-to-regexp";

export default function PaginationWithoutUrlChange(props) {
  const [history, setHistory] = useState([]);
  const totalPages = parseInt(props.totalPages);
  const neighborsSize = parseInt(props.neighborsSize);

  function goToNextPage() {
    if (props.currentPage < props.totalPages) {
      props.setCurrentPage((page) => page + 1);
    }
  }

  function goToPreviousPage() {
    if (props.currentPage > 1) {
      props.setCurrentPage((page) => page - 1);
    }
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    props.setCurrentPage(pageNumber);
  }

  function previousNeighborPages() {
    let previousPages = [];
    for (let i = props.currentPage - 1; i >= props.currentPage - neighborsSize && i > 1; i--) {
      previousPages.push(
        <a key={i} onClick={changePage}>
          {i}
        </a>
      );
    }
    previousPages.reverse();
    return previousPages;
  }

  function nextNeighborPages() {
    let nextPages = [];
    for (let i = props.currentPage + 1; i <= props.currentPage + neighborsSize && i < props.totalPages; i++) {
      nextPages.push(
        <a key={i} onClick={changePage}>
          {i}
        </a>
      );
    }
    return nextPages;
  }

  return (
    <div
      className={`${totalPages > 1 ? `flex` : "hidden"} ${styles.pagination} justify-center ${
        props.type == "bordered" ? `${styles.bordered}` : ""
      } ${
        props.alignament == "right"
          ? `md:justify-end`
          : props.alignament == "left"
          ? `md:justify-start`
          : `md:justify-center`
      }  pt-xl`}
    >
      <nav>
        <div className={`${styles["pagination-icon"]} ${styles["pagination-left"]}`}>
          <a onClick={goToPreviousPage}>
            <BsFillCaretLeftFill className="h-4 w-4 rtl:rotate-180" aria-hidden="true" />
          </a>
        </div>
        <div className={`${styles["pagination-center"]}`}>
          {props.currentPage != 1 ? <a onClick={changePage}>1</a> : null}
          {props.currentPage > neighborsSize + 2 ? <span>...</span> : null}
          {previousNeighborPages()}
          <a className={`${styles["active"]}`}>{props.currentPage}</a>
          {nextNeighborPages()}
          {props.currentPage < props.totalPages - neighborsSize - 1 ? <span>...</span> : null}
          {props.currentPage != props.totalPages ? <a onClick={changePage}>{props.totalPages}</a> : null}
        </div>
        <div className={`${styles["pagination-icon"]} ${styles["pagination-right"]}`}>
          <a onClick={goToNextPage}>
            <BsFillCaretRightFill className="h-4 w-4 rtl:rotate-180" aria-hidden="true" />
          </a>
        </div>
      </nav>
    </div>
  );
}
