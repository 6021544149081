import HTMLRender from '@components/core/HTMLRender';
import GenericSlider from '@components/custom/GenericSlider';
import SectionTitle from '@components/elements/SectionTitle';
import useSwrHook from '@lib/services/hooks/swr/useSwrHook';
import useLocale from '@lib/store/hooks/useLocale';
import Image from 'next/image';
import { SwiperSlide } from 'swiper/react';
import find from "lodash/find";
import remove from "lodash/remove";
import styles from './TradeAwards.module.css';
import NextImage from '@src/components/elements/Image';

const TradeAwards = (props) => {
  const locale = useLocale();
  const data = props.data || props;

  if (!data) return null;

  const { data: awards, loading, error } = useSwrHook(`awards`, {
    query: {
      locale,
      populate: {
        extras: {
          fields: ['content'],
          populate: {
            icon: {
              fields: ['url', 'width', 'height']
            }
          }
        },
        image: {
          fields: ['url', 'width', 'height']
        }
      },
      fields: ['title', 'year', 'featured', 'event', 'active'],
      sort: ['id:desc'],
      pagination: {
        start: 0,
        limit: 5,
      },
    }
  });

  if (!awards) return null;

  // find the middle position of the array and insert the featured in that position
  // this way the featured award will be always displayed in the middle

  // find index of the middle position of the array
  const indexOfMiddleArrPosition = awards?.data?.indexOf(awards.data[Math.round((awards.data.length - 1) / 2)])

  // find featured award
  const featuredAward = find(awards.data, ["attributes.featured", true]);

  // check if there is a featured award before array operations are performed on it
  if (featuredAward) {
    // remove featured award from the array
    remove(awards.data, featuredAward);
    // add the featured array in the middle position
    awards.data.splice(indexOfMiddleArrPosition, 0, featuredAward)
  }

  return (
    <div className='container'>
      <div className='hidden lg:block'>
        {/* Desktop Static Version */}
        <SectionTitle data={data?.section_title} />
        <div className='flex relative w-full flex-row justify-between mt-lg'>
          {awards?.data?.map((award, index) => (
            award.attributes.active &&
            <div key={index} className={`${styles.awardsCards} flex flex-col items-center ${awards.data.length % 2 !== 0 ? 'even:mt-lg' : 'even:mt-0'} ${award.attributes.featured && 'lg:mt-xl'}`}>
              <div className='relative'>
                <NextImage src="https://cdn.capex.com/uploads/awards_icon_67794a7489/awards_icon_67794a7489.png" width={award.attributes.featured ? 286 : 220} height={award.attributes.featured ? 170 : 130} alt="Awards" />
                <div className={`w-full max-w-[65%] flex flex-col items-center absolute ${award.attributes.featured ? "top-[10%]" : "top-0"} left-1/2 -translate-x-1/2`}>
                  <p className={`text-center text-[#bababa] ${!award.attributes.featured ? "text-[1.888rem]" : "text-[2.444em]"}  font-bold`}>{award?.attributes?.year}</p>
                  <p className={`text-center font-bold text-[#343F65]  ${!award.attributes.featured ? "text-sm" : "text-xl"} ltr:font-gilroy rtl:font-dubai`}>{award?.attributes?.title}</p>
                </div>
              </div>
              <div className={`${styles.event} w-full flex justify-center`}>
                <HTMLRender data={award?.attributes?.event} />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Mobile Slider Version */}
      <div className='lg:hidden flex justify-start ltr:items-center'>
        <div className={`relative w-full max-w-[20%] flex items-center md:max-w-[15%] bg-[#FCFFFB] z-10 ${styles.afterShadow}`} >
          <h2 className={`absolute top-1/2 -translate-y-1/2 left-0 ${styles.mobileTitleShadow} text-white opacity-50`}>{data?.section_title?.title}</h2>
          <h2 className={`${styles.mobileTitle} text-white opacity-50`}>{data?.section_title?.title}</h2>
        </div>
        <div className='w-full max-h-screen min-h-0 min-w-0 awardsSlider ltr:-mr-md rtl:-ml-md'>
          <GenericSlider slidesPerView={1.5} autoplay loop spaceBetween={5} breakpoints={{
            320: {
              slidesPerView: 1.5,
            },
            768: {
              slidesPerView: 2.5,
            },
          }}>
            {awards?.data?.map((award, index) => (
              <SwiperSlide key={index}>
                <div className='text-center'>
                  <div className='relative'>
                    <NextImage src="https://cdn.capex.com/uploads/awards_icon_67794a7489/awards_icon_67794a7489.png" width={276} height={176} alt="Awards" />
                    <div className="flex flex-col h-full justify-center items-center absolute top-0 left-1/2 -translate-x-1/2">
                      <p className="text-center text-[#bababa] text-[2.333rem] font-bold">{award?.attributes?.year}</p>
                      <p className="text-center font-bold text-[#343F65] text-sm md:text-lg ltr:font-gilroy rtl:font-dubai leading-[1.1] md:leading-snug">{award?.attributes?.title}</p>
                    </div>
                  </div>
                  <div className={`${styles.event} w-full text-center mx-auto flex justify-center`}>
                    <HTMLRender data={award?.attributes?.event} />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </GenericSlider>
        </div>
      </div>
    </div>
  );
};

export default TradeAwards;
