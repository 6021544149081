import { Tabs } from "@mantine/core";
import NextImage from "@src/components/elements/Image";
import styles from "../../TradeHomeInstruments.module.css";

const InstrumentCategoriesTabs = ({ data: { table_type, instrument_categories, qd_instruments }, activeTab, changeTab }) => {
  const hasTabs = table_type === "with_tabs_from_categories" && instrument_categories?.data.length > 0;

  const seenNames = new Set();
  const subcategories = qd_instruments && [
    ...new Set(qd_instruments?.data
      ?.map(category => category.global_instrument_types?.find(type => type.category_type === "subcategory"))
      ?.filter(item => item !== undefined && !seenNames?.has(item.name) && seenNames?.add(item.name))
    )
  ] || [];

  if (!hasTabs || !subcategories?.length > 0) {
    return null;
  }
  return (
    <Tabs
      grow
      active={activeTab}
      onTabChange={changeTab}
      classNames={{
        tabControl: styles.tabControl,
        tabActive: styles.tabActive,
        tabLabel: styles.tabLabel,
        tabIcon: styles.tabIcon,
      }}
    >
      {subcategories?.map((category) => (
        <Tabs.Tab
          key={category?.id}
          label={category?.display_name}
          tabKey={category?.name}
          icon={
            <NextImage
              src={category?.table_tab_icon}
              width={category?.table_tab_icon?.width}
              height={category?.table_tab_icon?.height}
            />
          }
        />
      ))}
    </Tabs>
  );
};

export default InstrumentCategoriesTabs;
