/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react'
import SectionTitle from '@src/components/elements/SectionTitle';
import { Select, Tabs } from '@mantine/core';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import { IoIosArrowDown } from '@react-icons/all-files/io/IoIosArrowDown';
import DifficultyLevel from '@src/components/elements/DifficultyLevel';
import Link from '@src/components/elements/Link';
import chunk from 'lodash/chunk';
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useStore } from '@lib/store';
import { shallow } from 'zustand/shallow';
import { IoIosArrowBack } from '@react-icons/all-files/io/IoIosArrowBack';
import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward';
import styles from '../AcademyLessonsV2.module.css';
import "swiper/css/pagination";
import "swiper/css";
import HTMLRender from '@src/components/core/HTMLRender';
import Button from '@src/components/elements/Button';
import { useRegistrationLink } from "@lib/store/hooks/useActiveProductType";
import NextImage from '@src/components/elements/Image';

function DesktopAcademyLessonsV2({ data }) {
    const [difficulty, setDifficulty] = useState(null)
    const academyParentGroup = data?.academy_categories?.data[0]?.category;
    const registrationLink = useRegistrationLink();
    const { gv } = useGlobalVariable()

    // get dir for swiper
    const store = useStore(
        (store) => ({
            dir: store.direction,
        }),
        shallow
    );

    const templateStyles = {
        trading: {
            color: '#FF4848',
            borderColor: '#FFAFAF',
            imageSrc: 'https://cdn.capex.com/uploads/trade_hero_bg_1b9c248b2a/trade_hero_bg_1b9c248b2a.png'
        },
        investing: {
            color: '#53266F',
            borderColor: '#ECB6FF',
            imageSrc: 'https://cdn.capex.com/uploads/invest_hero_bg_0f55a91363/invest_hero_bg_0f55a91363.png'
        },
        crypto: {
            color: '#E9FF60',
            borderColor: '#F3FFAB',
            imageSrc: 'https://cdn.capex.com/uploads/crypto_hero_bg_d461a79c2a/crypto_hero_bg_d461a79c2a.png'
        }
    }

    // swiper custom navigation ref
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    return (
        <div>
            <div className='container'>
                <SectionTitle data={data?.section_title} />
            </div>
            <div className='relative bg-[#1C1E21] mt-xl min-h-[45rem]'>
                <div className='h-full'>
                    <div className='absolute overflow-hidden top-0 left-0 h-full w-full'>
                        {academyParentGroup == 'crypto' ?
                            <div className='absolute -top-[4rem] -right-[13rem]'>
                                <NextImage
                                    width={879}
                                    height={1100}
                                    src="https://cdn.capex.com/uploads/Crypto_X_22710da483/Crypto_X_22710da483.png"
                                    priority
                                    quality="100"
                                    alt="Article Hero"
                                />
                            </div>
                            : academyParentGroup == 'investing' ?
                                <div className='absolute -top-[4rem] -right-[13rem]'>
                                    <NextImage
                                        width={879}
                                        height={1100}
                                        src="https://cdn.capex.com/uploads/Investing_X_573a4eec36/Investing_X_573a4eec36.png"
                                        priority
                                        quality="100"
                                        alt="Article Hero"
                                    />
                                </div> :
                                <div className='absolute -top-[4rem] -right-[13rem]'>
                                    <NextImage
                                        width={879}
                                        height={1100}
                                        src="https://cdn.capex.com/uploads/Trading_X_f697411072/Trading_X_f697411072.png"
                                        priority
                                        quality="100"
                                        alt="Article Hero"
                                    />
                                </div>
                        }
                    </div>
                    <div className='absolute bottom-0 left-[5px]'>
                        <NextImage
                            width={300}
                            height={400}
                            objectFit='cover'
                            src="https://cdn.capex.com/uploads/dots_academy_bg_a355b52d2c/dots_academy_bg_a355b52d2c.png"
                            priority
                            quality="100"
                            alt="Dots"
                        />
                    </div>
                </div>
                <div className='container relative z-[99]'>
                    <Tabs
                        grow
                        position="center"
                        variant="pills"
                        classNames={{
                            root: styles.tabsRoot,
                            tabsListWrapper: styles.tabsListWrapper,
                            tabLabel: styles.tabLabel,
                            tabActive: styles.tabActive,
                            tabControl: styles.tabControl
                        }}
                    >
                        {data?.academy_categories?.data?.map((category, index) => (
                            <Tabs.Tab key={index} label={category?.title}>
                                <div>
                                    {/* tab description content */}
                                    <div className='pb-xl'>
                                        <p className='text-[#CCCCCC]'>{category?.description}</p>
                                    </div>
                                    {/* difficulty selector  */}
                                    <div className='flex justify-end w-full pb-xl'>
                                        <Select
                                            value={difficulty}
                                            onChange={setDifficulty}
                                            allowDeselect
                                            rightSection={<IoIosArrowDown />}
                                            placeholder={gv('difficulty')}
                                            transitionDuration={300}
                                            classNames={{
                                                root: styles.selectRoot,
                                                input: styles.selectInput,
                                                rightSection: styles.selectRightSection,
                                                item: styles.selectItem,
                                                selected: styles.selectSelectedItem,
                                                dropdown: styles.selectDropdown
                                            }}
                                            data={[
                                                { value: 'beginner', label: `${gv('beginner')}` },
                                                { value: 'intermediate', label: `${gv('intermediate')}` },
                                                { value: 'advanced', label: `${gv('advanced')}` },
                                            ]}
                                        />
                                    </div>
                                    {/* lessons slider */}
                                    <div className='academy-lessons-slider relative'>
                                        <div
                                            ref={navigationPrevRef}
                                            className="absolute top-[35%] text-[#F8F6F6] opacity-60 cursor-pointer hover:opacity-100 ltr:left-[-7%] rtl:right-[-7%] rtl:rotate-180 text-[3rem]"
                                        >
                                            <IoIosArrowBack />
                                        </div>
                                        <div
                                            ref={navigationNextRef}
                                            className="absolute top-[35%] text-[#F8F6F6] opacity-60 cursor-pointer hover:opacity-100 ltr:right-[-7%] rtl:left-[-7%] rtl:rotate-180 text-[3rem]"
                                        >
                                            <IoIosArrowForward />
                                        </div>
                                        <Swiper
                                            dir={store ? store.dir : "ltr"}
                                            spaceBetween={15}
                                            slidesPerView={1}
                                            draggable
                                            loop={false}
                                            modules={[Navigation, Pagination]}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            navigation={{
                                                prevEl: navigationPrevRef.current,
                                                nextEl: navigationNextRef.current,
                                            }}
                                            onBeforeInit={(swiper) => {
                                                swiper.params.navigation.prevEl = navigationPrevRef.current;
                                                swiper.params.navigation.nextEl = navigationNextRef.current;
                                            }}
                                        >
                                            <div className='flex justify-between items-start flex-wrap'>
                                                {chunk(category?.academy_subjects
                                                    ?.filter(el => difficulty === null || (el?.difficulty_level !== null && el?.difficulty_level?.includes(difficulty))), 9)
                                                    .map((lessonGroup) => (
                                                        <SwiperSlide key={index}>
                                                            <div className='flex gap-x-[0.9rem] items-start flex-wrap'>
                                                                {lessonGroup.map((lessonCard) => (
                                                                    <div key={lessonCard?.id}
                                                                        className={`relative w-full max-w-[calc(100%/3-0.625rem)] ${`bg-[${templateStyles[academyParentGroup].color}] border-[${templateStyles[academyParentGroup].borderColor}]`}  p-md bg-opacity-[16%] cursor-pointer hover:bg-opacity-30 transition-all duration-200 ease-in-out border border-opacity-50 mb-lg rounded-lg`}>
                                                                        <Link href={lessonCard?.slug}>
                                                                            <a className='h-full w-full top-0 left-0 z-10' />
                                                                        </Link>
                                                                        <div className='flex flex-col'>
                                                                            <div className='flex justify-between items-center mb-4'>
                                                                                {/* reading time */}
                                                                                {lessonCard?.reading_time && <p className='text-sm text-[#cccccc] mb-0'>
                                                                                    {lessonCard?.reading_time > 1 ? Math.ceil(lessonCard?.reading_time) : 2} {gv("minutes")}
                                                                                </p>}

                                                                                {/* difficulty level */}
                                                                                {lessonCard?.difficulty_level &&
                                                                                    <div className='text-white text-sm'>
                                                                                        <DifficultyLevel level={lessonCard?.difficulty_level} />
                                                                                    </div>}
                                                                            </div>
                                                                            <p className='text-white text-xl font-bold'>
                                                                                {lessonCard?.title}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </SwiperSlide>
                                                    ))
                                                }
                                            </div>
                                        </Swiper>
                                    </div>
                                </div>
                            </Tabs.Tab>
                        ))}
                    </Tabs>
                </div>
            </div>
            {/* blue box component */}
            {data?.blue_box_text &&
                <div className='container bg-secondary flex flex-row justify-between items-center mt-2xl rounded-lg py-lg text-white text-xl px-3xl'>
                    <div className={`${styles.blue_box_text} lg:max-w-[75%]`}>
                        <HTMLRender data={data?.blue_box_text} />
                    </div>
                    <Button type="white-borderless" size="sm" label={gv('create_account')} link={registrationLink} />
                </div>}
        </div>
    )
}

export default DesktopAcademyLessonsV2