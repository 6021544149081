import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";

/*
    * Scroll Event Logo Change Color

    * @param {string} logoColor
    * @returns {string} logoColor
*/

export function useHeaderLogo(color = "red") {
  const store = useStore(
    (store) => ({
      header_style: store?.header_style,
    }),
    shallow
  );
  const [logo, setLogo] = useState(store?.header_style?.logo);

  useEffect(() => {
    setLogo(store?.header_style?.logo);
  }, [store.header_style]);

  return logo;
}

useHeaderLogo.propTypes = {
  color: PropTypes.string,
};
