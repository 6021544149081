import React from 'react'
import styles from '../AcademyOffers.module.css'
import SectionTitle from '@src/components/elements/SectionTitle'
import { Accordion } from '@mantine/core'
import Arrow from '@src/components/elements/Arrow'
import HTMLRender from '@src/components/core/HTMLRender'
import { useRegistrationLink } from '@lib/store/hooks/useActiveProductType';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import Button from '@src/components/elements/Button'
import NextImage from '@src/components/elements/Image'

// custom image Component, different image positioning if item's index is even or odd
function CustomImage({ index }) {
    return (
        index % 2 === 0 ?
            <div className='absolute -top-[13rem] -left-[8rem] z-0 opacity-30'>
                <NextImage
                    layout='intrinsic'
                    objectFit='cover'
                    width={312}
                    height={385}
                    src="https://cdn.capex.com/uploads/red_x_bb9e333d22/red_x_bb9e333d22.png"
                    priority
                    quality="100"
                    alt="Article Hero"
                />
            </div> :
            <div className='absolute -top-[5rem] -right-[13rem] z-0 opacity-30'>
                <NextImage
                    layout='intrinsic'
                    objectFit='cover'
                    width={312}
                    height={385}
                    src="https://cdn.capex.com/uploads/red_x_bb9e333d22/red_x_bb9e333d22.png"
                    priority
                    quality="100"
                    alt="Article Hero"
                />
            </div>
    )
}

function MobileAcademyOffers({ data }) {
    const registrationLink = useRegistrationLink();
    const { gv } = useGlobalVariable()
    return (
        <div>
            <div className='container'>
                <SectionTitle data={data?.section_title} />
            </div>
            <Accordion
                initialItem={0}
                icon={<Arrow direction="down" />}
                classNames={{
                    control: styles.AccordionControl,
                    root: styles.AccordionRoot,
                    itemOpened: styles.itemOpened,
                    label: styles.AccordionLabel,
                    item: styles?.AccordionItem,
                    contentInner: styles?.contentInner,
                    icon: styles.borderAccordionIcon,
                    content: styles?.AccordionContent,
                }}
                variant="unstyled"
            >
                {data?.content?.map((item, index) => (
                    <Accordion.Item key={index} label={<div><HTMLRender data={item?.title} /><CustomImage index={index} /></div>}>
                        <HTMLRender data={item?.content} />
                    </Accordion.Item>
                ))}
            </Accordion>
            {/* blue box component */}
            {data?.blue_box_text &&
                <div className='flex flex-col justify-center mt-xl container'>
                    <div className={styles.blue_box_text}>
                        <HTMLRender data={data?.blue_box_text} />
                    </div>
                    <div className='w-full mt-xl flex justify-center'>
                        <Button type="secondary" size="lg" label={gv('create_account')} link={registrationLink} />
                    </div>
                </div>}
        </div>
    )
}

export default MobileAcademyOffers