import GenericSlider from '@components/custom/GenericSlider';
import SectionLayout from '@components/elements/SectionLayout';
import TimelineCard from '@components/custom/TimelineCard';
import SectionTitle from '@components/elements/SectionTitle'
import { SwiperSlide } from 'swiper/react';
import './HistoryTimeline.module.css'

const HistoryTimeline = (props) => {
  const data = props.data || props;

  if (!data) {
    return null
  }
  return (
    <div className="container" id={data?.section_layout?.section_id || ''}>
      <SectionTitle data={data?.section_title} />
      <div className='timeline-slider'>
        <GenericSlider navigation pagination grabCursor freeMode autoplay slidesPerView={3}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1025: {
              slidesPerView: 3,
            },
          }}
        >
          {
            data?.timeline_items?.timeline_item.map((item, index) => (
              <SwiperSlide key={index}>
                <TimelineCard data={item} />
              </SwiperSlide>
            ))
          }
        </GenericSlider>
      </div>
    </div>
  );
};

export default HistoryTimeline;
