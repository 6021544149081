import './AcademyCardMobile.module.css';
import { BiTime } from '@react-icons/all-files/bi/BiTime';
import Link from 'next/link';
import NextImage from '@components/elements/Image';
import { useViewportSize } from '@mantine/hooks';

const AcademyCardMobile = (props) => {
  const {width} = useViewportSize();
  const data = props.data?.attributes || props;
  const available = props.available
  if(width > 768) return <></>
  return (
    <div className="mr-xs">
      <div className="pt-xl px-xs pb-lg relative h-[20rem] bg-secondary max-h-[20rem] w-full rounded-md lg:hidden overflow-hidden">
        {/* {imgSrc && <div className="img-wrapper absolute top-0 right-auto left-0  h-full w-full overflow-hidden"></div>} */}
        {data.mobile_image && <NextImage src={data.mobile_image} />}
        {data.link && (
          <Link href={`${data.link}`}>
            <a className="absolute top-0 left-0 z-10 h-full w-full bg-transparent"></a>
          </Link>
        )}
        <div className="z-1 relative flex h-full w-full flex-col items-center justify-between text-center text-white">
          {data.title && <div className="uppercase h-[25%]">{data.title}</div>}
          <div className="flex flex-col items-center">
            {data.lessons?.data?.length > 0 && <div>{data.lessons.data.length} Lessons</div>}
            {available && <div>{`(${available})`}</div>}
          </div>
          {data.total_time && (
            <div className="flex items-center">
              <BiTime />
              <span className="pl-xs">{data.total_time}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AcademyCardMobile;
