import React from 'react'
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables'
import { Accordion } from '@mantine/core'
import Arrow from '@src/components/elements/Arrow'
import HTMLRender from '@src/components/core/HTMLRender'
import styles from '../AcademyFeaturedLessons.module.css'
import Link from '@src/components/elements/Link'

function EssentialArticles({ data }) {
    const { gv } = useGlobalVariable()
    return (
        <div className='flex flex-col lg:bg-[#F8F6F6] lg:p-lg rounded-lg w-full lg:max-w-[41%]'>
            <div className='px-md lg:px-0'>
                <h3 className={`font-semibold text-dark text-2xl mb-0 pb-lg ${styles.essentialTItle}`}>{gv('essential_links')}</h3>
                {data?.essential_links?.data?.map((article, index) => (
                    <div className='pb-lg last-pb-0'>
                        <Link key={index} href={article?.slug}>
                            <a className='text-dark hover:text-secondary'>
                                <HTMLRender data={article?.title} />
                            </a>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default EssentialArticles