import HTMLRender from "@components/core/HTMLRender";
import styles from "./SponsorshipsSlide.module.css";
import { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import NextImage from "@components/elements/Image";
import Arrow from "@components/elements/Arrow";
import { Navigation } from "swiper";
import SliderModal from "./SliderModal";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";

const SponsorshipsSlide = (props) => {
  const data = props.data || props;
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openSlideIndex, setOpenSlideIndex] = useState(0);

  const store = useStore(
    (store) => ({
      dir: store.direction,
    }),
    shallow
  );

  if (!data) return null;

  const handleIndex = (index) => {
    setOpen(true);
    setOpenSlideIndex(index);
  };

  return (
    <div
      id={data?.section_layout?.section_id}
      style={{ scrollMarginTop: "4.88rem" }}
      className="container relative h-full"
    >
      <div
        className="m-0 flex h-auto items-start md:translate-y-[70%] md:items-center lg:h-[36rem]  lg:translate-y-0 2xl:h-[45rem]"
        style={{
          justifyContent: `${
            data?.position_left
              ? `${store && store?.dir === "ltr" ? "flex-start" : "flex-end"}`
              : `${store && store?.dir === "ltr" ? "flex-end" : "flex-start"}`
          }`,
        }}
      >
        <div className="basis-unset w-full grow-[unset] text-center md:w-1/2 md:basis-1/2 ltr:md:text-left rtl:md:text-right">
          <p className="text-white">{data?.year}</p>
          <h3 className="font-bold uppercase text-primary ltr:font-gilroy rtl:font-dubai">{data?.title}</h3>
          <div className={`${styles.description}`}>
            <HTMLRender data={data?.description} />
          </div>
          {data?.images?.data && (
            <div className="sponsorsWrapper absolute right-auto left-1/2 hidden w-full -translate-x-1/2 translate-y-1/2 px-md md:bottom-[-8rem] md:flex lg:bottom-[7.5rem]">
              {/* custom navigation - left */}
              <div
                ref={navigationPrevRef}
                className="mt-[0.5%] h-full cursor-pointer text-[1.5rem] text-[#777] transition-all duration-300 hover:text-white ltr:mr-md rtl:mr-md"
              >
                <Arrow direction="left" />
              </div>
              {/* thumbs gallery swiper slider */}
              <Swiper
                dir={store ? store.dir : "ltr"}
                modules={[Navigation]}
                navigation={{
                  prevEl: navigationPrevRef.current ? navigationPrevRef.current : undefined,
                  nextEl: navigationNextRef.current ? navigationNextRef.current : undefined,
                }}
                onSwiper={(swiper) => {
                  // Delay execution for the refs to be defined
                  setTimeout(() => {
                    // Override prevEl & nextEl now that refs are defined
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                    // Re-init navigation
                    swiper.navigation.destroy();
                    swiper.navigation.init();
                    swiper.navigation.update();
                  });
                }}
                spaceBetween={30}
                slidesPerView={5}
                breakpoints={{
                  1024: {
                    slidesPerView: 8,
                    spaceBetween: 12,
                  },
                }}
                loop={true}
              >
                {data?.images?.data?.map((image, index) => (
                  <SwiperSlide key={index} styles={{ cursor: "pointer" }}>
                    <div className={`${styles.imageWrapper}`} onClick={() => handleIndex(index)}>
                      <NextImage src={image?.attributes?.url} width={120} height={45} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* custom navigation - right */}
              <div
                ref={navigationNextRef}
                className="mt-[0.5%] h-full cursor-pointer text-[1.5rem] text-[#777] transition-all duration-300 hover:text-white ltr:ml-md rtl:mr-md"
              >
                <Arrow direction="right" />
              </div>
              {/* slider modal */}
              <SliderModal data={data} open={open} setOpen={setOpen} openSlideIndex={openSlideIndex} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SponsorshipsSlide;
