import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import styles from './WebinarTy.module.css';

const WebinarTy = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      <h1 className="text-secondary ltr:font-gilroy rtl:font-dubai font-bold md:font-normal text-center md:mb-[3.66rem]">{data?.section_title?.title}</h1>
      <div className={`${styles.subtitle} text-center mb-lg md:mb-[3.66rem]`}>
        <HTMLRender data={data?.section_title.subtitle} />
      </div>
      <div className={`${styles.secondaryTitle} text-center mb-lg md:mb-[3.66rem]`}>
        <HTMLRender data={data?.secondary_title} />
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center px-[2.777rem] w-full py-[2rem] bg-[#f6f6f6] rounded-lg">
        <div className={`${styles.boxText} md:max-w-[65%] mb-lg md:mb-0`}>
          <HTMLRender data={data?.box_text} />
        </div>
        <div className={`flex flex-col items-center md:flex-row md:justify-between ${data?.buttons?.length > 1 && 'w-full md:max-w-[40%]'}`}>
          {data?.buttons.map((button, index) => (
            <div key={index} className={`w-full ${data?.buttons?.length > 1 && 'lg:max-w-[calc(100%/2-0.5rem)]'} mb-lg last:mb-0 md:mb-0 ${styles.button}`}>
              <Button data={button} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WebinarTy;
