import ColoredBox from '@components/elements/ColoredBox';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './ProductFeatures.module.css';
import { GiCheckMark } from '@react-icons/all-files/gi/GiCheckMark';

const ProductFeatures = (props) => {
  const data = props.data || props;
  if (!data) return null;
  
  return (
    <>
      <div className='container'>
        {data?.section_title && <SectionTitle data={data?.section_title} />}
        <div className={`${styles?.boxStyles}`}>
          <ColoredBox data={data?.box} />
        </div>
      </div>
    </>
  );
};

export default ProductFeatures;
