import IPOCard from '@components/custom/IPOCard';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './IpoHero.module.css'

const IpoHero = (props) => {
  const data = props.data || props;

  if (!data) {
    return null;
  }
  return (
    <>
      {/* desktop */}
      <div className="container">
        <div className={styles.title}>
          <SectionTitle data={data.section_title} />
        </div>
        <div className="hidden lg:flex justify-center md:-mt-[3rem]">
          <IPOCard data={data?.ipo?.data[0]} />
        </div>
      </div>
      {/* mobile */}
      <div className="flex lg:hidden justify-center md:-mt-[3rem]">
        <div className='container'>
          <div className='md:max-w-[60%] mx-auto'>
            <IPOCard data={data?.ipo?.data[0]} />
          </div>
        </div>
      </div>
    </>
  );
};

export default IpoHero;
