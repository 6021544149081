import styles from './ArticleExploreCard.module.css'
import NextLink from "@components/elements/Link";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import dayjs from "dayjs";
import { IoCalendarOutline } from "@react-icons/all-files/io5/IoCalendarOutline";
import NextImage from '@src/components/elements/Image';
import DifficultyLevel from '@src/components/elements/DifficultyLevel';

const ArticleExploreCard = (props) => {
  const data = props.data || props;
  const formatDate = dayjs(data?.attributes?.updatedAt).format("DD MMMM YYYY");
  const { gv } = useGlobalVariable();
  if (!data) {
    return null;
  }

  return (
    <div className='p-lg rounded-lg border-dark border relative h-full flex flex-col justify-between shadow-none transition-shadow duration-300 hover:shadow-lg'>
      <NextLink href={data?.attributes?.slug} className="absolute top-0 left-0 w-full h-full" />
      <div>
        <h4 className="mb-md font-bold">
          {data?.attributes?.title}
        </h4>
        {data.attributes?.global_analyst?.data && data.attributes?.global_analyst?.data?.attributes?.name ? (
          <div className="flex items-center pb-md">
            <div className="w-[30px] h-[30px] rounded-full relative overflow-hidden bg-[#DEDEDE]">
              <NextImage
                priority
                src={data?.attributes?.global_analyst?.data?.attributes.image_variant_a?.data?.attributes?.formats?.thumbnail?.url}
                alt={data?.attributes?.global_analyst?.data?.attributes.name}
                width={data?.attributes?.global_analyst?.data?.attributes.image_variant_a?.data?.attributes?.formats?.thumbnail?.width}
                height={data?.attributes?.global_analyst?.data?.attributes.image_variant_a?.data?.attributes?.formats?.thumbnail?.height}
              />
            </div>
            <span className="ltr:pl-xs rtl:pr-xs text-darkGrey">{data?.attributes?.global_analyst?.data?.attributes.name}</span>
          </div>
        ) : null}
        <p className="flex items-center pb-md  lg:pb-xl">
          <IoCalendarOutline className="ltr:mr-xs rtl:ml-xs text-lg" />
          <span>{formatDate}</span>
        </p>
      </div>
      <div className='flex justify-between flex-col lg:flex-row'>
        <div>{gv('read_more')}</div>
        {data?.attributes?.difficulty_level &&
          <div className="pt-md lg:pt-0 lg:ltr:pl-lg lgrtl:pr-lg">
            <DifficultyLevel level={data?.attributes?.difficulty_level} />
          </div>
        }
      </div>
    </div>
  );
};

export default ArticleExploreCard;
