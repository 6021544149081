/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable arrow-body-style */
import Footer from "@components/sections/Footer";
import Header from "@components/sections/Header";
import { QuickEditPage } from "@components/custom/QuickEdit";
import ZendeskChat from "@components/custom/ZendeskChat";
import layoutDictionary from "./layout_component";
import StickyRiskWarning from "@src/components/custom/StickyRiskWarning";
import WrongLicenceBar from "@src/components/custom/WrongLicenceBar";
import useSessionStorage from "@lib/store/hooks/useSessionStorage";
import { createContext, useEffect, useState } from "react";
import Head from "next/head";
import Script from "next/script";
import AlternatesSection, { getFullUrl } from "@src/components/custom/Seo/Alternates";
import get from "lodash/get";
import replace from "lodash/replace";
import { useRouter } from "next/router";
import { useStore } from "@lib/store";
export default function Layout({ pageData, pageType }) {
  const UseComponent = layoutDictionary[pageType];
  const router = useRouter();
  const locale = useStore((state) => state.locale);
  const [stateEmbedded, setStateEmbedded] = useState(false);
  // const { embedded } = router.query;
  useEffect(() => {
    const queryKey = "embedded";
    let embedded = false;
    try {
      embedded = router.query[queryKey] || router.asPath.match(new RegExp(`[&?]${queryKey}=(.*?)(&|$)`))?.[1];
      if (embedded === "true") {
        embedded = true;
      } else {
        embedded = false;
      }
    } catch (error) {
      embedded = false;
    }
    if (embedded !== stateEmbedded) {
      setStateEmbedded(embedded);
    }
  }, [router.query, router.asPath]);

  const isIframe = get(pageData, "page_key", "").includes("iframe");
  const currentProductType = useStore((state) => state.currentProductType);

  if (!UseComponent) return null;
  const localizationVersions = {};
  [
    { attributes: { locale: pageData?.locale, slug: pageData?.slug, publishedAt: pageData?.publishedAt } },
    ...(pageData?.localizations?.data || []),
    ...(pageData?.alternates?.english || []),
    ...(pageData?.alternates?.spanish || []),
    ...(pageData?.alternates?.arabic || []),
  ].map((item, index) => {
    if (item?.attributes?.publishedAt) {
      localizationVersions[item.attributes.locale] = item.attributes.slug;
    }
    if (item?.type === "alternate") {
      localizationVersions[item.locale] = item.slug;
    }
    return item?.attributes || item;
  });
  const fullSlugClear = replace(pageData?.fullSlug, /\/pagination\/1/gi, "") || "";
  return (
    <>
      <Head>
        <link rel="canonical" href={fullSlugClear} />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="https://cdn.capex.com/uploads/apple_touch_icon_57x57_e1c6f9b9ff/apple_touch_icon_57x57_e1c6f9b9ff.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="https://cdn.capex.com/uploads/apple_touch_icon_72x72_4ad5330dfa/apple_touch_icon_72x72_4ad5330dfa.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="https://cdn.capex.com/uploads/apple_touch_icon_114x114_c597f2f573/apple_touch_icon_114x114_c597f2f573.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="https://cdn.capex.com/uploads/apple_touch_icon_144x144_c651608bcf/apple_touch_icon_144x144_c651608bcf.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="https://cdn.capex.com/uploads/apple_touch_icon_120x120_2881a432d9/apple_touch_icon_120x120_2881a432d9.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="https://cdn.capex.com/uploads/apple_touch_icon_152x152_923d6781d6/apple_touch_icon_152x152_923d6781d6.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="167x167"
          href="https://cdn.capex.com/uploads/apple_touch_icon_167x167_ee7c16c973/apple_touch_icon_167x167_ee7c16c973.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://cdn.capex.com/uploads/apple_touch_icon_180x180_aa8a10b317/apple_touch_icon_180x180_aa8a10b317.png"
        />
        <link
          rel="apple-touch-icon"
          href="https://cdn.capex.com/uploads/apple_touch_icon_482ba2cc4e/apple_touch_icon_482ba2cc4e.png"
        />
      </Head>
      <Head>
        <meta name="clarity-site-verification" content="2d8e34e8-8233-43a7-a17c-53868e0b0c18" />
      </Head>
      <AlternatesSection
        pageType={pageType}
        localizationVersions={localizationVersions}
        alternates={pageData?.alternates}
        locale={pageData?.locale}
      />

      <Head>
        {/* self refering canonical */}

        {/* alternate for articles/academy lessons */}
        <link rel="icon" type="image/x-icon" href="https://cdn.capex.com/uploads/favicon_ffd34294e8/favicon_ffd34294e8.ico" />
        <meta name="theme-color" content="#111114" />
      </Head>
      <QuickEditPage pageData={pageData} />

      {!isIframe && stateEmbedded === false && (
        <div>
          <WrongLicenceBar />
          {currentProductType?.attributes?.risk_warning_type === "adgm" && (
            <StickyRiskWarning
              currentProductType={currentProductType}
              id="adgm_desktop_risk_warning"
              key="adgm_desktop_risk_warning"
            />
          )}
          <Header />
        </div>
      )}

      <main dir={pageData?.direction}>
        <UseComponent pageType={pageType} pageData={pageData} />
      </main>
      {!isIframe && stateEmbedded === false && <Footer />}
      {!isIframe && stateEmbedded === false && currentProductType?.attributes?.risk_warning_type === "cysec" && (
        <StickyRiskWarning
          id="cysec_sticky_disclaimer"
          currentProductType={currentProductType}
          key="cysec_sticky_disclaimer"
        />
      )}

      {!isIframe && stateEmbedded === false && <ZendeskChat locale={locale || false} />}
      {!isIframe && stateEmbedded === false && (
        <Script strategy="lazyOnload" id="gtag-manager">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PHJRCT4');`}
        </Script>
      )}
      {!isIframe && stateEmbedded === false && (
        <Script
          strategy="lazyOnload"
          id="ck-disclaimer"
          data-cbid="0e783eed-30a0-4a3c-b331-83d8a0c9ac3c"
          src="https://consent.cookiebot.com/uc.js"
        />
      )}
    </>
  );
}
