/* eslint-disable prefer-arrow-callback */
/* eslint-disable arrow-body-style */
import "./ZendeskChat.module.css";
import Script from "next/script";
import { useEffect, useRef, memo, useState } from "react";
import get from "lodash/get";
import Tenants from "./configurations";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { usePersistentStore, useStore } from "@lib/store";

const ZendeskChat = memo(({ locale }) => {
  const tenantUrl = get(Tenants(locale), "url", false);
  const { gv, isValidating } = useGlobalVariable();
  const [zendeskLoaded, setZendeskLoaded] = usePersistentStore((state) => [state.zendeskLoaded, state.setZendeskLoaded]);
  const [prevLocale, setPrevLocale] = useState(null); // Use state instead of ref

  // FIXME: A part of the code to update the settings of zendesk when we actually change the locale,
  // and remove the code from useLocaleChange.js file that does full refresh of the page to reload the zendesk chat
  // we actually need to destroy the chat, and reinitiate it with the new tenant id

  // useEffect(() => {
  //   if (typeof window !== "undefined" && zendeskLoaded) {
  //       window.zE("webWidget", "updateSettings", {
  //         webWidget: {
  //           chat: {
  //             prechatForm: {
  //               greeting: {
  //                 "*": gv("zendesk_greeting"),
  //               },
  //               departmentLabel: {
  //                 "*": gv("language"),
  //               },
  //             },
  //             offlineForm: {
  //               greeting: {
  //                 "*": gv("zendesk_offline_greeting"),
  //               },
  //             },
  //           },
  //           launcher: {
  //             chatLabel: {
  //               "*": gv("zendesk_chat"),
  //             },
  //           },
  //         }
  //       });

  //     setPrevLocale(locale); // Update after comparison
  //   }
  // }, [locale, zendeskLoaded]);

  if (isValidating) return null;
  if (!tenantUrl) return null;
  return (
    <Script
      src={tenantUrl}
      strategy="lazyOnload"
      onLoad={() => {
        window.zESettings = {
          webWidget: {
            chat: {
              prechatForm: {
                greeting: {
                  "*": gv("zendesk_greeting"),
                },
                departmentLabel: {
                  "*": gv("language"),
                },
              },
              offlineForm: {
                greeting: {
                  "*": gv("zendesk_offline_greeting"),
                },
              },
            },
            launcher: {
              chatLabel: {
                "*": gv("zendesk_chat"),
              },
            },
          },
        };
        // Check if openChat=true in URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("openChat") === "true") {
          try {
            window?.zE("webWidget", "open");
          } catch (err) {
            console.warn("[Zendesk] WebWidget is not present on this license.");
          }
          try {
            window?.zE("messenger", "open");
          } catch (err) {
            console.warn("[Zendesk] messenger is not present on this license.");
          }
        }
        setZendeskLoaded(true);
      }}
      id="ze-snippet"
    />
  );
});

ZendeskChat.displayName = "ZendeskChat";

export default ZendeskChat;
