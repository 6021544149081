import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import './SafetyBoxes.module.css';

const SafetyBoxes = (props) => {
  const data = props.data || props;
  if(!data) return null;

  return (
    <>
      <div className='container'>
        <SectionTitle data={data?.section_title} />
        <div className='flex flex-col md:flex-row justify-between flex-wrap'>
          {data?.box_content.map((box, index) => (
            <div key={index} className='w-full md:w-[calc(33.333%-0.556rem)] bg-white rounded-lg p-lg mb-[0.556rem]'>
              <div className='pb-md text-center'>
                <NextImage src={box?.icon} width={box?.icon?.data?.attributes?.width} height={box?.icon?.data?.attributes?.height} />
              </div>
              <div className='pb-md text-center font-bold'>
                {box?.title}
              </div>
              <div className='pb-md'>
                <HTMLRender data={box?.content} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SafetyBoxes;
