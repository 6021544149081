import HTMLRender from '@components/core/HTMLRender';
import styles from './Disclaimer.module.css';

const Disclaimer = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      <p className='text-h2 pb-lg'>{props.title}</p>
      <div className={styles.content}>
        <HTMLRender data={props.content} />
      </div>

    </div>
  );
};

export default Disclaimer;
