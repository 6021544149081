import styles from "./PlatformsWebtrader.module.css";
import HTMLRender from "@components/core/HTMLRender";
import NextImage from "@components/elements/Image";
import List from "@components/elements/List";
import Button from "@components/elements/Button";
import { Controller, Scene } from "react-scrollmagic";
import { Tween } from "react-gsap";

const PlatformsWebtrader = (props) => {
  const data = props.data || props;
  if (!data) return null;
  const icons = data.os_icons.data;

  return (
    <div className="overflow-hidden">
      <div className="container">
        <div className="hidden lg:block">
          <Controller>
            <Scene triggerHook="0" duration={2500} pin>
              <div className="flex flex-col md:flex-row rtl:flex-row-reverse items-center lg:h-[100vh]  scene">
                <Controller>
                  <div className="md:w-1/2 block lg:hidden order-2 md:order-none">
                    <NextImage
                      src={data?.mobile_image}
                      width={data?.mobile_image?.data?.attributes?.width}
                      height={data?.mobile_image?.data?.attributes?.height}
                    />
                  </div>
                  <div className="grow w-1/2 2xl:w-auto hidden lg:block">
                    <Scene
                      triggerHook="0"
                      duration={1500}
                      offset="-500"
                      triggerElement=".scene"
                    >
                      <Tween
                        from={{ opacity: 0, transform: "translateX(-50%)" }}
                        to={{ opacity: 1, transform: "translateX(0%)" }}
                      >
                        <div>
                          <NextImage
                            src={data?.webtrader_image}
                            width={
                              data?.webtrader_image?.data?.attributes?.width
                            }
                            height={
                              data?.webtrader_image?.data?.attributes?.height
                            }
                          />
                        </div>
                      </Tween>
                    </Scene>
                  </div>
                  <div className="md:w-1/2 ltr:md:pl-xl rtl:md:pr-xl order-1 md:order-none text-center ltr:md:text-left rtl:md:text-right pb-md md:pb-0">
                    <Scene
                      triggerHook="1"
                      duration={1500}
                      offset="250"
                      triggerElement=".scene"
                    >
                      <Tween
                        from={{ opacity: 0, transform: "translateY(500px)" }}
                        to={{ opacity: 1, transform: "translateY(0%)" }}
                        stagger={0.1}
                      >
                        {data?.webtrader_header && (
                          <div
                            className={`${styles[`webtrader-header`]} pb-lg`}
                          >
                            <HTMLRender data={data?.webtrader_header} />
                          </div>
                        )}
                        {data?.webtrader_text_1 && (
                          <div className="pb-lg">
                            <HTMLRender data={data?.webtrader_text_1} />
                          </div>
                        )}
                        {icons && (
                          <div className="items-center hidden lg:flex">
                            {icons.map((image, index) => (
                              <div
                                key={index}
                                className="ltr:mr-2xl ltr:last:mr-0 rtl:ml-2xl rtl:last:ml-0 relative w-[3.111rem] h-[3.111rem] flex items-center"
                              >
                                <NextImage
                                  objectFit
                                  src={image.attributes?.url}
                                  width={image.attributes?.width}
                                  height={image.attributes?.height}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </Tween>
                    </Scene>
                  </div>
                  <div id="webtrader" />
                </Controller>
              </div>
            </Scene>
          </Controller>
        </div>
        <div className="block lg:hidden">
          <div className="flex flex-col md:flex-row items-center lg:h-[100vh]  scene">
            <div className="md:w-1/2 block lg:hidden order-2 md:order-none">
              <NextImage
                src={data?.mobile_image}
                width={data?.mobile_image?.data?.attributes?.width}
                height={data?.mobile_image?.data?.attributes?.height}
              />
            </div>
            <div className="md:w-1/2 ltr:md:pl-xl rtl:md:pr-xl order-1 md:order-none text-center ltr:md:text-left rtl:md:text-right pb-md md:pb-0">
              {data?.webtrader_header && (
                <div className={`${styles[`webtrader-header`]} pb-lg`}>
                  <HTMLRender data={data?.webtrader_header} />
                </div>
              )}
              {data?.webtrader_text_1 && (
                <div className="pb-lg">
                  <HTMLRender data={data?.webtrader_text_1} />
                </div>
              )}
              {icons && (
                <div className="items-center hidden lg:flex">
                  {icons.map((image, index) => (
                    <div
                      key={index}
                      className="ltr:mr-2xl rtl:mr-2xl ltr:last:mr-0 rtl:ml-0 relative w-[3.111rem] h-[3.111rem] flex items-center"
                    >
                      <NextImage
                        objectFit
                        src={image.attributes?.url}
                        width={image.attributes?.width}
                        height={image.attributes?.height}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div id="webtrader" />
          </div>
        </div>
        <div className="text-center pt-xl lg:pt-0">
          {data?.webtrader_text_2 && (
            <div>
              <HTMLRender data={data?.webtrader_text_2} />
            </div>
          )}
          {data?.webtrader_list && (
            <div
              className={`text-grey pt-xl ${styles[`webtrader-list`]
                } text-left`}
            >
              <List type="checked" border list_items={data?.webtrader_list} />
            </div>
          )}
          {data?.button && (
            <div className="pt-xl">
              <Button
                type={data?.button?.type}
                size={data?.button?.size}
                arrow={data?.button?.arrow}
                label={data?.button?.label}
                link={data?.button?.link}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlatformsWebtrader;
