import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';
import styles from './IconText.module.css'

const IconText = (props) => {
  const data = props.data || props

  return (
    <div className="relative h-full w-full flex flex-row flex-wrap">
      {data.map((column, index) => (
        <div key={index} className={`${styles.column}`}>
          <div className="flex md:flex-row items-center pb-lg">
            <div className={`${!column.title && 'w-full max-w-[20%]'}`}>
              <NextImage src={column?.icon} width={35} height={35} objectFit />
            </div>
            {column.title ?
              <p className="px-md text-lg text-secondary font-normal">{column.title}</p>
              :
              <div className="ltr:md:text-left rtl:md:text-right">
                <HTMLRender data={column.content} />
              </div>
            }
          </div>
          {column.title ? <div className={`h-full flex items-start ${column.center && 'mt-lg'}`}>
            <HTMLRender data={column.content} />
          </div> : null}
        </div>
      ))}
    </div>
  );
};

export default IconText;
