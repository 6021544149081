import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';
import styles from './PartnersDeals.module.css';

const PartnersDeals = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      <div className={`w-full md:max-w-[30%] mb-lg ${styles.content}`}>
        <HTMLRender data={data?.section_title?.subtitle} />
      </div>
      <div className='relative mt-lg lg:mt-xl'>
        <div className={`hidden md:block ${styles.image}`}>
          <NextImage src={data?.image} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
        </div>
        <div className='md:absolute z-10 -top-[8%] lg:-top-[4%] right-0 flex flex-col md:flex-row justify-end items-start md:items-center px-sm'>
          {
            data?.plans_list?.map((plan, index) => (
              <div key={index} className='flex flex-col w-full md:max-w-[30%] md:flex-row md:even:mx-sm mb-lg md:odd:-mb-[20%] md:first:-mb-[10%]'>
                <div className='flex'>
                  <p className='text-primary ltr:font-gilroy rtl:font-dubai ltr:font-black rtl:font-bold text-[7rem] leading-[5rem] lg:text-[8rem] mb-0 ltr:mr-md rtl:ml-md ltr:md:mr-0 rtl:md:ml-0'>{index + 1}</p>
                  <div className={styles.planContent}>
                    <HTMLRender data={plan.content} />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <div className='flex justify-center mt-lg'>
          <Button data={data?.button} />
        </div>
      </div>
    </div>
  );
};

export default PartnersDeals;
