import PepCard from '@src/components/custom/PepCard';
import SectionTitle from '@src/components/elements/SectionTitle';
import './PepCards.module.css';

const PepCards = (props) => {
  const data = props.data || props;
  if (!data) return null;
  return (
    <div>
      <div className='container'>
        <SectionTitle data={data?.section_title} />
        {data?.pep_card &&
          <div className='flex flex-col lg:grid md:grid-cols-1 lg:grid-cols-4 gap-5 h-full items-center  lg:pt-0'>
            {
              data?.pep_card?.map((card) => (
                <div className='max-w-[32rem] w-full' key={card.licence}>
                  <PepCard card={card} />
                </div>
              ))
            }
          </div>
        }

      </div>
    </div>
  );
};

export default PepCards;
