import React from 'react'
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables'
import HTMLRender from '@src/components/core/HTMLRender'
import NextImage from '@src/components/elements/Image'
import Link from '@src/components/elements/Link'

function LatestArticle({ data }) {
    const { gv } = useGlobalVariable();

    // if analyst has no image, use name initials instead
    function getFirstLetters(str) {
        const firstLetters = str
            .split(' ')
            .map(word => word.charAt(0))
            .join('');

        return firstLetters;
    }

    return (
        <div className='flex flex-col lg:flex-row bg-[#F8F6F6] overflow-hidden rounded-lg w-full lg:max-w-[57.5%] mt-lg lg:mt-0'>
            <div className='w-full lg:max-w-[40%] h-[9.5rem] lg:h-auto overflow-hidden relative'>
                <NextImage src={data?.latest_articles?.data[0]?.image?.url} />
            </div>
            <div className='flex flex-col w-full lg:max-w-[60%] pt-lg lg:pt-lg pb-lg lg:pb-lg lg:px-lg justify-between px-md'>
                <div className='mb-lg'>
                    <h2 className='font-semibold'>{data?.latest_articles?.data[0]?.title}</h2>
                    <HTMLRender data={data?.latest_articles?.data[0]?.shortDescription} />
                </div>
                <div className='flex justify-between items-center'>
                    {
                        data?.latest_articles?.data[0]?.global_analyst &&
                        <div className='flex items-center'>
                            {data?.latest_articles?.data[0]?.global_analyst?.image_variant_a?.url ?
                                <div className="flex items-center">
                                    <div className="w-[30px] h-[30px] rounded-full relative overflow-hidden bg-[#DEDEDE]">
                                        <NextImage
                                            priority
                                            src={data?.latest_articles?.data[0]?.global_analyst?.image_variant_a?.url}
                                            alt={data?.latest_articles?.data[0]?.global_analyst?.name}
                                            width={data?.latest_articles?.data[0]?.global_analyst?.image_variant_a?.width}
                                            height={data?.latest_articles?.data[0]?.global_analyst?.image_variant_a?.height}
                                        />
                                    </div>
                                </div>
                                :
                                <div className="flex items-center">
                                    <div className="w-[30px] h-[30px] rounded-full relative overflow-hidden bg-[#DEDEDE] flex justify-center items-center">
                                        <p className='text-base font-[600]'>{getFirstLetters(data?.latest_articles?.data[0]?.global_analyst?.name)}</p>
                                    </div>
                                </div>
                            }
                            <div className='px-sm'>
                                <p>{data?.latest_articles?.data[0]?.global_analyst?.name}</p>
                            </div>
                        </div>
                    }
                    <Link href={data?.latest_articles?.data[0]?.slug}>
                        <a className='text-dark'>
                            {gv('read_more')}
                        </a>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default LatestArticle