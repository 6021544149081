import { useLocalStorage } from "@mantine/hooks";
import HTMLRender from "@src/components/core/HTMLRender";
import Link from "@components/elements/Link";
import styles from "./SharesTabs.module.css";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";
import { useEffect, useState } from "react";

const SharesTabs = (props) => {
  const data = props.data || props;

  const page_key = useStore((store) => store.page_key, shallow);

  const [shares_tabs_active_tab, setShares_tabs_active_tab] = useState(null);
  //FIX: to be replaced in feature with a "key" from cms (shares/stox) and remove the index.
  useEffect(() => {
    setShares_tabs_active_tab(page_key && page_key === "cfd-trading-shares" ? 0 : 1);
  }, [page_key]);

  if (!data) return null;

  return (
    <div className="container">
      <div className={`flex w-full ${styles.wrapper}`}>
        {data?.tabs.map((tab, index) => (
          <div
            key={index}
            className={`relative w-1/2 cursor-pointer p-md text-center hover:bg-white ${
              shares_tabs_active_tab !== index ? `bg-[#f6f6f6]` : "pointer-events-none bg-white"
            }`}
            onClick={() => {
              setShares_tabs_active_tab(index);
            }}
          >
            <Link href={tab?.link}>
              <a className={`absolute top-0 left-0 z-10 h-full w-full`} />
              <div className={shares_tabs_active_tab !== index ? styles.activeText : styles.notActiveText}>
                <HTMLRender data={tab?.text} />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SharesTabs;
