import { Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import "swiper/css/effect-creative";
import "swiper/css/free-mode";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";

import { Navigation, Pagination, Autoplay, EffectCoverflow, EffectFade, EffectCreative, FreeMode } from "swiper";

const GenericSlider = (props) => {
  const store = useStore(
    (store) => ({
      dir: store.direction,
    }),
    shallow
  );

  if (props.children) {
    return (
      <div>
        <Swiper
          effect={props.effect ? props.effect : {}}
          dir={store ? store.dir : "ltr"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          creativeEffect={{
            prev: {
              shadow: false,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          breakpoints={props?.breakpoints || {}}
          rewind={props.rewind ? true : false}
          fadeEffect={{ crossFade: true }}
          autoHeight={props.autoHeight && true}
          freeMode={props.freeMode ? { enabled: true, sticky: true } : false}
          freeModeMomentum={props?.freeModeMomentum}
          preventInteractionOnTransition={true}
          grabCursor={props.grabCursor ? true : false}
          modules={[Navigation, Pagination, Autoplay, EffectCoverflow, EffectFade, EffectCreative, FreeMode]}
          slidesPerView={props.slidesPerView ? props.slidesPerView : 1}
          spaceBetween={props.spaceBetween ? props.spaceBetween : 0}
          direction={props.vertical ? "vertical" : "horizontal"}
          loop={props.loop ? true : false}
          navigation={props.navigation ? true : false}
          pagination={
            props.pagination
              ? {
                clickable: true,
                dynamicBullets: props.dynamicPagination ? true : false,
              }
              : false
          }
          centeredSlides={props.centeredSlides ? true : false}
          speed={props.speed ? props.speed : 600}
          autoplay={
            props.autoplay
              ? {
                delay: props.autoplayDelay ? props.autoplayDelay : 3000,
                pauseOnMouseEnter: props.pauseOnMouseEnter ? true : false,
                disableOnInteraction: props.disableOnInteraction ? true : false,
              }
              : false
          }
          slidesPerGroup={props.slidesPerGroup ? props.slidesPerGroup : 1}
        >
          {props.children}
        </Swiper>
      </div>
    );
  }
  return null;
};

export default GenericSlider;
