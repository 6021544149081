import Button from '@components/elements/Button';
import ColoredBox from '@components/elements/ColoredBox';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './CmdtsFactors.module.css';

const CmdtsFactors = (props) => {
  const data = props.data || props

  if (!data) {
    return null;
  } return (
      <div className="container">
        <div className="pt-[5rem] lg:pt-0 flex flex-col lg:flex-row w-full flex-wrap">
          <div className={styles.title}>
            <SectionTitle data={data.section_title} />
          </div>
          {data.box.map((item, index) => (
            <div key={index} className="w-full lg:odd:w-[30%] lg:even:w-[70%] ltr:lg:even:pl-md rtl:lg:even:pr-md mb-md">
              <div className='h-full'>
                <ColoredBox data={item} />
              </div>
            </div>
          ))}
        </div>
        <div className='flex justify-center pt-lg'>
          <Button data={data.button} />
        </div>
      </div>
  );
};

export default CmdtsFactors;
