import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './ReferralBox.module.css';

const ReferralBox = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      <div className={styles.title}>
        <SectionTitle data={data?.section_title} />
      </div>
      <div className='flex flex-col md:flex-row justify-between items-center p-[2.2222rem] bg-[#f6f6f6] rounded-lg'>
        <div className={`${styles.content} max-w-full ltr:md:pr-xl rtl:md:pl-xl ltr:md:border-r-2 rtl:md:border-l-2 border-[#dcdcdc] mb-lg md:mb-0`}>
          <HTMLRender data={data?.content} />
        </div>
        <div className='md:min-w-[30%] ltr:md:pl-xl rtl:md:pr-xl'>
          <Button data={data?.button} />
        </div>
      </div>
    </div>
  );
};

export default ReferralBox;
