import InstrumentCard from '@components/custom/InstrumentCard';
import SectionLayout from '@components/elements/SectionLayout';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './CryptoPopular.module.css'

const CryptoPopular = (props) => {
  const data = props.data || props;

  if (!data) {
    return null;
  }

  return (
      <div className="container">
        <div className='flex flex-wrap'>
          <div className={`w-full md:max-w-[calc(33.33%)] ${styles.title}`}>
            <SectionTitle data={data?.section_title} />
          </div>
          {
            data?.instrument_card?.map((instrumentCard, index) => (
              <div key={index} className='w-full flex md:max-w-[calc(33.33%-1rem)] mb-md mx-auto'>
                <InstrumentCard data={instrumentCard} />
              </div>
            ))
          }
        </div>
      </div>
  );
};

export default CryptoPopular;
