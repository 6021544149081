import HTMLRender from '@components/core/HTMLRender';
import NextImage from '@components/elements/Image';
import styles from './CryptoPromo.module.css';
import * as Yup from 'yup';
import { Form, Formik, Field } from "formik";
import Loaders from '@components/elements/Loaders';
import Button from '@components/elements/Button';
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";

const CryptoPromo = (props) => {
  const data = props.data || props;
  if (!data) return null;
  const {gv} = useGlobalVariable()

  const formInitialValues = {
    email: "",
  };
  const ValidationBlock = ({ text }) => {
    return (
      <p className="p-1 text-[red] text-xs">{text}</p>
    )
  }
  const RegisterValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(<ValidationBlock text={gv("email_validation", 'raw')} />)
      .required(<ValidationBlock text={gv("required_validation", 'raw')} />),
  })

  const registerHandler = async (values, actions) => {
    actions.setSubmitting(true);
    const requestBody = {
      email: values.email,
    };

    const request = fetch('https://webhook.site/f4e9024f-54d3-4960-a658-f63e13fc2e1e', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', },
      body: JSON.stringify(requestBody),
    }).then((response) => response)
      .then((response) => {
        response.success ?
           ((response) => {
              if (response.success) {
                actions.setSubmitting(true)
              } 
              return null;
            })
          :
          actions.setSubmitting(false);
    })}


  return (
    <>
      <div className='container'>
        <div className="flex flex-col lg:flex-row items-center">
          <div className='flex-1 relative'>
            <div className='relative z-10'>
              <NextImage src={data?.image?.data?.attributes?.url} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
            </div>
            <div className='absolute top-0 left-1/2 -translate-x-1/2'>
              <NextImage src={data?.gif?.data?.attributes?.url} width={data?.gif?.data?.attributes?.width} height={data?.gif?.data?.attributes?.height} />
            </div>

          </div>
          <div className='flex-1 mt-[-2rem] lg:mt-0 text-center lg:ltr:text-left lg:rtl:text-right'>
            <div className={styles.rightText}>
              <HTMLRender data={data?.text} />
            </div>
            <div className={`pt-lg ${styles.formWrapper}`}>
              <Formik
                initialValues={formInitialValues}
                onSubmit={(values, actions) => { registerHandler(values, actions); }}
                validationSchema={RegisterValidationSchema}>
                {({ isSubmitting, errors, touched }) => (
                  <Form>
                    <div className="w-full flex  flex-col lg:flex-row ">
                      <div className='grow pb-lg lg:pb-0 lg:ltr:pr-md lg:rtl:pl-md'>
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          placeholder={data?.input_label}
                          className=""
                        />
                       
                      </div>
                      {errors.email && touched.email ? <div className='block lg:hidden'>{errors.email}</div> : null}
                      
                      
                     <div>
                     <Button arrow="right" submit type="crypto" size="lg" label={!isSubmitting ? data?.button_label : <Loaders color="primary"/>}  fullWidth={true}/>
                     </div>
                     
                    </div>
                    {errors.email && touched.email ? <div className='hidden lg:block'>{errors.email}</div> : null}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CryptoPromo;
