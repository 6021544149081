import { Input, Collapse } from "@mantine/core";
import styles from "./LegalDocumentsView.module.css";
import { useState } from "react";
import { BsThreeDots } from "@react-icons/all-files/bs/BsThreeDots";
import Link from "@components/elements/Link";
import { IoSearchOutline } from "@react-icons/all-files/io5/IoSearchOutline";
import { useRouter } from "next/router";
import get from "lodash/get";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import NextImage from "@components/elements/Image";
import map from "lodash/map";
import { getStrapiMedia } from "@utils/media";
import includes from "lodash/includes";
import SectionTitle from "@src/components/elements/SectionTitle";

export function LegalDocumentFile(props) {
  const { file = {}, name = "" } = props;
  return (
    <div className="flex justify-between items-start ltr:pl-lg rtl:pl-lg">
      <div className="flex items-center">
        <div className="ltr:pr-md rtl:pl-md">
          <NextImage
            src="https://cdn.capex.com/uploads/pdf_folder_58b90882a8/pdf_folder_58b90882a8.png?updated_at=2022-10-06T10:48:50.660Z"
            width={25}
            height={25}
          />
        </div>
        <Link href={getStrapiMedia(file?.file?.data?.attributes?.url)}>
          <p className="text-secondary">{name}</p>
        </Link>
      </div>
    </div>
  );
}

export function LegalDocumentItem({ simple, legalDocument }) {
  const { gv } = useGlobalVariable();
  const [opened, setOpen] = useState(false);
  const router = useRouter();
  const data = legalDocument;
  let { versions } = data;
  if (versions) versions = versions.reverse();

  return (
    <div className="relative">
      <div
        style={{ boxShadow: `${opened ? '0 0 15px -1px rgb(0 0 0 / 27%)' : ''}`, transition: 'box-shadow .3s ease-in-out' }}
        className={`bg-white ${simple ? 'border-0 p-0' : 'border py-md px-lg'} ${opened ? 'h-auto' : `${simple ? 'h-auto' : 'md:h-[9rem]'}`} border-[#cccccc] rounded-md w-full flex flex-col items-center ${simple && 'px-sm lg:px-lg py-xs'} transition-all duration-300 ease-in-out`}
      >
        <div className="flex justify-between items-center w-full">
          <div className="w-full max-w-[90%] flex justify-between items-center">
            <div className="w-full max-w-[20%]">
              <NextImage
                src="https://cdn.capex.com/uploads/pdf_folder_58b90882a8/pdf_folder_58b90882a8.png?updated_at=2022-10-06T10:48:50.660Z"
                width={25}
                height={25}
              />
            </div>
            <div className="w-full max-w-[80%]">
              <Link href={`${data?.slug || ""}`}>
                <p className="text-secondary">{data?.title}</p>
              </Link>
              {!simple && versions?.length > 0 && (
                <p className="text-base inline-flex text-[#737373]">
                  {versions?.length}&nbsp;
                  {gv("versions")}
                </p>
              )}
            </div>
          </div>
          {versions?.length > 0 && (
            <div className="w-full max-w-[5%]">
              <div className="cursor-pointer">
                <BsThreeDots color="#737373" onClick={() => setOpen((o) => !o)} />
              </div>
            </div>
          )}
        </div>
        {versions?.length > 0 && (
          <div className={`relative ltr:mr-auto rtl:ml-auto ${opened ? 'h-full pt-lg' : 'h-0 pt-0'}`}>
            <Collapse transitionDuration="0" in={opened}>
              {map(versions, (versionItem, index) => (
                <Link key={index} style={{ cursor: "pointer" }} href={getStrapiMedia(versionItem?.file?.url)}>
                  <p className="w-fit mb-0 pb-xs text-secondary hover:opacity-70 transition-all duration-300 ease-in-out" key={versionItem.id}>{versionItem.name}</p>
                </Link>
              ))}
            </Collapse>
          </div>
        )}
      </div>
    </div >
  );
}

function LegalDocumentsView(props) {
  const data = props.data || props;
  const { gv } = useGlobalVariable();
  const [search, setSearch] = useState("");
  const legalDocuments = get(props, "legal_documents.data", []);
  return (
    <>
      {/* title and search bar */}
      <div className="bg-[#f5f5f5] pt-3xl lg:pt-[10rem]  pb-xl lg:pb-[5rem]">
        <div className={`container ${styles.inputContainer}`}>
          <SectionTitle data={data?.section_title} />
          <Input
            className="w-full lg:max-w-[60%] mx-auto mt-md border rounded-md overflow-hidden"
            classNames={{
              input: styles.input,
              rightSection: styles.rightSection,
            }}
            size="md"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={gv('legal_documents_page_search_placeholder')}
            rightSection={
              <div className="text-[#737373]">
                <IoSearchOutline className="text-lg" />
              </div>
            }
          />
        </div>
      </div>
      {/* legal docs body */}
      {legalDocuments.length > 0 && (
        <div className="container pt-xl lg:pt-[5rem]">
          <div className="w-full h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {map(legalDocuments, (legalDocument, index) => {
              const sLegal = JSON.stringify(legalDocument);
              if (!includes(sLegal, search)) return null;
              return (
                <div key={index} className="w-full">
                  <LegalDocumentItem legalDocument={legalDocument} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default LegalDocumentsView;
