import PressRoomCard from "@components/custom/PressRoomCard";
import useSwrHook from "@lib/services/hooks/swr/useSwrHook";
import { Skeleton } from "@mantine/core";
import PaginationWithoutUrlChange from "@src/components/elements/PaginationWithoutUrlChange";
import { useState } from "react";

function getRandomWidth(min, max) {
  return Math.random() * (max - min) + min;
} // Generate random widths for the skeleton.

function PressRoomPage({ doNotRender = false }) {
  const [page, setPage] = useState(1);

  const { data: pressRoomCards, isValidating } = useSwrHook("pressRoomCards", {
    query: {
      populate: {
        press_publisher: {
          fields: "*",
          populate: {
            logo: {
              fields: ["url", "width", "height"],
            },
          },
        },
        pdf_file: {
          fields: ["url"]
        }
      },
      fields: ["title", "description", "link_url", "publishedAt", 'press_type', 'publication_date'],
      sort: ["publication_date:desc"],
      pagination: {
        page,
        pageSize: 10,
      },
    },
  });

  if (!pressRoomCards || doNotRender) {
    return null;
  }

  return (
    <>
      <ul className="flex flex-col flex-wrap  md:flex-row">
        {pressRoomCards?.data?.map((pressRoomCard, index) => (
          <li
            className="mb-[3.33rem] w-full border-b border-b-[#e6e6e6] pb-[2.777rem] last:mb-0 md:last:mb-[3.33rem]"
            key={index}
          >
            {isValidating ? (
              <>
                <Skeleton height={190} mb="xl" />
                <Skeleton height={8} mt={6} width={`${getRandomWidth(20, 100)}%`} radius="xl" />
                <Skeleton height={8} mt={6} radius="xl" width={`${getRandomWidth(20, 100)}%`} mb="xl" />
                <Skeleton height={8} mt={6} width={`${getRandomWidth(20, 80)}%`} radius="xl" />
              </>
            ) : (
              <PressRoomCard data={pressRoomCard?.attributes} />
            )}
          </li>
        ))}
      </ul>
      <div className="flex justify-center">
        <PaginationWithoutUrlChange
          type="primary"
          alignament="center"
          totalPages={pressRoomCards?.meta?.pagination?.pageCount}
          neighborsSize="1"
          currentPage={page}
          setCurrentPage={setPage}
        />
      </div>
    </>
  );
}

const PressShowCase = (props) => {
  const data = props.data || props;

  if (!data) {
    return null;
  }
  return (
    <div className="container">
      <PressRoomPage />
      <PressRoomPage doNotRender />
    </div>
  );
};

export default PressShowCase;
