import styles from './WebtraderHero.module.css';
import NextImage from '@components/elements/Image';
import List from '@components/elements/List';
import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import SectionTitle from '@components/elements/SectionTitle';

const WebtraderHero = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className="text-dark">
      {data?.hero_image?.data &&
        <div className={data?.full_width_image ? `-mb-lg md:-mb-2xl` : `container`}>
          <div className={`w-full  mx-auto ${data?.full_width_image ? `` : `xl:w-[80%] 2xl:w-full`}`}>
            <div className="relative w-full justify-center flex">
              <NextImage objectFit src={data?.hero_image} width={data?.hero_image?.data?.attributes?.width} height={data?.hero_image?.data?.attributes?.height} />
            </div>
          </div>
        </div>
      }
      <div className='container relative'>
        {data?.section_title && <div className={`${styles.title}`}><SectionTitle data={data?.section_title} /></div>}
        <div className={`${styles[`hero-list`]} py-xl md:py-2xl`}>
          <List type="checked" border list_items={data?.list} />
        </div>
        {data?.red_box_text &&
          <div className="bg-primary rounded-md px-md py-lg md:py-lg md:px-xl flex justify-between items-center text-center rtl:md:text-right ltr:md:text-left flex-col md:flex-row">
            <div className={`pb-[2rem] md:pb-0 lg:max-w-[70%] ${styles.red_box_text}`}>
              <HTMLRender data={data?.red_box_text} />
            </div>
            <div className="grow-1 whitespace-nowrap">
              <Button type={data?.red_box_button?.type} size={data?.red_box_button?.size} arrow={data?.red_box_button?.arrow} label={data?.red_box_button?.label} link={data?.red_box_button?.link} />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default WebtraderHero;
