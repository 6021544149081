import HTMLRender from '@components/core/HTMLRender';
import GenericSlider from '@components/custom/GenericSlider';
import NextImage from '@components/elements/Image';
import useSwrHook from '@lib/services/hooks/swr/useSwrHook';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import { Skeleton } from '@mantine/core';
import SectionTitle from '@src/components/elements/SectionTitle';
import { Fragment } from 'react';
import { SwiperSlide } from 'swiper/react';
import styles from './AwardsShowcase.module.css';

function getRandomWidth(min, max) {
  return Math.random() * (max - min) + min;
} // Generate random widths for the skeleton.

const AwardsShowcase = (props) => {
  const { gv } = useGlobalVariable()
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      {data?.section_title && <SectionTitle data={data?.section_title} />}
      {/* desktop version */}
      <div className='w-full hidden lg:flex flex-col md:flex-row flex-wrap justify-between my-0'>
        {
          // first map for awards without extra data
          data?.awards?.data?.map((award, index) => (
            !data?.awards ?
              <div className='w-[33.33%] pb-[3rem] flex flex-col justify-center items-center'>
                <Skeleton height={190} circle mb="xl" />
                <Skeleton height={8} mt={6} width={`${getRandomWidth(20, 30)}%`} radius="xl" />
                <Skeleton height={8} mt={6} width={`${getRandomWidth(50, 80)}%`} radius="xl" />
                <Skeleton height={8} mt={6} width={`${getRandomWidth(30, 50)}%`} radius="xl" />
              </div>
              :
              <Fragment key={index}>
                {/* data without extras */}
                {
                  !award?.extras?.length > 0 &&
                  <div className={`w-[33.33%] flex flex-col pb-[3rem] ${styles[`not-featured`]}`}>
                    <div className='text-center'>
                      <NextImage src={award?.image} width={award?.image?.width || award?.image?.data?.attributes?.width} height={award?.image?.height || award?.image?.data?.attributes?.height} />
                    </div>
                    <div className='text-center'>
                      <p className='text-secondary text-xl pt-md pb-sm'>{award?.year}</p>
                      <h3 className={`mb-xs text-center flex text-2xl  ${styles.title}`}>
                        <span className='block py-0 px-sm'>{award.title}</span>
                      </h3>
                      <div className={styles.content}>
                        <HTMLRender data={award?.event} />
                      </div>
                    </div>
                  </div>
                }
              </Fragment>
          ))
        }
        {
          // second map for awards with extra data
          data?.awards?.data?.map((award, index) => (
            !data?.awards ?
              <div className='w-[33.33%] pb-[3rem] flex flex-col justify-center items-center'>
                <Skeleton height={190} circle mb="xl" />
                <Skeleton height={8} mt={6} width={`${getRandomWidth(20, 30)}%`} radius="xl" />
                <Skeleton height={8} mt={6} width={`${getRandomWidth(50, 80)}%`} radius="xl" />
                <Skeleton height={8} mt={6} width={`${getRandomWidth(30, 50)}%`} radius="xl" />
              </div>
              :
              <Fragment key={index}>
                {/* data with extra highlights */}
                {
                  award?.extras?.length > 0 &&
                  <div className="w-full max-w-[50%] flex mb-[3rem] flex-row items-center justifty-center first:justify-start last:justify-end ltr:last:border-l rtl:last:border-r border-[#e3e3e3]" >
                    <div className='w-full max-w-[40%]'>
                      <NextImage src={award?.image} width={award?.image?.width || award?.image?.data?.attributes?.width} height={award?.image?.height || award?.image?.data?.attributes?.height} />
                    </div>
                    <div className='flex flex-col w-full max-w-[50%]'>
                      <div className='mb-md'>
                        <p className='text-secondary text-xl pt-md mb-0'>{award?.year}</p>
                        <h3 className="mb-sm text-2xl">
                          <span className='block py-0'>{award.title}</span>
                        </h3>
                        <div className={styles.content}>
                          <HTMLRender data={award?.event} />
                        </div>
                      </div>
                      {
                        award?.extras?.map((extra) => (
                          <div key={index} className="flex justify-between items-center py-md border-t border-t-[#e3e3e3]">
                            <div className='w-full max-w-[15%]'>
                              <NextImage src={extra?.icon} width={extra?.icon?.width || extra?.icon?.data?.attributes?.width} height={extra?.icon?.height || extra?.icon?.data?.attributes?.height} />
                            </div>
                            <div className='w-full max-w-[80%] flex flex-col'>
                              <p className='mb-0 text-dark'>{extra?.title}</p>
                              <p className='text-secondary mb-0'>{gv('runner_up', 'raw')}</p>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                }
              </Fragment>
          ))
        }
      </div>
      {/* mobile version - slider */}
      <div className="block lg:hidden">
        <GenericSlider autoHeight navigation pagination loop slidesPerView="1">
          {
            data?.awards?.data?.map((award, index) => (
              <SwiperSlide key={index}>
                <div className='flex flex-col items-center text-center justify-center min-h-[20rem]'>
                  <div className='flex flex-col w-full pb-lg'>
                    <p className='text-secondary text-xl pt-md mb-0'>{award?.year}</p>
                    <h3 className="mb-0 text-xl">
                      <span className='block py-0'>{award.title}</span>
                    </h3>
                    <div className={styles.content}>
                      <HTMLRender data={award?.event} />
                    </div>
                  </div>
                  <div>
                    <NextImage src={award?.image} width={award?.image?.width || award?.image?.data?.attributes?.width} height={award?.image?.height || award?.image?.data?.attributes?.height} />
                  </div>
                </div>
                {
                  award?.extras.length > 0 &&
                  <ul className={`${award?.extras.length >= 1 && `border-t border-[#e3e3e3]`} flex items-start justify-between md:justify-evenly pt-md mt-lg`}>
                    {
                      award?.extras?.map((extra) => (
                        <li key={index} className='text-center max-w-[calc(50%-0.5rem)] flex-wrap py-sm mb-0 flex flex-col justify-center items-center'>
                          <div className='pb-md'>
                            <NextImage src={extra?.icon} width={90} height={80} />
                          </div>
                          <div>
                            <p className='mb-0 text-dark text-base'>{extra?.title}</p>
                            <p className='text-secondary mb-0'>{gv('runner_up', 'raw')}</p>
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                }
              </SwiperSlide>
            ))
          }
        </GenericSlider>
      </div>
    </div>
  );
};

export default AwardsShowcase;
