import HTMLRender from "@components/core/HTMLRender";
import NextImage from "@components/elements/Image";
import Link from "@components/elements/Link";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { useRegistrationLink } from "@lib/store/hooks/useActiveProductType";
import styles from "./AccountTypeHero.module.css";

const AccountTypeHero = (props) => {
  const { gv } = useGlobalVariable();
  const data = props.data || props;
  const registrationLink = useRegistrationLink();
  if (!data) return null;

  return (
    <div className="container md:pb-[4rem] lg:pb-0">
      <div className="flex w-full flex-row flex-wrap justify-between">
        {data?.registration_steps?.map((item, index) => (
          <div
            key={index}
            className={`flex w-full max-w-[calc(100%/2-0.5rem)] flex-col items-center justify-start md:max-w-[calc(100%/4-2rem)] md:justify-end ${styles.colWrapper} mb-[1rem] rounded-lg bg-[#ececec] p-sm md:bg-transparent md:p-0`}
          >
            <NextImage
              src={item?.icon}
              width={item?.icon?.data?.attributes?.width}
              height={item?.icon?.data?.attributes?.height}
            />
            <h4 className="mb-sm pt-sm text-center text-base uppercase text-dark md:mb-lg md:text-white lg:text-h5">
              <span className="uppercase text-grey">
                {gv("step")} {index + 1}:
              </span>{" "}
              {item?.title}
            </h4>
            <div className={styles.content}>
              <HTMLRender data={item?.content} />
            </div>
            <div className={styles.link}>
              <Link color="blue" text={item?.link_text} arrow="right" href={registrationLink} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountTypeHero;
