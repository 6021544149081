import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './InvestEtFsCards.module.css';

const InvestEtFsCards = (props) => {
  const data = props.data || props;
  if(!data) return null;
  
  return (
    <>
      <div className='container'>
        <SectionTitle data={data?.section_title} />
        <div className="flex flex-row justify-between flex-wrap w-full">
          {data?.cards?.map((card, index) => (
            <div key={index} className={`${styles.card} w-full max-w-[calc(100%/2-0.5rem)] md:max-w-[calc(100%/3-1rem)] flex flex-col`}>
              <div className={styles.title}>
                <HTMLRender data={card?.title} />  
              </div>
              <div className={styles.content}>
                <HTMLRender data={card?.content} />  
              </div>
            </div>
          ))}
        </div>
        <div className='flex justify-center pt-lg'>
          <Button data={data?.button} />
        </div>
      </div>
    </>
  );
};

export default InvestEtFsCards;
