import styles from './faq.module.css'
import Arrow from '@components/elements/Arrow';
import { Accordion } from '@mantine/core';
import { FAQPageJsonLd } from 'next-seo';
import HTMLRender from '@src/components/core/HTMLRender';

import htmlToCleanText from '@utils/html-to-text';
import NextImage from '@src/components/elements/Image';

export default function LayoutFaq({ pageData }) {
  return (
    <>
      <FAQPageJsonLd
        mainEntity={
          pageData.accordion_item.map(item => (
            {
              questionName: htmlToCleanText(item.title),
              acceptedAnswerText: htmlToCleanText(item.content)
            }
          ))
        }
      />
      <div className="xl:mt-0 pt-[7rem] pb-[5rem] md:py-[8rem] lg:py-[10rem] -mb-[1rem]   relative">
        <div className="absolute h-full w-full left-0 top-0 ">
          <NextImage
            src="https://cdn.capex.com/uploads/hero_background_8160ee082e/hero_background_8160ee082e.png"
            layout="fill"
            quality="100"
          />
        </div>
        <div className="container relative">
          <h1 className="text-center h3 m-0">
            {pageData?.title}
          </h1>
        </div>
      </div>
      <div className="container relative">
        <div className="py-[5rem] mb-xl">


          <Accordion
            iconPosition="right"
            transitionDuration={600}
            icon={<div className="text-secondary"><Arrow direction="down" /></div>}>
            {pageData?.accordion_item?.map((faq, index) => (
              <Accordion.Item
                key={index}
                label={
                  <div className={`${styles.title}`}>
                    <HTMLRender data={faq?.title} />
                  </div>
                }
                classNames={{
                  control: styles.accordionControl,
                  accordionLabel: styles.accordionLabel,
                  itemOpened: styles.itemOpened,
                  label: styles.accordionLabel,
                  item: styles.accordionItem,
                  itemTitle: styles.accordionItemTitle,
                  contentInner: styles.contentInner,
                  icon: styles.icon,
                }}
              >
                <div className={`w-full flex justify-between ${styles.content}`}>
                  <HTMLRender data={faq.content} />
                </div>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>

    </>
  )
}