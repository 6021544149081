import styles from "./NewsCard.module.css";
import NextImage from "@components/elements/Image";
import Link from "@components/elements/Link";
import { IoCalendarSharp } from "@react-icons/all-files/io5/IoCalendarSharp";
import dayjs from "dayjs";
import HTMLRender from "@components/core/HTMLRender";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";

const NewsCard = (props) => {
  const data = props.data || props;
  const order = props?.order || false;
  const formatDate = dayjs(data?.publishedAt).format("DD MMMM YYYY");
  const formatDateUpdatedAt = dayjs(data?.updatedAt).format("DD MMMM YYYY");
  const articleUniqueKey = data && data?.articles_category?.data?.attributes?.unique_key
  const { gv } = useGlobalVariable();

  if (!data) {
    return null;
  }

  return (
    <div
      className={`${styles[`article-card`]} flex h-full cursor-pointer flex-col justify-between ${styles?.primary} relative`}
    >
      <div className={styles.linkWrapper}>
        <Link href={data?.slug} />
      </div>
      <div className={`${styles["article-card-image"]} h-full`}>
        <div
          className={`${styles["article-card-image"]} relative h-[10em] overflow-hidden rounded-md md:h-[8em] lg:h-[10em]`}
        >
          {!data?.image?.data ? null : (
            <NextImage
              priority={order && order < 4}
              src={data?.image?.data?.attributes?.formats?.small?.url || data?.image}
              width={data?.image?.data?.attributes?.formats?.small?.width || data?.image?.data?.attributes?.width}
              height={data?.image?.data?.attributes?.formats?.small?.height || data?.image?.data?.attributes?.height}
            />
          )}
          {!data?.desktop_image?.data ? null : (
            <NextImage
              priority={order && order < 4}
              src={data?.desktop_image}
              width={data?.desktop_image?.data?.attributes?.width}
              height={data?.desktop_image?.data?.attributes?.height}
            />
          )}
          {articleUniqueKey === 'market_news_articles' ? (
            <div className="article-card-date absolute right-0 bottom-4 flex items-center bg-primary px-md py-[0.25rem] text-white">
              <IoCalendarSharp className="ltr:mr-md rtl:ml-md" />
              <span>{formatDate}</span>
            </div>
          ) :
            <div className="article-card-date absolute right-0 bottom-4 flex items-center bg-primary px-md py-[0.25rem] text-white">
              <IoCalendarSharp className="ltr:mr-md rtl:ml-md" />
              <span>{formatDateUpdatedAt}</span>
            </div>
          }
        </div>
        {data?.global_analyst?.data?.length >= 1 && (
          <p className="mt-sm mb-0">
            <span className="text-secondary">
              {gv("by_author", {
                template: {
                  author_name: data?.global_analyst?.data[0]?.attributes?.name,
                },
              })}
            </span>
          </p>
        )}
        <div className="m-0 my-lg w-full last:my-lg">
          <div className="flex h-full w-full justify-between">
            <h5 className="h-full">{data.title}</h5>
            {data?.lessons && (
              <p className="whitespace-nowrap">
                {data?.lessons?.data?.length} {gv("lessons")}
              </p>
            )}
          </div>
          <div className={`mt-md ${styles.descriptionText} flex  h-full items-start justify-start`}>
            {data?.shortDescription && (
              <div>
                <HTMLRender data={data?.shortDescription} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <Link arrow="right" text={<HTMLRender data={gv("read_more")} />} link={data?.slug} />
      </div>
    </div>
  );
};

export default NewsCard;
