import HTMLRender from '@components/core/HTMLRender';
import Arrow from '@components/elements/Arrow';
import NextImage from '@components/elements/Image';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import styles from './LAndRLicence.module.css';

const LAndRLicence = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable()

  if (!data) return null;

  return (
    <div className='container'>
      <div className='flex flex-col md:flex-row justify-between'>
        <div className={`order-1 md:order-0 w-full md:max-w-[calc(50%-2rem)] ${styles.image}`}>
          <NextImage src={data?.image} width={data?.image?.data?.attributes?.width} height={data?.image?.data?.attributes?.height} />
        </div>
        <div className='mb-lg md:mb-0  order-0 md:order-1 w-full md:max-w-[calc(50%-2rem)] rounded bg-[#f6f6f6] py-lg px-md  flex flex-col'>
          <div className='pb-sm border-b border-b-[#cecece]'>
            {data?.licence_type ? <p>{gv(data?.licence_type)}</p> : <p>{gv('licenced_by')}</p>}
          </div>
          <div>
            <div className='flex items-center pt-xl pb-md'>
              <NextImage src={data?.flag} width={data?.flag?.data?.attributes?.width} height={data?.flag?.data?.attributes?.height} />
              <p className='text-dark text-lg px-md'>{data?.name}</p>
            </div>
            <div className="flex items-center">
              <HTMLRender data={data?.licence_website} />
              <div className='px-sm text-secondary '>
                <Arrow direction="right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LAndRLicence;
