import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './AffiliatesModel.module.css';

const AffiliatesModel = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      {data?.section_title && <SectionTitle data={data?.section_title} />}
      <div className={`${styles?.content}`}>
        <HTMLRender data={data?.content} />
      </div>
      <div className="mt-lg md:mt-xl flex justify-center w-full">
        <Button data={data?.button} />
      </div>
    </div>
  );
};

export default AffiliatesModel;
