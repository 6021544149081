import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import SectionTitle from '@components/elements/SectionTitle';
import { Accordion, Tabs } from '@mantine/core';
import { useState } from 'react';
import styles from './PartnerTabs.module.css';

const PartnerTabs = (props) => {
  const data = props.data || props;
  const [activeTab, setActiveTab] = useState(0);
  if (!data) return null;

  return (
    <div className='container'>
      {data?.section_title && <SectionTitle data={data?.section_title} />}
      <div className="hidden md:block">
        <Tabs
          active={activeTab} onTabChange={setActiveTab}
          position='left' variant="unstyled"
          classNames={{
            root: styles.tabsRoot,
            tabsList: styles.tabsList,
            tabsListWrapper: styles.wrapper,
            tabControl: styles.tabControl,
            tabActive: styles.tabActive,
            tabLabel: styles.tabLabel,
            tabInner: styles.tabInner,
            body: styles.tabsBody,
          }}>
          {
            data?.content_list?.map((list, index) => (
              <Tabs.Tab key={index} label={<HTMLRender data={list?.title} />}>
                <div className={`${styles?.content}`}>
                  <HTMLRender data={list?.content} />
                </div>
                <div className={`relative mt-xl ${styles.mobileButton}`}>
                  <Button data={data?.button} />
                </div>
              </Tabs.Tab>
            ))
          }
        </Tabs>
      </div>
      <div className='block md:hidden'>
        <Accordion initialItem={0} variant="unstyled" iconSize={0} transitionDuration={500}
          classNames={{
            control: styles.accordionControl,
            itemOpened: styles.itemOpened,
            label: styles.accordionLabel,
            itemTitle: styles.accordionItemTitle,
          }}>
          {data?.content_list?.map((list, index) => (
            <Accordion.Item key={index} label={<HTMLRender data={list?.title} />}>
              <div className={`${styles?.content}`}>
                <HTMLRender data={list?.content} />
              </div>
              <div className={`relative mt-md ${styles.mobileButton}`}>
                <Button data={data?.button} />
              </div>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default PartnerTabs;
