import { Grid, Text, Title } from "@mantine/core";
import SectionTitle from "@src/components/elements/SectionTitle";
import get from "lodash/get";
import map from "lodash/map";
import { LegalDocumentItem } from "../legal-documents-view";
import "./LegalKeyInformationDocuments.module.css";

function LegalKeyInformationDocuments(props) {
  const data = props.data || props;
  const legalDocuments = get(props, "legal_documents.data", []);
  return (
    <div className="container">
      {data?.section_title && <SectionTitle data={data?.section_title} />}
      <div className="border border-[#cccc] px-sm lg:px-lg py-sm lg:py-lg mt-lg lg:mt-xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 gap-x-[0.25rem]">
        {map(legalDocuments, (legalDocument, index) => (
          <div key={index}>
            <LegalDocumentItem simple legalDocument={legalDocument} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default LegalKeyInformationDocuments;
