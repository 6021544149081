import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import SectionTitle from '@components/elements/SectionTitle';
import { Slider } from '@mantine/core';
import { useEffect, useState } from 'react';
import styles from './ReferralDeposit.module.css';
import { useLicenceId } from "@lib/store/hooks/useLocale";

const ReferralDeposit = (props) => {
  const data = props.data || props;
  const [depositValue, setDepositValue] = useState(data && data?.default_value || 5000);
  const [introductionValueFee, setIntroductionValueFee] = useState(500)
  const minDepositValue = data?.min_value
  const maxDepositValue = data?.max_value
  const companyId = useLicenceId();

  useEffect(() => {
    if (companyId === '65') {
      switch (true) {
        case depositValue >= 500 && depositValue < 1000:
          setIntroductionValueFee(300);
          break;
        case depositValue >= 1000 && depositValue <= 5000:
          setIntroductionValueFee(450);
          break;
        case depositValue > 5000 && depositValue < 50000:
          setIntroductionValueFee(900);
          break;
        case depositValue >= 50000:
          setIntroductionValueFee(2100);
          break;
        default:
          setIntroductionValueFee(300);
      }
    }
    if (companyId === '60'){
      switch (true) {
        case depositValue > 1999 && depositValue < 5000:
          setIntroductionValueFee(75);
          break;
        case depositValue > 4999 && depositValue < 10000:
          setIntroductionValueFee(100);
          break;
        case depositValue > 9999 && depositValue < 15000:
          setIntroductionValueFee(150);
          break;
        case depositValue > 14999 && depositValue < 25000:
          setIntroductionValueFee(500);
          break;
        case depositValue > 24999 && depositValue < 50000:
          setIntroductionValueFee(800);
          break;
        case depositValue > 49999 && depositValue < 75000:
          setIntroductionValueFee(1000);
          break;
        case depositValue > 74999 && depositValue < 100000:
          setIntroductionValueFee(1500);
          break;
        case depositValue > 99999 && depositValue < 150000:
          setIntroductionValueFee(2000);
          break;
        case depositValue > 149999 && depositValue < 200000:
          setIntroductionValueFee(3000);
          break;
        case depositValue > 199999 && depositValue < 300000:
          setIntroductionValueFee(4000);
          break;
        case depositValue === 300000:
          setIntroductionValueFee(5000);
          break;
        default:
          setIntroductionValueFee(50);
      }
    }
  }, [depositValue]);

  if (!data) return null;

  return (
    <div className='container p-md md:py-[2.777rem] md:px-[5rem] bg-[#f6f6f6] md:rounded-lg'>
      <SectionTitle data={data?.section_title} />
      <div className='flex flex-col'>
        <div className="flex flex-col">
          <div className='flex justify-between'>
            <p className='text-xs md:text-sm'>{data?.first_deposit_text}{' '}<span className="text-secondary">{`$ ${new Intl.NumberFormat('de-DE').format(depositValue)}`}</span></p>
            <p className='text-xs md:text-sm'>{data?.fee_text}{' '}<span className="text-secondary">{`$ ${new Intl.NumberFormat('de-DE').format(introductionValueFee)}`}</span></p>
          </div>
          <div>
            <Slider
              label={null}
              value={depositValue}
              onChange={setDepositValue}
              defaultValue={25}
              min={minDepositValue}
              max={maxDepositValue}
              step={1}
              classNames={{
                bar: styles.sliderBar,
                thumb: styles.sliderThumb,
                mark: styles.sliderMark,
                markWrapper: styles.sliderMarkWrapper,
                track: styles.sliderTrack
              }}
            />
          </div>
          <div className='flex justify-between mt-md'>
            <p className='text-xs md:text-sm'>{`$ ${new Intl.NumberFormat('de-DE').format(minDepositValue)}`}</p>
            <p className='text-xs md:text-sm'>{`$ ${new Intl.NumberFormat('de-DE').format(maxDepositValue)}`}</p>
          </div>
        </div>
        <div className='bg-[#ebebeb] px-[2rem] py-md rounded-lg flex flex-col md:flex-row justify-between items-center mt-lg md:mt-0'>
          <p className='md:max-w-[65%] font-bold text-dark text-base md:text-lg text-center ltr:md:text-left rtl:md:text-right md:mb-0'>
            {data?.total_deposit_text}
          </p>
          <div className='w-auto md:max-w-[30%]'>
            <Button data={data?.button} />
          </div>
        </div>
        <div className='text-sm md:text-base mt-xl mb-lg'>
          <HTMLRender data={data?.example_content} />
        </div>
      </div>
    </div>
  );
};

export default ReferralDeposit;
