import NextImage from '@components/elements/Image';
import SectionTitle from '@components/elements/SectionTitle';
import useSwrHook from '@lib/services/hooks/swr/useSwrHook';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import HTMLRender from '@src/components/core/HTMLRender';
import { CorporateContactJsonLd } from 'next-seo';
import htmlToCleanText from "@utils/html-to-text";
import { useStore } from '@lib/store';
import { shallow } from 'zustand/shallow';
import { useLocaleObject } from '@lib/store/hooks/useLocale';


const CuLocations = (props) => {
  const data = props.data || props;
  const localeObject = useLocaleObject();
  const { gv } = useGlobalVariable();

  if (!data) return null;

  const { data: officeLocations, loading, error } = useSwrHook('officeLocations', {
    query: {
      populate: {
        image: {
          fields: ['url', 'width', 'height']
        },
        icon: {
          fields: ['url', 'width', 'height']
        }
      },
      fields: ['name', 'support_nr', 'dealing_nr', 'fax', 'email', 'location', 'location_2', 'map_url', 'map_url_2', 'content', 'head_office_text', 'support_nr_gr', 'dealing_nr_gr', 'hr_number', 'hr_email'],
      sort: ['publishedAt:asc'],
    }
  });

  if (!officeLocations) return null;

  return (
    <>
      {/* structured data component */}
      <CorporateContactJsonLd
        url="https://capex.com"
        logo="https://capex.com/assets/logo/capex-com-logo-red.svg"
        contactPoint={
          officeLocations?.data?.map((office) => ({
            name: office?.attributes?.name,
            ...(
              office?.attributes?.dealing_nr ? {
                telephone: [
                  office?.attributes?.support_nr,
                  office?.attributes?.dealing_nr,
                ]
              } : {
                telephone: office?.attributes?.support_nr,
              }
            ),
            ...(
              office?.attributes?.dealing_nr ? {
                contactType: [
                  htmlToCleanText(gv('customer_support')),
                  htmlToCleanText(gv('dealing_desk'))
                ]
              } : {
                contactType: htmlToCleanText(gv('customer_support')),
              }
            ),
            email: office?.attributes?.email,
            areaServed: {
              "@type": "Country",
              "name": localeObject?.region === "global" ? "Global" : "Europe"
            }
          }))
        }
      />

      <div className='container'>
        {data?.section_title && <SectionTitle data={data?.section_title} />}
        <div className={`flex flex-col items-start lg:flex-row w-full flex-wrap ${officeLocations?.data?.length === 1 ? 'justify-center' : officeLocations?.data?.length === 2 ? 'justify-around' : 'justify-between'}`}>
          {officeLocations?.data?.map((location, index) => (
            <div key={index} className="w-full text-center lg:max-w-[calc(33.333%-2rem)] flex flex-col justify-end mb-xl lg:mb-2xl">
              <div className='mt-lg lg:h-[8rem] flex justify-center items-end'>
                <NextImage src={location?.attributes?.image} width={location?.attributes?.image?.data?.attributes?.width} height={location?.attributes?.image?.data?.attributes?.height} />
              </div>
              {/* location name */}
              <div className='mt-md'>
                <p>{location?.attributes?.name}</p>
              </div>
              {/* head office name for ADGM */}
              {location?.attributes?.head_office_text &&
                <div className='mt-md'>
                  <p>{location?.attributes?.head_office_text}</p>
                </div>
              }
              <div className='mt-md'>
                <p className='flex justify-center ltr:text-left rtl:text-right'>
                  <div>{gv('customer_support')}:{' '}&nbsp;</div>
                  <div>
                    {/* customer support number */}
                    <div>
                      {location?.attributes?.support_nr &&
                        <a dir="ltr" href={`tel:${location?.attributes?.support_nr}`}>{location?.attributes?.support_nr}</a>
                      }
                    </div>
                    {/* greece customer support number */}

                    {location?.attributes?.support_nr_gr &&
                      <div>
                        <div className='flex items-center'>
                          <a dir="ltr" href={`tel:${location?.attributes?.support_nr_gr}`}>{location?.attributes?.support_nr_gr}</a>&nbsp;
                          <NextImage
                            src="https://cdn.capex.com/uploads/gr_3e7ba27871/gr_3e7ba27871.svg"
                            alt="Greece flag"
                            title="Greece flag"
                            priority
                            width={24}
                            height={14}
                          />
                          {location?.attributes?.content &&
                            <span>&nbsp; *</span>
                          }
                        </div>
                      </div>
                    }
                  </div>
                </p>
              </div>
              <div className='mt-md'>
                <p className='flex justify-center ltr:text-left rtl:text-right'>
                  {/* dealing desk number */}
                  <div>{gv('dealing_desk')}:{' '}&nbsp;</div>
                  <div>
                    <div>
                      {location?.attributes?.dealing_nr &&
                        <a dir="ltr" href={`tel:${location?.attributes?.dealing_nr}`}>{location?.attributes?.dealing_nr}</a>
                      }
                    </div>
                    {location?.attributes?.dealing_nr_gr &&
                      <div>
                        <div className='flex items-center'>
                          <a dir="ltr" href={`tel:${location?.attributes?.dealing_nr_gr}`}>{location?.attributes?.dealing_nr_gr}</a>&nbsp;
                          <NextImage
                            src="https://cdn.capex.com/uploads/gr_3e7ba27871/gr_3e7ba27871.svg"
                            alt="Greece flag"
                            title="Greece flag"
                            priority
                            width={24}
                            height={14}
                          />
                          {location?.attributes?.content &&
                            <span>&nbsp; *</span>
                          }
                        </div>
                      </div>
                    }
                  </div>
                </p>
              </div>
              {/* customer support email address */}
              <div className='mt-md'>
                <p>{gv('email')}:{' '}<a href={`mailto:${location?.attributes?.email}`}>{location?.attributes?.email}</a></p>
              </div>
              {/* fax number */}
              {location?.attributes?.fax &&
                <div className='mt-md'>
                  <p>{gv('fax')}:{' '}<a dir="ltr" href={`tel:${location?.attributes?.fax}`}>{location?.attributes?.fax}</a></p>
                </div>
              }
              {/* HR contact number */}
              {location?.attributes?.hr_number &&
                <div className='mt-md'>
                  <p>{gv('hr_number')}:{' '}<a dir="ltr" href={`tel:${location?.attributes?.hr_number}`}>{location?.attributes?.hr_number}</a></p>
                </div>
              }
              {/* HR contact email address */}
              {location?.attributes?.hr_email &&
                <div className='mt-md'>
                  <p>{gv('hr_email')}:{' '}<a href={`mailto:${location?.attributes?.hr_email}`}>{location?.attributes?.hr_email}</a></p>
                </div>
              }
              {/* office location gmap location */}
              <div className='relative mt-lg max-w-[16rem] mx-auto flex justify-between items-center cursor-pointer p-sm rounded bg-[#f6f6f6]'>
                {/* eslint-disable-next-line */}
                <a href={location?.attributes?.map_url} target="_blank" className='absolute h-full w-full top-0 left-0' rel="noreferrer" />
                <p className='text-secondary text-left w-full max-w-[74%] rtl:text-right mb-0'>{location?.attributes?.location}</p>
                <div className="w-full max-w-[25%]">
                  <NextImage src={location?.attributes?.icon} width={location?.attributes?.icon?.data?.attributes?.width} height={location?.attributes?.icon?.data?.attributes?.height} />
                </div>
              </div>
              {/* office location gmap location 2 if exists */}
              {location?.attributes?.map_url_2 && <div className='relative mt-lg max-w-[16rem] mx-auto flex justify-between items-center cursor-pointer p-sm rounded bg-[#f6f6f6]'>
                {/* eslint-disable-next-line */}
                <a href={location?.attributes?.map_url_2} target="_blank" className='absolute h-full w-full top-0 left-0' rel="noreferrer" />
                <p className='text-secondary text-left w-full max-w-[74%] rtl:text-right mb-0'>{location?.attributes?.location_2}</p>
                <div className="w-full max-w-[25%]">
                  <NextImage src={location?.attributes?.icon} width={location?.attributes?.icon?.data?.attributes?.width} height={location?.attributes?.icon?.data?.attributes?.height} />
                </div>
              </div>}
              {/* additional content */}
              <div className='mt-md'>
                <HTMLRender data={location?.attributes?.content} />
              </div>
            </div>
          ))}
        </div>
      </div >
    </>
  );
};

export default CuLocations;
