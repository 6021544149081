import { useEffect, useRef } from "react";

import FooterApps from "../footer-apps";
import FooterCta from "../footer-cta";
import FooterDisclaimer from "../footer-disclaimer";
import FooterEntity from "../footer-entity";
import FooterLogo from "../footer-logo";
import FooterMenu from "../footer-menu";
import FooterPayments from "../footer-payments";
import FooterPress from "../footer-press";
import FooterSocials from "../footer-socials";
import { shallow } from "zustand/shallow";
import useActiveProductType from "@lib/store/hooks/useActiveProductType";
import useLayoutSharedData from "@lib/store/hooks/useLayoutSharedData";
import useModalHook from "@lib/modals";
import { useStore } from "@lib/store";

const Footer = () => {
  const { layoutSharedData, locale } = useLayoutSharedData();
  const navigation = layoutSharedData?.globalNavigation;
  const globalData = layoutSharedData?.globalData;

  const currentProductTypeKey = useStore((state) => state.currentProductTypeKey);
  const logo_url = globalData?.data?.website?.logo?.url;
  const footerData = globalData?.data?.website?.footer;
  const modal = useModalHook();

  //TODO ADD PRODUCT TYPE instead of [0]

  const store = useStore(
    (store) => ({
      page_key: store.page_key,
    }),
    shallow
  );

  const footerNavigation = navigation?.footer?.filter((el) => el.product_type === currentProductTypeKey);
  const menu = footerNavigation && footerNavigation.length > 0 ? footerNavigation[0].structure.tree : null;

  function filterData(attr) {
    return footerData?.filter((item) => item.hasOwnProperty(attr));
  }

  // Open ModalSessionExpiration if user is idle for 15min
  const inactivityTimeoutRef = useRef(null);

  useEffect(() => {
    const handleUserActivity = () => {
      clearTimeout(inactivityTimeoutRef.current);
      inactivityTimeoutRef.current = setTimeout(() => {
        modal.open("ModalSessionExpiration", { closeOnClickOutside: false, centered: true });
      }, 30 * 60 * 1000);
    };

    handleUserActivity();
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    return () => {
      clearTimeout(inactivityTimeoutRef.current);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  }, []);

  if (!footerData) return null;

  return (
    <footer className="-mt-[3rem] rounded-t-[20px] bg-[#111114] pt-xl lg:rounded-t-[50px] lg:pt-2xl">
      <div className="container">
        <FooterPayments data={filterData("images")} locale={locale}/>
        {/* container for socials and cta - left col, and menu - right col */}
        <div className="flex w-full flex-col justify-center border-b-[#7c7c7c] border-opacity-50 pb-xl pt-[2.1875rem] lg:flex-row lg:justify-between lg:border-b lg:pb-xs lg:pt-xl">
          {/* container for left col */}
          <div className="flex w-full flex-col lg:max-w-[43%]">
            <div className="w-full">
              <FooterLogo logo={logo_url} data={filterData("logo")} />
            </div>
            <div className="flex flex-col justify-between lg:flex-row">
              <div className="w-full lg:max-w-[45%] 2xl:max-w-[36%]">
                <FooterSocials data={filterData("socials")} />
              </div>
              <div className="w-full pt-xs lg:max-w-[50%]">
                <FooterEntity data={filterData("socials")} />
              </div>
            </div>
            <div className="my-xl hidden w-full lg:block">
              <FooterApps data={filterData("apps")} locale={locale}/>
            </div>
          </div>
          {/* container for right col */}
          <div className="w-full lg:max-w-[50%]">
            <FooterMenu data={menu} />
          </div>
        </div>
        <div className="pb-3xl lg:pb-3xl lg:pt-xl">
          <FooterDisclaimer data={filterData("disclaimer")} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
