import Arrow from '@components/elements/Arrow';
import SectionTitle from '@components/elements/SectionTitle';
import { Select } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import styles from './TradingCHero.module.css';

const TradingCHero = (props) => {
  const data = props.data || props;

  if (!data) return null;

  return (
    <>
      <div className='container'>
        <div className={styles.title}>
          <SectionTitle data={data?.section_title} />
        </div>

      </div>
    </>
  );
};

export default TradingCHero;
