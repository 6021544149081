import styles from './Arrow.module.css'
import PropTypes from 'prop-types';

export default function Arrow({direction}){
  return (
    <>
        <div className={`${styles.arrow} ${styles[`arrow-${direction}`]} `}></div>
    </>
  );
};
