import './FeaturedAcademyV2.module.css';
import AcademyLessonCardDesktop from "@components/custom/AcademyLessonCardDesktop";
import AcademyLessonCardMobile from "@components/custom/AcademyLessonCardMobile";
import Button from "@components/elements/Button";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import get from "lodash/get";
import { useRegistrationLink } from "@lib/store/hooks/useActiveProductType";
import SectionTitle from '@src/components/elements/SectionTitle';

const FeaturedAcademyV2 = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable();
  const registrationLink = useRegistrationLink();
  const academyCategories = get(data, "academy_categories.data", []);
  if (!data) return null;

  return (
    <div className="container">
      <SectionTitle data={data?.section_title} />
      {/* mobile version */}
      <div className="relative mb-xl block w-full overflow-hidden rounded-md border border-[#ebebeb] md:hidden">
        <div className="p-md">
          {academyCategories?.map((academyCategory, index) => (
            <AcademyLessonCardMobile data={academyCategory} index={index} key={index} />
          ))}
        </div>
      </div>
      {/* desktop version */}
      {/* get first three categories */}
      <div className="hidden flex-wrap justify-between md:flex">
        {academyCategories?.map((category, index) => (
          <div key={index} className="mb-xl max-w-[calc(33.33%-0.5em)]">
            <AcademyLessonCardDesktop data={category} />
          </div>
        ))}
      </div>
      {/* blue box */}
      <div className="flex flex-col items-center justify-between rounded-lg bg-secondary py-lg px-lg text-center text-white md:flex-row ltr:md:text-left rtl:md:text-right">
        <h4 className="md:mb-0">{data?.blue_box_text}</h4>
        <div>
          <Button type="white-borderless" size="lg" label={gv("create_account", "raw")} link={registrationLink} />
        </div>
      </div>
    </div>
  );
};

export default FeaturedAcademyV2;
