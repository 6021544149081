import HTMLRender from '@components/core/HTMLRender';
import ColumnImage from '@components/elements/ColumnImage';
import SectionTitle from '@components/elements/SectionTitle';
import styles from './SharesTools.module.css';

const SharesTools = (props) => {
  const data = props.data || props;
  if (!data) {
    return null;
  } return (
    <div className="container">
      <div className={styles.title}>
        <SectionTitle data={data?.section_title} />
      </div>
      <div className={`${styles.column}`}>
        <ColumnImage data={data?.column_image} />
      </div>
      <div className='hidden lg:block mt-[4.444rem]'>
        <ul className='flex'>
          {data.columns.map((column, index) => (
            <li key={index} className={styles.columnList}>
              <HTMLRender data={column.content} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SharesTools;
