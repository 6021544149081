export default (locale) => {
  switch (locale) {
    // cysec languages
    case "cz":
    case "eu":
    case "de":
    case "it":
    case "hu":
    case "pl":
    case "ro":
    case "es":
    case "el":
      return { url: "https://static.zdassets.com/ekr/snippet.js?key=30a479be-f87d-47e5-95ff-2116169c0941" };
    // adgm languages
    case "ae-ar":
    case "ae":
      return { url: "https://static.zdassets.com/ekr/snippet.js?key=822e18dc-bd62-4393-bfa5-d936885eede5" };
    // south africa (FSCA) languages
    case "za":
      return { url: "https://static.zdassets.com/ekr/snippet.js?key=de157e83-e2ac-4e05-a7fb-f3f6e25b941f" };
    // Seychelles (FSA) languages
    case "ar":
    case "en":
    case "lat":
    case "vn":
      return { url: "https://static.zdassets.com/ekr/snippet.js?key=3cb53f08-bb1e-4ecc-b80d-817dcb81201c" };
    default:
      return false;
  }
};
