import HTMLRender from '@components/core/HTMLRender';
import Arrow from '@components/elements/Arrow';
import Button from '@components/elements/Button';
import SectionTitle from '@components/elements/SectionTitle';
import { Accordion } from '@mantine/core';
import styles from './PartnersSoon.module.css';

const PartnersSoon = (props) => {
  const data = props.data || props;
  if (!data) return null;

  return (
    <div className='container'>
      {data?.section_title && <div className={`${styles?.title} mb-lg`}><SectionTitle data={data?.section_title} /></div>}
      <Accordion variant="unstyled" icon={<Arrow direction="down" />} iconSize={15} iconPosition="right" transitionDuration={500}
        classNames={{
          control: styles.accordionControl,
          itemOpened: styles.itemOpened,
          label: styles.accordionLabel,
          item: styles?.accordionItem,
          contentInner: styles?.contentInner,
          icon: styles?.accordionIcon
        }}>
        {data?.list?.map((item, index) => (
          <Accordion.Item key={index} label={<HTMLRender data={item?.title} />}>
            <div className={`${styles?.boxStyles}`}>
              <HTMLRender data={item?.content} />
            </div>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default PartnersSoon;
