import styles from './InvestHomeTools.module.css';
import NextImage from '@src/components/elements/Image';
import Button from '@src/components/elements/Button';
import { getStrapiMedia } from '@utils/media';
import HTMLRender from "@components/core/HTMLRender";

const InvestHomeTools = (props) => {
  const data = props.data || props;
  if (!data) return null;


  return (
    <div className={styles.InvestHomeTools}>
      <div className='container'>
        <div className={`flex flex-col  justify-between items-center ${data?.media_position === 'right' ? `lg:flex-row-reverse` : `lg:flex-row`}`}>
          <div className={`pb-lg lg:pb-0 flex-shrink-0 lg:w-[55%] ${data?.media_position === 'right' ? `ltr:xl:-mr-3xl rtl:xl:-ml-3xl` : `ltr:xl:-ml-3xl rtl:xl:-mr-3xl`}`}>
            {data?.media?.data?.attributes?.ext === ".mp4" ?
              <video className='h-auto  w-full ' muted playsInline autoPlay preload="none" loop >
                <source src={getStrapiMedia(data?.media?.data?.attributes?.url)} type="video/mp4" />
                <source src={getStrapiMedia(data?.media?.data?.attributes?.url)} type="video/ogg" />
                Your browser does not support the video tag.
              </video>
              :
              <NextImage
                src={data?.media}
                width={data?.media?.data?.attributes?.width}
                height={data?.media?.data?.attributes?.height}
              />
            }

          </div>
          <div className={`lg:w-[50%] ${data?.media_position === 'right' ? `ltr:pr-md rtl:pl-md` : `ltr:pl-md rtl:pr-md`}`}>
            {data?.tool_logo?.data?.attributes?.url &&
              <div className='pb-lg flex justify-center lg:justify-start'>
                <NextImage
                  src={data?.tool_logo}
                  width={data?.tool_logo?.data?.attributes?.width}
                  height={data?.tool_logo?.data?.attributes?.height}
                />
              </div>
            }
            {data?.text &&
              <div className='pb-lg'>
                <HTMLRender data={data?.text} />
              </div>
            }
            {data?.button.label &&
              <div className='flex justify-center lg:justify-start'>
                <Button data={data?.button} />
              </div>
            }
          </div>

        </div>
      </div>
    </div >
  );
};

export default InvestHomeTools;
