import GenericSlider from '@components/custom/GenericSlider';
import NextImage from '@components/elements/Image';
import { useDeviceDetect } from '@utils/hooks';
import { SwiperSlide } from 'swiper/react';
import './CareersSlider.module.css';

const CareersSlider = (props) => {
  const data = props.data || props;
  const device = useDeviceDetect()
  if (!data) return null;
  
  return (
    <>
      <div className={`${device === 'desktop' ? '' : 'container'}`}>
        <GenericSlider pagination spaceBetween={50} slidesPerView={device === 'desktop' ? 2 : 1} loop>
          {data?.images?.data?.map((image, index) => (
            <SwiperSlide>
              <div className='rounded-3xl overflow-hidden max-h-[10rem] md:max-h-[20rem] lg:max-h-[23rem]'>
                <NextImage src={image?.attributes?.url} width={image?.attributes?.width} height={image?.attributes?.height} />
              </div>
            </SwiperSlide>
          ))}
        </GenericSlider>
      </div>
    </>
  );
};

export default CareersSlider;
