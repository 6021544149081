import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';
import List from '@components/elements/List';
import SectionTitle from '@components/elements/SectionTitle';
import { Accordion, Tabs } from '@mantine/core';
import { useState } from 'react';
import styles from './ClientTabs.module.css';

const ClientTabs = (props) => {
  const data = props.data || props;
  const [activeTab, setActiveTab] = useState(0);

  if (!data) return null;

  return (
    <div className='container'>
      {data?.section_title && <SectionTitle data={data?.section_title} />}
      <div className="hidden md:block">
        <Tabs
          active={activeTab}
          onTabChange={setActiveTab}
          position='left'
          variant="unstyled" grow
          classNames={{
            root: styles.tabsRoot,
            tabsList: styles.tabsList,
            tabsListWrapper: styles.wrapper,
            tabControl: styles.tabControl,
            tabActive: styles.tabActive,
            tabLabel: styles.tabLabel,
            tabInner: styles.tabInner,
            body: styles.tabsBody,
          }}>
          {
            data?.tabs?.map((tab, index) => (
              <Tabs.Tab key={index} label={<p>{tab?.title}</p>}>
                <div className='flex flex-col justify-between'>
                  <div className='flex flex-col md:flex-row justify-between items-center'>
                    <div className='flex flex-col  w-full md:max-w-[calc(50%-2rem)]'>
                      <div className={`${styles?.content}`}>
                        <HTMLRender data={tab?.content} />
                      </div>
                      <div className='pt-md'>
                        <List type='checked' list_items={tab?.list} />
                      </div>
                    </div>
                    <div className="w-full md:max-w-[calc(50%-2rem)]">
                      <NextImage src={tab?.image} width={tab?.image?.data?.attributes?.width} height={tab?.image?.data?.attributes?.height} />
                    </div>
                  </div>
                  <div className={`relative mt-xl w-full flex justify-center ${styles.mobileButton}`}>
                    <Button data={data?.button} />
                  </div>
                </div>
              </Tabs.Tab>
            ))
          }
        </Tabs>
      </div>
      <div className='block md:hidden mt-lg'>
        <Accordion initialItem={0} variant="unstyled" iconSize={0} transitionDuration={500}
          classNames={{
            control: styles.accordionControl,
            accordionLabel: styles.accordionLabel,
            itemOpened: styles.itemOpened,
            label: styles.accordionLabel,
          }}>
          {data?.tabs?.map((tab, index) => (
            <Accordion.Item key={index} label={<p>{tab?.title}</p>}>
              <div className='flex flex-col justify-between'>
                <div className='flex flex-col md:flex-row justify-between items-center'>
                  <div className='flex flex-col  w-full md:max-w-[calc(50%-2rem)]'>
                    <div className={`${styles?.content}`}>
                      <HTMLRender data={tab?.content} />
                    </div>
                    <div className='pt-md text-grey'>
                      <List type='checked' list_items={tab?.list} />
                    </div>
                  </div>
                </div>
                <div className={`relative mt-xl ${styles.mobileButton}`}>
                  <Button data={data?.button} />
                </div>
              </div>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default ClientTabs;
