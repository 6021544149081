import HTMLRender from '@components/core/HTMLRender';
import './StepSlider.module.css'

const StepSlider = ({ stepColor = 'secondary', data = [], index, text }) => {
  if (data.length > 0) {
    return (
      <div className="md:hidden">
        <div key={index} className="relative flex grow  basis-1 flex-col items-center">
          <div className="mb-md">
            <div
              className={`border-${stepColor} text-${stepColor} z-1 p-md relative flex h-[1.6em] w-[1.6em] items-center justify-center rounded-full border-2 bg-white text-[1.75rem]`}
            >
              {index + 1}
            </div>
          </div>
          <div className="px-sm mb-xl text-center">
            <HTMLRender data={text} />
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default StepSlider;
