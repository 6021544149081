import styles from './WebinarHeroSingle.module.css';
import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';
import useModalHook from '@lib/modals';
import useGlobalVariable from '@lib/store/hooks/useGlobalVariables';
import { SwiperSlide } from 'swiper/react';
import SectionTitle from '@src/components/elements/SectionTitle';
import HTMLRender from '@src/components/core/HTMLRender';

const WebinarHeroSingle = (props) => {
  const data = props.data || props;
  const { gv } = useGlobalVariable();
  const featuredAnalysts = data?.analysts?.data.slice(0, 1) || [];
  const modal = useModalHook();


  if (!data) return null;

  return (
    <div className='container pt-xl lg:pt-0 overflow-hidden'>
      {/* Desktop Version */}
      <div className="hidden md:block">
        <SectionTitle data={data?.section_title} />
        <div className="flex justify-between items-end">
          <div className={`${styles.content} w-full max-w-[55%]`}>
            <HTMLRender data={data?.content} />
          </div>
          {featuredAnalysts.map((analyst, index) => (
            <div
              key={index}
              className="relative z-10"
            >
              <NextImage
                src={analyst?.attributes?.image_variant_b}
                width={
                  analyst?.attributes?.image_variant_b?.data
                    ?.attributes?.width
                }
                height={
                  analyst?.attributes?.image_variant_b?.data
                    ?.attributes?.height
                }
              />
            </div>
          ))}
        </div>
        <div
          className={`${styles.textWrapper}`}
        >
          <div className='container flex'>
            <Button
              onClick={() => {
                modal.open('analystModal', {
                  innerProps: featuredAnalysts[0],
                });
              }}
              arrow="none"
              label={gv('read_more_about', {
                template: {
                  about:
                    featuredAnalysts[0]?.attributes?.name.split(' ')[0] || '',
                },
              })}
              size="sm"
              type="link"
            />
          </div>
        </div>
      </div>
      {/* Mobile Version */}
      <div className='md:hidden'>
        <div className='flex flex-col'>
          <div className='relative flex justify-between mb-md'>
            <div className={`${styles.title} w-full max-w-[70%] pb-lg`}>
              <SectionTitle data={data?.section_title} />
            </div>
            <div className='w-full max-w-[55%] absolute bottom-0 ltr:right-[-20%] rtl:left-[-20%]'>
              {featuredAnalysts.map((analyst, index) => (
                <div
                  key={index}
                  className="relative z-10"
                >
                  <NextImage
                    src={analyst?.attributes?.image_variant_b}
                    width={
                      analyst?.attributes?.image_variant_b?.data
                        ?.attributes?.width
                    }
                    height={
                      analyst?.attributes?.image_variant_b?.data
                        ?.attributes?.height
                    }
                  />
                </div>
              ))}
            </div>
            <div
              className={`${styles.textWrapper} -mx-md`}
            >
              <div className='container flex'>
                <Button
                  onClick={() => {
                    modal.open('analystModal', {
                      innerProps: featuredAnalysts[0],
                    });
                  }}
                  arrow="none"
                  label={gv('read_more_about', {
                    template: {
                      about:
                        featuredAnalysts[0]?.attributes?.name.split(' ')[0] || '',
                    },
                  })}
                  size="sm"
                  type="link"
                />
              </div>
            </div>
          </div>
          <div className='pb-md'>
            <div className='py-md'>
              <HTMLRender data={data?.section_title?.subtitle} />
            </div>
            <HTMLRender data={data?.content} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebinarHeroSingle;
