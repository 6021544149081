import HTMLRender from '@components/core/HTMLRender';
import Button from '@components/elements/Button';
import NextImage from '@components/elements/Image';
import styles from './GenericHero.module.css';
import { VscQuestion } from "@react-icons/all-files/vsc/VscQuestion";
import Link from 'next/link';

const GenericHero = (props) => {
  const data = props.data || props
  if (!data) {
    return null;
  } return (
    <div className="relative lg:pt-[5.55rem]">
      {data?.image?.data &&
        <div className={`${styles.image} lg:absolute ${data?.section_layout?.section_id === 'cryptocurrencies-hero' || data?.section_layout?.section_id === 'commodities-hero' ? 'top-[15%]' : data?.section_layout?.section_id === 'forex-hero' ? '-top-[20%]' : 'top-0'} ltr:right-0 rtl:left-0 bottom-0 ${data?.section_layout?.section_id === 'stox-hero' ? 'ltr:left-[calc(50%+3.33rem)] rtl:right-[calc(50%+3.33rem)] top-[15%]' : 'ltr:left-[calc(45%+3.33rem)] rtl:right-[calc(45%+3.33rem)]'}`}>
          <NextImage src={data?.image} height={data?.image?.data?.attributes?.height} width={data?.image?.data?.attributes?.width} />
        </div>
      }
      <div className='container md:pb-xl lg:pb-0'>
        <div className={`flex w-full ${data?.section_layout?.image?.data && styles.whiteText}`}>
          <div className='lg:w-[23rem] w-full flex flex-col'>
            <div className='text-center ltr:md:text-left rtl:md:text-right flex flex-col justify-start md:mb-xl'>
              <div className='flex justify-center md:justify-start mb-xl'>
                <h1 className='relative mb-0 text-[1.666rem]'>
                  {data?.title}
                  {
                    data?.intro_section &&
                    <Link href="#introduction">
                      <a className='px-[0.25rem] opacity-70'>
                        <VscQuestion className='text-2xl inline font-extralight relative top-[-0.25rem]' />
                      </a>
                    </Link>
                  }
                </h1>
              </div>
              <div className='hidden md:block'><HTMLRender data={data?.content} /></div>
            </div>
            <div className='hidden md:block text-center ltr:lg:text-left rtl:lg:text-right'>
              {!data.button_text ? null : <Button arrow="right" type="white-transparent" size="lg" link={data?.button_url} label={data?.button_text} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericHero;
