import LanguageSelector from "@components/custom/LanguageSelector";
import styles from "./footer-entity.module.css";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import useLayoutSharedData from "@lib/store/hooks/useLayoutSharedData";

const FooterEntity = ({ data = false }) => {
  const { layoutSharedData } = useLayoutSharedData();
  const websiteLanguages = layoutSharedData?.languages;
  const websiteLanguagesPerRegion = websiteLanguages?.data?.reduce((r, a) => {
    r[a.attributes.region] = [...(r[a.attributes.region] || []), a];
    return r;
  }, {});

  const { gv } = useGlobalVariable();

  if (!data) return null;

  return (
    <div className="relative z-10 -mx-[1.111rem] px-[1.111rem] lg:m-0 lg:p-0 lg:px-0">
      <div className="mb-lg flex flex-row items-center justify-center text-white lg:mt-0 lg:flex-col">
        <div>
          <p className="text-center text-xs tracking-[1px] text-[#999999]">{gv("reserved_rights")}</p>
        </div>
        <div className="lg:pt-sm ltr:lg:pl-[2.25rem] rtl:lg:pr-[2.25rem]">
          <LanguageSelector
            containerId="lang_selector_container_footer"
            langSelectorId="lang_selector_footer"
            placement="center"
            websiteLanguagesPerRegion={websiteLanguagesPerRegion}
            websiteLanguages={websiteLanguages}
            grid
            triggerText="Language"
            triggerBg
          />
        </div>
      </div>
    </div>
  );
};

export default FooterEntity;
