import useSetProductType from "@lib/store/hooks/useActiveProductType";
import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import NextImage from "@components/elements/Image";
import styles from "./MobileProductTabs.module.css";
import NextLink from "@src/components/elements/Link";
import useLocale from "@lib/store/hooks/useLocale";
import useLayoutSharedData from "@lib/store/hooks/useLayoutSharedData";
import { useStore } from "@lib/store";
import { useEffect, useState } from 'react';

// TODO - ask images from design & get them from pageprops

const MobileProductTabs = ({ props }) => {
  const productTypes = useStore((state) => state.productTypes);
  const currentProductTypeKey = useStore((state) => state.currentProductTypeKey);
  const { changeProductType } = useSetProductType();
  const { gv } = useGlobalVariable();

  const sortedProductTypes = productTypes.sort((a, b) => {
    const orderA = a.attributes?.order || 9999; // Default value to 9999 if "order" key does not exist
    const orderB = b.attributes?.order || 9999; // Default value to 9999 if "order" key does not exist

    return orderA - orderB;
  });

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    // Prevent rendering until the component is mounted on the client side
    return null;
  }


  return (
    <div
      className={`relative flex rounded-lg ${styles[`product-tabs`]} flex pb-lg pt-[4em] ${sortedProductTypes.length < 3 ? `justify-center` : ` justify-between`
        }`}
    >
      {sortedProductTypes?.map((product, index) =>
        product?.attributes?.availability_status !== "soon" ? (
          <NextLink
            key={index}
            href={product?.attributes?.product_homepage?.data?.attributes?.slug}
            onClick={() => changeProductType(product?.attributes?.product_type)}
            className={`relative ${currentProductTypeKey === product?.attributes?.product_type ? styles.active : ""} ${product?.attributes?.availability_status === "soon" ? `pointer-events-none text-lightGrey` : ``
              } ${sortedProductTypes.length < 3 ? `mx-sm` : ` mx-0`} ${styles.productItem} `}
          >
            <>
              <div className="relative h-[7.125em] w-[5.8em]">
                <NextImage src={product?.attributes?.logo?.data?.attributes?.url} />
              </div>
              <span>{product?.attributes?.website_name}</span>
            </>
          </NextLink>
        ) : (
          <span
            key={index}
            className={`pointer-events-none relative text-lightGrey ${sortedProductTypes.length < 3 ? `mx-sm` : ` mx-0`
              } ${styles.productItem} `}
          >
            <div className="relative h-[7.125em] w-[5.8em]">
              <NextImage src={product?.attributes?.logo?.data?.attributes?.url} />
            </div>
            <span>{product?.attributes?.website_name}</span>

            {product?.attributes?.availability_status === "soon" && (
              <strong className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 -rotate-[11deg] rounded-md bg-primary px-[0.6em] py-[0.3em] text-[1em] font-normal leading-[1] text-white ltr:font-body rtl:font-cairo">
                {gv("soon")}
              </strong>
            )}
          </span>
        )
      )}
    </div>
  );
};

export default MobileProductTabs;
