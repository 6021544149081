import get from "lodash/get";
/* eslint-disable no-restricted-syntax */
import { match } from "path-to-regexp";
import { memoizedPatterns } from "../default";
import { strapi } from "lib/strapi";
import { PHASE_PRODUCTION_BUILD } from "next/constants";
import fs from "fs/promises";
import path from "path";

export async function getLayoutSharedData(locale) {
  return await strapi.get({
    api: "pages/layout-shared-data",
    query: {
      locale: locale,
    },
  });
}

export async function getInstruments(locale) {
  return await strapi.getCached({
    api: "instruments",
    query: {
      locale: locale,
      populate: "all",
    },
  });
}
export async function getSitemap(config) {
  return await strapi.get({
    api: "pages/sitemap",
    query: {
      ...config,
    },
  });
}
export async function getGlobals(locale) {
  return await strapi.getCached({
    api: "dataglobals/data",
    query: {
      locale: locale,
      populate: "all",
    },
  });
}
export async function getGlobalVariables(locale) {
  return await strapi.getCached({
    api: "json/global-variables.json",
    query: {
      locale: locale,
    },
  });
}

export async function getNavigation(locale) {
  return await strapi.getCached({
    api: "navigations/navigation",
    query: {
      locale: locale,
      populate: "all",
    },
  });
}

export async function getSlide(locale) {
  return await strapi.get({
    api: "home-slides",
    query: {
      populate: "*",
    },
  });
}

export async function getNotifications(locale) {
  return await strapi.getCached({
    api: "notifications",
    query: {
      locale: locale,
      populate: "image",
      fields: ["title", "slug", "message", "duration"],
    },
  });
}

export async function getModals(locale) {
  return await strapi.get({
    api: "modals",
    query: {
      locale: locale,
      populate: "*",
    },
  });
}

export async function getPagePaths(locale) {
  return await strapi.get({
    api: "pages/path",
    query: {
      locale: locale,
    },
  });
}
export async function getAllCollectionNameAPI() {
  return await strapi.get({
    api: "pages",
    query: {
      populate: ["localizations", "child_nested_page"],
    },
  });
}

export async function fetchPagesAPI(locale) {
  return await strapi.get({
    api: "pages",
    query: {
      locale: locale,
      fields: ["slug", "locale"],
    },
  });
}

export async function fetchProductTypes(locale) {
  return await strapi.get({
    api: "api/product-types/get-all",
    query: {
      locale: locale,
    },
  });
}

/**
 *
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
export async function getPageData(params) {
  const slug = params.slug;
  const pageData = await strapi.get({
    api: "pages",
    query: {
      filters: {
        slug: slug === "/" ? slug : slug,
      },
      locale: params.locale,
      populate: "all",
    },
  });
  return pageData.data[0];
}

// Get site data from Strapi (metadata, navbar, footer...)
export async function getGlobalData(locale) {
  return await strapi.getCached({
    api: "global",
    query: {
      locale: locale,
    },
  });
}

export async function getPageDataAPI({ slug, api, locale, type, params }) {
  const populate =
    type === "article"
      ? {
          image: "*",
          articles_category: {
            populate: {
              nested_page: {
                populate: "*",
              },
              page: {
                populate: {
                  child_nested_page: "*",
                },
              },
            },
          },
        }
      : "*";
  const collectionData = await strapi.get({
    api: api,
    query: {
      filters: {
        slug: api == "pages" ? "/" + slug.join("/") : "/" + slug[slug.length - 1],
      },
      locale: locale,
      populate: type === "page" || type === "legal-documents" ? "all" : populate,
    },
  });
  let pageSeo = null;
  if (type != "page") {
    pageSeo = await strapi.get({
      api: "pages",
      query: {
        filters: {
          slug: "/" + params.slice(0, params.length - 1).join("/"),
        },
        locale: locale,
        populate: {
          seo: "*",
        },
      },
    });
  }
  if (pageSeo != null && collectionData.data && collectionData.data.length > 0) {
    return {
      data: collectionData.data[0],
      variablesUsed: collectionData.data[0]?.variablesUsed || [],
      pageSeo: pageSeo.data[0]?.attributes?.seo,
    };
  }
  return {
    ...collectionData,
  };
}

/*
 * Get all the pages from Strapi
 * @param {string} locale The current locale
 * @param {string} slug The current slug
 *
 * @returns {object} The page data
 */
export async function validateRequestUrl(locale, slug) {
  let urlPatterns = await memoizedPatterns(locale);
  slug = slug[0] === "/" ? slug : "/" + slug;
  let fn, r, currentPage;
  for (let page of urlPatterns) {
    currentPage = page;
    if (!page.slug || page.slug === null) {
      r = false;
      continue;
    }
    fn = match(page.slug);
    r = fn(slug);
    if (r !== false) break;
  }
  if (r === false) return null;
  return {
    r,
    page: {
      type: currentPage.type,
      pagination: currentPage?.pagination || false,
      id: currentPage.id,
      hide_on_production: currentPage.hide_on_production,
    },
  };
}

export async function getCurrentLanguage(locale) {
  const data = await fetch(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/languages?populate[flag]=*&locale=${locale}`)
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
      return null;
    });
  return get(data, "data.[0].attributes", null);
}

export async function getLanguages() {
  const data = await fetch(
    `${process.env.NEXT_PUBLIC_API_STRAPI}/api/languages?locale=all&populate[flag]=*&build_id=${process.env.NEXT_PUBLIC_BUILD_ID}`
  )
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
      return null;
    });
  return data;
}

export async function getUrlPatterns(locale) {
  let urlPatterns = [];
  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    urlPatterns = await fs.readFile(path.join(process.cwd(), `./build-data/${locale}-url-patterns.json`));
    try {
      urlPatterns = JSON.parse(urlPatterns);
    } catch (error) {
      console.error("Error parsing url patterns:", error);
      return [];
    }
  } else {
    urlPatterns = await fetch(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/utils/get-url-patterns/${locale}`)
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return [];
      });
  }
  return urlPatterns;
}

export async function fetchPagePaths(locale) {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/pages/path?locale=${locale}`);
    const paths = await response.json();
    const formattedPaths = paths.map((path) => ({
      params: {
        slug: path?.params?.page[0].split("/").filter((p) => p !== ""),
      },
    }));
    return { paths: formattedPaths, fallback: "blocking" };
  } catch (error) {
    console.log(error);
    return { paths: [], fallback: "blocking" };
  }
}

export async function fetchPageData(query) {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_STRAPI}/api/page?${query}`);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Failed to fetch page data:", error);
    return null;
  }
}
